/**
 * Checks to see if resident is the current resident, if not returns null
 *
 * @param {object} opts
 * @param {array} opts.resident
 * @param {string} opts.state
 * @param {string} opts.city
 * @param {string} opts.streetName
 * @param {string} opts.streetNum
 * @param {string} opts.defaultValue
 * @returns {array|null}
 */
const getCurrentResident = (opts = {}) => {
  if (!opts || !opts.resident) {
    return opts?.defaultValue ?? null;
  }

  if (
    opts.resident.state === opts.state &&
    opts.resident.city === opts.city &&
    opts.streetName.includes(opts.resident.street_name) &&
    opts.resident.street_number == opts.streetNum
  ) {
    return opts.resident;
  }
  return null;
};

export default getCurrentResident;

import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import getRegex from '@/assets/js/shared/helpers/getRegex';

/**
 * Address Page Redirect Middleware
 * @param route
 * @param redirect
 * @returns {*}
 */
export default function({ route, redirect }) {
  const { params, fullPath, query } = route;

  let redirectParams = {};
  const hasValidPage = params.page && params.page <= 10;
  const hasQuery = route.query && Object.keys(route.query).length > 0;

  const redirectStreet = setTitleCase({
    text: params.street.replace('-', ' ')
  }).replace(' ', '-');
  const redirectCity = setTitleCase({
    text: params.city.replace(getRegex({ regexType: 'underscoreRegEx' }), ' ')
  }).replace(getRegex({ regexType: 'spaceRegEx' }), '_');
  const redirectState = params.state.toUpperCase();
  let redirectPath = `/address/${redirectStreet}--${redirectCity}-${redirectState}-${params.zipcode}/`;

  if (hasValidPage) {
    redirectParams.page = params.page;
    redirectPath = redirectPath + redirectParams.page + '/';
  }

  if (hasQuery) {
    redirectPath = `${redirectPath}?${new URLSearchParams(query)}`;
    return redirect(301, redirectPath);
  }

  if (fullPath !== redirectPath) {
    return redirect(301, redirectPath);
  }

  if (fullPath[fullPath.length - 1] !== '/') {
    return redirect(301, redirectPath + '/');
  }
}

/**
 * Return a string with requested number of masking symbols
 *
 * @param {object} opts
 * @param {number} opts.number
 * @param {string} opts.masking
 * @returns {string}
 */
const getMaskingString = (opts = {}) => {
  let finalString = '';

  if (!opts.number || !opts.masking) {
    return finalString;
  }

  for (let i = 0; i < opts.number; i++) {
    finalString += opts.masking;
  }

  return finalString;
};

export default getMaskingString;

import getStates from './getStatesList';
import getStateAbbrFromName from './getStateAbbrFromName';
/**
 * Gets states arranged as an array of objects with props name and value for a dropdown/select element
 * @param {object} opts
 * @param {array} opts.selectStates
 * @param {boolean} opts.useStateAbbrAsValue
 * @param {boolean} opts.includeAllStatesOption
 * @param {string} opts.allStatesDisplayText
 * @param {string} opts.isAllStatesDisabled
 * @param {string} opts.isAllStatesSelected
 * @param {boolean} opts.hasTerritories
 * @param {default} opts.default
 */

const getStatesForDropdown = (opts = {}) => {
  const statesArray = [];
  if (opts.selectStates && typeof opts.selectStates !== 'array') {
    opts.selectStates.forEach(state => {
      if (state.displayName && state.displayName !== 'All States') {
        statesArray.push({
          value: getStateAbbrFromName({ state: state.displayName }),
          name: state.displayName
        });
      }
    });
  } else {
    const states = getStates({ hasTerritories: opts.hasTerritories });
    for (const property in states) {
      statesArray.push({
        value: opts.useStateAbbrAsValue
          ? property
          : states[property].replace(/ /g, '-').toLowerCase(),
        name: states[property]
      });
    }
  }
  if (opts.includeAllStatesOption) {
    const stateObjToAdd = {
      value: '',
      name: opts.allStatesDisplayText ?? 'All States'
    };
    if (!!opts.isAllStatesSelected) {
      stateObjToAdd.selected = true;
    }
    if (!!opts.isAllStatesDisabled) {
      stateObjToAdd.disabled = true;
    }
    statesArray.unshift(stateObjToAdd);
  }
  return statesArray;
};

export default getStatesForDropdown;

import hasOtherLocations from './hasOtherLocations';

/**
 * Returns formatted locations list
 *
 * @param {object} opts
 * @param {array} opts.locationList
 * @param {number} opts.displayCount
 * @param {string} opts.defaultValue
 * @returns {string}
 */
const formattedLocations = (opts = {}) => {
  // Early exit if no locationList
  if (!opts || !opts.locationList || !opts.locationList.length) {
    return opts?.defaultValue ?? '';
  }
  const displayCount = opts.displayCount || 3;
  const { locationList } = opts;
  const locations = locationList.slice(0, displayCount);
  const locationsLength = locations.length;
  const isOnlyOneItem = locationsLength === 1;
  const hasOther = hasOtherLocations({ locationList }); // Check if there are other locations

  // If only one location, early exit and return the name
  if (isOnlyOneItem) {
    return locations[0].name;
  }

  let list = '';
  // Grab all locations from array
  for (let i = 0; i < locationsLength; i++) {
    const isLastItem = i === locationsLength - 1;
    // Space should be added for lists with only two items, or for first and second item only in least of three total
    const addSpace = locationsLength === 2 || (!hasOther && i === 1);
    // Only add starter of "and" for last item with no other locations
    const starter = isLastItem && !hasOther ? 'and ' : '';
    // End with nothing for last item, space if qualifies, otherwise comma and space
    const delineator = isLastItem ? '' : addSpace ? ' ' : ', ';
    // Add to string of locations
    list += `${starter}${locations[i].name}${delineator}`;
  }

  return list;
};

export default formattedLocations;

import getSubset from '../helpers/getSubset';
import getFormattedNonNullObject from '../helpers/getFormattedNonNullObject';
import formatStringAsUsDate from '../helpers/formatStringAsUsDate';
import getFormattedMultilineAddressOrUnknown from '../helpers/getFormattedMultilineAddressOrUnknown';
import getAge from '../helpers/getAge';
import formatMoney from '../helpers/formatMoney';

/**
 *
 * @param {object} opts (report)
 * @param {object} opts.crimes
 * @param {string} opts.default
 */
const getFormattedCriminalHistory = opts => {
  if (!opts || !opts.crimes || !opts.crimes.length) {
    return opts?.default || [];
  }

  let crimesList = [];

  const crimes = opts.crimes.map(i => {
    let main = {},
      details = {},
      offense = {};

    main = getSubset({
      object: i,
      paths: ['when', 'description', 'state']
    });

    main.when = formatStringAsUsDate({
      string: main.when,
      defaultValue: 'Unknown'
    });

    //use charges_filed_date if no arrest date listed
    if (!main.when && i.extra?.crime?.charges_filed_date)
      main.charges_filed_date = formatStringAsUsDate({
        string: i.extra.crime.charges_filed_date
      });

    main = getFormattedNonNullObject({
      obj: main,
      defaultValue: 'Unknown',
      setTitleCase: true
    });

    details = getSubset({
      object: i,
      paths: [
        'extra.crime.suspect.street',
        'extra.crime.suspect.city',
        'extra.crime.suspect.state',
        'extra.crime.suspect.zip',
        'extra.crime.suspect.dob',
        'extra.crime.suspect.extra.entity.is_sex_offender',
        'extra.crime.suspect.extra.entity.ethnicity',
        'extra.crime.suspect.extra.entity.gender',
        'sexoffender'
      ]
    });

    let isSexOffender = () => {
      if (details.is_sex_offender != undefined) return details.is_sex_offender;
      else if (details.sexoffender != undefined) {
        switch (details.sexoffender) {
          case true:
            return 'YES';
          case false:
            return 'NO';
          default:
            return null;
        }
      } else return null;
    };
    details.sex_offender = isSexOffender();
    details.dob = formatStringAsUsDate({
      string:
        details.dob && details.dob !== '1600-01-01' && details.dob !== 'Unknown'
          ? details.dob
          : opts.dates_of_birth &&
            opts.dates_of_birth.length &&
            opts.dates_of_birth[0].dob
          ? opts.dates_of_birth[0].dob
          : 'Unknown'
    });
    const reportAge = getAge({
      dateString: details.dob
    });
    details.age = reportAge ? reportAge.toString() : null;

    details.address = getFormattedMultilineAddressOrUnknown({
      address: details,
      defaultAddress: 'Unknown'
    });
    details.gender =
      details.gender ||
      (opts?.traits.length && opts?.traits[0].gender) ||
      'Unknown';
    details.ethnicity =
      details.ethnicity ||
      (opts?.traits.length && opts?.traits[0].ethnicity) ||
      'Unknown';
    details = getFormattedNonNullObject({
      obj: details,
      defaultValue: 'Unknown',
      skipCasingKeys: ['address'],
      setTitleCase: true
    });
    delete details.street;
    delete details.city;
    delete details.state;
    delete details.zip;
    delete details.is_sex_offender;

    offense = Object.assign(
      {},
      getSubset({ object: i, paths: ['extra.crime'] })
    );

    offense = offense.crime ?? offense;
    offense = {
      ...offense,
      agency: i.agency,
      source: offense.source_name,
      crime_type: offense.case_type ?? i.crimetype,
      case_number: i.casenumber,
      disposition: i.disposition,
      plea: i.plea,
      crime_location: i.county ?? offense.crime_county,
      court_name: offense.court,
      court_costs: formatMoney({ number: offense.court_costs })
    };

    if (offense) {
      for (const [key, value] of Object.entries(offense)) {
        if (value) {
          if (typeof key === 'string' && key.indexOf('date') !== -1) {
            const date = formatStringAsUsDate({
              string: value
            });
            offense[key] = date;
          }
        }
      }

      offense.location = `${
        offense.crime_location ? offense.crime_location : 'Unknown'
      }${offense.source_state ? ', ' + offense.source_state : ''}`;

      offense = getFormattedNonNullObject({
        obj: offense,
        defaultValue: 'Unknown',
        setTitleCase: true
      });

      delete offense.victim;
      delete offense.source_name;
      delete offense.suspect;
      delete offense.criminal_detail_items;
      delete offense.match_indicators;
      delete offense.crime_county;
      delete offense.case_type;
    }

    return { main, details, offense };
  });

  // Dedupe crimes by case number and state
  crimes.forEach(crime => {
    let match = crimesList.find(
      item =>
        item.offense.case_number === crime.offense.case_number &&
        item.main.state === crime.main.state
    );
    // combine data objects
    if (match) {
      crime = { ...match, ...crime };
      let index = crimesList.indexOf(match);
      crimesList.splice(index, 1);
    }
    crime.main.sortDate = new Date(
      crime.main.charges_filed_date ?? crime.main.when
    );
    crimesList.push(crime);
  });
  crimesList.sort((a, b) => {
    return b.main.sortDate - a.main.sortDate;
  });
  crimesList.forEach(crime => {
    delete crime.main.sortDate;
  });

  return crimesList;
};

export default getFormattedCriminalHistory;

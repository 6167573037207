<template>
  <div class="ic-modal-body">
    <h2 class="ic-modal-body__title">{{ title }}</h2>
    <form
      class="ic-modal-body__form"
      @submit="updatePassword"
      method="POST"
      autocomplete="on"
    >
      <div
        class="ic-modal-body__form-fields ic-modal-body__form-fields--full-width"
      >
        <div class="ic-modal-body__form-field">
          <label
            for="newPassword"
            class="ic-modal-body__form-label"
            v-text="'New Password'"
          />
          <input
            type="password"
            id="newPassword"
            name="new_password"
            class="ic-modal-body__form-input"
            :class="{
              'ic-modal-body__form-input--error': error.newPassword || didError
            }"
            v-model="newPassword"
            maxlength="30"
            @input="removeError"
            required
          />
          <p class="ic-modal-body__error-message" v-if="message.newPassword">
            {{ message.newPassword }}
          </p>
          <p class="ic-modal-body__error-message" v-if="errorMessage">
            {{ errorMessage }}
          </p>
        </div>
        <div class="ic-modal-body__form-field">
          <label
            for="confirmPassword"
            class="ic-modal-body__form-label"
            v-text="'Confirm Password'"
          />
          <input
            type="password"
            id="confirmPassword"
            name="confirm_password"
            class="ic-modal-body__form-input"
            :class="{
              'ic-modal-body__form-input--error':
                error.confirmPassword || didError
            }"
            v-model="confirmPassword"
            maxlength="30"
            @input="removeError"
            required
          />
          <p
            class="ic-modal-body__error-message"
            v-if="message.confirmPassword"
          >
            {{ message.confirmPassword }}
          </p>
        </div>
      </div>
      <div class="ic-modal-body__form-buttons">
        <button
          type="submit"
          class="ic-modal-body__form-button"
          :class="{ 'ic-modal-body__form-button--loading': isSubmitting }"
          v-text="submitButtonText"
          :disabled="isSubmitting"
        />
        <button
          class="ic-modal-body__form-button ic-modal-body__form-button--cancel"
          :disabled="isSubmitting"
          v-touch:tap="closeModal"
          v-text="cancelButtonText"
        />
      </div>
    </form>
  </div>
</template>

<script>
import getRegex from '@/assets/js/shared/helpers/getRegex';

export default {
  name: 'ic-edit-password',
  props: {
    // Text to display for title
    title: {
      type: String,
      required: true
    },
    // Text to display for the submit button
    submitButtonText: {
      type: String,
      required: true
    },
    // Text to display for the cancel button
    cancelButtonText: {
      type: String,
      required: true
    },
    // Checks if form is being submitted
    isSubmitting: {
      type: Boolean,
      required: true
    },
    // Checks if form failed to submit
    didError: {
      type: Boolean,
      required: true
    },
    // Error message when it fails to submit
    errorMessage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      isPasswordValid: null,
      doesPasswordMatch: null,
      error: {
        newPassword: false,
        confirmPassword: false
      },
      message: []
    };
  },
  methods: {
    closeModal() {
      this.$emit('ic-edit-password-close-modal');
    },
    /**
     * Removes error message and red border when users re-types in the field
     *
     * @param event
     */
    removeError(event) {
      const fieldName = event.target.name;

      if (fieldName === 'new_password' && this.error.newPassword) {
        this.error.newPassword = false;
        this.message['newPassword'] = '';
      }

      if (fieldName === 'confirm_password' && this.error.confirmPassword) {
        this.error.confirmPassword = false;
        this.message['confirmPassword'] = '';
      }
    },
    /**
     * Checks for password validation and conformation before emitting the event
     *
     * @param event
     */
    updatePassword(event) {
      event.preventDefault();

      if (!this.validateNewPassword()) {
        this.isPasswordValid = false;
        this.error.newPassword = true;
        this.message['newPassword'] =
          'Password should contain at least eight (8) characters, including one (1) number, one (1) lowercase character, one (1) uppercase character & one (1) special character.';
      } else {
        this.isPasswordValid = true;
        this.error.newPassword = false;
        this.message['newPassword'] = '';
      }

      if (!this.verifyConfirmPassword()) {
        this.doesPasswordMatch = false;
        this.error.confirmPassword = true;
        this.message['confirmPassword'] = 'Password does not match';
      } else {
        this.doesPasswordMatch = true;
        this.error.confirmPassword = false;
        this.message['confirmPassword'] = '';
      }

      if (this.isPasswordValid && this.doesPasswordMatch) {
        this.$emit('ic-edit-password-update', this.confirmPassword);
      }
    },
    validateNewPassword() {
      return getRegex({ regexType: 'passwordRegex' }).test(this.newPassword);
    },
    verifyConfirmPassword() {
      return this.confirmPassword === this.newPassword;
    }
  }
};
</script>

import formatNameCaseWithSplitterAndSpacer from '../helpers/formatNameCaseWithSplitterAndSpacer';

/**
 * Returns employer company name or unknownText
 *
 * @param {object} opts
 * @param {object} opts.employer
 * @param {string} opts.default
 * @returns {string}
 */
const getEmploymentCompany = opts => {
  if (!opts || !opts.employer) {
    return opts.default || 'Unknown';
  }
  // Accommodate both key names
  let businessName = opts.employer.businessname || opts.employer.business_name;
  return businessName
    ? formatNameCaseWithSplitterAndSpacer({
        name: businessName
      })
    : opts.default || 'Unknown';
};

export default getEmploymentCompany;

<template>
  <div
    class="wl-banner-alert"
    :class="{
      'wl-banner-alert--hurricane': isHurricane
    }"
  >
    <div class="wl-banner-alert__container">
      <div class="wl-banner-alert__icon" />
      <tz-markdown class="wl-banner-alert__copy" :content="copy" />
    </div>
  </div>
</template>

<script>
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
export default {
  name: 'wl-banner-alert',
  components: {
    TzMarkdown
  },
  props: {
    copy: {
      type: String,
      default: ''
    },
    isHurricane: {
      type: Boolean,
      default: false
    }
  }
};
</script>

import hasExtraTaxesData from './hasExtraTaxesData';
import setTitleCase from '../helpers/setTitleCase';
/**
 * Returns Municipality name or unknown text
 *
 * @param {object} opts
 * @param {object} opts.asset
 * @param {object} opts.default
 * @return {string}
 */
const getMunicipalityName = opts => {
  if (!opts || !opts.asset) {
    return opts?.default || 'Unknown';
  }
  if (opts.asset.municipality) {
    return setTitleCase({ text: opts.asset.municipality });
  }
  return hasExtraTaxesData({ asset: opts.asset }) &&
    opts.asset.extra.property.taxes[0].municipality_name
    ? setTitleCase({
        text: opts.asset.extra.property.taxes[0].municipality_name
      })
    : opts.default || 'Unknown';
};

export default getMunicipalityName;

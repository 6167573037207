import setTitleCase from '../helpers/setTitleCase';

/**
 * Gets skills as a list in a string, comma separated
 * @param {object} opts
 * @param {array} opts.skills
 * @param {string} opts.default
 * @returns {string}
 */
const getSkillsListString = opts => {
  if (!opts || !opts.skills || !opts.skills.length) {
    return opts?.default ?? '';
  }
  let skillListString = '';
  for (let i = 0; i < opts.skills.length; i++) {
    const skillObj = opts.skills[i];
    const isLastIndex = i === opts.skills.length - 1;
    let skillString = skillObj.pre ?? '';

    const conPiece =
      skillObj.con && skillObj.con !== '' && skillObj && skillObj.pre !== ''
        ? ` ${skillObj.con}`
        : skillObj.con && skillObj.con !== ''
        ? skillObj.con
        : '';
    if (conPiece) {
      skillString += conPiece;
    }

    const affPiece =
      skillObj.aff && skillObj.aff !== '' && skillString && skillString !== ''
        ? ` ${skillObj.aff}`
        : skillObj.aff && skillObj.aff !== ''
        ? skillObj.aff
        : '';
    if (affPiece) {
      skillString += affPiece;
    }

    const skill = setTitleCase({
      text: skillString
    });
    skillListString += skill + (!isLastIndex ? ', ' : '');
  }
  return skillListString;
};

export default getSkillsListString;

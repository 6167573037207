<template>
  <div class="ic-modal-body">
    <h2 class="ic-modal-body__title">{{ title }}</h2>
    <p v-if="subtitle" class="ic-modal-body__sub-title">
      {{ subtitle }}
    </p>
    <form class="ic-modal-body__form" @submit="getPDF" method="POST">
      <div
        class="ic-modal-body__form-fields ic-modal-body__form-fields--full-width"
      >
        <WlRadio
          v-for="(option, index) in options"
          :key="`radio-option-${index}`"
          :is-selected="index === selectedOptionIndex"
          :title="option.title"
          :value="index"
          @radio-selection="setSelectedOptionIndex"
        />
      </div>
      <div
        class="ic-modal-body__form-buttons ic-modal-body__form-buttons--two-columns"
      >
        <button
          type="submit"
          class="ic-modal-body__form-button"
          :class="{ 'ic-modal-body__form-button--loading': isSubmitting }"
          v-text="submitButtonText"
          :disabled="
            typeof this.selectedOptionIndex !== 'number' || isSubmitting
          "
        />
        <button
          class="ic-modal-body__form-button ic-modal-body__form-button--cancel"
          :disabled="isSubmitting"
          v-touch:tap="closeModal"
          v-text="cancelButtonText"
        />
      </div>
    </form>
  </div>
</template>

<script>
import WlRadio from '@/components/shared/wl-radio';
export default {
  name: 'pdf-option',
  components: {
    WlRadio
  },
  props: {
    // Text to display for the submit button
    title: {
      type: String,
      required: true
    },
    // Text to display for the submit button
    subtitle: {
      type: String
    },
    // Text to display for the submit button
    submitButtonText: {
      type: String,
      required: true
    },
    // Text to display for the cancel button
    cancelButtonText: {
      type: String,
      required: true
    },
    // Generate PDF Options
    options: {
      type: Array
    },
    // Error message when endpoint fails
    errorBody: {
      type: String
    }
  },
  data() {
    return {
      isSubmitting: false,
      selectedOptionIndex: 0
    };
  },
  methods: {
    closeModal() {
      this.$emit('pdf-option-close-modal');
    },
    setSelectedOptionIndex(index) {
      this.selectedOptionIndex = index;
    },
    async getPDF(e) {
      e.preventDefault();
      if (typeof this.selectedOptionIndex === 'number') {
        this.isSubmitting = true;
        await this.$emit('generate-pdf', this.selectedOptionIndex);
      }
    }
  }
};
</script>

/**
 * Formats a number into money
 *
 * @param {object} opts
 * @param {number} opts.number
 * @param {string} opts.currency
 * @param {string} opts.default
 * @returns {string}
 */
const formatMoney = (opts = {}) => {
  if (opts.number == null || opts.number === '' || isNaN(Number(opts.number))) {
    return opts?.default || 'Unknown';
  }

  const number = Number(opts.number);
  const currencyString = opts.currency || 'USD';
  // List of currencies that do not use decimal places
  const noDecimalCurrencies = ['JPY', 'KRW', 'VND'];
  // Set default options for formatting
  const options = {
    style: 'currency',
    currency: currencyString
  };

  // If the currency is in the no-decimal list, adjust the fraction digits to 0
  // EX: JPY, KRW, VND
  if (noDecimalCurrencies.includes(currencyString)) {
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = 0;
  } else {
    // This is default for currencies that use two decimal places
    options.minimumFractionDigits = 2;
    options.maximumFractionDigits = 2;
  }

  return new Intl.NumberFormat('en-US', options).format(number);
};

export default formatMoney;

/**
 * Formats Phone Number
 *
 * @param {object} opts
 * @param {string} opts.phoneNumber
 * @returns {string}
 */
const formatPhoneNumber = (opts = {}) => {
  // Formats phone number. e.g. (123) 345-6789
  if (!opts.phoneNumber) return '';
  const cleaned = ('' + opts.phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
};

export default formatPhoneNumber;

import Handlebars from 'handlebars';
import fs from 'fs';
import setExtraDataProps from '../../assets/js/shared/templates/js/setExtraDataProps';
import setHandlebarsHelpers from '../../assets/js/shared/templates/js/setHandlebarsHelpers';
import bugsnag from '@bugsnag/js';

const compiledPDFReport = async (data = {}) => {
  try {
    const fullPdfHbs = fs.readFileSync(
      'assets/js/templates/fullPDF.hbs',
      'utf-8'
    );

    const footerHBS = fs.readFileSync(
      'assets/js/templates/partials/footer.hbs',
      'utf-8'
    );

    const coverPageHBS = fs.readFileSync(
      'assets/js/templates/sections/coverPage.hbs',
      'utf-8'
    );

    const tableOfContentsHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/tableOfContents.hbs',
      'utf-8'
    );

    const personalInfoHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/personalInfo.hbs',
      'utf-8'
    );

    const overviewHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/personalInfo/overview.hbs',
      'utf-8'
    );

    const emailsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/personalInfo/emails.hbs',
      'utf-8'
    );

    const phoneNumbersHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/personalInfo/phoneNumbers.hbs',
      'utf-8'
    );

    const photosHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/personalInfo/photos.hbs',
      'utf-8'
    );

    const relatedPeopleHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/relatedPeople.hbs',
      'utf-8'
    );

    const likelyAssociatesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/relatedPeople/likelyAssociates.hbs',
      'utf-8'
    );

    const likelyRelativesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/relatedPeople/likelyRelatives.hbs',
      'utf-8'
    );

    const possibleAssociatesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/relatedPeople/possibleAssociates.hbs',
      'utf-8'
    );

    const criminalHistoryHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/criminalHistory.hbs',
      'utf-8'
    );

    const sexOffendersHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/sexOffenders.hbs',
      'utf-8'
    );

    const jobsAndEducationHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/jobsAndEducation.hbs',
      'utf-8'
    );

    const employmentHistoryHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/jobsAndEducation/employmentHistory.hbs',
      'utf-8'
    );

    const educationHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/jobsAndEducation/education.hbs',
      'utf-8'
    );

    const skillsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/jobsAndEducation/skills.hbs',
      'utf-8'
    );

    const groupsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/jobsAndEducation/groups.hbs',
      'utf-8'
    );

    const coursesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/jobsAndEducation/courses.hbs',
      'utf-8'
    );

    const volunteeringHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/jobsAndEducation/volunteering.hbs',
      'utf-8'
    );

    const addressHistoryHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/addressHistory.hbs',
      'utf-8'
    );

    const socialMediaDeepWebHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/socialMediaDeepWeb.hbs',
      'utf-8'
    );

    const financialInfoHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/financialInfo.hbs',
      'utf-8'
    );

    const bankruptciesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/financial/bankruptcies.hbs',
      'utf-8'
    );

    const evictionsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/financial/evictions.hbs',
      'utf-8'
    );

    const judgementsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/financial/judgements.hbs',
      'utf-8'
    );

    const liensHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/financial/liens.hbs',
      'utf-8'
    );

    const uccFilingsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/financial/uccFilings.hbs',
      'utf-8'
    );

    const businessHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/business.hbs',
      'utf-8'
    );

    const trademarksHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/business/trademarks.hbs',
      'utf-8'
    );

    const affiliationsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/business/affiliations.hbs',
      'utf-8'
    );

    const corporateFilingsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/business/corporateFilings.hbs',
      'utf-8'
    );

    const footerCSS = fs.readFileSync(
      'assets/js/templates/css/footer.css',
      'utf-8'
    );

    const coverPageCSS = fs.readFileSync(
      'assets/js/templates/css/coverPage.css',
      'utf-8'
    );

    const sectionCSS = fs.readFileSync(
      'assets/js/shared/templates/css/section.css',
      'utf-8'
    );

    const licensesAndPermitsHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/licensesAndPermits.hbs',
      'utf-8'
    );

    const faaLicensesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/licensesAndPermits/faaLicenses.hbs',
      'utf-8'
    );

    const deaLicensesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/licensesAndPermits/deaLicenses.hbs',
      'utf-8'
    );

    const professionalLicensesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/licensesAndPermits/professionalLicenses.hbs',
      'utf-8'
    );

    const concealedWeaponsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/licensesAndPermits/concealedWeapons.hbs',
      'utf-8'
    );

    const huntingHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/licensesAndPermits/hunting.hbs',
      'utf-8'
    );

    const driversLicensesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/licensesAndPermits/driversLicenses.hbs',
      'utf-8'
    );

    const personalAssetsHBS = fs.readFileSync(
      'assets/js/shared/templates/sections/personalAssets.hbs',
      'utf-8'
    );

    const currentPropertiesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/personalAssets/currentProperties.hbs',
      'utf-8'
    );

    const pastPropertiesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/personalAssets/pastProperties.hbs',
      'utf-8'
    );

    const propertyHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/personalAssets/property.hbs',
      'utf-8'
    );

    const vehiclesHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/personalAssets/vehicles.hbs',
      'utf-8'
    );

    const aircraftsHBS = fs.readFileSync(
      'assets/js/shared/templates/partials/personalAssets/aircrafts.hbs',
      'utf-8'
    );

    await setExtraDataProps(data);
    setHandlebarsHelpers(Handlebars);

    data.footerCSS = footerCSS;
    data.coverPageCSS = coverPageCSS;
    data.sectionCSS = sectionCSS;

    const coverPageHTML = Handlebars.compile(coverPageHBS);
    const tableOfContentsHTML = Handlebars.compile(tableOfContentsHBS);
    const personalInfoHTML = Handlebars.compile(personalInfoHBS);
    const overviewHTML = Handlebars.compile(overviewHBS);
    const emailsHTML = Handlebars.compile(emailsHBS);
    const phoneNumbersHTML = Handlebars.compile(phoneNumbersHBS);
    const photosHTML = Handlebars.compile(photosHBS);
    const relatedPeopleHTML = Handlebars.compile(relatedPeopleHBS);
    const likelyAssociatesHTML = Handlebars.compile(likelyAssociatesHBS);
    const likelyRelativesHTML = Handlebars.compile(likelyRelativesHBS);
    const possibleAssociatesHTML = Handlebars.compile(possibleAssociatesHBS);
    const addressHistoryHTML = Handlebars.compile(addressHistoryHBS);
    const sexOffendersHTML = Handlebars.compile(sexOffendersHBS);
    const jobsAndEducationHTML = Handlebars.compile(jobsAndEducationHBS);
    const employmentHistoryHTML = Handlebars.compile(employmentHistoryHBS);
    const educationHTML = Handlebars.compile(educationHBS);
    const skillsHTML = Handlebars.compile(skillsHBS);
    const groupsHTML = Handlebars.compile(groupsHBS);
    const coursesHTML = Handlebars.compile(coursesHBS);
    const volunteeringHTML = Handlebars.compile(volunteeringHBS);
    const criminalHistoryHTML = Handlebars.compile(criminalHistoryHBS);
    const financialInfoHTML = Handlebars.compile(financialInfoHBS);
    const socialMediaDeepWebHTML = Handlebars.compile(socialMediaDeepWebHBS);
    const bankruptciesHTML = Handlebars.compile(bankruptciesHBS);
    const evictionsHTML = Handlebars.compile(evictionsHBS);
    const judgementsHTML = Handlebars.compile(judgementsHBS);
    const liensHTML = Handlebars.compile(liensHBS);
    const uccFilingsHTML = Handlebars.compile(uccFilingsHBS);
    const licensesAndPermitsHTML = Handlebars.compile(licensesAndPermitsHBS);
    const faaLicensesHTML = Handlebars.compile(faaLicensesHBS);
    const deaLicensesHTML = Handlebars.compile(deaLicensesHBS);
    const professionalLicensesHTML = Handlebars.compile(
      professionalLicensesHBS
    );
    const concealedWeaponsHTML = Handlebars.compile(concealedWeaponsHBS);
    const huntingHTML = Handlebars.compile(huntingHBS);
    const driversLicensesHTML = Handlebars.compile(driversLicensesHBS);
    const businessHTML = Handlebars.compile(businessHBS);
    const trademarksHTML = Handlebars.compile(trademarksHBS);
    const affiliationsHTML = Handlebars.compile(affiliationsHBS);
    const corporateFilingsHTML = Handlebars.compile(corporateFilingsHBS);
    const personalAssetsHTML = Handlebars.compile(personalAssetsHBS);
    const currentPropertiesHTML = Handlebars.compile(currentPropertiesHBS);
    const pastPropertiesHTML = Handlebars.compile(pastPropertiesHBS);
    const propertyHTML = Handlebars.compile(propertyHBS);
    const vehiclesHTML = Handlebars.compile(vehiclesHBS);
    const aircraftsHTML = Handlebars.compile(aircraftsHBS);

    Handlebars.registerPartial('coverPage', coverPageHTML);
    Handlebars.registerPartial('tableOfContents', tableOfContentsHTML);
    Handlebars.registerPartial('personalInfo', personalInfoHTML);
    Handlebars.registerPartial('overview', overviewHTML);
    Handlebars.registerPartial('emails', emailsHTML);
    Handlebars.registerPartial('phoneNumbers', phoneNumbersHTML);
    Handlebars.registerPartial('photos', photosHTML);
    Handlebars.registerPartial('relatedPeople', relatedPeopleHTML);
    Handlebars.registerPartial('likelyAssociates', likelyAssociatesHTML);
    Handlebars.registerPartial('likelyRelatives', likelyRelativesHTML);
    Handlebars.registerPartial('possibleAssociates', possibleAssociatesHTML);
    Handlebars.registerPartial('sexOffenders', sexOffendersHTML);
    Handlebars.registerPartial('jobsAndEducation', jobsAndEducationHTML);
    Handlebars.registerPartial('employmentHistory', employmentHistoryHTML);
    Handlebars.registerPartial('education', educationHTML);
    Handlebars.registerPartial('skills', skillsHTML);
    Handlebars.registerPartial('groups', groupsHTML);
    Handlebars.registerPartial('courses', coursesHTML);
    Handlebars.registerPartial('volunteering', volunteeringHTML);
    Handlebars.registerPartial('criminalHistory', criminalHistoryHTML);
    Handlebars.registerPartial('addressHistory', addressHistoryHTML);
    Handlebars.registerPartial('financialInfo', financialInfoHTML);
    Handlebars.registerPartial('socialMediaDeepWeb', socialMediaDeepWebHTML);
    Handlebars.registerPartial('bankruptcies', bankruptciesHTML);
    Handlebars.registerPartial('evictions', evictionsHTML);
    Handlebars.registerPartial('judgements', judgementsHTML);
    Handlebars.registerPartial('liens', liensHTML);
    Handlebars.registerPartial('uccFilings', uccFilingsHTML);
    Handlebars.registerPartial('licensesAndPermits', licensesAndPermitsHTML);
    Handlebars.registerPartial('faaLicenses', faaLicensesHTML);
    Handlebars.registerPartial('deaLicenses', deaLicensesHTML);
    Handlebars.registerPartial(
      'professionalLicenses',
      professionalLicensesHTML
    );
    Handlebars.registerPartial('concealedWeapons', concealedWeaponsHTML);
    Handlebars.registerPartial('hunting', huntingHTML);
    Handlebars.registerPartial('driversLicenses', driversLicensesHTML);
    Handlebars.registerPartial('business', businessHTML);
    Handlebars.registerPartial('trademarks', trademarksHTML);
    Handlebars.registerPartial('affiliations', affiliationsHTML);
    Handlebars.registerPartial('corporateFilings', corporateFilingsHTML);
    Handlebars.registerPartial('personalAssets', personalAssetsHTML);
    Handlebars.registerPartial('currentProperties', currentPropertiesHTML);
    Handlebars.registerPartial('pastProperties', pastPropertiesHTML);
    Handlebars.registerPartial('property', propertyHTML);
    Handlebars.registerPartial('vehicles', vehiclesHTML);
    Handlebars.registerPartial('aircrafts', aircraftsHTML);

    const footerHTML = Handlebars.compile(footerHBS);
    const bodyHTML = Handlebars.compile(fullPdfHbs);

    const body = bodyHTML(data)
      .toString()
      .replace(/\n|\t/g, ' ');

    const footer = footerHTML(data)
      .replace(/\n|\t/g, ' ')
      .replace(/"/g, "'");

    const payload = JSON.stringify({
      body,
      footer,
      options: {
        'margin-right': 0,
        'margin-bottom': 29,
        'margin-left': 0,
        'disable-smart-shrinking': true
      }
    });
    // using this code for for quicker css testing, can take out when we are finished with pdf tickets.
    // fs.writeFileSync('body.html', body, err => {
    //   if (err) {
    //     console.error(err);
    //   }
    // });
    return payload;
  } catch (err) {
    throw new Error(`Failed to compile PDF template: ${err}`);
  }
};

const compiledPdfPhoneReport = async (data = {}) => {
  try {
    let fullPdfHbs;
    let footerHBS;
    let coverPageHBS;
    let tableOfContentsHBS;
    let personalInfoHBS;
    let overviewHBS;
    let emailsHBS;
    let phoneNumbersHBS;
    let sexOffendersHBS;
    let addressHistoryHBS;
    let footerCSS;
    let coverPageCSS;
    let sectionCSS;

    const setTemplates = () => {
      return new Promise((resolve, reject) => {
        try {
          fullPdfHbs = fs.readFileSync(
            'assets/js/templates/fullPhonePdf.hbs',
            'utf-8'
          );

          footerHBS = fs.readFileSync(
            'assets/js/templates/partials/footer.hbs',
            'utf-8'
          );

          coverPageHBS = fs.readFileSync(
            'assets/js/templates/sections/phoneReport/coverPage.hbs',
            'utf-8'
          );

          tableOfContentsHBS = fs.readFileSync(
            'assets/js/shared/templates/sections/phoneReport/tableOfContents.hbs',
            'utf-8'
          );

          personalInfoHBS = fs.readFileSync(
            'assets/js/shared/templates/sections/phoneReport/personalInfo.hbs',
            'utf-8'
          );

          overviewHBS = fs.readFileSync(
            'assets/js/shared/templates/partials/personalInfo/phoneReport/overview.hbs',
            'utf-8'
          );

          phoneNumbersHBS = fs.readFileSync(
            'assets/js/shared/templates/partials/personalInfo/phoneReport/phoneNumbers.hbs',
            'utf-8'
          );

          sexOffendersHBS = fs.readFileSync(
            'assets/js/shared/templates/sections/phoneReport/sexOffenders.hbs',
            'utf-8'
          );

          addressHistoryHBS = fs.readFileSync(
            'assets/js/shared/templates/sections/phoneReport/addressHistory.hbs',
            'utf-8'
          );

          footerCSS = fs.readFileSync(
            'assets/js/templates/css/footer.css',
            'utf-8'
          );

          coverPageCSS = fs.readFileSync(
            'assets/js/templates/css/coverPage.css',
            'utf-8'
          );

          sectionCSS = fs.readFileSync(
            'assets/js/shared/templates/css/section.css',
            'utf-8'
          );
          resolve();
        } catch (error) {
          reject();
        }
      });
    };

    await setTemplates();
    await setExtraDataProps(data);
    setHandlebarsHelpers(Handlebars);

    data.footerCSS = footerCSS;
    data.coverPageCSS = coverPageCSS;
    data.sectionCSS = sectionCSS;

    const coverPageHTML = Handlebars.compile(coverPageHBS);
    const tableOfContentsHTML = Handlebars.compile(tableOfContentsHBS);
    const personalInfoHTML = Handlebars.compile(personalInfoHBS);
    const overviewHTML = Handlebars.compile(overviewHBS);
    const phoneNumbersHTML = Handlebars.compile(phoneNumbersHBS);
    const addressHistoryHTML = Handlebars.compile(addressHistoryHBS);
    const sexOffendersHTML = Handlebars.compile(sexOffendersHBS);

    Handlebars.registerPartial('coverPage', coverPageHTML);
    Handlebars.registerPartial('tableOfContents', tableOfContentsHTML);
    Handlebars.registerPartial('personalInfo', personalInfoHTML);
    Handlebars.registerPartial('overview', overviewHTML);
    Handlebars.registerPartial('phoneNumbers', phoneNumbersHTML);
    Handlebars.registerPartial('sexOffenders', sexOffendersHTML);
    Handlebars.registerPartial('addressHistory', addressHistoryHTML);

    const footerHTML = Handlebars.compile(footerHBS);
    const bodyHTML = Handlebars.compile(fullPdfHbs);

    const body = bodyHTML(data)
      .toString()
      .replace(/\n|\t/g, ' ');

    const footer = footerHTML(data)
      .replace(/\n|\t/g, ' ')
      .replace(/"/g, "'");

    const payload = JSON.stringify({
      body,
      footer,
      options: {
        'margin-right': 0,
        'margin-bottom': 29,
        'margin-left': 0,
        'disable-smart-shrinking': true
      }
    });
    return payload;
  } catch (err) {
    throw new Error(`Failed to compile PDF Phone template: ${err}`);
  }
};
const compiledPdfAddressReport = async (data = {}) => {
  try {
    let fullPdfHbs;
    let footerHBS;
    let coverPageHBS;
    let tableOfContentsHBS;
    let overviewHBS;
    let neighborsHBS;
    let sexOffendersHBS;
    let footerCSS;
    let coverPageCSS;
    let sectionCSS;

    const setTemplates = () => {
      return new Promise((resolve, reject) => {
        try {
          fullPdfHbs = fs.readFileSync(
            'assets/js/templates/fullAddressPdf.hbs',
            'utf-8'
          );

          footerHBS = fs.readFileSync(
            'assets/js/templates/partials/footer.hbs',
            'utf-8'
          );

          coverPageHBS = fs.readFileSync(
            'assets/js/templates/sections/addressReport/coverPage.hbs',
            'utf-8'
          );

          tableOfContentsHBS = fs.readFileSync(
            'assets/js/shared/templates/sections/addressReport/tableOfContents.hbs',
            'utf-8'
          );

          overviewHBS = fs.readFileSync(
            'assets/js/shared/templates/sections/addressReport/propertyOverview.hbs',
            'utf-8'
          );

          sexOffendersHBS = fs.readFileSync(
            'assets/js/shared/templates/sections/sexOffenders.hbs',
            'utf-8'
          );

          neighborsHBS = fs.readFileSync(
            'assets/js/shared/templates/sections/addressReport/neighbors.hbs',
            'utf-8'
          );

          footerCSS = fs.readFileSync(
            'assets/js/templates/css/footer.css',
            'utf-8'
          );

          coverPageCSS = fs.readFileSync(
            'assets/js/templates/css/coverPage.css',
            'utf-8'
          );

          sectionCSS = fs.readFileSync(
            'assets/js/shared/templates/css/section.css',
            'utf-8'
          );
          resolve();
        } catch (error) {
          reject();
        }
      });
    };

    await setTemplates();
    await setExtraDataProps(data);
    setHandlebarsHelpers(Handlebars);

    data.footerCSS = footerCSS;
    data.coverPageCSS = coverPageCSS;
    data.sectionCSS = sectionCSS;

    const coverPageHTML = Handlebars.compile(coverPageHBS);
    const tableOfContentsHTML = Handlebars.compile(tableOfContentsHBS);
    const overviewHTML = Handlebars.compile(overviewHBS);
    const neighborsHTML = Handlebars.compile(neighborsHBS);
    const sexOffendersHTML = Handlebars.compile(sexOffendersHBS);

    Handlebars.registerPartial('coverPage', coverPageHTML);
    Handlebars.registerPartial('tableOfContents', tableOfContentsHTML);
    Handlebars.registerPartial('overview', overviewHTML);
    Handlebars.registerPartial('neighbors', neighborsHTML);
    Handlebars.registerPartial('sexOffenders', sexOffendersHTML);

    const footerHTML = Handlebars.compile(footerHBS);
    const bodyHTML = Handlebars.compile(fullPdfHbs);

    const body = bodyHTML(data)
      .toString()
      .replace(/\n|\t/g, ' ');

    const footer = footerHTML(data)
      .replace(/\n|\t/g, ' ')
      .replace(/"/g, "'");

    const payload = JSON.stringify({
      body,
      footer,
      options: {
        'margin-right': 0,
        'margin-bottom': 29,
        'margin-left': 0,
        'disable-smart-shrinking': true
      }
    });
    return payload;
  } catch (err) {
    throw new Error(`Failed to compile PDF Address template: ${err}`);
  }
};

const pdfHandler = async (req, res) => {
  const payload = await new Promise(async resolve => {
    let body = '';
    req.on('data', chunk => {
      body += chunk.toString();
    });
    req.on('end', () => {
      resolve(body);
    });
  })
    .then(body => {
      const json = body.length ? JSON.parse(body) : {};
      return json;
    })
    .catch(err => {
      console.error(err);
      bugsnag.notify(err);
    });

  const reportGenType = req.originalUrl.substring(
    req.originalUrl.lastIndexOf('/') + 1
  );
  payload.reportType = reportGenType;
  let output;
  switch (reportGenType) {
    case 'phone':
      output = await compiledPdfPhoneReport(payload);
      break;
    case 'address':
      output = await compiledPdfAddressReport(payload);
      break;
    default:
      output = await compiledPDFReport(payload);
  }
  res.setHeader('content-type', 'application/json; charset=utf-8');
  res.end(output);
};

const routes = [
  {
    path: `/dashboard/generate-pdf/type/people`,
    handler: pdfHandler
  },
  {
    path: `/dashboard/generate-pdf/type/phone`,
    handler: pdfHandler
  },
  {
    path: `/dashboard/generate-pdf/type/address`,
    handler: pdfHandler
  }
];

const [peopleReportRoute, phoneReportRoute, addressReportRoute] = routes;

export { peopleReportRoute, phoneReportRoute, addressReportRoute };

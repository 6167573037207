/**
 * Calculates age from dob property returned in the response
 *
 * @param {object} opts
 * @param {string} opts.dateString
 * @returns {null|number}
 */
const getAge = (opts = {}) => {
  const today = new Date();

  if (!opts.dateString) {
    return null;
  }
  const birthDate = new Date(opts.dateString);

  if (1900 >= birthDate.getFullYear()) {
    return null;
  }

  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export default getAge;

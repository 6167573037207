<template>
  <div
    class="wl-upsell-content-body"
    :class="`wl-upsell-content-body--${bodyModifier}`"
  >
    <h2 v-if="heading" class="wl-upsell-content-body__title" v-text="heading" />
    <p
      class="wl-upsell-content-body__sub-title"
      v-if="subHeading"
      v-html="subHeading"
    />
    <p class="wl-upsell-content-body__perks-heading" v-text="perksTitle" />
    <slot name="perks"></slot>
    <div class="dashboard__disclaimer" v-if="warningText">
      <p v-text="warningText" />
    </div>
    <div class="wl-upsell-content-body__form-buttons">
      <button
        class="wl-upsell-content-body__form-button"
        v-touch:tap="handleSubmit"
        :disabled="isProcessing"
      >
        <TzMarkdown :content="isProcessing ? processingText : submitText" />
        <tz-spinner
          v-if="isProcessing"
          :is-check="false"
          spinner-size="20"
          :spinner-animation="true"
          spinner-color="#4476ff"
          spinner-stroke="2"
          :spinner-delay="100"
          :is-starting-spin="true"
        />
      </button>
      <button
        class="wl-upsell-content-body__form-button wl-upsell-content-body__form-button--cancel"
        v-touch:tap="closeModal"
        :disabled="isProcessing"
      >
        <TzMarkdown :content="cancelButtonText" />
      </button>
    </div>
  </div>
</template>

<script>
import TzSpinner from '@trazi/tz-spinner/src/tz-spinner.vue';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
export default {
  name: 'wl-upsell-content',
  components: {
    TzSpinner,
    TzMarkdown
  },
  props: {
    heading: {
      type: String
    },
    subHeading: {
      type: String
    },
    perksTitle: {
      type: String
    },
    warningText: {
      type: String
    },
    submitText: {
      type: String
    },
    cancelButtonText: {
      type: String
    },
    isProcessing: {
      type: Boolean
    },
    processingText: {
      type: String,
      default: 'Payment Processing'
    },
    bodyModifier: {
      type: String
    }
  },
  methods: {
    /**
     * handle submission
     */
    async handleSubmit() {
      await this.$emit('wl-upsell-modal-submit');
    },
    /**
     * Closes modal
     */
    closeModal() {
      this.$emit('wl-upsell-modal-close');
    }
  }
};
</script>

/**
 * Chunks arrays (used for pagination)
 * @param {object} opts
 * @param {array} opts.arr
 * @param {number} opts.size
 * @returns {*[]}
 */
const chunkArray = (opts = {}) => {
  if (!opts.arr || !opts.size) {
    return [];
  }
  const { arr, size } = opts;
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
};

export default chunkArray;

<template>
  <div class="wl-trust-badges">
    <div
      class="wl-trust-badges__item trustedsite-trustmark"
      data-type="202"
      data-width="60"
      data-height="25"
    />
    <img
      :src="badgeNorton"
      alt="Norton Security Seal"
      aria-label="Norton Security Seal"
      :width="width"
      :height="height"
      loading="lazy"
    />
    <img
      class="wl-trust-badges__item"
      :src="hackerSafeIcon"
      alt="HackerSafe"
      :width="width"
      :height="height"
      loading="lazy"
    />
  </div>
</template>

<script>
import hackerSafeIcon from '@/assets/images/shared/hacker-safe.webp';
import badgeNorton from '@/assets/images/shared/norton-secured.webp';

export default {
  name: 'wl-trust-badges',
  data() {
    return {
      hackerSafeIcon,
      badgeNorton
    };
  },
  props: {
    // Sets a specific width for the image
    width: {
      type: String,
      default: '60'
    },
    // Sets a specific height for the image
    height: {
      type: String,
      default: '23'
    }
  }
};
</script>

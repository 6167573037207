import formatStringAsUsDate from '../helpers/formatStringAsUsDate';
import getDateRange from './getDateRange';

/**
 * Returns formatted Owner Since dates or unknown text
 *
 * @param {object} opts
 * @param {object} opts.asset
 * @param {string} opts.default
 * @returns {string}
 */
const getOwnerSinceDates = opts => {
  if (!opts || !opts.asset) {
    return opts?.default || 'Unknown';
  }
  if (
    opts.asset.first_seen_as_owner &&
    opts.asset.last_seen_as_owner &&
    opts.asset.first_seen_as_owner === opts.asset.last_seen_as_owner
  ) {
    return formatStringAsUsDate({
      string: opts.asset.last_seen_as_owner,
      defaultValue: opts.default || 'Unknown'
    });
  }
  return getDateRange({
    objWithDates: opts.asset,
    default: opts.default || 'Unknown'
  });
};

export default getOwnerSinceDates;

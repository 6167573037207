import setTitleCase from '../helpers/setTitleCase';

/**
 * Title case each item in array if string and return as comma delineated string
 * @param {array} list
 * @returns {string}
 */
const formatArrayOrUnknown = (opts = {}) => {
  if (!opts || !opts.list || !opts.list.length) {
    return opts.defaultValue ? opts.defaultValue : '';
  }

  const text = [];
  const { list } = opts;
  for (let i = 0; i < list.length; i++) {
    const type = typeof list[i];

    switch (type) {
      case 'string':
        text.push(setTitleCase({ text: list[i] }));
        break;
      case 'object':
        const keys = Object.keys(list[i]);

        for (let k = 0; k < keys.length; k++) {
          let value = list[i][keys[k]];

          // Format value strings in title case
          if (typeof value === 'string') {
            value = setTitleCase({ text: value });
          }

          // Push title cased key: value to list
          text.push(`${setTitleCase({ text: keys[k] })}: ${value}`);
        }
        break;
      default:
        text.push(list[i]);
    }
  }
  return text.join('; ');
};

export default formatArrayOrUnknown;

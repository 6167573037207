const dateHelper = {
  /**
   * calculateAge
   * @param dob
   * @param $bugsnag
   * @returns {number|string}
   */
  calculateAge(dob, $bugsnag = {}) {
    if (!dob || dob === '1600-01-01') {
      return 'Unknown';
    }
    try {
      const birthDate = new Date(dob);
      const date = new Date();

      let years = date.getFullYear() - birthDate.getFullYear();

      if (
        date.getMonth() < birthDate.getMonth() ||
        (date.getMonth() == birthDate.getMonth() &&
          date.getDate() < birthDate.getDate())
      ) {
        years--;
      }

      return years;
    } catch (err) {
      $bugsnag?.notify(err);
      return 'Unknown';
    }
  },
  /**
   * Format age to have dash if null and  80+ if over 79
   * @param age
   * @returns {number|string}
   */
  formatAge(age) {
    if (!age || age === 'Unknown' || age >= 400) {
      return '-';
    }

    return age <= 79 ? age : '80+';
  },
  /**
   * Gets age from dob string
   * @param {string} dob
   * @returns {number|string} age number, '80+' or '-'
   */
  getAgeFromDate(dob) {
    const age = this.calculateAge(dob);
    return this.formatAge(age);
  },
  /**
   * format date to MM DD YYYY with your chosen separator
   * @param date
   * @param separator
   * @returns {string}
   */
  formatDate(date, separator) {
    if (!date) {
      return '-';
    }

    return new Date(date)
      .toLocaleDateString(undefined, {
        timeZone: 'UTC',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace('-', separator);
  }
};

export default dateHelper;

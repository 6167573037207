/**
 * Returns formatted employment dates or unknown text
 *
 * @param {object} opts
 * @param {object} opts.licenses
 * @param {string} opts.default
 * @returns {string}
 */
const getLicenseData = opts => {
  if (!opts || !opts.licenses || !opts.licenses.length) {
    return opts.default || 'Unknown';
  }
  const { licenses } = opts;
  const faaLicenses = [];
  const professionalLicenses = [];
  for (let i = 0; i < licenses.length; i++) {
    const extra = licenses[i].extra;
    if (extra) {
      const license = licenses[i];
      // Get key name of section
      const section = Object.keys(extra)[0];
      switch (section) {
        case 'pilot_license':
          faaLicenses.push(license);
          break;
        case 'professional_license':
          professionalLicenses.push(license);
          break;
        default:
        // do nothing
      }
    }
  }
  return { faaLicenses, professionalLicenses };
};

export default getLicenseData;

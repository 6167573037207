import setTitleCase from './setTitleCase';
import parseParams from './parseParams';

/**
 * Return object containing formatted names for display and requests
 *
 * @param {object} opts
 * @param {object} opts.params
 * @param {RegExp} opts.regexMatch
 * @param {object} $bugsnag
 * @returns {{firstName: (*|string), lastName: (*|string), firstNameRequestFormat: string, lastNameRequestFormat: string}|null}
 */

const getResultNames = (opts = {}, $bugsnag = {}) => {
  if (
    !opts ||
    !opts.params ||
    (!opts.params.firstname && !opts.params.firstName) ||
    (!opts.params.lastname && !opts.params.lastName)
  ) {
    return null;
  }
  try {
    const { params, regexMatch } = opts;

    const firstname = params.firstname ?? params.firstName;
    const lastname = params.lastname ?? params.lastName;

    const firstName = setTitleCase({
      text: parseParams({
        word: firstname.toLowerCase(),
        splitBy: [' '],
        parseBy: '_'
      })
    });

    const lastName = setTitleCase({
      text: parseParams({
        word: lastname,
        splitBy: ['_'],
        parseBy: ' '
      })
    });
    const firstNameRequestFormat = firstName
      .toUpperCase()
      .replace(regexMatch, '%20');
    const lastNameRequestFormat = lastName
      .toUpperCase()
      .replace(regexMatch, '%20');
    return {
      firstName,
      lastName,
      firstNameRequestFormat,
      lastNameRequestFormat
    };
  } catch (err) {
    console.error(err);
    $bugsnag?.notify(err);
    return null;
  }
};

export default getResultNames;

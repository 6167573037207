/**
 * Formats phone number with dashes only. e.g. 1234567890 -> 123-456-7890
 *
 * @param {object} opts
 * @param {string} opts.phoneNumber
 * @returns {string}
 */
const dashedPhoneNumber = (opts = {}) => {
  if (!opts.phoneNumber) return '';

  opts.phoneNumber = opts.phoneNumber.toString();
  const replaceValue = () => {
    if (opts.phoneNumber.length > 6) {
      return '$1-$2-$3';
    }
    if (opts.phoneNumber.length > 3) {
      return '$1-$2';
    }
    return '$1';
  };
  if (opts.phoneNumber.charAt(0) === '1') {
    return (opts.phoneNumber = `1-${opts.phoneNumber
      .slice(1)
      .replace(/(\d{3})(\d{0,3})(\d*)/, replaceValue())}`);
  }

  return opts.phoneNumber.replace(/(\d{3})(\d{0,3})(\d*)/, replaceValue());
};

export default dashedPhoneNumber;

/**
 * Returns formatted string of relatives for optouts
 *
 * @param {object} opts
 * @param {string} opts.time
 * @param {string} opts.defaultValue
 * @returns {string}
 */

const getFormattedTime = (opts = {}) => {
  if (!opts || !opts.time || opts.time == '00:00:00') {
    return opts?.defaultValue ?? '';
  }

  const split = opts.time.split(':');
  return `${split[0] !== '00' ? `${split[0]} Hours ` : ''}${
    split[1] !== '00' ? `${split[1]} Minutes ` : ''
  }${split[2] !== '00' ? `${split[2]} Seconds` : ''}`;
};

export default getFormattedTime;

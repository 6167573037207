export const state = () => ({
  country: null,
  city: null,
  state: null,
  fullState: null,
  zip: null
});

export const mutations = {
  setLocation(state, location) {
    Object.assign(state, location);
  }
};

export const getters = {
  country: state => state.country,
  city: state => state.city,
  state: state => state.state,
  fullState: state => state.fullState,
  zip: state => state.zip
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};

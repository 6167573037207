const middleware = {}

middleware['seo-redirect'] = require('../middleware/seo-redirect.js')
middleware['seo-redirect'] = middleware['seo-redirect'].default || middleware['seo-redirect']

middleware['shared/address-redirect'] = require('../middleware/shared/address-redirect.js')
middleware['shared/address-redirect'] = middleware['shared/address-redirect'].default || middleware['shared/address-redirect']

middleware['shared/generate-pdf'] = require('../middleware/shared/generate-pdf.js')
middleware['shared/generate-pdf'] = middleware['shared/generate-pdf'].default || middleware['shared/generate-pdf']

middleware['shared/results-redirect'] = require('../middleware/shared/results-redirect.js')
middleware['shared/results-redirect'] = middleware['shared/results-redirect'].default || middleware['shared/results-redirect']

middleware['shared/set-headers'] = require('../middleware/shared/set-headers.js')
middleware['shared/set-headers'] = middleware['shared/set-headers'].default || middleware['shared/set-headers']

middleware['shared/trending-name-redirect'] = require('../middleware/shared/trending-name-redirect.js')
middleware['shared/trending-name-redirect'] = middleware['shared/trending-name-redirect'].default || middleware['shared/trending-name-redirect']

export default middleware

import formatFullName from '../../helpers/formatFullName';
import setTitleCase from '../../helpers/setTitleCase';
import dateHelper from '../../dateHelper';
import getCorporateFilingDetails from '../../reportHelpers/getCorporateFilingDetails';
import getLicenseData from '../../reportHelpers/getLicenseData';
import isCurrentProperty from '../../reportHelpers/isCurrentProperty';
import getAircraftData from '../../reportHelpers/getAircraftData';
import getFormattedCriminalHistory from '../../reportHelpers/getFormattedCriminalHistory';
import sortEmployersByDate from '../../reportHelpers/sortEmployersByDate';
import formatPhoneNumber from '../../helpers/formatPhoneNumber';
import fetch from 'node-fetch';

/**
 * Sets extra data props for report data
 * @param {object} data
 * @returns {string}
 */
const setLicensesData = data => {
  const licensesData = getLicenseData({ licenses: data.licenses });

  data.professionalLicenses = licensesData.professionalLicenses;
  data.faaLicenses = licensesData.faaLicenses;
};
const setAircrafts = data => {
  data.aircrafts = getAircraftData({ aircrafts: data.aircraft });
};

const setProperties = data => {
  if (!data.assets) {
    data.current_properties = [];
    data.past_properties = [];
    return;
  }
  let currentProperties = [];
  let pastProperties = [];
  for (let i = 0; i < data.assets.length; i++) {
    const asset = data.assets[i];
    const isCurrentProp = isCurrentProperty({ asset });
    if (isCurrentProp) {
      currentProperties.push(asset);
    } else {
      pastProperties.push(asset);
    }
  }
  data.current_properties = currentProperties;
  data.past_properties = pastProperties;
};

const setCorporateFilings = data => {
  if (!data || !data.corporate_filings || !data.corporate_filings.length) {
    data.corporateFilings = [];
    return;
  }
  const corporateFilingsClone = [...data.corporate_filings];
  const corporateFilings = corporateFilingsClone.map(i => {
    return getCorporateFilingDetails({ data: i, formatForPDF: true });
  });
  data.corporateFilings = corporateFilings;
};

const setCoverPageData = data => {
  if (data.firstname && data.middlename && data.lastname) {
    data.fullName = formatFullName({
      firstName: data.firstname,
      lastName: data.lastname,
      titleCase: true
    });
  } else if (data.firstname && data.lastname) {
    data.fullName = formatFullName({
      firstName: data.firstname,
      lastName: data.lastname,
      titleCase: true
    });
  } else if (data.metadata && data.metadata.name) {
    data.fullName = formatFullName({
      firstName: data.metadata.name.first,
      lastName: data.metadata.name.last,
      titleCase: true
    });
  } else {
    data.fullName = 'Unknown';
  }
  if (data.reportType === 'address') {
    data.currentAddress = data.address;
  } else {
    data.currentAddress =
      data.addresses && data.addresses.length
        ? data.addresses.filter(x => x.current_address)[0] || data.addresses[0]
        : '';
  }

  const city = setTitleCase({
    text: `${data.currentAddress.city}`
  });
  const state = data.currentAddress.state;
  if (!state || !city) {
    data.cityState = '';
  } else {
    data.cityState = `${city}, ${state}`;
  }

  const age = dateHelper.getAgeFromDate(data.dob);
  data.age = age === '-' ? 'Unknown' : age;

  if (data.reportType === 'phone') {
    // Formatted Full Phone Number
    data.formattedPhone = formatPhoneNumber({
      phoneNumber: data.phones[0].full
    });

    // Phone Line Type
    data.phoneLineType = setTitleCase({
      text: data.phones[0].type,
      defaultValue: 'Unknown'
    });
  }
};

const setSexOffenders = data => {
  data.sexOffenders = data?.offenders ? data.offenders : [];
};

const setDatesOfBirth = data => {
  for (let i = 0; i < data.sexOffenders.length; i++) {
    if (!data.sexOffenders[i].dob || data.sexOffenders[i].dob === '') {
      data.sexOffenders[i].dob = 'Unknown';
    } else {
      data.sexOffenders[i].dob;
    }
    if (!data.sexOffenders[i].weight || data.sexOffenders[i].weight === '') {
      data.sexOffenders[i].weight = 'Unknown';
    } else {
      data.sexOffenders[i].weight += ' lbs';
    }
    if (
      !data.sexOffenders[i].height ||
      data.sexOffenders[i].height === '' ||
      data.sexOffenders[i].height === '0ft 00in'
    ) {
      data.sexOffenders[i].height = 'Unknown';
    } else {
      data.sexOffenders[i].height;
    }
  }
  // maybe get rid of all below this line since seems like data is using 'dob' and not 'dates_of_birth'
  let knownBirthDate = null;
  let otherBirthDates = [];

  if (!data.dates_of_birth || !data.dates_of_birth.length) {
    data.dateOfBirth = null;
    data.otherDatesOfBirth = null;
    return;
  }

  if (data.dates_of_birth.length > 1) {
    otherBirthDates = data.dates_of_birth.slice(1);
  }

  knownBirthDate = data.dates_of_birth[0].dob;
  data.dateOfBirth = knownBirthDate;
  data.otherDatesOfBirth = otherBirthDates;
};

const setGroups = data => {
  if (!data.groups) {
    data.groups = [];
  }
};

const setCriminalHistory = data => {
  data.crimes = getFormattedCriminalHistory(data);
};

const setEmployers = data => {
  data.employers = sortEmployersByDate({
    employers: data.employers,
    dateDefault: 'Unknown'
  });
};

const setNeighbors = data => {
  if (!data.neighbors || !Array.isArray(data.neighbors)) {
    return;
  }
  // Filter neighbors to include only those with residents
  data.neighbors = data.neighbors.filter(neighbor => {
    return neighbor.residents && neighbor.residents.length > 0;
  });
};

const setExtraDataProps = async data => {
  setNeighbors(data);
  setAircrafts(data);
  setProperties(data);
  setCorporateFilings(data);
  setLicensesData(data);
  setCoverPageData(data);
  setCriminalHistory(data);
  setSexOffenders(data);
  setDatesOfBirth(data);
  setGroups(data);
  setEmployers(data);
};

export default setExtraDataProps;

import createPersistedState from 'vuex-persistedstate';
import cookie from 'cookie';

const createStorage = (req, res, expirationDays) => {
  if (process.server) {
    return {
      getItem: key => {
        if (req.headers.cookie) {
          const parsedCookies = cookie.parse(req.headers.cookie);
          return parsedCookies[key] || null;
        }
        return null;
      },
      setItem: (key, value) => {
        res.setHeader(
          'Set-Cookie',
          cookie.serialize(key, value, {
            maxAge: expirationDays * 24 * 60 * 60,
            path: '/',
            sameSite: 'lax'
          })
        );
      },
      removeItem: key => {
        res.setHeader(
          'Set-Cookie',
          cookie.serialize(key, '', {
            maxAge: -1,
            path: '/'
          })
        );
      }
    };
  } else {
    return {
      getItem: key => {
        const cookies = cookie.parse(document.cookie || '');
        return cookies[key] || null;
      },
      setItem: (key, value) => {
        document.cookie = cookie.serialize(key, value, {
          maxAge: expirationDays * 24 * 60 * 60,
          path: '/',
          sameSite: 'lax'
        });
      },
      removeItem: key => {
        document.cookie = cookie.serialize(key, '', {
          maxAge: -1,
          path: '/'
        });
      }
    };
  }
};

export default ({ $config, store, req, res, isDev }) => {
  const cookiePrefix = `${$config.website}-`;

  const persistConfig = [{ key: 'location', expirationDays: 1 }];

  persistConfig.forEach(({ key, expirationDays }) => {
    const fullKey = `${cookiePrefix}${key}`;
    createPersistedState({
      key: fullKey,
      paths: [key],
      storage: createStorage(req, res, expirationDays)
    })(store);
  });
};

/**
 * Prevent duplicate relative links
 * @param {object} opts
 * @param opts.people
 * @param opts.firstName
 * @param opts.lastName
 * @returns {*}
 */
const unlinkRelatives = (opts = {}) => {
  if (!opts.people) {
    return null;
  }
  const { people, firstName, lastName } = opts;
  const uniqueNames = [];

  // Add name if available (Used primarily for root, state, city seo pages)
  if (firstName && lastName) {
    uniqueNames.push({
      firstname: firstName,
      lastname: lastName
    });
  }

  const newPeople = people;
  newPeople.forEach(function(person) {
    if (person.relatives) {
      person.relatives.forEach(function(relative) {
        const nameMatch = uniqueNames.filter(function(person) {
          return (
            person.firstname === relative.firstname &&
            person.lastname === relative.lastname
          );
        });
        if (nameMatch.length > 0) {
          relative['unlink'] = true;
          return relative;
        }
        uniqueNames.push({
          firstname: relative.firstname,
          lastname: relative.lastname
        });
      });
    }
  });

  return newPeople;
};

export default unlinkRelatives;

/**
 * getStateCount - Return first object from statelist that matches staeAbbr
 * @param {object} opts
 * @param {array} opts.stateList - a list of states from server
 * @param {string} opts.stateAbbr - an all caps abbreviation of a state eg. OH
 * @returns {object|*} - the count of this state
 */
const getStateCount = (opts = {}) => {
  const { stateList, stateAbbr } = opts;
  if (!Array.isArray(stateList) || stateList.length === 0) {
    return 0;
  }
  const stateObj = stateList.filter(item => item.state === stateAbbr)[0];
  return stateObj || null;
};

export default getStateCount;

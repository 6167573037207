import getMaskingString from './getMaskingString';

/**
 * Return masked email address
 *
 * @param {object} opts
 * @param {string} opts.email
 * @param {string|null} [opts.separator]
 * @param {string|null} [opts.maskingSymbol]
 * @returns {string|null}
 */
const maskEmail = (opts = {}) => {
  const { email, maskingSymbol } = opts;
  const separator = opts.separator || '';
  if (!email) {
    return null;
  }
  // No masking then display to user as is
  if (!maskingSymbol) {
    return email + separator;
  }
  // Otherwise return first three characters, masking string, and everything from @symbol on
  return (
    email.slice(0, 3) +
    getMaskingString({ number: 4, masking: maskingSymbol }) +
    email.slice(email.indexOf('@')) +
    separator
  );
};

export default maskEmail;

import billingSDK from './services/billing-sdk';
import billingV2SDK from './services/billing-v2-sdk';

const billingHelper = {
  async getInventory(vm) {
    let inventory;
    try {
      if (!vm.currentUser.user_data?.v2) {
        inventory = await vm.initializedBillingSDK.Billing.Inventories.byAccount(
          vm.domain,
          vm.currentUser.id,
          true
        );
      } else {
        inventory = await vm.initializedBillingSDK.Inventories.byAccount(
          vm.domain,
          vm.currentUser.id,
          true
        );
      }
    } catch (err) {
      console.error(err);
      vm.$bugsnag?.notify(err);
      return [];
    }

    if (
      (inventory && inventory.type && inventory.type.includes('error')) ||
      (inventory && !inventory.length)
    ) {
      return [];
    }

    return inventory;
  },
  /**
   * From a user's inventory of products and subscriptions this method will return all inventory subscriptions plus their tied unlockables
   * note: subscriptions and products here are referring to account inventorySubscriptions and inventoryProducts
   * @param {object} opts
   * @param {object} opts.vm nuxt context
   * @param {array} opts.subscriptions user's inventory subscriptions
   * @param {array} opts.products user's inventory products
   * @param {object} opts.unlockables unlockable ids available to environment
   * @param {*} opts.defaultValue
   * @returns {*}
   */
  async getInventorySubscriptionsWithUnlockables(opts) {
    if (
      !opts &&
      !opts.vm &&
      !opts.subscriptions &&
      !opts.subscriptions.length &&
      !opts.products &&
      !opts.products.length &&
      !opts.unlockables
    ) {
      return opts?.defaultValue ?? [];
    }
    const matchedUnlockablesByProductId = {};
    for (let i = 0; i < opts.products.length; i++) {
      if (opts.products[i].item?.unlockables) {
        matchedUnlockablesByProductId[opts.products[i].product_id] =
          opts.products[i].item?.unlockables ?? [];
      }
    }
    const subscriptionsPlusUnlockables = [];
    for (let i = 0; i < opts.subscriptions.length; i++) {
      const subscription = opts.subscriptions[i];
      if (
        !subscription.item ||
        !subscription.item.products ||
        !subscription.item.products.length
      ) {
        continue;
      }
      let unlockablesToAttach = [];
      for (let j = 0; j < subscription.item.products.length; j++) {
        const productId = subscription.item.products[j];
        if (matchedUnlockablesByProductId[productId]) {
          unlockablesToAttach = unlockablesToAttach.concat(
            matchedUnlockablesByProductId[productId]
          );
          continue;
        }
        // if a user's unlockables does not come back with inventory endpoint, it means they no longer have the
        // permission, however we still need to get what unlockables that subscription would have, for checking if they
        // have an active subscription with unlockable people_reports, even if their permission for it was revoked
        try {
          const product = await opts.vm.initializedBillingSDK.Products.byId(
            opts.vm.domain,
            productId,
            null
          );
          if (product && product.type && product.type.includes('error')) {
            throw new Error('Billing error fetching product');
          }
          // if no unlockables have been set for productID above and pulling the products has no unlockable Ids, set to empty array
          if (!product.unlockables_ids || !product.unlockables_ids.length) {
            // matchedUnlockablesByProductId[opts.products[i].product_id] = [];
            continue;
          }
          for (let j = 0; j < product.unlockables_ids.length; j++) {
            const unlockableId = product.unlockables_ids[i];
            for (let k = 0; k < opts.unlockables.length; k++) {
              if (unlockableId === opts.unlockables[k].id) {
                unlockablesToAttach.push(opts.unlockables[k]);
              }
            }
          }
        } catch (err) {
          console.error(err);
          opts.vm.$bugsnag?.notify(err);
          continue;
        }
      }

      const uniqueUnlockableIds = [];
      subscription.unlockables = unlockablesToAttach.filter(unlockable => {
        // get unique unlockables
        if (
          uniqueUnlockableIds.includes(unlockable.id) ||
          unlockable.name === 'full_reports'
        ) {
          return false;
        }
        uniqueUnlockableIds.push(unlockable.id);
        return true;
      });

      subscriptionsPlusUnlockables.push(subscription);
    }
    return subscriptionsPlusUnlockables;
  },
  /**
   * Gets active(autorenew = true) subscription that is tied to the people_reports unlockable
   * Please note although subscription is active, they may still not have people_reports access due to a failed payment (which is not checked in this method)
   * @param {object} opts
   * @param {array} opts.subscriptionsPlusUnlockables user's inventory subscriptions data plus array of tied unlockables, use getInventorySubscriptionsWithUnlockables for this array
   * @param {string} opts.type the type of subscription to look for an active one of (ex: people_reports, phone_reports, address_reports, email_reports)
   * @param {*} opts.defaultValue
   * @returns {*}
   */
  getActiveSubscriptionByType(opts) {
    if (!opts && !opts.subscriptionsPlusUnlockables && !opts.type) {
      return opts?.defaultValue ?? null;
    }
    for (let i = 0; i < opts.subscriptionsPlusUnlockables.length; i++) {
      const subscription = opts.subscriptionsPlusUnlockables[i];
      let hasUnlockableType = false;
      for (let i = 0; i < subscription.unlockables.length; i++) {
        if (subscription.unlockables[i].name === opts.type) {
          hasUnlockableType = true;
        }
      }
      if (subscription.autorenew && hasUnlockableType) {
        return subscription;
      }
    }
    return opts?.defaultValue ?? null;
  },
  /**
   * Returns name index url
   * @param {object} opts
   * @param {object} opts.body
   * @param {string} opts.domain
   * @param {boolean} opts.shouldReturnRes
   * @param {*} opts.defaultValue
   * @param {string} opts.billingVersion // 'v1' / 'v2'
   * @returns {*}
   */
  async chargeByInventoryRetryRebill(opts) {
    if (!opts || !opts.body || !opts.domain) {
      return opts?.defaultValue ?? false;
    }
    const { body, domain } = opts;
    const billingVersion = opts.billingVersion ?? 'v1';
    try {
      let res;
      if (billingVersion === 'v1') {
        res = await billingSDK(window).Charge.byInventory(
          body,
          domain,
          false,
          true
        );
      } else {
        res = await billingV2SDK(window).Charge.byInventory(
          body,
          domain,
          false,
          true
        );
      }
      if (
        !res ||
        (typeof res === 'object' && res.type && res.type.includes('error'))
      ) {
        return opts.shouldReturnRes ? res : opts.defaultValue ?? null;
      }
      return res;
    } catch (err) {
      console.error(err);
      opts.vm?.$bugsnag?.notify(err);
      return opts.shouldReturnRes ? err : opts.defaultValue ?? null;
    }
  },
  /**
   * Get a subscription by id (note this is not an inventorySubscription)
   * @param {object} opts
   * @param {string} opts.id subscription ID
   * @param {string} opts.domain
   * @param {*} opts.defaultValue
   * @param {object} opts.vm nuxt context
   * @param {object} $bugsnag Bugsnag instance
   * @returns {*}
   */
  async getSubscriptionById(opts) {
    if ((!opts && !opts.id && !opts.domain, !opts.vm)) {
      return opts?.defaultValue ?? null;
    }
    let subscription;
    try {
      if (!opts.vm.currentUser.user_data?.v2) {
        subscription = await billingSDK(window).Billing.Subscriptions.byId(
          opts.domain,
          opts.id
        );
      } else {
        subscription = await billingV2SDK(window).Subscriptions.byId(
          opts.domain,
          opts.id
        );
      }
    } catch (err) {
      console.error(err);
      opts.vm.$bugsnag?.notify(err);
      return opts.defaultValue ?? null;
    }

    if (
      !subscription ||
      (subscription && subscription.type && subscription.type.includes('error'))
    ) {
      return opts.defaultValue ?? null;
    }

    return subscription;
  },
  /**
   * Get a product by id (note this is not an inventoryProduct)
   * @param {object} opts
   * @param {string} opts.id product ID
   * @param {string} opts.domain
   * @param {*} opts.defaultValue
   * @param {object} $bugsnag = {}
   * @returns {*}
   */
  async getProductById(opts, $bugsnag = {}) {
    if (!opts && !opts.id && !opts.domain) {
      return opts?.defaultValue ?? null;
    }
    let product;
    try {
      product = await billingSDK(window).Billing.Products.byId(
        opts.domain,
        opts.id
      );
    } catch (err) {
      console.error(err);
      $bugsnag?.notify(err);
      return opts.defaultValue ?? null;
    }

    if (
      !product ||
      (product && product.type && product.type.includes('error'))
    ) {
      return opts.defaultValue ?? null;
    }

    return product;
  }
};

export default billingHelper;

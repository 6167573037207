/**
 * Return masked phone number
 *
 * @param {object} opts
 * @param {string|number} opts.phone
 * @param {string} opts.separator
 * @param {string} [opts.maskingSymbol]
 * @param {number} opts.exposedDigits
 * @returns {string|null}
 */
const maskPhoneNumber = (opts = {}) => {
  if (!opts.phone) {
    return null;
  }
  let phone = opts.phone;
  const { separator, exposedDigits, maskingSymbol } = opts;

  // Ensure phone is string
  phone = '' + phone;

  // Grab all the exposed digits first
  let formattedNumber = maskingSymbol ? phone.slice(0, exposedDigits) : phone;

  // If the digits should be masked, mask it, and append all to formatted number
  if (maskingSymbol) {
    formattedNumber += phone.slice(exposedDigits).replace(/\d/g, maskingSymbol);
  }

  // Now make the number pretty for our users
  formattedNumber =
    '(' +
    formattedNumber.slice(0, 3) +
    ') ' +
    formattedNumber.slice(3, 6) +
    '-' +
    formattedNumber.slice(6) +
    separator;

  return formattedNumber;
};

export default maskPhoneNumber;

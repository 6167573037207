/**
 * Return an array formatted for the selected locations
 *
 * @param {object} opts
 * @param {string} opts.allText
 * @param {string} opts.displayName
 * @param {number | null} opts.count
 * @param {string} opts.url
 * @param {string} [opts.allUrl] - all text url string
 * NOTE: allUrl is defaulted to undefined to signal its optional to eslint
 * @returns {[{displayName: string, urlValue: string | undefined}, {displayName: string, urlValue: string, count: number}] || []}
 */
const getSelectedLocationArray = (opts = {}) => {
  if (!opts || !Object.keys(opts).length) {
    return [];
  }
  const { allText, displayName, count, url, allUrl } = opts;

  if (
    typeof allText !== 'string' ||
    typeof displayName !== 'string' ||
    typeof url !== 'string'
  ) {
    return [];
  }

  return [
    {
      displayName: allText,
      urlValue: allUrl
    },
    {
      displayName: displayName,
      count: count,
      urlValue: url
    }
  ];
};

export default getSelectedLocationArray;

import getFormattedNonNullObject from '../helpers/getFormattedNonNullObject';
import getSubset from '../helpers/getSubset';
import setTitleCase from '../helpers/setTitleCase';
import dateHelper from '../dateHelper';
import formatStreetOnly from '../helpers/formatStreetOnly';
import formatFullName from '../helpers/formatFullName';
import formatAddressWithoutStreet from '../helpers/formatAddressWithoutStreet';
import formatAddressWithStreet from '../helpers/formatAddressWithStreet';
const falseyTextValue = 'Unknown';

/**
 * Returns formatted title for presentation on address card
 *
 * @param {object} address
 * @returns {string}
 */
const getAddressTitle = address => {
  const street = formatStreetOnly({ address });
  const location = formatAddressWithoutStreet({ address });
  let formattedLocation = '';
  if (street) {
    formattedLocation = street;
  }
  if (location) {
    formattedLocation +=
      formattedLocation === '' ? location : `<br/>${location}`;
  }
  return formattedLocation;
};

/**
 * Returns formatted address, or returns "Unknown"
 * @param {object} data
 * @returns {string}
 */
const formatAddress = data => {
  const address = getAddressTitle(data);
  return address === '' ? 'Unknown' : address;
};

const formatDate = text => {
  const date = dateHelper.formatDate(text, '/');
  return !date || date === '01/01/1600' ? falseyTextValue : date;
};

/**
 * Returns formatted corporate filing details
 *
 * @param {object} opts
 * @param {object} opts.data
 * @param {object} opts.default
 * @param {boolean} opts.formatForPDF
 * @param {object} $bugsnag
 * @returns {object}
 */
const getCorporateFilingDetails = (opts, $bugsnag = {}) => {
  if (!opts || !opts.data) {
    return opts?.default ? opts.default : {};
  }
  let header = {},
    details = {},
    corporate_amendments = [],
    business_contacts = [];
  try {
    const headerObj = getSubset(opts.data, ['business_name', 'filing_date']);
    headerObj.defaultValue = falseyTextValue;
    header = getFormattedNonNullObject(
      { obj: headerObj, defaultValue: falseyTextValue },
      null,
      null
    );
    header.business_name = setTitleCase({ text: header.business_name });
    header.filing_date = formatDate(header.filing_date);

    const detailsObj = getSubset(opts.data, [
      'filing_date',
      'business_name',
      'corporation_type',
      'extra.corporate_filing.address',
      'extra.corporate_filing.address_type',
      'extra.corporate_filing.incorporation.state',
      'filing_number',
      'extra.corporate_filing.filing_office.duns_number',
      'extra.corporate_filing.filing_office.name',
      'extra.corporate_filing.filing_office',
      'extra.corporate_filing.registration_type',
      'extra.corporate_filing.sec.status',
      'extra.corporate_filing.verification_date',
      'extra.corporate_filing.received_date',
      'extra.corporate_filing.perpetual_indicator',
      'extra.corporate_filing.corporate_amendments',
      'extra.corporate_filing.business_contacts',
      'extra.corporate_filing.sic',
      'extra.corporate_filing.misc_details'
    ]);
    detailsObj.defaultValue = falseyTextValue;
    details = getFormattedNonNullObject(
      { obj: detailsObj, defaultValue: falseyTextValue },
      null,
      null
    );
    details.filing_date = header.filing_date;
    details.business_name = header.business_name;

    details.address_type = setTitleCase({ text: details.address_type });
    details.status = setTitleCase({ text: details.status });
    details.registration_type = setTitleCase({
      text: details.registration_type
    });

    if (opts.formatForPDF) {
      const filingOffice = formatAddressWithStreet({
        address: details.filing_office
      });
      details.filing_office = !!filingOffice ? filingOffice : falseyTextValue;
    } else {
      details.filing_office = formatAddress(details.filing_office);
    }
    details.name = setTitleCase({ text: details.name });
    details.verification_date = formatDate(details.verification_date);
    details.received_date = formatDate(details.received_date);

    const formatedAddress = formatAddress(details.address);
    if (formatedAddress === 'Unknown' && details.address.full_address) {
      details.address = details.address.full_address;
    } else {
      details.address = formatedAddress;
    }

    corporate_amendments = details.corporate_amendments.map(i => {
      let filingDate = formatDate(i.filing_date);
      let desc = setTitleCase({ text: i.amendment_desc });
      let reason = setTitleCase({ text: i.reason });

      if (opts.formatForPDF) {
        filingDate = filingDate.length ? filingDate : falseyTextValue;
        desc = desc.length ? desc : falseyTextValue;
        reason = reason.length ? reason : falseyTextValue;
        return {
          filingDate,
          desc,
          reason
        };
      }
      if (filingDate || desc || reason) {
        return `${filingDate || '<i> Filing Date Unavailable </i>'}
              <br/> ${desc || '<i> Description Unavailable </i>'}
              <br/> ${reason || '<i> ReasonUnavailable </i>'}`;
      }
    });

    business_contacts = details.business_contacts
      .map(i => {
        let title = setTitleCase({ text: i.title });
        let business_name = setTitleCase({ text: i.business_name });
        let contact_name = formatFullName({
          firstName: i.firstname,
          middleName:
            i.middlename && i.middlename !== 'Unknown' ? i.middlename : null,
          lastName: i.lastname,
          useMiddleInitial: true,
          titleCase: true,
          defaultValue: 'Unknown'
        });
        let address;
        if (opts.formatForPDF) {
          title = title.length ? title : falseyTextValue;
          const businessAddress = formatAddressWithStreet({
            address: i
          });
          address = businessAddress.length ? businessAddress : falseyTextValue;
          return {
            title,
            contact_name,
            address
          };
        }
        title = title === 'Unknown' ? null : title;
        contact_name = contact_name === 'Unknown' ? null : contact_name;
        address = formatAddress(i);
        address = address === 'Unknown' ? null : address;

        if (title || contact_name || address) {
          return `<span>${title || '<i> Title Unavailable </i>'}</span>
              <br/> ${contact_name || '<i> Contact Name Unavailable </i>'}
              <br/> ${address || '<i> Address Unavailable </i>'}`;
        }
      })
      .filter(i => i !== undefined);
  } catch (err) {
    console.error(err);
    $bugsnag?.notify(err);
  }

  return { header, details, corporate_amendments, business_contacts };
};

export default getCorporateFilingDetails;

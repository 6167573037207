import setTitleCase from './setTitleCase';
import getMaskingString from './getMaskingString';
import formatAddressWithoutStreet from './formatAddressWithoutStreet';
import getSeoLink from '../../helpers/getSeoLink';
/**
 * Return address as a title cased string with optional masking
 *
 * @param {object} opts
 * @param {object} opts.address
 * @param {string} opts.separator
 * @param {string|null} opts.maskingSymbol
 * @param {boolean} opts.shouldMaskStreet
 * @param {boolean|null} opts.spaceBeforeSeparator
 * @param {string} opts.defaultValue
 * @param {boolean|null} opts.includeAnchorTags
 * @param {object} opts.router
 * @returns {string}
 */
const formatAddressWithStreet = (opts = {}) => {
  if (!opts || !opts.address) {
    return opts?.defaultValue ?? '';
  }
  // Map over street address object to ensure title case
  let street = setTitleCase({ text: opts.address.street });

  if (!street || opts.shouldMaskStreet) {
    if (!opts.address.city && !opts.address.state && !opts.address.zip) {
      return '';
    }

    let streetArray = [
      opts.address.street_number,
      opts.address.street_name,
      opts.address.street_type,
      opts.address.street_affix
    ];

    street =
      opts.address.street_name || opts.address.street_type
        ? streetArray
            .map(function(item) {
              // changes street number to a string
              if (typeof item === 'number') {
                return item.toString();
              }
              return setTitleCase({ text: item });
            })
            .filter(function(item) {
              return !!item;
            })
        : [];

    // If we should mask the street name
    if (opts.shouldMaskStreet && opts.maskingSymbol && street.length) {
      // Street name is the first and last item of street array with masking string in between
      street = `${street[0]} ${getMaskingString({
        number: 5,
        masking: opts.maskingSymbol
      })} ${street[street.length - 1]}`;
      // Otherwise street name is recompiled with title case
    } else {
      street = street.join(' ');
    }
  }

  const formattedAddress = formatAddressWithoutStreet({
    address: opts.address,
    separator: opts.separator,
    spaceBeforeSeparator: opts.spaceBeforeSeparator
  });

  let formattedString = `${
    street ? street.trim() + ', ' : ''
  }${formattedAddress}`;

  //if address should display as link to respective SEO address page
  if (opts.includeAnchorTags && !opts.shouldMaskStreet && street) {
    const cityStateZipArr = formattedAddress.split(',');
    const streetParam = street
      .trim()
      .split(' ')
      .join('-');
    const cityParam = cityStateZipArr[0];
    const stateParam = cityStateZipArr[1].trimStart().split(' ')[0];
    const zipParam = cityStateZipArr[1].trimStart().split(' ')[1];
    const addressLink = getSeoLink({
      params: {
        street: streetParam,
        city: cityParam,
        state: stateParam,
        zipcode: zipParam
      },
      seoType: 'address',
      router: opts.router
    });

    formattedString = `<a href="${addressLink}">${formattedString}</a>`;
  }

  return formattedString;
};

export default formatAddressWithStreet;

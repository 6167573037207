import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import parseParams from 'assets/js/shared/helpers/parseParams';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';

/**
 * Results Redirect Middleware
 * @param route
 * @param redirect
 * @returns {*}
 */
export default function({ route, redirect }) {
  const { params, fullPath, query } = route;
  const queryParams = Object.keys(query);
  const hasValidState =
    params.stateAbbr &&
    !!getStateFromAbbr({
      stateAbbr: params.stateAbbr,
      includeTerritories: true
    });
  let redirectParams = {};
  const hasValidPage = params.page && params.page <= 10;
  const hasValidCity = hasValidState && params.city;
  const hasValidQueryParams = queryParams.length;

  redirectParams.firstName = parseParams({ word: params.firstName })
    .replace(getRegex({ regexType: 'spaceRegEx' }), '_')
    .replace(getRegex({ regexType: 'dashRegEx' }), '')
    .replace(getRegex({ regexType: 'specialCharacters' }), '');

  redirectParams.lastName = parseParams({
    word: params.lastName,
    splitBy: ['_', ' '],
    parseBy: '_'
  }).replace(getRegex({ regexType: 'specialCharacters' }), '');

  let redirectUrl = `/${redirectParams.firstName}-${redirectParams.lastName}/`;

  if (hasValidState) {
    redirectParams.stateAbbr = params.stateAbbr.toUpperCase();
    redirectUrl = redirectUrl + redirectParams.stateAbbr;
    if (route.name === 'results.state') {
      redirectUrl = redirectUrl + '/';
    }
  }

  if (hasValidCity) {
    redirectParams.city = setTitleCase({
      text: params.city.replace(getRegex({ regexType: 'underscoreRegEx' }), ' ')
    }).replace(getRegex({ regexType: 'spaceRegEx' }), '_');
    redirectUrl = redirectUrl + `-${redirectParams.city}/`;
  }

  if (hasValidPage) {
    redirectParams.page = params.page;
    redirectUrl = redirectUrl + redirectParams.page + '/';
  }

  if (hasValidQueryParams) {
    const queryString = queryParams
      .map(key => key + '=' + query[key])
      .join('&');
    redirectUrl = redirectUrl + '?' + queryString;
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }

  // TODO: Try to get forward slash working on pretty solution for redirect
  // return redirect(301, {
  //   name: route.name,
  //   params: redirectParams,
  //   query: route.query
  // });
}

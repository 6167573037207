import formatUnderScoreToSpace from './formatUnderScoreToSpace';
import setTitleCase from './setTitleCase';
import formatFirstName from './formatFirstName';
import formatNameCaseWithSplitterAndSpacer from './formatNameCaseWithSplitterAndSpacer';

/**
 * Formats the full name using formatFirstName for first name and formatUnderscoreToSpace on last
 *
 * @param {object} opts
 * @param {string} opts.firstName
 * @param {string|null} [opts.middleName]
 * @param {boolean} [opts.useMiddleInitial]
 * @param {string} opts.lastName
 * @param {boolean} [opts.titleCase]
 * @param {string} opts.defaultValue
 * @returns {string}
 */
const formatFullName = (opts = {}) => {
  if (!opts || !opts.firstName || !opts.lastName) {
    return opts?.defaultValue ?? '';
  }

  const firstName = formatFirstName({
    name: opts.firstName,
    titleCase: true
  });

  let lastName = formatUnderScoreToSpace({ string: opts.lastName });

  let middleName = opts.middleName
    ? formatNameCaseWithSplitterAndSpacer({
        name: opts.middleName
      })
    : null;

  if (middleName && opts.useMiddleInitial) {
    middleName = middleName.charAt(0);
  }

  if (opts.titleCase) {
    lastName = setTitleCase({ text: lastName });
  }

  return `${firstName}${middleName ? ` ${middleName} ` : ' '}${lastName}`;
};

export default formatFullName;

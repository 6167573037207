import formatNameCaseWithSplitterAndSpacer from './formatNameCaseWithSplitterAndSpacer';
import formatUnderScoreToSpace from './formatUnderScoreToSpace';

/**
 * Returns formatted city name in title case with no underscores or dashes
 * @param {object} opts
 * @param {string} opts.city
 * @returns {null|string}
 */
const formatCity = (opts = {}) => {
  const { city } = opts;
  if (!city || city.toLowerCase().trim() === 'null') {
    return null;
  }
  const noscoreCity = formatUnderScoreToSpace({ string: city });
  const nodashCity = noscoreCity.replace(/-/g, ' ');
  return formatNameCaseWithSplitterAndSpacer({ name: nodashCity });
};

export default formatCity;

/**
 * Remove caches and unregister service workers
 * Source: https://stackoverflow.com/questions/47358643/how-do-i-clear-service-worker-caches-when-unregistering?noredirect=1&lq=1
 * @returns {Promise<void>}
 */
export async function unregisterAndClearCaches() {
  // Return early if browser doesn't support service worker
  if (!('serviceWorker' in navigator)) {
    return;
  }

  const registrations = await navigator.serviceWorker.getRegistrations();
  const unregisterPromises = registrations.map(registration =>
    registration.unregister()
  );

  const allCaches = await caches.keys();
  const cacheDeletionPromises = allCaches.map(cache => caches.delete(cache));

  await Promise.all([...unregisterPromises, ...cacheDeletionPromises]);
}

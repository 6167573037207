import formatStreetOnly from '../../helpers/formatStreetOnly';
import formatAddressWithoutStreet from '../../helpers/formatAddressWithoutStreet';
import formatNameCaseWithSplitterAndSpacer from '../../helpers/formatNameCaseWithSplitterAndSpacer';
import formatAddressWithStreet from '../../helpers/formatAddressWithStreet';
import setTitleCase from '../../helpers/setTitleCase';
import setAddressDates from '../../reportHelpers/setAddressDates';
import getAge from '../../helpers/getAge';
import getGender from '../../helpers/getGender';
import dateHelper from '../../dateHelper';
import getSkillsListString from '../../reportHelpers/getSkillsListString';
import formatArrayOrUnknown from '../../helpers/formatArrayOrUnknown';
import formatFullName from '../../helpers/formatFullName';
import formatPhoneNumbersAsString from '../../helpers/formatPhoneNumbersAsString';
import formatPhoneNumber from '../../helpers/formatPhoneNumber';
import getEmploymentDates from '../../reportHelpers/getEmploymentDates';
import formatMoney from '../../helpers/formatMoney';
import getFormattedNameOwner from '../../reportHelpers/getFormattedNameOwner';
import getFormattedDateYear from '../../helpers/getFormattedDateYear';
import getFormattedNumberWithCommas from '../../helpers/getFormattedNumberWithCommas';
import getDateRange from '../../reportHelpers/getDateRange';
import getEmploymentCompany from '../../reportHelpers/getEmploymentCompany';
import formatDates from '../../helpers/formatDates';
import getFormattedTime from '../../helpers/getFormattedTime';
import formatPhoneFromObject from '../../helpers/formatPhoneFromObject';
import getOwnerSinceDates from '../../reportHelpers/getOwnerSinceDates';
import getMunicipalityName from '../../reportHelpers/getMunicipalityName';
import getMunicipalityCode from '../../reportHelpers/getMunicipalityCode';
import getLanguageSpoken from '../../helpers/getLanguageSpoken';
import getZodiac from '../../reportHelpers/getZodiac';
const falseyTextValue = 'Unknown';
/**
 * Sets helper functions for handlebars templates
 * @param {object} handlebars
 * @returns {string}
 */
const setHandlebarsHelpers = handlebars => {
  handlebars.registerHelper('increment', function(index) {
    return parseInt(index) + 1;
  });
  handlebars.registerHelper('getFullName', function(fullName, unknownText) {
    return fullName === 'Unknown' || !fullName
      ? unknownText
        ? unknownText
        : 'Unknown'
      : fullName;
  });
  handlebars.registerHelper('getStreet', function(address, includeComma) {
    const street = formatStreetOnly({ address });
    if (!street) {
      return 'N/A';
    }
    if (street && includeComma) {
      return street + ',';
    }
    return street;
  });
  handlebars.registerHelper('getCityState', function(address) {
    let addressObj;
    if ('zip' in address) {
      const { zip, ...addressObj } = address;
    } else {
      addressObj = address;
    }
    return formatAddressWithoutStreet({
      address: addressObj,
      defaultValue: 'Unknown'
    });
  });
  handlebars.registerHelper('getCityStateZip', function(address) {
    return formatAddressWithoutStreet({ address });
  });
  handlebars.registerHelper('getAddressExtra', function(address, property) {
    const text = address.extra?.address_record?.address?.[property];
    return text ? setTitleCase({ text }) : falseyTextValue;
  });
  handlebars.registerHelper('getAddressDates', function(address) {
    return setAddressDates({ address, unknownText: falseyTextValue });
  });
  handlebars.registerHelper('setTitleCase', function(text) {
    return text ? setTitleCase({ text }) : falseyTextValue;
  });
  handlebars.registerHelper('formatArray', function(list = []) {
    return formatArrayOrUnknown({ list }) || falseyTextValue;
  });
  handlebars.registerHelper('formatAge', function(text) {
    return getAge({ dateString: text }) || falseyTextValue;
  });
  handlebars.registerHelper('formatArray', function(list = []) {
    return formatArrayOrUnknown({ list }) || falseyTextValue;
  });
  handlebars.registerHelper('formatGender', function(text) {
    return getGender({ gender: text, defaultValue: 'N/A' });
  });
  handlebars.registerHelper('getRelativeName', function(relative) {
    const opts = {
      firstName: relative.firstname,
      lastName: relative.lastname,
      middleName: relative.middlename,
      titleCase: true
    };
    return formatFullName(opts);
  });
  handlebars.registerHelper('getRelativeAge', function(relative) {
    const dobs = relative.extra?.person_summary?.dates_of_birth;
    if (!dobs || !dobs.length) {
      return 'Unknown';
    }
    return handlebars.helpers.formatAge(dobs[0].dob);
  });
  handlebars.registerHelper('getRelativeLocation', function(relative) {
    const city = formatNameCaseWithSplitterAndSpacer({
      name: relative.city
    });
    let location = city ? city : '';
    if (!city && !relative.state) {
      return 'Unknown';
    }
    location += `, ${relative.state}`;
    return location;
  });
  handlebars.registerHelper('getPhoneList', function(person) {
    const phones = person.extra?.person_summary?.other_phones;
    if (!phones || !phones.length) {
      return 'Unknown';
    }
    return formatPhoneNumbersAsString({
      phoneList: phones,
      displayCount: 50,
      generalSeparatorSymbol: ','
    });
  });
  handlebars.registerHelper('formatDate', function(text) {
    const date = dateHelper.formatDate(text, '/');
    return !date || date === '01/01/1600' || date === '-'
      ? falseyTextValue
      : date;
  });
  handlebars.registerHelper('getEmploymentCompany', function(employer) {
    return getEmploymentCompany({ employer, default: falseyTextValue });
  });
  handlebars.registerHelper('getEmploymentDates', function(employer) {
    return getEmploymentDates({ employer, default: falseyTextValue });
  });
  handlebars.registerHelper('getEmploymentAddress', function(employer) {
    return formatAddressWithStreet({
      address: employer.extra?.employer?.address,
      separator: '',
      shouldMaskStreet: false,
      defaultValue: falseyTextValue
    });
  });
  handlebars.registerHelper('getEmploymentPhone', function(employer) {
    return formatPhoneFromObject({
      area: employer.phone?.area,
      line: employer.phone?.line,
      office: employer.phone?.office,
      defaultValue: falseyTextValue
    });
  });
  handlebars.registerHelper('getDateRange', function(objWithDates) {
    return getDateRange({ objWithDates, default: falseyTextValue });
  });
  handlebars.registerHelper('formatDates', function(startDate, endDate) {
    return formatDates({ startDate, endDate, defaultValue: falseyTextValue });
  });
  handlebars.registerHelper('ternary', function(value, yes, no) {
    const noValue = typeof no === 'string' ? no : falseyTextValue;
    return !!value ? yes : noValue;
  });
  handlebars.registerHelper('falseyCheck', function(value, no) {
    const noValue = typeof no === 'string' ? no : falseyTextValue;
    return !!value ? value : noValue;
  });
  handlebars.registerHelper('formatAddress', function(address, type = '') {
    if (!address) {
      return falseyTextValue;
    }
    let addressData;

    switch (type) {
      case 'court':
        addressData = {
          city: address.court_city,
          state: address.court_state,
          street_number: address.court_street_number,
          street_name: address.court_street_name,
          street_type: address.court_street_type,
          street_affix: address.court_street_affix
        };
        break;
      case 'trustee':
        addressData = {
          city: address.trustee_city,
          state: address.trustee_state,
          street_number: address.trustee_street_number,
          street_name: address.trustee_street_name,
          street_type: address.trustee_street_type,
          street_affix: address.trustee_street_affix
        };
        break;
      case 'attorney':
        addressData = {
          city: address.attorney_city,
          state: address.attorney_state,
          street_number: address.attorney_street_number,
          street_name: address.attorney_street_name,
          street_type: address.attorney_street_type,
          street_affix: address.attorney_street_affix
        };
        break;
      default:
        addressData = address;
        break;
    }

    const formattedAddress = formatAddressWithStreet({ address: addressData });

    if (formattedAddress.includes('Unknown')) {
      return falseyTextValue;
    }

    return !!formattedAddress ? formattedAddress : falseyTextValue;
  });
  handlebars.registerHelper('getFormattedTime', function(time) {
    return getFormattedTime({ time }) || falseyTextValue;
  });
  handlebars.registerHelper('formatPhone', function(phoneNumber) {
    return phoneNumber ? formatPhoneNumber({ phoneNumber }) : falseyTextValue;
  });
  handlebars.registerHelper('concat', function(a, b) {
    return a + b;
  });
  handlebars.registerHelper('replace', function(str, value, replacement) {
    return str.replace(value, replacement);
  });
  handlebars.registerHelper('formatMoney', function(number) {
    return formatMoney({ number, default: falseyTextValue });
  });
  handlebars.registerHelper('getFormattedNameOwner', function(property) {
    return getFormattedNameOwner({ data: property, default: falseyTextValue });
  });
  handlebars.registerHelper('getFormattedDateYear', function(date) {
    return getFormattedDateYear({ date, default: falseyTextValue });
  });
  handlebars.registerHelper('getFormattedNumberWithCommas', function(number) {
    return getFormattedNumberWithCommas({ number, default: falseyTextValue });
  });
  handlebars.registerHelper('toLowerCase', function(str) {
    return str.toLowerCase();
  });
  handlebars.registerHelper('formatFullName', function(person) {
    const opts = {
      firstName: person.firstname,
      lastName: person.lastname,
      middleName: person.middlename,
      titleCase: true
    };
    const fullName = formatFullName(opts);
    return !!fullName ? fullName : falseyTextValue;
  });
  handlebars.registerHelper('formatPhoneNumber', function(
    phoneNumber,
    type = ''
  ) {
    let phoneData;

    switch (type) {
      case 'attorney':
        phoneData = formatPhoneFromObject({
          area: phoneNumber.attorney_phone_area,
          office: phoneNumber.attorney_phone_office,
          line: phoneNumber.attorney_phone_line
        });
        break;
      case 'trustee':
        phoneData = formatPhoneFromObject({
          area: phoneNumber.trustee_phone_area,
          office: phoneNumber.trustee_phone_office,
          line: phoneNumber.trustee_phone_line
        });
        break;
      default:
        phoneData = formatPhoneNumber({ phoneNumber });
        break;
    }

    return phoneData ? phoneData : falseyTextValue;
  });
  handlebars.registerHelper('socialMediaTableRows', function(accountsArray) {
    const filteredAccounts = filterSocialMediaAccounts(accountsArray);
    let filteredAccountsMarkup = '';

    for (let index = 0; index < filteredAccounts.length; index++) {
      const account = filteredAccounts[index];

      filteredAccountsMarkup += `
        <tr>
          <td>${index + 1}</td>
          <td>${setTitleCase({ text: account.platform })}</td>
          <td class="cell">${account.username}</td>
          <td class="cell">${account.url}</td>
        </tr>`;
    }

    return new handlebars.SafeString(filteredAccountsMarkup);
  });
  handlebars.registerHelper('getOwnerSinceDates', function(asset) {
    return getOwnerSinceDates({ asset, default: falseyTextValue });
  });
  handlebars.registerHelper('getMunicipalityName', function(asset) {
    return getMunicipalityName({ asset, default: falseyTextValue });
  });
  handlebars.registerHelper('getMunicipalityCode', function(asset) {
    return getMunicipalityCode({ asset, default: falseyTextValue });
  });
  handlebars.registerHelper('socialMediaAccountCount', function(accountsArray) {
    const filteredAccounts = filterSocialMediaAccounts(accountsArray);
    return filteredAccounts.length;
  });
  handlebars.registerHelper('removeDuplicates', function(array) {
    // prettier-ignore
    const filteredOwners = array.filter((owner, index, self) =>
      index === self.findIndex(o =>
        o.name.firstname.trim().toLowerCase() === owner.name.firstname.trim().toLowerCase() &&
        o.name.lastname.trim().toLowerCase() === owner.name.lastname.trim().toLowerCase()
      )
    );
    return filteredOwners;
  });
  handlebars.registerHelper('formatName', function(name) {
    if (!name || typeof name !== 'string') return '';
    return name
      .trim()
      .split(' ')
      .map(w => w.charAt(0).toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
  });
  handlebars.registerHelper('formatFullName', function(name) {
    let fullName = {
      firstName: this.firstname,
      middleName: this.middlename,
      lastName: this.lastname,
      titleCase: true
    };

    if (name) {
      fullName = {
        firstName: name.firstname,
        middleName: name.middlename,
        lastName: name.lastname,
        titleCase: true
      };
    }

    return formatFullName(fullName) || falseyTextValue;
  });
  handlebars.registerHelper('uniqueDebtorsRows', function(debtors) {
    let markupArray = [];

    for (let i = 0; i < debtors.length; i++) {
      const debtor = debtors[i];
      const {
        firstname,
        middlename,
        lastname,
        phone,
        street_affix,
        street_name,
        street_number,
        street_type,
        city,
        state
      } = debtor;

      const phoneNumber = !phone?.full
        ? 'Unknown'
        : formatPhoneNumber({ phoneNumber: phone.full });

      markupArray.push(`
        <tr>
          <td class="cell cell--span-fourth">${formatFullName({
            firstName: firstname,
            middleName: middlename,
            lastName: lastname,
            titleCase: true
          })}</td>
          <td class="cell cell--span-fourth">${phoneNumber}</td>
          <td class="cell">${formatAddressWithStreet({
            address: {
              street_number,
              street_name,
              street_type,
              street_affix,
              city,
              state
            }
          })}</td>
        </tr>
      `);
    }

    const set = [...new Set(markupArray)];
    return set;
  });
  handlebars.registerHelper('getSkillsListString', function(skills) {
    return getSkillsListString({ skills });
  });
  handlebars.registerHelper('today', function() {
    return new Date().toLocaleDateString(undefined, {
      timeZone: 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  });
  handlebars.registerHelper('getLanguages', function(languages) {
    if (!languages) {
      return 'N/A';
    }
    let languagesSpoken = [];
    for (let c = 0; c < languages.length; c++) {
      let tmp = getLanguageSpoken({
        languageAbbr: languages[c].abbreviation
      });
      if (tmp !== 'N/A') {
        languagesSpoken.push(tmp);
      }
    }
    if (languagesSpoken.length > 1) {
      languagesSpoken = languagesSpoken.join(', ');
      return languagesSpoken;
    }
    return languagesSpoken[0];
  });
  handlebars.registerHelper('getZodiac', function(report) {
    return getZodiac({ report, defaultValue: falseyTextValue });
  });
  handlebars.registerHelper('getFormattedNeighborName', function(neighbor) {
    let formattedName = '';
    if (neighbor.residents && neighbor.residents.length > 0) {
      const resident = neighbor.residents.find(resident => {
        return (
          resident.names &&
          resident.names.length > 0 &&
          resident.names[0].firstname &&
          resident.names[0].lastname &&
          resident.extra &&
          resident.extra.resident &&
          resident.extra.resident.report_token
        );
      });
      const name = resident.names[0];
      formattedName = formatFullName({
        firstName: name.firstname,
        lastName: name.lastname,
        middleName: name.middlename,
        titleCase: true
      });
    }
    return formattedName;
  });
  handlebars.registerHelper('getNeighborAge', function(neighbor) {
    if (neighbor.residents && neighbor.residents.length > 0) {
      const resident = neighbor.residents.find(resident => {
        return (
          resident.current_age &&
          resident.extra &&
          resident.extra.resident &&
          resident.extra.resident.report_token
        );
      });
      if (resident) {
        return resident.current_age;
      } else return 'Unknown';
    }
  });
  handlebars.registerHelper('ifEquals', function(a, b, options) {
    return a == b ? options.fn(this) : options.inverse(this);
  });
};

const filterSocialMediaAccounts = (accountsArray = []) => {
  return accountsArray.filter((account, index) => {
    if (!!account.url) {
      const firstIndex = accountsArray.findIndex(acc => {
        return acc.username === account.username;
      });
      return firstIndex === index;
    }
  });
};

export default setHandlebarsHelpers;

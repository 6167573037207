import dateHelper from '../dateHelper';

/**
 * Returns formatted start and end dates
 * @param {object} opts
 * @param {string} opts.startDate
 * @param {string} opts.endDate
 * @param {string} opts.defaultValue
 * @returns {string}
 */

const formatDates = (opts = {}) => {
  const { startDate, endDate, defaultValue } = opts;
  if (!opts || (!startDate && !endDate)) {
    return defaultValue ? defaultValue : '';
  }
  const firstYear = new Date(startDate).getFullYear() + 1;
  const lastYear = new Date(endDate).getFullYear() + 1;

  if (firstYear < 1800 && lastYear < 1800) {
    return defaultValue ? defaultValue : '';
  }

  const firstDate =
    startDate && firstYear > 1800
      ? dateHelper.formatDate(startDate, '/')
      : 'Unknown';
  const lastDate =
    endDate && lastYear > 1800
      ? dateHelper.formatDate(endDate, '/')
      : 'Present';
  return `${firstDate} - ${lastDate}`;
};

export default formatDates;

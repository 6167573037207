<template>
  <client-only v-if="showLoggedInUserElements">
    <div>
      <wl-header
        id="header"
        ref="wlHeaderRef"
        :logo="header.logo"
        :logo-standard-size="logoSize"
        :logo-small="header.logo_small"
        :logo-small-size="logoSmallSize"
        :logo-title="header.logo_title"
        :menu="dashboardMenu"
        :logo-path="`/dashboard/`"
        :path="path"
        :is-fixed="true"
        :hide-menu="isLanding"
        :show-aux-menu="true"
        class="wl-header--dashboard"
        :class="{
          'wl-header--mobile-search': mobileSearchActive,
          'wl-header--upsell': isUpsell
        }"
      >
        <template #header-bar v-if="!isLanding">
          <button
            type="button"
            id="searchToggle"
            class="ic-search-toggle"
            aria-controls="searchBar"
            aria-expanded="false"
            aria-label="Toggle Search"
            v-touch:tap.prevent="mobileSearchOpen"
          >
            <span class="ic-search-toggle__text" v-text="mobileSearchText" />
            <div class="ic-search-toggle__icon">
              <img
                :src="header.mobile_search_icon"
                alt="Search Icon"
                loading="lazy"
                width="15px"
                height="15px"
                class="ic-search-toggle__icon-image"
              />
            </div>
          </button>
          <tz-search
            :in-header="true"
            :prefill-fields="prefillFields"
            :fallback-fields="fallbackFields"
            :people-link="peopleURL()"
            :phone-link="phoneURL()"
            :search-icon="header.mobile_search_icon"
            class="tz-search--dashboard"
            :no-query-string="true"
            @tz-search-override-redirect="navigateToResultsSearch"
          />
          <button
            type="button"
            id="searchClose"
            class="ic-search-cancel"
            v-touch:tap.prevent="mobileSearchClose"
            v-text="`Cancel`"
          />
          <div class="ic-dropdown-menu">
            <ul class="ic-dropdown-menu__items">
              <li
                v-for="(navItem, index) in dashboardDropdownMenu"
                class="ic-dropdown-menu__item"
                :class="
                  getItemClass({
                    label: navItem.label,
                    blockEl: 'ic-dropdown-menu__item'
                  })
                "
                :key="`dropdownItem${index}`"
              >
                <button
                  v-if="navItem.label === 'Report Monitoring'"
                  v-touch:tap="handleReportMonitoring"
                  class="ic-dropdown-menu__item-content"
                >
                  {{ navItem.label }}
                </button>
                <button
                  v-else-if="navItem.label === 'Log Out'"
                  v-touch:tap="handleLogOut"
                  class="ic-dropdown-menu__item-content"
                >
                  {{ navItem.label }}
                </button>
                <button
                  v-else
                  v-touch:tap="
                    async () => navigateToDashboardRoute(navItem.url)
                  "
                  class="ic-dropdown-menu__item-content"
                >
                  {{ navItem.label }}
                </button>
              </li>
            </ul>
          </div>
          <wl-trust-badges v-if="isUpsell" />
        </template>
      </wl-header>
      <div class="account__banner" v-if="displayBanner">
        <p>
          {{ accountCMS.reactivation_bar.title }}
        </p>
        <router-link
          :to="{ path: '/dashboard/reactivate/' }"
          class="account__banner-btn"
        >
          {{ accountCMS.reactivation_bar.button_text }}
        </router-link>
      </div>
      <div class="main" ref="insideContent">
        <div
          class="ic-dashboard"
          :class="{
            'ic-dashboard--not-dashboard-route':
              !shouldShowUpsells && !shouldShowSideNav,
            'ic-dashboard--side-nav-only':
              shouldShowSideNav && !shouldShowUpsells
          }"
        >
          <ul v-if="shouldShowSideNav" class="ic-dashboard__menu">
            <li
              class="ic-dashboard__menu-item"
              :class="
                getItemClass({
                  label: link.label,
                  blockEl: 'ic-dashboard__menu-item'
                })
              "
              v-for="(link, index) in header.dashboard_navigation_menu"
              :key="`menuItem${index}`"
            >
              <router-link :to="link.url">{{ link.label }}</router-link>
            </li>
            <li
              v-if="showTestFeatures"
              class="ic-dashboard__menu-item ic-dashboard__menu-item--search-history"
            >
              <router-link
                v-html="`Search History`"
                to="/dashboard/search-history/"
              />
            </li>
            <li
              v-if="showTestFeatures"
              class="ic-dashboard__menu-item ic-dashboard__menu-item--monitored-reports"
              :class="{
                'ic-dashboard__menu-item--locked': !hasEnabledMonitoring
              }"
            >
              <component
                v-bind:is="hasEnabledMonitoring ? 'router-link' : 'button'"
                v-html="`Report Monitoring`"
                :to="hasEnabledMonitoring && '/dashboard/monitor/'"
                v-touch:tap="
                  () => !hasEnabledMonitoring && toggleModal('reportMonitoring')
                "
              />
              <span
                v-if="hasEnabledMonitoring"
                class="ic-dashboard__menu-total"
                v-text="`2`"
              />
            </li>
            <li class="ic-dashboard__menu-item ic-dashboard__menu-item--logout">
              <button v-touch:tap="handleLogOut" v-text="`Log Out`" />
            </li>
          </ul>
          <div class="ic-dashboard__main">
            <nuxt
              :key="`permissionUpdated-${pdfPermissionUpdated}`"
              v-if="!this.isLoadingInventory"
              ref="page"
              :prefill-fields="prefillFields"
              :currentUser="currentUser"
              @no-active-subscription="handleNoSubscription"
              @display-pdf-upsell-modal="toggleModal('pdf')"
              @trigger-pdf-option="openPDFOptionModal"
              @send-toast="sendToastFromChild"
              @trigger-report-monitoring="handleMonitoringReport"
            />
          </div>
          <div v-if="shouldShowUpsells" class="ic-dashboard__sidebar">
            <ic-monitoring-updates
              v-if="showMonitorReportWidget && showTestFeatures"
              :title="accountCMS.latest_monitoring_updates.title"
              :button-url="accountCMS.latest_monitoring_updates.button.url"
              :button-text="accountCMS.latest_monitoring_updates.button.text"
              :secondary-title="
                accountCMS.latest_monitoring_updates.no_updates.title
              "
              :secondary-paragraph="
                accountCMS.latest_monitoring_updates.no_updates.body
              "
              :tertiary-title="
                accountCMS.latest_monitoring_updates.not_monitoring.title
              "
              :tertiary-paragraph="
                accountCMS.latest_monitoring_updates.not_monitoring.body
              "
              :list="[1, 2, 3]"
            />
            <div
              v-if="displayUpsellLinks && !isLoadingPermissions"
              class="ic-dashboard__upsell-cta-section"
            >
              <h2 v-text="accountCMS.upsell_cta.section_title" />
              <wl-upsell-cta
                v-if="!this.hasEnabledPdf && pdfProduct"
                class="wl-upsell-cta--pdf"
                :title="accountCMS.upsell_cta.pdf.title"
                :sub-text="accountCMS.upsell_cta.pdf.subtext"
                :cta-label="accountCMS.upsell_cta.pdf.cta_label"
                @wl-upsell-cta-click="toggleModal('pdf')"
              />
              <wl-upsell-cta
                v-if="!this.hasEnabledMonitoring && reportMonitoringProduct"
                class="wl-upsell-cta--report-monitoring"
                :title="accountCMS.upsell_cta.report_monitoring.title"
                :sub-text="accountCMS.upsell_cta.report_monitoring.subtext"
                :cta-label="accountCMS.upsell_cta.report_monitoring.cta_label"
                @wl-upsell-cta-click="toggleModal('reportMonitoring')"
              />
              <wl-upsell-cta
                v-if="!this.hasPhonePermission"
                class="wl-upsell-cta--phone"
                :title="accountCMS.upsell_cta.phone_search.title"
                :sub-text="accountCMS.upsell_cta.phone_search.subtext"
                :cta-label="accountCMS.upsell_cta.phone_search.cta_label"
                @wl-upsell-cta-click="
                  navigateToDashboardRoute('/dashboard/subscribe/phone/')
                "
              />
              <wl-upsell-cta
                v-if="!this.hasEmailPermission"
                class="wl-upsell-cta--email"
                :title="accountCMS.upsell_cta.email_search.title"
                :sub-text="accountCMS.upsell_cta.email_search.subtext"
                :cta-label="accountCMS.upsell_cta.email_search.cta_label"
                @wl-upsell-cta-click="
                  navigateToDashboardRoute('/dashboard/subscribe/email/')
                "
              />
              <wl-upsell-cta
                v-if="!this.hasAddressPermission"
                class="wl-upsell-cta--address"
                :title="accountCMS.upsell_cta.address_search.title"
                :sub-text="accountCMS.upsell_cta.address_search.subtext"
                :cta-label="accountCMS.upsell_cta.address_search.cta_label"
                @wl-upsell-cta-click="
                  navigateToDashboardRoute('/dashboard/subscribe/address/')
                "
              />
            </div>
            <wl-banner-alert
              v-for="item in renderIfReturnArray({
                condition: global.banner_alert.is_visible
              })"
              :key="`bannerAlert_${item}`"
              class="wl-banner-alert--sidebar"
              :copy="global.banner_alert.copy"
              :is-hurricane="global.banner_alert.is_hurricane"
            />
            <wl-card
              :title="accountCMS.sidebar.contact.title"
              class="wl-card--help"
            >
              <tz-markdown :content="accountCMS.sidebar.contact.subtext" />
            </wl-card>
          </div>
          <template v-if="isPersonReport">
            <div class="ic-dashboard__footer" v-if="showFooterSearch">
              <div class="ic-dashboard__footer-wrapper">
                <h5
                  class="ic-dashboard__footer-title"
                  v-text="`Try Making Another Search`"
                />
                <div class="ic-dashboard__search-wrapper">
                  <div class="ic-dashboard__search-dropdown">
                    <button
                      class="ic-dashboard__dropdown"
                      :class="{
                        'ic-dashboard__dropdown--open': isDropdownOpen
                      }"
                      v-touch:tap="handleDropdown"
                      v-text="searchType"
                    />
                    <ul
                      class="ic-dashboard__dropdown-list"
                      :class="{
                        'ic-dashboard__dropdown-list--show': isDropdownOpen
                      }"
                    >
                      <li
                        class="ic-dashboard__dropdown-item"
                        :class="{
                          'ic-dashboard__dropdown-item--active':
                            searchItem.name === searchType
                        }"
                        :key="searchItem.name"
                        v-for="searchItem in searchTypes"
                        v-touch:tap="
                          () => handleSearchType({ type: searchItem.type })
                        "
                        v-text="searchItem.name"
                      />
                    </ul>
                  </div>
                  <tz-search
                    :display-search-text="true"
                    :prefill-fields="prefillFields"
                    :fallback-fields="fallbackFields"
                    :people-link="peopleURL()"
                    :phone-link="phoneURL()"
                    :text-search-icon="header.mobile_search_icon"
                    :no-query-string="true"
                    @tz-search-override-redirect="navigateToResultsSearch"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
        <tz-footer
          v-if="!isUpsell"
          class="tz-footer--dashboard"
          :header-fixed="isFixed"
          :footer="{
            ...footer,
            disclaimer: dashboardDisclaimer
          }"
          :has-trailing-slash="true"
          :display-sections="['disclaimer']"
          :page="{
            path: $route.path,
            name: $route.name,
            params: $route.params
          }"
          alpha-base-link="/names-by-letter/"
        />
      </div>
      <ic-account-modal
        :is-modal-open="isReactivateModalOpen"
        @ic-account-modal-close="toggleModal('reactivate')"
        class="ic-account-modal--upsell"
      >
        <ic-upsell
          :heading="accountCMS.subscription.renew_text"
          :sub-heading="accountCMS.subscription.no_subscription_text"
          :perks="accountCMS.subscription.perks"
          :submitText="accountCMS.subscription.no_subscription_submit_text"
          :cancelButtonText="accountCMS.buttons.cancel"
          @ic-account-modal-close="toggleModal('reactivate')"
        />
      </ic-account-modal>
      <ic-account-modal
        v-if="activePeopleSubscription"
        :is-modal-open="isRetryRebillModalOpen"
        @ic-account-modal-close="toggleModal('retry-rebill-error')"
        class="ic-account-modal--no-search ic-account-modal--retry"
      >
        <ic-retry-rebill-error-modal
          subscription-type="default"
          :inventory-id="activePeopleSubscription.id"
          :hide-retry-btn="hideRetryBtn"
          :user="currentUser"
          :domain="domain"
          :redirectPath="redirectPath"
          @successful-charge-by-inventory="handleChargeByInventorySuccess"
          @failed-charge-by-inventory="handleChargeByInventoryFailure"
          @ic-account-modal-close="toggleModal('retry-rebill-error')"
        />
      </ic-account-modal>
      <ic-account-modal
        :is-modal-open="isPdfModalOpen"
        @ic-account-modal-close="toggleModal('pdf')"
        class="ic-account-modal--upsell-cta"
      >
        <wl-upsell-content
          body-modifier="pdf-page-upsell"
          :heading="accountCMS.upsell_modal.pdf.title"
          :sub-heading="accountCMS.upsell_modal.pdf.secondary_subtitle"
          :warning-text="pdfWarningText"
          :submit-text="accountCMS.upsell_modal.pdf.submit_text"
          :cancel-button-text="accountCMS.upsell_modal.pdf.cancel_text"
          :is-processing="isUpsellProcessing"
          @wl-upsell-modal-submit="submitUpsell('pdf', pdfProduct)"
          @wl-upsell-modal-close="toggleModal('pdf')"
        >
          <template #perks>
            <div class="wl-upsell-content-body__wrapper">
              <p class="wl-upsell-content-body__perks-heading">
                Why get PDF Reports?
              </p>
              <div class="wl-upsell-content-body__img">
                <img :src="upsellImg" />
              </div>
              <ul class="wl-upsell-content-body__perks">
                <li
                  v-for="perk in accountCMS.upsell_modal.pdf.perks"
                  class="wl-upsell-content-body__perk"
                >
                  {{ perk }}
                </li>
              </ul>
            </div>
          </template>
        </wl-upsell-content>
      </ic-account-modal>
      <ic-account-modal
        :is-modal-open="isMonitoringModalOpen"
        @ic-account-modal-close="toggleModal('reportMonitoring')"
        class="ic-account-modal--upsell-cta"
      >
        <wl-upsell-content
          body-modifier="report-monitoring-upsell"
          :heading="accountCMS.upsell_modal.report_monitoring.title"
          :sub-heading="accountCMS.upsell_modal.report_monitoring.subtitle"
          :perks-title="accountCMS.upsell_modal.report_monitoring.perks_title"
          :warning-text="reportMonitoringWarningText"
          :submit-text="accountCMS.upsell_modal.report_monitoring.submit_text"
          :cancel-button-text="
            accountCMS.upsell_modal.report_monitoring.cancel_text
          "
          :is-processing="isUpsellProcessing"
          @wl-upsell-modal-close="toggleModal('reportMonitoring')"
          @wl-upsell-modal-submit="
            submitUpsell('reportMonitoring', reportMonitoringProduct)
          "
        >
          <template #perks>
            <div class="wl-upsell-content-body__perks">
              <ul>
                <li
                  v-for="perk in accountCMS.upsell_modal.report_monitoring.perks.slice(
                    0,
                    5
                  )"
                  class="wl-upsell-content-body__perk"
                >
                  {{ perk }}
                </li>
              </ul>
              <ul>
                <li
                  v-for="perk in accountCMS.upsell_modal.report_monitoring.perks.slice(
                    5
                  )"
                  class="wl-upsell-content-body__perk"
                >
                  {{ perk }}
                </li>
              </ul>
            </div>
          </template>
        </wl-upsell-content>
      </ic-account-modal>
      <ic-account-modal
        :isModalOpen="isPaymentModalOpen"
        @ic-account-modal-close="toggleModal('payment')"
        class="ic-account-modal--payment"
      >
        <ic-add-payment
          :userId="currentUser.id"
          :domain="currentUser.domain"
          :error-msg="paymentErrorMsg"
          :billing-version="currentUser?.user_data?.v2 ? 'v2' : 'v1'"
          @ic-account-modal-close="toggleModal('payment')"
          @add-payment="retryUpsellWithNewPayment"
        />
      </ic-account-modal>
      <ic-account-modal
        :isModalOpen="isPdfOptionModalOpen"
        @ic-account-modal-close="toggleModal('pdf-option')"
        class="ic-account-modal--pdf-option"
      >
        <pdf-option
          @pdf-option-close-modal="toggleModal('pdf-option')"
          :title="pdfOptionCms.title"
          :subtitle="pdfOptionSubtitle"
          :submit-button-text="pdfOptionCms.submit_button_text"
          :options="pdfOptions"
          :cancel-button-text="header.modal.cancel_button_text"
          @generate-pdf="generatePDF"
        />
      </ic-account-modal>
      <ic-account-modal
        :is-modal-open="isEditPasswordModalOpen"
        @ic-account-modal-close="toggleModal('edit-password')"
        class="ic-account-modal--password"
      >
        <ic-edit-password
          :title="this.passwordModalTitle"
          :submit-button-text="this.passwordModalSubmitTxt"
          cancel-button-text="Cancel"
          :is-submitting="isUpdatingPassword"
          :did-error="passwordUpdateFail"
          :error-message="passwordErrorMessage"
          @ic-edit-password-close-modal="toggleModal('edit-password')"
          @ic-edit-password-update="updatePassword"
        />
      </ic-account-modal>
      <ic-account-modal
        :is-modal-open="isEditAddressModalOpen"
        @ic-account-modal-close="toggleModal('personalAddress')"
        class="ic-account-modal--address"
      >
        <WlAddressForm
          @address-submit="updateAddress"
          @cancel-submit="toggleModal('personalAddress')"
          title="Edit Address"
          submitBtnText="Update"
          :initialValues="addressInitialValues"
          :showCancelBtn="true"
          :formErrorMsg="addressErrorMsg"
        />
      </ic-account-modal>
      <div
        class="ic-scrim"
        v-touch:tap.self.stop="closeHeaderModal"
        :class="{ 'ic-scrim__results': isResults }"
      >
        <wl-modal-form
          :class="{ 'wl-modal-form--dashboard-phone': hasPhoneQuery }"
          :open="true"
          :modal-title="modalTitle"
          :modal-text="modalText"
          :inputs="inputs"
          :confirm-button-text="header.modal.confirm_button_text"
          :cancel-button-text="header.modal.cancel_button_text"
          :close-modal="closeHeaderModal"
          @wl-modal-input-focus="removeInputError"
          @wl-modal-form-confirm="navigateToDashboardResults"
          @wl-modal-form-cancel="closeHeaderModal"
        >
          <template
            #above-form-content
            v-for="item in renderIfReturnArray({
              condition: isResults && !hasPhoneQuery
            })"
          >
            <ul class="ic-hint">
              <li
                v-for="(listItem, index) in renderIfReturnArray({
                  condition: header.modal.results.hints
                })"
                :key="`hint_${listItem.text}`"
                class="ic-hint__item"
              >
                <span class="ic-hint__icon" v-text="index + 1" />
                <p class="ic-hint__text" v-text="listItem.text" />
              </li>
            </ul>
          </template>
        </wl-modal-form>
      </div>
    </div>
  </client-only>
</template>

<script>
// Components
import WlHeader from '@/components/shared/wl-header';
import WlCard from '@/components/shared/wl-card';
import PdfOption from '@/components/pdf-option';
import WlModalForm from '@/components/shared/wl-modal-form';
import WlUpsellCta from '@/components/shared/wl-upsell-cta';
import IcAccountModal from '@/components/ic-account-modal';
import WlUpsellContent from '~/components/shared/wl-upsell-content';
import IcUpsell from '@/components/ic-upsell';
import IcRetryRebillErrorModal from '@/components/ic-retry-rebill-error-modal';
import IcAddPayment from '@/components/ic-add-payment';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import TzSearch from '@trazi/tz-search/src/tz-search.vue';
import TzSpinner from '@trazi/tz-spinner/src/tz-spinner.vue';
import TzFooter from '@trazi/tz-footer/src/tz-footer.vue';
import IcMonitoringUpdates from '@/components/ic-monitoring-updates.vue';
import IcEditPassword from '@/components/ic-edit-password';
import WlTrustBadges from '@/components/shared/wl-trust-badges.vue';
import upsellImg from '@/assets/images/upsell-img.webp';
// Helpers
import billingSDK from '@/assets/js/shared/services/billing-sdk';
import billingV2SDK from '@/assets/js/shared/services/billing-v2-sdk';
import getMobileQuery from '@/assets/js/shared/helpers/getMobileQuery';
import mediaQueryListener from '@/assets/js/shared/helpers/mediaQueryListener';
import formatCity from '@/assets/js/shared/helpers/formatCity';
import formatMoney from 'assets/js/shared/helpers/formatMoney';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import getStateAbbrFromName from '@/assets/js/shared/helpers/getStateAbbrFromName';
import resultHelper from '@/assets/js/resultHelper';
import { unregisterAndClearCaches } from '@/assets/js/shared/service-worker';
import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';
import getStatesForDropdown from '@/assets/js/shared/helpers/getStatesForDropdown';
import renderIfReturnArray from '@/assets/js/shared/helpers/renderIfReturnArray';
import paymentHelper from 'assets/js/paymentHelper';
import setSessionItem from 'assets/js/shared/helpers/setSessionItem';
import getSessionItem from 'assets/js/shared/helpers/getSessionItem';
import formatFullName from '@/assets/js/shared/helpers/formatFullName';
import dashedPhoneNumber from '@/assets/js/shared/helpers/dashedPhoneNumber';
import getUrlQueryString from '@/assets/js/shared/helpers/getUrlQueryString';
import formatPhoneNumber from '@/assets/js/shared/helpers/formatPhoneNumber';
import getSessionId from '@/assets/js/helpers/getSessionId';
import pdfHelper from '@/assets/js/shared/pdfHelper';
import billingHelper from '@/assets/js/shared/billingHelper';
import WlBannerAlert from '@/components/shared/wl-banner-alert.vue';
import WlAddressForm from '@/components/shared/wl-address-form.vue';
import getFormattedMultilineAddressOrUnknown from '../assets/js/shared/helpers/getFormattedMultilineAddressOrUnknown';
import formatStreetOnly from '@/assets/js/shared/helpers/formatStreetOnly';
import accountHelper from '@/assets/js/shared/accountHelper';
import getUsersInitialAid from '@/assets/js/shared/helpers/getUsersInitialAid';

import userLocation from '@/mixins/shared/userLocation';
const NBars = require('nbars/commonjs.js');

const mobileMediaQuery = getMobileQuery({ width: 992 });

export default {
  mixins: [userLocation],
  components: {
    WlBannerAlert,
    IcMonitoringUpdates,
    IcEditPassword,
    WlCard,
    WlHeader,
    WlModalForm,
    WlUpsellCta,
    WlUpsellContent,
    WlAddressForm,
    IcAccountModal,
    IcUpsell,
    IcRetryRebillErrorModal,
    IcAddPayment,
    TzMarkdown,
    TzSearch,
    TzSpinner,
    TzFooter,
    PdfOption,
    WlTrustBadges
  },
  data() {
    return {
      initializedBillingSDK: null,
      domain: null,
      isLoadingInventory: true,
      activePeopleSubscription: null,
      inventory: [],
      billingHelper,
      billingSDK,
      billingV2SDK,
      fallbackFields: [],
      logoSize: { height: '40', width: '158' },
      logoSmallSize: { height: '29', width: '28' },
      prefillFields: {
        firstName: '',
        lastName: '',
        city: '',
        state: this.$store.getters['location/state'],
        phoneNumber: ''
      },
      isMobile: false,
      isLoadingPermissions: true,
      mobileSearchActive: false,
      mobileSearchText: '',
      isFixed: false,
      params: {},
      path: null,
      showLoggedInUserElements: false,
      showCancelSubscriptionAction: false,
      dashboardDisclaimer: this.footer.dashboard_disclaimer,
      hasPeoplePermission: false,
      hasEmailPermission: false,
      hasAddressPermission: false,
      hasPhonePermission: false,
      hasOffenderPermission: false,
      hasEnabledMonitoring: false,
      hasEnabledPdf: false,
      isReactivateModalOpen: false,
      isPdfModalOpen: false,
      isPdfOptionModalOpen: false,
      isPaymentModalOpen: false,
      isMonitoringModalOpen: false,
      isEditAddressModalOpen: false,
      isEditPasswordModalOpen: false,
      isRetryRebillModalOpen: false,
      activeUpsell: null,
      pdfProduct: null,
      pdfWarningText: null,
      reportMonitoringProduct: null,
      reportMonitoringWarningText: null,
      isUpsellProcessing: false,
      paymentErrorMsg: null,
      inputs: [],
      dashboardMenu: [
        {
          label: '',
          url: '#'
        }
      ],
      showBanner: false,
      pdfInfo: null,
      isGeneratingPDF: false,
      openPDFOptionAfterUpsellConfirm: false,
      isInitialUpsellRequest: true,
      passwordModalTitle: 'Edit Password',
      passwordModalSubmitTxt: 'Edit',
      isUpdatingPassword: false,
      passwordUpdateFail: false,
      passwordErrorMessage: '',
      searchType: 'People Search',
      searchTypes: [
        {
          name: 'People Search',
          type: 'people'
        },
        {
          name: 'Phone Search',
          type: 'phone'
        }
      ],
      isDropdownOpen: false,
      showFooterSearch: false,
      pdfPermissionUpdated: false,
      address: null,
      isAddressUpdateError: false,
      addressInitialValues: { street: '', city: '', state: '', zip: '' },
      upsellImg,
      inventories: null,
      inventoryItemsObj: {
        subscriptions: [],
        products: []
      },
      subscriptionsPlusUnlockables: [],
      hideRetryBtn: false,
      redirectPath: 'header-search',
      unlockables: []
    };
  },
  props: {
    footer: {
      type: Object,
      default: () => require(`@/assets/cms/components/footer.json`)
    },
    header: {
      type: Object,
      default: () => require(`@/assets/cms/components/header.json`)
    },
    global: {
      type: Object,
      default: () => require(`@/assets/cms/global.json`)
    },
    accountCMS: {
      type: Object,
      default: () =>
        require(`@/assets/cms/pages/dashboard/dashboard-account.json`)
    },
    pdfOptionCms: {
      type: Object,
      default: () => require(`@/assets/cms/pages/dashboard/pdf-option.json`)
    },
    reactivateCms: {
      type: Object,
      default: () => require(`@/assets/cms/pages/dashboard/reactivate.json`)
    }
  },
  computed: {
    shouldShowUpsells() {
      const routesWithUpsellSidebar = [
        'dashboard-monitor',
        'dashboard_search_history',
        'dashboard-billing-history',
        'dashboard-account',
        'dashboard-subscribe',
        'dashboard-reports'
      ];
      return (
        this.$route.name.includes('dashboard') &&
        routesWithUpsellSidebar.includes(this.$route.name)
      );
    },
    isLanding() {
      return this.$route.path.includes('searching') || this.isSearchResults;
    },
    isUpsell() {
      return this.$route.name.includes('dashboard.upsell');
    },
    isResults() {
      return (
        this.$route.path.includes('results') ||
        this.$route.path.includes('dashboard/search')
      );
    },
    isSearchResults() {
      return (
        this.$route.path.includes('searching') ||
        this.$route.path.includes('results')
      );
    },
    dashboardDropdownMenu() {
      const dropdownMenu = [...this.header.dashboard_navigation_menu];
      // Search History feature isn't fully complete
      // Only show search history feature if testing param exists
      if (this.showTestFeatures) {
        dropdownMenu.push({
          label: 'Search History',
          url: '/dashboard/search-history/'
        });
        // Report monitoring feature isn't fully complete
        // Only show report monitoring features if testing param exists
        dropdownMenu.push({
          label: 'Report Monitoring',
          url: '/dashboard/monitor/'
        });
      }
      dropdownMenu.push({
        label: 'Log Out',
        url: '#'
      });
      return dropdownMenu;
    },
    displayUpsellLinks() {
      return !this.hasEnabledPdf || !this.hasEnabledMonitoring;
    },
    modalTitle() {
      if (this.hasPhoneQuery) {
        return this.header.modal.phone_title;
      }

      return this.header.modal.title;
    },
    modalText() {
      if (this.hasPhoneQuery) {
        return this.header.modal.phone_text;
      }

      return this.isResults ? this.header.modal.results.subtitle : null;
    },
    hasPhoneQuery() {
      return this.$route.query.phoneNumber;
    },
    displayBanner() {
      return (
        !this.isActivePeopleSubscriptionFailingPayment &&
        !this.hasPeoplePermission &&
        !this.isLoadingInventory &&
        !this.$route.path.includes('reactivate') &&
        !this.$route.path.includes('report/')
      );
    },
    pdfOptions() {
      if (!this.currentUser.email) {
        return [];
      }
      // truncate email for display.
      const mobileDisplayEmail =
        this.currentUser.email.length >= 20
          ? `${this.currentUser.email.slice(0, 17)}...`
          : this.currentUser.email;
      return [
        {
          type: 'download',
          title: 'Download PDF'
        },
        {
          type: 'email',
          title: this.isMobile
            ? `Email PDF <span>(to ${mobileDisplayEmail})</span>`
            : `Email PDF <span>(to ${this.currentUser.email})</span>`
        }
      ];
    },
    pdfOptionSubtitle() {
      if (this.pdfInfo && this.pdfInfo.name) {
        return NBars.transform(this.pdfOptionCms.subtitle, {
          reportIdentifier: formatFullName({
            firstName: this.pdfInfo.name.first,
            lastName: this.pdfInfo.name.last,
            titleCase: true
          })
        });
      }
      return '';
    },
    showMonitorReportWidget() {
      if (this.hasEnabledMonitoring && !this.$route.name.includes('monitor')) {
        return true;
      }
    },
    showTestFeatures() {
      return this.$route.query.test;
    },
    isPersonReport() {
      return (
        this.$route.name === 'dashboard-reports-person' ||
        this.$route.name === 'dashboard-reports-person-generate'
      );
    },
    shouldShowSideNav() {
      const routesWithoutSideNav = [
        'dashboard-search',
        'dashboard-privacy-policy',
        'dashboard-terms-conditions',
        'dashboard-reports-person-generate',
        'dashboard-reports-phone',
        'dashboard-reports-person',
        'dashboard-reports-address',
        'dashboard.upsell',
        'dashboard.upsell2',
        'dashboard.cancel'
      ];
      return !routesWithoutSideNav.includes(this.$route.name);
    },
    addressErrorMsg() {
      return this.isAddressUpdateError
        ? 'There was an error updating your address'
        : '';
    },
    isActivePeopleSubscriptionFailingPayment() {
      return this.activePeopleSubscription
        ? this.activePeopleSubscription &&
          this.activePeopleSubscription.retry_count > 0
          ? true
          : false
        : false;
    }
  },
  async created() {
    this.isErrorPage = this.$route.name === null;
    this.path = this.$route.path;

    this.updatePrefillFields(this.$route.query);
    this.setMobileText();

    if (this.showLoggedInUserElements) {
      // locking into having the search bar in view at all times
      this.isFixed = true;
    }

    // Add Sift
    if (process.client) {
      const siftConversion = document.createElement('script');
      siftConversion.innerHTML = `
      (function() {
          function ls() {
              var e = document.createElement('script');
              e.src = 'https://cdn.sift.com/s.js';
              document.body.appendChild(e);
          }
          if (window.attachEvent) {
              window.attachEvent('onload', ls);
          } else {
              window.addEventListener('load', ls, false);
          }
      })();
    `;
      document.head.appendChild(siftConversion);
    }
  },
  async mounted() {
    this.domain =
      this.$config.environment === 'production'
        ? `${this.$config.website}.com`
        : this.$config.testingAccountDomain;

    const isUserLoggedIn = this.$authHelper.isUserLoggedInSync();

    if (!isUserLoggedIn) {
      return this.redirect(this.$router, 'login');
    }

    this.currentUser = await this.$authHelper.getOrSetCurrentUser();
    if (this.currentUser === 'Access Denied') {
      await this.$authHelper.logoutUser('/login/');
    }
    this.setDashboardMenuLabel(this.currentUser);
    this.showLoggedInUserElements = true;
    await this.setUserPermissions();
    if (!this.currentUser.user_data?.v2) {
      this.initializedBillingSDK = this.billingSDK(window);
    } else {
      this.initializedBillingSDK = this.billingV2SDK(window);
    }
    if (this.displayUpsellLinks) {
      await this.setCtaProducts();
    }

    if (this.$route.name === 'dashboard.upsell') {
      const gtagEnhancedConversion = document.createElement('script');
      let gtagStreet = '';
      if (this.currentUser.street_number) {
        gtagStreet += `${this.currentUser.street_number} `;
      }
      if (this.currentUser.street_name) {
        gtagStreet += this.currentUser.street_name + ' ';
      }
      if (this.currentUser.street_affix) {
        gtagStreet += this.currentUser.street_affix;
      }

      gtagEnhancedConversion.innerHTML =
        `
        gtag('set', 'user_data', { "email": "` +
        this.currentUser.email +
        `", "address": { "first_name": "` +
        this.currentUser.firstname +
        `", "last_name": "` +
        this.currentUser.lastname +
        `", "street": "` +
        gtagStreet +
        `", "city": "` +
        this.currentUser.city +
        `", "region": "` +
        this.currentUser.state +
        `", "postal_code": "` +
        this.currentUser.zip +
        `", "country": "US" } });
        gtag('event', 'user_data');
      `;
      document.head.appendChild(gtagEnhancedConversion);
    }

    // Add Sift
    const siftConversion = document.createElement('script');
    siftConversion.innerHTML =
      `
      var _user_id = '` +
      this.currentUser.email +
      `'; /* Set user id to '' or email to match user id when order is created */
      var _session_id = '` +
      getSessionId() +
      `'; /* Set to a unique session ID for the visitor's current browsing session. */
      var _sift = window._sift = window._sift || [];
      _sift.push(['_setAccount', '` +
      this.$config.defaultVars.siftBeaconKey +
      `']);
      _sift.push(['_setUserId', _user_id]);
      _sift.push(['_setSessionId', _session_id]);
      _sift.push(['_trackPageview']);
    `;
    document.head.appendChild(siftConversion);

    if (this.shouldRedirectIfLoggedIn()) {
      await this.$router.push({ name: 'dashboard-home' });
      this.isFixed = true;
    }

    // If user does not have report monitoring and try to navigate to the monitor page
    // Redirect them back to the dashboard
    if (this.$route.name.includes('monitor') && !this.hasEnabledMonitoring) {
      this.redirect(this.$router, 'dashboard-home');
    }

    const unlockablesPath =
      this.$config.environment === 'production'
        ? require(`@/assets/cms/unlockables/production/information.com.json`)
        : require(`@/assets/cms/unlockables/staging/${this.$config.testingAccountDomain}.json`);

    this.unlockables = this.currentUser.user_data?.v2
      ? unlockablesPath.unlockables_v2
      : unlockablesPath.unlockables;

    try {
      await this.setInventoryData();
    } catch (err) {
      console.error(err);
      this.$bugsnag?.notify(err);
    }

    this.isLoadingInventory = false;
    //Check SessionStorage To See If Reactivate Modal Needs To Display
    this.checkShowReactivateModal();

    this.siteURL = window.location.origin;

    if (
      (this.$route.name === 'dashboard-home' ||
        this.$route.name === 'dashboard-reports') &&
      (!this.currentUser.state?.length || !this.currentUser.city?.length)
    ) {
      this.currentUser.state = this.$store.getters['location/state'] ?? '';
      this.currentUser.city = this.$store.getters['location/city'] ?? '';
      this.currentUser.country = this.$store.getters['location/country'] ?? '';
    }
    this.showCancelSubscription();
    this.addressInitialValues = {
      street: formatStreetOnly({ address: this.currentUser }),
      city: formatCity({ city: this.currentUser.city }) ?? '',
      state: this.currentUser.state ? this.currentUser.state.toUpperCase() : '',
      zip: this.currentUser.zip ?? ''
    };
    this.address = getFormattedMultilineAddressOrUnknown({
      address: this.currentUser,
      default: 'Unknown'
    });

    // Remove any registered service workers
    unregisterAndClearCaches();

    window.onpageshow = function(event) {
      /* Safari Back Button Persistent Cache Fix */
      if (event.persisted) {
        window.location.reload();
      }
    };

    mediaQueryListener({
      selector: mobileMediaQuery,
      handleMethod: this.setIsMobile
    });
    this.isMobile = mobileMediaQuery.matches;

    this.inputs = this.hasPhoneQuery
      ? [
          {
            name: 'phone',
            type: 'tel',
            defaultValue: this.$route.query.phoneNumber
              ? formatPhoneNumber({
                  phoneNumber: this.$route.query.phoneNumber.replace(/\D+/g, '')
                })
              : '',
            optionalText: 'Phone Number',
            errorMsg: null
          }
        ]
      : [
          {
            name: 'firstName',
            type: 'text',
            defaultValue:
              this.$route.query.firstName || this.$route.params.firstName,
            validationType: 'text',
            optionalText: 'First Name',
            errorMsg: null
          },
          {
            name: 'lastName',
            type: 'text',
            defaultValue:
              this.$route.query.lastName || this.$route.params.lastName,
            validationType: 'text',
            optionalText: 'Last Name',
            errorMsg: null
          },
          {
            name: 'city',
            type: 'text',
            defaultValue:
              formatCity({ city: this.$route.query.city }) ||
              formatCity({ city: this.$route.params.city }) ||
              '',
            validationType: 'text',
            optionalText: 'City'
          },
          {
            name: 'state',
            type: 'select',
            defaultValue: this.$route.query.state
              ? getStateFromAbbr({
                  stateAbbr: this.$route.query.state
                })?.toLowerCase() ?? ''
              : '',
            valueOptions: getStatesForDropdown({
              selectStates: null,
              useStateAbbrAsValue:
                this.isSeoSearch || !this.isLanding ? false : true
            }),
            optionalText: 'State'
          }
        ];
  },
  beforeDestroy() {
    mobileMediaQuery.removeEventListener('change', this.setIsMobile);
  },
  methods: {
    async setInventoryData() {
      // reinitialize values
      this.inventory = [];
      this.inventoryItemsObj = {
        subscriptions: [],
        products: []
      };
      this.subscriptionsPlusUnlockables = [];

      this.inventory = await this.billingHelper.getInventory(this);

      for (let i = 0; i < this.inventory.length; i++) {
        const item = this.inventory[i];
        if (item.type === 'subscription' && item.enabled) {
          this.inventoryItemsObj.subscriptions.push(item);
          continue;
        }
        this.inventoryItemsObj.products.push(item);
      }

      this.subscriptionsPlusUnlockables = await this.billingHelper.getInventorySubscriptionsWithUnlockables(
        { ...this.inventoryItemsObj, vm: this, unlockables: this.unlockables }
      );

      this.activePeopleSubscription = this.billingHelper.getActiveSubscriptionByType(
        {
          subscriptionsPlusUnlockables: this.subscriptionsPlusUnlockables,
          type: 'people_reports'
        }
      );
    },
    async navigateToResultsSearch(event) {
      const {
        firstName,
        lastName,
        city,
        state,
        phoneNumber,
        searchType
      } = event;

      // Phone Search
      if (searchType === 'phone') {
        const rawPhoneNumber = phoneNumber.replace(/\D+/g, '');
        const phoneQuery = dashedPhoneNumber({ phoneNumber: rawPhoneNumber });
        const query = {
          phoneNumber: phoneQuery
        };

        const queryString = getUrlQueryString({ queryObj: query });

        window.location = `${this.phoneURL()}${queryString}`;
        return;
      }

      if (!this.hasPeoplePermission) {
        await this.handleNoPeopleSearchAccess();
        return;
      }

      // People Search
      const query = {
        firstName,
        lastName,
        city,
        state
      };

      const queryString = getUrlQueryString({ queryObj: query });

      window.location = `${this.peopleURL()}${queryString}`;
    },
    mobileSearchOpen() {
      if (this.$refs.wlHeaderRef.show === true) {
        this.$refs.wlHeaderRef.show = false;
      }
      this.mobileSearchActive = true;
    },
    /**
     * @param {object} e
     */
    setIsMobile(e) {
      this.isMobile = e.matches;
    },
    /**
     * Redirects user
     * @param {object} router
     * @param {string} routeName
     */
    redirect(router, routeName) {
      const { href } = router.resolve({
        name: routeName
      });
      location.href = href;
    },
    /**
     * returns path for people search
     * @returns {String}
     */
    peopleURL() {
      return this.showLoggedInUserElements
        ? `${this.siteURL}/dashboard/search/`
        : '';
    },
    /**
     * Returns path for phone search
     * @returns {String}
     */
    phoneURL() {
      return this.showLoggedInUserElements
        ? `${this.siteURL}/dashboard/search/`
        : '';
    },
    /**
     * Handle logout
     * @returns {Promise<void>}
     */
    handleLogOut() {
      this.clearSessionVars();
      this.$authHelper.logoutUser('/login/');
    },
    /**
     * Toggle upsell modal if they don't have report monitoring enabled
     * If they do, navigate them to the monitor page when clicking on the menu link
     */
    handleReportMonitoring() {
      if (!this.hasEnabledMonitoring) {
        this.toggleModal('reportMonitoring');
      } else {
        this.navigateToDashboardRoute('/dashboard/monitor/');
      }
    },
    setMobileText() {
      const { query } = this.$route;
      this.mobileSearchText = this.header.mobile_search_label;

      if (this.params.firstName || this.params.lastName) {
        this.mobileSearchText = `${this.params.firstName} ${this.params.lastName}`;

        if (this.params.city) {
          this.mobileSearchText += ` in ${this.params.city}, ${this.params.state}`;
        } else if (this.params.state) {
          this.mobileSearchText += ` in ${this.params.state}`;
        }
      }

      if (query.firstName || query.lastName) {
        this.mobileSearchText = `${query.firstName || ''} ${query.lastName ||
          ''}`;

        if (query.city) {
          const formattedCity = setTitleCase({
            text: query.city.replace(/-|_/g, ' ')
          });
          this.mobileSearchText += ` in ${formattedCity}, ${query.state}`;
        } else if (query.state) {
          this.mobileSearchText += ` in ${query.state}`;
        }
      }
      if (query.phoneNumber) {
        this.mobileSearchText = formatPhoneNumber({
          phoneNumber: query.phoneNumber
        });
      }
    },
    updatePrefillFields(updatedFields) {
      this.prefillFields = Object.assign({}, this.prefillFields, updatedFields);
    },
    shouldRedirectIfLoggedIn() {
      // redirect if page is the results by name page and no people permission
      if (
        this.$route.name === 'dashboard-search' &&
        this.$route.fullPath.includes('firstName') &&
        !this.hasPeoplePermission
      ) {
        sessionStorage.setItem('showReactivateModal', 'true');
        return true;
      }
      return !/dashboard|privacy|terms/.test(this.$route.name);
    },
    setDashboardMenuLabel(currentUser) {
      const rawLabel = currentUser.firstname
        ? currentUser.firstname
        : currentUser.email;
      const displayLabel =
        rawLabel.length > 30 ? `${rawLabel.slice(0, 30)}...` : rawLabel;
      this.dashboardMenu[0].label = displayLabel;
    },
    async navigateToDashboardRoute(routePath) {
      this.closeHeaderMenu();
      await this.$router.push({ path: routePath });
    },
    async setUserPermissions() {
      try {
        const permissions = await this.$authHelper.checkUserPermissions({
          userId: this.currentUser.id
        });
        this.hasPeoplePermission = permissions.peopleReport;
        this.hasEmailPermission = permissions.emailReport;
        this.hasAddressPermission = permissions.addressReport;
        this.hasPhonePermission = permissions.phoneReport;
        this.hasOffenderPermission = permissions.sexOffenderReport;
        this.hasEnabledPdf = permissions.generatePdf;
        this.hasEnabledMonitoring = permissions.reportMonitoring;
        this.isLoadingPermissions = false;
      } catch (err) {
        console.error(err);
        this.$bugsnag?.notify(err);
      }
    },
    checkShowReactivateModal() {
      if (sessionStorage.getItem('showReactivateModal') === 'true') {
        sessionStorage.removeItem('showReactivateModal');
        this.isReactivateModalOpen = true;
      }
    },
    async handleNoPeopleSearchAccess(redirectPath = null) {
      if (!this.isActivePeopleSubscriptionFailingPayment) {
        return this.toggleModal('reactivate');
      }

      this.redirectPath = redirectPath ?? 'header-search';
      const preferredPayment = await paymentHelper.getPreferredPayment(
        this.currentUser,
        this.$bugsnag
      );
      const lastRetriedDate = new Date(
        this.activePeopleSubscription.last_retried
      );
      const paymentUpdatedDate = preferredPayment.updated_at
        ? new Date(preferredPayment.updated_at)
        : new Date(preferredPayment.created_at);

      // if subscription is showing payment failed and the last time it was retried was after the account's default payment was last updated
      // they would then need to update their default payment, so we will hide retry button in retry-rebill modal
      if (lastRetriedDate > paymentUpdatedDate) {
        this.hideRetryBtn = true;
      }

      return this.toggleModal('retry-rebill-error');
    },
    resetNameInputErrors() {
      if (this.hasPhoneQuery) {
        this.inputs[0].errorMsg = null;
      } else {
        this.inputs[0].errorMsg = null;
        this.inputs[1].errorMsg = null;
      }
    },
    openHeaderModal() {
      document.body.classList.add('body--show-modal');
    },
    closeHeaderModal() {
      this.resetNameInputErrors();
      document.body.classList.remove('body--show-modal');
    },
    removeInputError(inputIndex) {
      this.inputs[inputIndex].errorMsg = null;
    },
    mobileSearchClose() {
      this.mobileSearchActive = false;
    },
    navigateToPhoneResults() {
      const phone = this.inputs[0].defaultValue;
      const phoneParam = dashedPhoneNumber({
        phoneNumber: phone.replace(/\D+/g, '')
      });

      if (phone === '') {
        this.inputs[0].errorMsg = this.isMobile
          ? 'Required'
          : 'Phone number is Required';
      } else if (phone && phone.length < 14) {
        this.inputs[0].errorMsg = this.isMobile ? ' ' : ' ';
      }

      if (phone.length < 14 || phone === '') {
        return;
      }

      const query = {
        ...this.$route.query,
        phoneNumber: phoneParam
      };
      const { href } = this.$router.resolve({
        name: 'dashboard-search',
        query
      });

      window.location.href = href;
    },
    navigateToDashboardResults() {
      if (!this.hasPeoplePermission) {
        document.body.classList.remove('body--show-modal');
        this.isReactivateModalOpen = true;
        return;
      }

      if (this.hasPhoneQuery) {
        this.navigateToPhoneResults();
        return;
      }

      const firstName = this.inputs[0].defaultValue;
      const lastName = this.inputs[1].defaultValue;
      const city = this.inputs[2].defaultValue
        ? setTitleCase({ text: this.inputs[2].defaultValue }).replace(/ /g, '_')
        : '';
      const state = this.inputs[3].defaultValue
        ? getStateAbbrFromName({ state: this.inputs[3].defaultValue })
        : '';
      // TODO: Use method from js submodule once its available
      let params = resultHelper.convertArrayOfObjectsToObject(
        this.header.cp_search_params,
        'name',
        'value'
      );

      if (!firstName) {
        this.inputs[0].errorMsg = this.isMobile
          ? 'Required'
          : 'First Name is Required';
      }
      if (!lastName) {
        this.inputs[1].errorMsg = this.isMobile
          ? 'Required'
          : 'Last Name is Required';
      }
      if (!firstName || !lastName) {
        return;
      }

      params = Object.assign(params, {
        firstName,
        lastName,
        city,
        state
      });

      const href = this.$router.resolve({
        name: 'dashboard-search',
        query: params
      }).href;

      window.location.href = href;
    },
    toggleModal(modal) {
      switch (modal) {
        case 'reactivate':
          this.isReactivateModalOpen = !this.isReactivateModalOpen;
          break;
        case 'pdf':
          this.isPdfModalOpen = !this.isPdfModalOpen;
          break;
        case 'pdf-option':
          this.openPDFOptionAfterUpsellConfirm = false;
          if (!this.isGeneratingPDF) {
            this.isPdfOptionModalOpen = !this.isPdfOptionModalOpen;
          }
          break;
        case 'reportMonitoring':
          this.isMonitoringModalOpen = !this.isMonitoringModalOpen;
          break;
        case 'payment':
          this.isPaymentModalOpen = !this.isPaymentModalOpen;
          break;
        case 'edit-password':
          if (this.isUpdatingPassword) {
            return;
          } else if (sessionStorage.getItem('setNewPassword')) {
            this.passwordModalTitle = 'Set Password';
            this.passwordModalSubmitTxt = 'Set';
            sessionStorage.removeItem('setNewPassword');
          } else {
            this.passwordModalTitle = 'Edit Password';
            this.passwordModalSubmitTxt = 'Edit';
            this.passwordUpdateFail = false;
            this.passwordErrorMessage = '';
          }
          this.isEditPasswordModalOpen = !this.isEditPasswordModalOpen;
          break;
        case 'personalAddress':
          this.isEditAddressModalOpen = !this.isEditAddressModalOpen;
          break;
        case 'retry-rebill-error':
          this.isRetryRebillModalOpen = !this.isRetryRebillModalOpen;
          break;
      }
    },
    /**
     * @param {Object} opts
     */
    async setCtaProducts(opts = {}) {
      const upsellProductIds =
        this.$config.environment === 'production'
          ? require(`@/assets/cms/upsell-products/production/information.com/upsell-products.json`)
          : require(`@/assets/cms/upsell-products/staging/${this.$config.testingAccountDomain}/upsell-products.json`);

      // Get PDF Product
      if (!this.hasEnabledPdf) {
        const idsByBillingVersion = this.currentUser.user_data?.v2
          ? upsellProductIds.pdf_v2
          : upsellProductIds.pdf;
        const productId =
          this.$route.name === 'dashboard.upsell'
            ? idsByBillingVersion.id
            : idsByBillingVersion.adId;

        this.pdfProduct = await this.initializedBillingSDK.Products.byId(
          this.domain,
          productId,
          null
        );
      }

      // Get Report Monitoring Product
      const idByBillingVersion = this.currentUser.user_data?.v2
        ? upsellProductIds.report_monitoring_v2.id
        : upsellProductIds.report_monitoring.id;
      if (!this.hasEnabledMonitoring && idByBillingVersion) {
        this.reportMonitoringProduct = await this.initializedBillingSDK.Products.byId(
          this.domain,
          idByBillingVersion,
          null
        );
      }

      if (this.pdfProduct) {
        this.pdfWarningText = NBars.transform(
          this.accountCMS.upsell_modal.pdf.warning,
          {
            productName: setTitleCase({ text: this.pdfProduct.name }),
            productCost: formatMoney({
              number: this.pdfProduct.price,
              currency: 'USD'
            })
          }
        );
      }

      if (this.reportMonitoringProduct) {
        this.reportMonitoringWarningText = NBars.transform(
          this.accountCMS.upsell_modal.report_monitoring.warning,
          {
            productName: this.reportMonitoringProduct.name,
            productCost: formatMoney({
              number: this.reportMonitoringProduct.price,
              currency: 'USD'
            })
          }
        );
      }
    },
    async retryUpsellWithNewPayment(addedPayment) {
      // ic-payment-modal is opened if submitUpsell fails due to having no preferred payment method
      // when user completes payment form and adds new payment retryUpsellWithNewPayment runs completing upsell
      // using this.activeUpsell as product (set in paymentFailure)
      const aid = await this.getAid();
      let extraData = window.sessionStorage.landing;
      //check for extra data in session storage
      if (extraData) {
        extraData = JSON.parse(extraData);
        const extraParsed = { ...extraData, ...extraData.response };
        delete extraParsed.response; //remove duplicated recursive object
        Object.keys(extraParsed).forEach(key => {
          // remove empty values (they cause errors in crm display)
          if (
            extraParsed[key] === '' ||
            extraParsed[key] === null ||
            extraParsed[key] === undefined ||
            (typeof extraParsed[key] === 'array' &&
              extraParsed[key].length === 0) ||
            (typeof extraParsed[key] === 'object' &&
              Object.keys(extraParsed[key].length === 0))
          ) {
            delete extraParsed[key];
          }
        });
        extraData = extraParsed;
      }
      //merge session storage with query params if they both exist,
      //use one or the other if only one exists.
      const extra_tracking =
        Object.keys(this.$route.query).length != 0 && extraData
          ? { ...extraData, ...this.$route.query }
          : Object.keys(this.$route.query).length != 0
          ? this.$route.query
          : extraData;
      if (extra_tracking) {
        delete extra_tracking.aid;
      }
      const product =
        this.activeUpsell === 'pdf'
          ? this.pdfProduct
          : this.reportMonitoringProduct;
      const productAdded = await paymentHelper.chargeCard(
        addedPayment,
        product,
        this.currentUser,
        aid,
        extra_tracking,
        'product',
        this.$bugsnag
      );
      if (productAdded && !productAdded.type?.includes('error')) {
        this.isPaymentModalOpen = false;
        await this.setUserPermissions();
        if (this.$route.name === 'dashboard.upsell') {
          this.setUpsellSessionItem();
        } else {
          this.sendUpsellToast(this.activeUpsell);
        }
      } else {
        this.paymentErrorMsg = 'Error Processing Payment';
        this.isPaymentModalOpen = true;
      }
    },
    async submitUpsell(type, product) {
      if (!this.isInitialUpsellRequest) {
        return;
      }
      this.isInitialUpsellRequest = false;
      this.activeUpsell = null;
      this.isUpsellProcessing = true;
      const preferredPayment = await paymentHelper.getPreferredPayment(
        this.currentUser,
        this.$bugsnag
      );
      if (!preferredPayment) {
        return this.paymentFailure(type);
      }
      const aid = await this.getAid();

      let extraData = window.sessionStorage.landing;
      //check for extra data in session storage
      if (extraData) {
        extraData = JSON.parse(extraData);
        const extraParsed = { ...extraData, ...extraData.response };
        delete extraParsed.response; //remove duplicated recursive object
        Object.keys(extraParsed).forEach(key => {
          // remove empty values (they cause errors in crm display)
          if (
            extraParsed[key] === '' ||
            extraParsed[key] === null ||
            extraParsed[key] === undefined ||
            (typeof extraParsed[key] === 'array' &&
              extraParsed[key].length === 0) ||
            (typeof extraParsed[key] === 'object' &&
              Object.keys(extraParsed[key].length === 0))
          ) {
            delete extraParsed[key];
          }
        });
        extraData = extraParsed;
      }
      //merge session storage with query params if they both exist,
      //use one or the other if only one exists.
      const extra_tracking =
        Object.keys(this.$route.query).length != 0 && extraData
          ? { ...extraData, ...this.$route.query }
          : Object.keys(this.$route.query).length != 0
          ? this.$route.query
          : extraData;
      if (extra_tracking) {
        delete extra_tracking.aid;
      }

      const productAdded = await paymentHelper.chargeCard(
        preferredPayment,
        product,
        this.currentUser,
        aid,
        extra_tracking,
        'product',
        this.$bugsnag
      );
      if (!productAdded) {
        return this.paymentFailure(type);
      }
      this.forceCloseUpsellModals();
      await this.setUserPermissions();
      if (this.openPDFOptionAfterUpsellConfirm) {
        this.openPDFOptionAfterUpsellConfirm = false;
        this.isUpsellProcessing = false;
        this.toggleModal('pdf-option');
      } else if (this.$route.name === 'dashboard-account') {
        this.sendUpsellToast('pdf-account-page');
      } else {
        this.sendUpsellToast(type);
        this.pdfPermissionUpdated = true;
      }
      this.isUpsellProcessing = false;
      this.isInitialUpsellRequest = true;
    },
    async getAid() {
      return await getUsersInitialAid({
        defaultValue: this.$config?.defaultVars.aid,
        user: this.currentUser,
        window,
        route: this.$route
      });
    },
    forceCloseUpsellModals() {
      this.isPdfModalOpen = false;
      this.isMonitoringModalOpen = false;
    },
    setUpsellSessionItem() {
      setSessionItem({
        itemName: 'pdfProductEnabled',
        item: true
      });
      this.$router.push('/dashboard/');
    },
    checkUpsellSession() {
      const pdfEnabled = getSessionItem({
        itemName: 'pdfProductEnabled'
      });
      if (pdfEnabled && JSON.stringify(pdfEnabled) !== '{}') {
        this.sendUpsellToast('pdf');
        if (sessionStorage.getItem('setNewPassword')) {
          // If New Account Delay Password Toast If Upsell Was Purchased
          sessionStorage.setItem('setNewPasswordDelay', 'true');
        }
        sessionStorage.removeItem('pdfProductEnabled');
      }
    },
    async openPDFOptionModal(report) {
      if (!this.hasEnabledPdf) {
        this.openPDFOptionAfterUpsellConfirm = true;
        return this.toggleModal('pdf');
      }
      this.pdfInfo = report;
      this.isPdfOptionModalOpen = true;
    },
    async generatePDF(index) {
      let displayParam;
      let selectedOption = this.pdfOptions[index];
      switch (this.pdfInfo.type) {
        case 'phone':
          displayParam = formatPhoneNumber({
            phoneNumber: this.pdfInfo.phone
          });
          break;
        case 'address':
          if (!this.pdfInfo.address) {
            // report data received from reports overview page is different than from address page
            const { city, state, street, zip } = this.pdfInfo;
            this.pdfInfo.address = { city, state, street, zip };
          }
          this.pdfInfo.addressFormatted = formatStreetOnly({
            address: this.pdfInfo.address
          });
          displayParam = this.pdfInfo.addressFormatted;
          break;
        default:
          displayParam = formatFullName({
            firstName: this.pdfInfo.name.first,
            lastName: this.pdfInfo.name.last,
            titleCase: true
          });
      }

      this.isGeneratingPDF = true;
      switch (selectedOption.type) {
        case 'download':
          await this.downloadPdf(displayParam);
          break;
        case 'email':
          await this.emailPdf(displayParam);
          break;
      }
      this.isGeneratingPDF = false;
      this.toggleModal('pdf-option');
    },
    async downloadPdf(displayData) {
      const args = {
        vm: this,
        reportCode: this.pdfInfo.reportCode,
        type: this.pdfInfo.type || 'people',
        autoPermissionGranted: true
      };

      switch (this.pdfInfo.type) {
        case 'phone':
          args.phone = this.pdfInfo.phone;
          break;
        case 'address':
          args.address = this.pdfInfo.address;
          break;
        default:
          args.name = this.pdfInfo.name;
      }

      try {
        this.sendPDFDownloadToast(displayData);
        await pdfHelper.downloadPDF(args);
      } catch (err) {
        this.$bugsnag?.notify(err);
        this.sendPDFErrorToast(displayData);
      }
    },
    async emailPdf(name) {
      try {
        this.sendPDFEmailToast(name);
        let filename;
        switch (this.pdfInfo.type) {
          case 'phone': {
            filename = `report_${formatPhoneNumber({
              phoneNumber: this.pdfInfo.phone
            }).replace(' ', '-')}`;
            break;
          }
          case 'address': {
            filename = `report_${this.pdfInfo.addressFormatted.replaceAll(
              ' ',
              '-'
            )}`;
            break;
          }
        }
        const res = await pdfHelper.emailPDF({
          userId: this.currentUser.id,
          reportType: this.pdfInfo.type ?? 'people',
          reportCode: this.pdfInfo.reportCode,
          filename
        });
      } catch (err) {
        this.$bugsnag?.notify(err);
        this.sendPDFEmailErrorToast(name);
      }
    },
    sendToastFromChild(opts) {
      switch (opts.type) {
        case 'download':
          this.sendPDFDownloadToast(opts.name);
          break;
        case 'download-error':
          this.sendPDFErrorToast(opts.name);
          break;
        case 'error':
          this.sendErrorToast(opts.name);
          break;
        case 'enable-notifications':
          this.sendSuccessToast(
            'Report Monitoring notifications has been enabled'
          );
          break;
        case 'disable-notifications':
          this.sendWarningToast({
            msg: 'Report Monitoring notifications has been disabled'
          });
          break;
      }
    },
    sendPDFEmailToast(name) {
      //Send Toast For PDF that is downloading
      this.sendLoadingToast(
        `Your PDF people report for ${name} is being emailed to ${this.currentUser.email}`
      );
    },
    sendPDFEmailErrorToast(name) {
      //Send Toast when PDF email fails
      this.sendErrorToast(`Error emailing your PDF people report for ${name}`);
    },
    sendPDFDownloadToast(name) {
      //Send Toast For PDF that is downloading
      switch (this.pdfInfo.type) {
        case 'address':
          this.sendLoadingToast(`Your Address Report PDF is now downloading.`);
          break;
        case 'phone':
          this.sendLoadingToast(
            `Your PDF phone report for ${name} is now downloading`
          );
        default:
          this.sendLoadingToast(
            `Your PDF people report for ${name} is now downloading`
          );
      }
    },
    sendPDFErrorToast(name) {
      //Send Toast For PDF that is downloading
      this.sendErrorToast(
        `Error downloading your PDF people report for ${name}`
      );
    },
    /**
     * @param {object} opts
     * @param {string} opts.msg
     */
    sendWarningToast(opts = {}) {
      this.$toast.show(opts.msg, {
        position: 'toasted-container--absolute-top-right',
        className: 'toast toast--warning',
        duration: 7000
      });
    },
    sendUpsellToast(type) {
      //Send Toast For PDF Product
      switch (type) {
        case 'pdf':
          this.sendSuccessToast(
            `${
              this.pdfProduct && this.pdfProduct.name
                ? this.pdfProduct.name
                : 'PDF Downloads'
            } Enabled`
          );
          break;
        case 'pdf-account-page':
          this.sendSuccessToast(
            `${
              this.pdfProduct && this.pdfProduct.name
                ? this.pdfProduct.name
                : 'PDF Downloads'
            } Enabled. Refresh the page for latest billing history.`
          );
          break;
        case 'reportMonitoring':
          this.sendSuccessToast(`${this.reportMonitoringProduct.name} Enabled`);
          break;
      }
    },
    sendSuccessToast(msg) {
      this.$toast.show(msg, {
        position: 'toasted-container--absolute-top-right',
        className: 'account__toast',
        duration: 3000
      });
    },
    sendLoadingToast(msg) {
      this.$toast.show(msg, {
        position: 'toasted-container--absolute-top-right',
        className: 'toast toast--loading',
        duration: 7000
      });
    },
    sendErrorToast(msg) {
      this.$toast.clear();
      this.$toast.show(msg, {
        position: 'toasted-container--absolute-top-right',
        className: 'toast toast--error',
        duration: 3000
      });
    },
    paymentFailure(type) {
      this.isUpsellProcessing = false;
      this.isInitialUpsellRequest = true;
      this.paymentErrorMsg =
        'Payment method failed, please enter a new payment method.';
      this.forceCloseUpsellModals();
      this.isPaymentModalOpen = true;
      this.activeUpsell = type;
    },
    handleNoSubscription() {
      this.showBanner = true;
    },
    handleMonitoringReport(report) {
      const formattedName = formatFullName({
        firstName: report.name.first,
        lastName: report.name.last,
        titleCase: true
      });
      // If user doesn't have report monitoring permission, show upsell modal
      if (!report.hasMonitoringPermission) {
        return this.toggleModal('reportMonitoring');
      }
      if (report.isMonitoringReport) {
        this.sendSuccessToast(
          `Your report for ${formattedName} is now being monitored`
        );
        // TODO: Hit endpoint to monitor report
      } else {
        this.sendWarningToast({
          msg: `Your report for ${formattedName} is no longer being monitored`
        });
        // TODO: Hit endpoint to unmonitor report
      }
    },
    renderIfReturnArray,
    closeHeaderMenu() {
      if (!document) {
        return;
      }
      const isDropdownMenuOpen = document.querySelector('.wl-header--open-nav');
      if (isDropdownMenuOpen) {
        this.$refs.wlHeaderRef.menuToggle();
      }
    },
    /**
     * Hits endpoint to update the user's password
     *
     * @param newPassword
     * @return {Promise}
     */
    async updatePassword(newPassword) {
      this.isUpdatingPassword = true;
      try {
        const res = await this.$travellingSDK.UserCurrent.editProperty(
          newPassword,
          'password'
        );

        if (res.type === 'password-error') {
          throw res.msg;
        }
        // update user data to track 'set your password' - 'syp' if not already
        await this.persistVisitedStep('syp');
        await this.refetchCurrentUser();
        this.isEditPasswordModalOpen = false;
        this.sendSuccessToast('Password Set Successfully');
      } catch (err) {
        this.isEditPasswordModalOpen = true;
        this.passwordUpdateFail = true;
        this.passwordErrorMessage = err;
        this.$bugsnag?.notify(err);
      }
      this.isUpdatingPassword = false;
    },
    async updateAddress(addressObj) {
      const isAddressUpdateSuccessful = await accountHelper.updateAddress({
        addressObj,
        travellingSdk: this.$travellingSDK,
        vm: this
      });
      if (!isAddressUpdateSuccessful) {
        this.isAddressUpdateError = true;
        return;
      }
      // update user data to track 'set your address' - 'sya' if not already
      await this.persistVisitedStep('sya');
      await this.refetchCurrentUser();
      this.toggleModal('personalAddress');
      if (isAddressUpdateSuccessful) {
        this.isAddressUpdateError = false;
      }
    },
    // this function updates user data to mark whether they have done a certain thing on their account yet
    async persistVisitedStep(step) {
      const rawUserNSteps = this.currentUser.user_data?.nsteps;
      const userNSteps = rawUserNSteps ? JSON.parse(rawUserNSteps) : null;
      if (userNSteps && userNSteps[step]) {
        return;
      }
      let reqNsteps;
      if (!userNSteps) {
        reqNsteps = {
          vyr: false,
          mas: false,
          syp: false,
          apn: false,
          mar: false,
          sya: false
        };
        reqNsteps[step] = true;
      } else {
        reqNsteps = Object.assign(userNSteps, {
          [step]: true
        });
      }
      try {
        // when hitting this endpoint please note, it will overwrite whatever is there, so pass in entire object
        await this.$travellingSDK.UserCurrent.editUserDataProperty(
          JSON.stringify(reqNsteps),
          'nsteps'
        );
      } catch (err) {
        console.error(err);
        this.$bugsnag?.notify(err);
      }
    },
    /**
     * @param {object} opts
     * @param {string} opts.type
     */
    handleSearchType(opts = {}) {
      this.searchType =
        opts.type === 'people' ? 'People Search' : 'Phone Search';
      const peopleButton = document.querySelectorAll(
        '.main .tz-search__button'
      )[0];
      const phoneButton = document.querySelectorAll(
        '.main .tz-search__button'
      )[1];

      if (this.searchType === 'Phone Search') {
        phoneButton.click();
      } else {
        peopleButton.click();
      }

      // Close dropdown menu
      this.handleDropdown();
    },
    handleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async refetchCurrentUser() {
      this.currentUser = await this.$authHelper.getAndSetUserData();
    },
    /**
     * @param {object} opts
     * @param {string} opts.label
     * @param {string} opts.blockEl
     * @return {string}
     */
    getItemClass(opts = {}) {
      const { label, blockEl } = opts;
      switch (label) {
        case 'Home':
          return `${blockEl}--home`;
        case 'My Reports':
          return `${blockEl}--reports`;
        case 'Address Reports':
          return `${blockEl}--address`;
        case 'Email Search':
          return `${blockEl}--email`;
        case 'Phone Reports':
          return `${blockEl}--phone`;
        case 'Sex Offender Watch':
          return `${blockEl}--sex-offenders`;
        case 'My Account':
          return `${blockEl}--account`;
        case 'Log Out':
          return `${blockEl}--logout`;
        default:
          return '';
      }
    },
    async handleChargeByInventorySuccess() {
      await this.setUserPermissions();
      if (this.redirectPath && this.redirectPath !== 'header-search') {
        return await this.$router.push(this.redirectPath);
      }
      const searchBtn = document.querySelector('.tz-search__btn-search');
      searchBtn.click();
    },
    handleChargeByInventoryFailure() {
      // if charge fails, show modal to direct them to update their default payment
      this.isChargeOnly = false;
    },
    clearSessionVars() {
      if (sessionStorage.getItem('myReportsFilter')) {
        sessionStorage.removeItem('myReportsFilter');
      }
    },
    showCancelSubscription() {
      const enabledStates = [
        'IL',
        'CO',
        'CT',
        'DE',
        'FL',
        'ID',
        'ME',
        'VA',
        'VT',
        'DC',
        'WA',
        'WV'
      ];
      const geoData = JSON.parse(localStorage.getItem('geoip-v1-location'));
      const userState = geoData ? geoData.value.state : this.currentUser.state;
      this.showCancelSubscriptionAction =
        userState && enabledStates.includes(userState.toUpperCase());
    }
  },
  watch: {
    $route(to, from) {
      this.closeHeaderMenu();
      _sift.push(['_trackPageview']);
    }
  },
  updated() {
    this.checkShowReactivateModal();
    this.checkUpsellSession();
  },
  head() {
    const head = {
      meta: [],
      link: [],
      script: [],
      __dangerouslyDisableSanitizers: ['script']
    };

    const hasNoCrawling = /optout-*|people-flow-results/.test(this.$route.name);

    if (!head.meta) {
      head.meta = [];
    }

    // Add Google Tag Manager
    head.script.push({
      async: true,
      src: 'https://www.googletagmanager.com/gtm.js?id=AW-11137963343'
    });

    head.script.push({
      innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11137963343', { 'allow_enhanced_conversions': true });
          `
    });

    // If route has no crawling add noindex nofollow noarchive and return early
    if (hasNoCrawling) {
      head.meta.push({
        name: 'robots',
        hid: 'robots',
        content: 'noindex, nofollow, noarchive'
      });

      return head;
    }

    const website = `https://information.com`;

    // Push org schema to head
    head.script.push({
      type: 'application/ld+json',
      json: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Information',
        url: website,
        alternateName: 'Information.com',
        logo: `${website}/site/information/images/logo.webp`,
        contactPoint: [
          {
            '@type': 'ContactPoint',
            email: 'contact@information.com',
            url: `${website}/contact/`,
            contactType: 'Customer Service',
            areaServed: 'US'
          }
        ]
      }
    });

    if (/seo./.test(this.$route.name)) {
      return head;
    }

    // Setup nav links for nav schema
    const navLinks = []
      .concat(this.footer.primary_list)
      .concat(this.footer.secondary_list)
      .concat(this.footer.tertiary_list)
      .concat(this.footer.quarternary_list)
      .concat(
        [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'].map(letter => ({
          url: `/names-by-letter/${letter.toLowerCase()}/`,
          name: letter
        }))
      );

    const navSchema = [];
    // Loop through links to build nav schema
    for (let i = 0; i < navLinks.length; i++) {
      const link = navLinks[i].url.startsWith('/')
        ? website + navLinks[i].url
        : navLinks[i].url;
      navSchema.push({
        '@type': 'SiteNavigationElement',
        position: i + 1,
        '@id': link,
        url: link,
        name: navLinks[i].label
      });
    }
    // Push nav schema to head
    head.script.push({
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: navSchema
      }
    });

    return head;
  }
};
</script>

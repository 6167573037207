import {
  getWithExpiry,
  setWithExpiry
} from '../../assets/js/shared/local-storage';
const locationKey = 'geoip-v1-location';
const ttl = 86400 * 1000; // 1 day

export default ({ $bugsnag, app, $config: { apiLocationUrl } }, inject) => {
  inject('location', getLocation);

  /**
   * Get Location
   * @returns {Promise<{state}|*>}
   */
  async function getLocation() {
    const location = getWithExpiry(locationKey);
    if (location && location.state) {
      return location;
    }
    let fetchData;
    try {
      fetchData = await fetch(apiLocationUrl).then(response => response.json());
    } catch (err) {
      console.error(err);
      $bugsnag?.notify(err);
      return null;
    }

    if (fetchData && fetchData.state) {
      setWithExpiry(locationKey, fetchData, ttl);

      return fetchData;
    }
  }
};

const dateRegex = new RegExp(/(\d){4}\-(\d){2}\-(\d){2}/);

/**
 * Returns people data with age
 * @param {object} opts
 * @param opts.people
 * @returns {array}
 */
const setPeopleAges = (opts = {}) => {
  if (!opts || !opts.people || !opts.people.length) return [];
  const { people } = opts;
  const currentDate = new Date();
  for (let i = 0; i < people.length; i++) {
    if (
      !people[i].dob ||
      people[i].dob === '1600-01-01' ||
      !dateRegex.test(people[i].dob)
    ) {
      people[i].age = null;
    } else {
      const birthDate = new Date(people[i].dob);
      // Current Date - DOB /(Milliseconds in a Given Year)
      people[i].age = Math.floor((currentDate - birthDate) / 31556952000);
    }
  }
  return people;
};

export default setPeopleAges;

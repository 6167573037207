var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{ref:"header",staticClass:"wl-header",class:{
    'wl-header--open-nav': _vm.show,
    'wl-header--has-auxiliary-menu': _vm.hasAuxiliaryMenu,
    'wl-header--fixed': _vm.isFixed
  },attrs:{"id":"header","role":"banner"}},[_c('div',{staticClass:"wl-header__wrapper"},[_vm._l((_vm.renderIf(_vm.logo)),function(item){return _c('a',{key:item,staticClass:"wl-header__logo",attrs:{"title":_vm.logoTitle,"href":_vm.logoPath,"aria-label":_vm.logoAria}},[_c('span',{staticClass:"wl-header__logo-image",domProps:{"textContent":_vm._s(`${_vm.$config.website}.com`)}}),_vm._v(" "),_vm._l((_vm.renderIf(_vm.logoName)),function(item){return _c('span',{key:item,staticClass:"wl-header__logo-name",domProps:{"textContent":_vm._s(_vm.logoName)}})})],2)}),_vm._v(" "),_vm._t("header-logo"),_vm._v(" "),_vm._t("header-bar"),_vm._v(" "),_vm._l((_vm.renderIf(!_vm.hideMenu)),function(item){return _c('button',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.menuToggle),expression:"menuToggle",arg:"tap"}],key:item,staticClass:"wl-header__btn-menu",attrs:{"id":"btn-menu","aria-controls":"navbarContent","aria-expanded":"false","aria-label":"Toggle navigation"}},_vm._l((_vm.renderIf(_vm.menuToggleLabel)),function(item){return _c('span',{key:item,staticClass:"wl-header__btn-menu-label",domProps:{"textContent":_vm._s(_vm.menuToggleLabel)}})}),0)}),_vm._v(" "),_vm._l((_vm.renderIf(_vm.showAuxMenu)),function(item){return _c('section',{key:item,staticClass:"wl-header__navgroup"},[_vm._l((_vm.renderIf(_vm.hasMenu)),function(item){return _c('nav',{key:item,staticClass:"wl-header__navbar"},[_c('ul',{staticClass:"wl-header__nav"},_vm._l((_vm.menu),function(link){return _c('li',{key:link.label,staticClass:"wl-header__nav-item",class:{ 'wl-header__nav-item--home': link.url === '/' }},[_c('a',{staticClass:"wl-header__nav-link",class:{
                'wl-header__nav-link--active': _vm.path === link.url,
                'wl-header__nav-link--dropdown':
                  link.links && link.links.length > 0
              },attrs:{"href":link.url,"role":"button","aria-haspopup":"true","aria-expanded":"false"},domProps:{"textContent":_vm._s(link.label)}}),_vm._v(" "),_vm._l((_vm.renderIf(
                !_vm.hasAuxiliaryMenu && link.children && link.children.length > 0
              )),function(item){return _c('div',{key:item,staticClass:"wl-header__sub-nav",attrs:{"aria-labelledby":"navDropdown"}},_vm._l((link.children),function(child){return _c('a',{key:child.label,staticClass:"wl-header__sub-nav-link",class:{
                  'wl-header__nav-link--active': _vm.path === child.url
                },attrs:{"href":child.url},domProps:{"textContent":_vm._s(child.label)}})}),0)})],2)}),0)])}),_vm._v(" "),_vm._l((_vm.renderIf(_vm.hasAuxiliaryMenu)),function(item){return _c('nav',{key:item,staticClass:"wl-header__navbar"},[_c('ul',{staticClass:"wl-header__nav"},_vm._l((_vm.auxiliaryMenu),function(link){return _c('li',{key:link.label,staticClass:"wl-header__nav-item",class:{ 'wl-header__nav-item--home': link.url === '/' }},[_c('a',{staticClass:"wl-header__nav-link",class:{
                'wl-header__nav-link--active': _vm.path === link.url
              },attrs:{"href":link.url,"role":"button","aria-haspopup":"true","aria-expanded":"false"},domProps:{"textContent":_vm._s(link.label)}})])}),0)])})],2)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
/**
 * Returns string with spaces replacing underscores
 *
 * @param {object} opts
 * @param {string|null} opts.string
 * @returns {string|null}
 */
const formatUnderScoreToSpace = (opts = {}) => {
  if (!opts.string || opts.string === '') {
    return null;
  }

  return opts.string.replace(/_/g, ' ');
};

export default formatUnderScoreToSpace;

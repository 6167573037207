import maskEmail from './maskEmail';

/**
 * Formats List of Emails
 * @param {object} opts
 * @param {object} opts.person
 * @param {string} opts.maskingSymbol
 * @param {number} opts.count
 * @param {string} opts.label
 * @param {string} opts.className
 * @param {string} opts.separator
 * @returns {{label: string, className: string, list: *[], separator: string}|null}
 */
const formatPersonListEmails = (opts = {}) => {
  if (!opts || !opts.person || !opts.person.emails) {
    return null;
  }
  const { person, maskingSymbol, count, label, className, separator } = opts;

  if (!(Array.isArray(person.emails) && person.emails.length)) {
    return null;
  }

  const emails = [];

  for (let i = 0; i < person.emails.slice(0, count).length; i++) {
    emails.push(
      maskEmail({
        email: person.emails[i].full.toLowerCase(),
        maskingSymbol: maskingSymbol
      })
    );
  }

  return {
    label: label,
    className: className,
    separator: separator,
    list: emails
  };
};

export default formatPersonListEmails;

<style lang="scss">
.wl-header {
  $self: &;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  &--mobile-search {
    #{$self}__wrapper {
      grid-column-gap: 0;
    }
    #{$self}__logo {
      width: 0;
      visibility: hidden;
    }
  }
  &--fixed {
    @media (max-width: 992px) {
      #{$self}__logo {
        overflow: hidden;
        justify-self: start;
      }
      #{$self}__logo-standard {
        width: 28px;
      }
    }
  }
  *:focus {
    outline: none;
  }
  a {
    text-decoration: none;
    transition: color 0.3s ease-in;
  }
  &__wrapper {
    width: 100%;
    margin: 0 auto;
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 1fr minmax(auto, 100%) 1fr;
    grid-column-gap: 14px;
    align-items: center;
    #{$self}:not(#{$self}--has-auxiliary-menu) & {
      @media (min-width: 992px) {
        grid-template-columns: 1fr minmax(auto, 768px) 1fr;
      }
    }
    @media (min-width: 1200px) {
      grid-column-gap: 32px;
    }
  }
  &__logo {
    grid-column: 1/2;
    grid-row: 1/2;
    line-height: normal;
  }
  &__logo-image {
    overflow: hidden;
    text-indent: -9999px;
  }
  &__btn-menu {
    grid-column: 4/4;
    grid-row: 1/2;
    font-family: inherit;
    line-height: inherit;
    -webkit-appearance: button;
    img {
      vertical-align: middle;
      border-style: none;
    }
  }
  &__navgroup {
    grid-column: 3/4;
    grid-row: 1/2;
    #{$self}:not(#{$self}--has-auxiliary-menu) & {
      display: none;
      @media (min-width: 992px) {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &__navbar {
    &--mobile {
      @media (max-width: 992px) {
        display: flex;
        position: fixed;
        top: 0;
        bottom: 0;
        right: -100%;
        transition: right, 0.4s;
        width: 70%;
        height: 100%;
        align-items: center;
        align-content: center;
        padding-left: 40px;
      }
    }
    &--mobile {
      #{$self}__close-menu-btn {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 16px;
        right: 16px;
        text-align: center;
        padding: 11px;
        z-index: 99;
        background: #fff;
        border-radius: 50%;
        border: 0;
        @media (min-width: 992px) {
          display: none;
        }
      }
      #{$self}__close-icon {
        display: block;
        margin: auto;
      }
      #{$self}__nav {
        overflow-y: auto;
        max-height: 69%;
        height: 100%;
        width: 100%;
        padding-right: 64px;
        align-self: center;
        #{$self}:not(#{$self}--has-auxiliary-menu) & {
          @media (min-width: 992px) {
            display: none;
          }
        }
      }
      #{$self}__nav-link--dropdown {
        &:first-child {
          padding-top: 0;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  &__nav {
    padding-left: 0;
    list-style: none;
    margin: 0;
    @media (min-width: 992px) {
      display: flex;
      justify-content: flex-end;
      overflow-y: visible;
      padding: 0;
    }
  }
  &__nav-item {
    white-space: nowrap;
    @media (min-width: 992px) {
      position: relative;
    }
    &:hover {
      #{$self}__sub-nav {
        display: block;
      }
    }
  }
  &__nav-link {
    display: inline-block;
    position: relative;
    &--dropdown {
      white-space: nowrap;
      &:after {
        display: inline-block;
        margin-left: 4px;
        vertical-align: 4px;
        content: '';
        border-top: 5px solid;
        border-right: 5px solid transparent;
        border-bottom: 0;
        border-left: 5px solid transparent;
      }
    }
  }
  &__sub-nav {
    list-style: none;
    @media (min-width: 992px) {
      display: none;
      position: absolute;
      top: 96%;
      left: 0;
      z-index: 1000;
      min-width: 160px;
      background-clip: padding-box;
    }
  }
  &__sub-nav-item {
    display: block;
  }
  &__sub-nav-link {
    display: inline-block;
    @media (min-width: 992px) {
      display: block;
    }
  }
}
</style>

<template>
  <header
    id="header"
    class="wl-header"
    role="banner"
    ref="header"
    :class="{
      'wl-header--open-nav': show,
      'wl-header--has-auxiliary-menu': hasAuxiliaryMenu,
      'wl-header--fixed': isFixed
    }"
  >
    <div class="wl-header__wrapper">
      <a
        v-for="item in renderIf(logo)"
        :key="item"
        :title="logoTitle"
        class="wl-header__logo"
        :href="logoPath"
        :aria-label="logoAria"
      >
        <span class="wl-header__logo-image" v-text="`${$config.website}.com`" />
        <span
          v-for="item in renderIf(logoName)"
          :key="item"
          class="wl-header__logo-name"
          v-text="logoName"
        />
      </a>
      <!-- slot for css controlled logo image -->
      <slot name="header-logo"></slot>
      <!-- Slot for anything inside the header bar -->
      <slot name="header-bar"></slot>
      <!-- Toggle menu for mobile -->
      <button
        id="btn-menu"
        v-for="item in renderIf(!hideMenu)"
        :key="item"
        class="wl-header__btn-menu"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        v-touch:tap="menuToggle"
      >
        <span
          v-for="item in renderIf(menuToggleLabel)"
          :key="item"
          class="wl-header__btn-menu-label"
          v-text="menuToggleLabel"
        />
      </button>
      <!-- ~./ Toggle menu for mobile/Ipad -->
      <section
        class="wl-header__navgroup"
        v-for="item in renderIf(showAuxMenu)"
        :key="item"
      >
        <!-- Navigation -->
        <nav
          v-for="item in renderIf(hasMenu)"
          :key="item"
          class="wl-header__navbar"
        >
          <ul class="wl-header__nav">
            <li
              class="wl-header__nav-item"
              :class="{ 'wl-header__nav-item--home': link.url === '/' }"
              :key="link.label"
              v-for="link in menu"
            >
              <a
                class="wl-header__nav-link"
                :class="{
                  'wl-header__nav-link--active': path === link.url,
                  'wl-header__nav-link--dropdown':
                    link.links && link.links.length > 0
                }"
                :href="link.url"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                v-text="link.label"
              />
              <div
                v-for="item in renderIf(
                  !hasAuxiliaryMenu && link.children && link.children.length > 0
                )"
                :key="item"
                class="wl-header__sub-nav"
                aria-labelledby="navDropdown"
              >
                <a
                  :key="child.label"
                  v-for="child in link.children"
                  class="wl-header__sub-nav-link"
                  :class="{
                    'wl-header__nav-link--active': path === child.url
                  }"
                  :href="child.url"
                  v-text="child.label"
                />
              </div>
            </li>
          </ul>
        </nav>
        <!-- ~./ Navigation -->
        <!-- Auxiliary Menu -->
        <nav
          v-for="item in renderIf(hasAuxiliaryMenu)"
          :key="item"
          class="wl-header__navbar"
        >
          <ul class="wl-header__nav">
            <li
              class="wl-header__nav-item"
              :class="{ 'wl-header__nav-item--home': link.url === '/' }"
              :key="link.label"
              v-for="link in auxiliaryMenu"
            >
              <a
                class="wl-header__nav-link"
                :class="{
                  'wl-header__nav-link--active': path === link.url
                }"
                :href="link.url"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                v-text="link.label"
              />
            </li>
          </ul>
        </nav>
        <!-- ~./ Auxiliary Menu -->
      </section>
    </div>
  </header>
</template>

<script>
export default {
  name: 'WlHeader',
  props: {
    // Menu navigation
    menu: {
      type: Array,
      default: Array
    },
    // Auxiliary Menu navigation
    auxiliaryMenu: {
      type: Array,
      default: Array
    },
    // Customer support icon
    supportIcon: {
      type: String
    },
    // Logo url for the website
    logo: {
      type: String
    },
    // Small logo url for the website when mobile and search bar present
    logoSmall: {
      type: String
    },
    // Sizing for small logo as object {height: string, width: string}
    logoSmallSize: {
      type: Object,
      default() {
        return { height: null, width: null };
      }
    },
    // Logo text that's used on the logo
    logoName: {
      type: String,
      default: String
    },
    // Title for the logo
    logoTitle: {
      type: String,
      default: String
    },
    // Aria label for the logo
    logoAria: {
      type: String,
      default: 'Home'
    },
    // Sizing for Logo as object {height: string, width: string}
    logoStandardSize: {
      type: Object,
      default() {
        return { height: null, width: null };
      }
    },
    // Sizing for support icon as object {height: string, width: string}
    supportIconSize: {
      type: Object,
      default() {
        return { height: null, width: null };
      }
    },
    // Customer support link label
    supportLabel: {
      type: String
    },
    // Customer support link
    supportLink: {
      type: String
    },
    // Customer support text that appears above link on mobile
    supportText: {
      type: String,
      default: String
    },
    // Current route path
    path: {
      type: String,
      default: String
    },
    // Redirects to this path when logo is clicked
    logoPath: {
      type: String,
      default: '/'
    },
    // Is Header Fixed
    isFixed: {
      type: Boolean,
      default: false
    },
    hideMenu: {
      type: Boolean,
      default: false
    },
    showAuxMenu: {
      type: Boolean,
      default: false
    },
    menuToggleLabel: {
      type: String
    }
  },
  computed: {
    isHome() {
      return this.path === '/';
    },
    hasAuxiliaryMenu() {
      return this.auxiliaryMenu && this.auxiliaryMenu.length > 0;
    },
    hasMenu() {
      return this.menu && this.menu.length > 0;
    }
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.handleToggleMenu();
  },
  methods: {
    /**
     * If statement vue hack to prevent <!-- --> comment
     * @param condition
     * @returns {[]}
     */
    renderIf(condition) {
      return condition ? [Math.random()] : [];
    },
    menuToggle() {
      this.show = !this.show;
      this.$emit('menu-toggle', this.show);
    },
    /**
     * If toggle menu is present handle close ability when clicking outside of menu
     */
    handleToggleMenu() {
      const toggleMenuElement = document.getElementById('toggle-menu');
      if (toggleMenuElement) {
        window.addEventListener('click', evt => {
          if (
            !toggleMenuElement.contains(evt.target) &&
            evt.target?.getAttribute('id') !== 'btn-menu' &&
            this.show
          ) {
            this.show = false;
            this.$emit('menu-toggle', this.show);
          }
        });
      }
    }
  }
};
</script>

/**
 * TODO: Remove once endpoint returns current_property on asset
 * Returns true if property sale date is prior to property last seen as owner date
 *
 * @param {object} opts
 * @param {object} opts.asset
 * @return {boolean}
 */
const isCurrentProperty = opts => {
  if (
    !opts ||
    (!opts.asset && !opts.asset.sale_date) ||
    !opts.asset.last_seen_as_owner
  ) {
    return false;
  }
  const saleDate = opts.asset.sale_date.split('-');
  const saleYear = saleDate[0];
  const saleMonth = saleDate[1];
  const lastSeenAsOwner = opts.asset.last_seen_as_owner.split('-');
  const lastYear = lastSeenAsOwner[0];
  const lastMonth = lastSeenAsOwner[1];

  const lastYearAfterLatestSale = saleYear < lastYear;
  const sameYearLastMonthAfterLatestSale =
    saleYear === lastYear && saleMonth <= lastMonth;

  return lastYearAfterLatestSale || sameYearLastMonthAfterLatestSale;
};

export default isCurrentProperty;

/**
 * Returns number formatted with commas
 *
 * @param {object} opts
 * @param {string} opts.number
 * @param {string} opts.default
 * @returns {string}
 */
const getFormattedNumberWithCommas = opts => {
  if (!opts || (opts.number !== 0 && !opts.number)) {
    return opts?.default || 'Unknown';
  }
  return parseFloat(opts.number).toLocaleString('en-US');
};

export default getFormattedNumberWithCommas;

<template>
  <div class="ic-modal-body">
    <h2 class="ic-modal-body__title">{{ heading }}</h2>
    <p class="ic-modal-body__subtitle" v-if="subtitle">{{ subtitle }}</p>
    <p
      v-if="formErrorMessage !== ''"
      class="ic-modal-body__error-body"
      v-text="formErrorMessage"
    />
    <form
      class="ic-modal-body__form"
      :class="{ 'ic-modal-body__form--loading': !cardValidatorLoaded }"
      @submit="addPaymentInfo($event)"
      method="POST"
      autocomplete="on"
    >
      <div
        class="ic-modal-body__form-fields ic-modal-body__form-fields--add-payment"
      >
        <div class="ic-modal-body__form-field">
          <label for="firstName" class="ic-modal-body__form-label">
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            class="ic-modal-body__form-input ic-modal-body__form-input--font-dark"
            v-model="form.firstName"
            required
            @input="checkToActivateAddButton"
            @blur="handleBlur"
            @focus="handleFocus"
            @keypress="handleLettersSpacesDashes"
          />
          <p class="ic-modal-body__error-message" v-if="inputErrors.first_name">
            {{ inputErrorMessages.first_name }}
          </p>
        </div>
        <div class="ic-modal-body__form-field">
          <label for="lastName" class="ic-modal-body__form-label">
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            class="ic-modal-body__form-input ic-modal-body__form-input--font-dark"
            v-model="form.lastName"
            required
            @input="checkToActivateAddButton"
            @blur="handleBlur"
            @focus="handleFocus"
            @keypress="handleLettersSpacesDashes"
          />
          <p class="ic-modal-body__error-message" v-if="inputErrors.last_name">
            {{ inputErrorMessages.last_name }}
          </p>
        </div>
        <div class="ic-modal-body__form-field">
          <label for="cardNumber" class="ic-modal-body__form-label">
            Card Number
          </label>
          <div
            :class="cardNumberModifier"
            class="ic-modal-body__form-input-wrapper"
          >
            <div
              v-if="billingVersion === 'v2'"
              id="cc-number"
              class="vgs-wrapper"
            ></div>
            <div v-else id="spreedly-number" class="spreedly-wrapper"></div>
            <p class="ic-modal-body__error-message" v-if="inputErrors.number">
              {{ inputErrorMessages.number }}
            </p>
          </div>
        </div>
        <div class="ic-modal-body__form-field">
          <label for="expMonth" class="ic-modal-body__form-label">
            Exp. Month
          </label>
          <select
            id="expMonth"
            name="expMonth"
            v-model="form.expMonth"
            class="ic-modal-body__form-input ic-modal-body__form-input--select ic-modal-body__form-input--font-dark"
            @input="checkToActivateAddButton()"
            @blur="handleBlur"
            @focus="handleFocus"
          >
            <option :value="null" disabled hidden>MM</option>
            <option
              v-for="(monthNum, index) in formFields.month.options"
              :value="Number(monthNum)"
              :key="`month_input_${index}`"
            >
              {{ `${monthNum} - ${getMonthName(monthNum)}` }}
            </option>
          </select>
          <p class="ic-modal-body__error-message" v-if="inputErrors.month">
            {{ inputErrorMessages.month }}
          </p>
        </div>
        <div class="ic-modal-body__form-field">
          <label for="expYear" class="ic-modal-body__form-label">
            Exp. Year
          </label>
          <select
            id="expYear"
            name="expYear"
            v-model="form.expYear"
            class="ic-modal-body__form-input ic-modal-body__form-input--select ic-modal-body__form-input--font-dark"
            @input="checkToActivateAddButton()"
            @blur="handleBlur"
            @focus="handleFocus"
          >
            <option :value="null" disabled hidden>YYYY</option>
            <option
              v-for="(year, index) in formFields.year.options"
              :value="year"
              :key="`year_input_${index}`"
            >
              {{ year }}
            </option>
          </select>
          <p class="ic-modal-body__error-message" v-if="inputErrors.year">
            {{ inputErrorMessages.year }}
          </p>
        </div>
        <div class="ic-modal-body__form-field">
          <label for="cvv" class="ic-modal-body__form-label">
            CVV
          </label>
          <div
            v-if="billingVersion === 'v2'"
            id="cc-cvc"
            class="vgs-wrapper"
          ></div>
          <div v-else id="spreedly-cvv" class="spreedly-wrapper"></div>
          <p
            class="ic-modal-body__error-message"
            v-if="inputErrors.verification_value"
          >
            {{ inputErrorMessages.verification_value }}
          </p>
        </div>
        <div class="ic-modal-body__form-field">
          <label class="ic-modal-body__form-label" for="billingZipCode">
            Billing Zip Code
          </label>
          <input
            @keypress="handleNumbersDashes"
            @paste="handleNumbersDashes"
            @blur="handleBlur"
            @focus="handleFocus"
            @input="checkToActivateAddButton()"
            type="text"
            v-model="form.zipCode"
            id="billingZipCode"
            class="ic-modal-body__form-input ic-modal-body__form-input--font-dark"
            name="zipCode"
            required
            maxlength="5"
          />
          <div v-if="inputErrors.zipCode" class="ic-modal-body__error-message">
            {{ inputErrorMessages.zipCode }}
          </div>
        </div>
        <div class="ic-modal-body__form-field" v-if="!forceDefaultPayment">
          <label
            class="ic-modal-body__form-label ic-modal-body__form-label--checkbox"
          >
            <input
              class="ic-modal-body__form-checkbox"
              type="checkbox"
              v-model="form.isDefaultPayment"
            />
            <span class="ic-modal-body__form-label-text">{{
              makeDefaultPaymentText
            }}</span>
          </label>
        </div>
      </div>
      <div class="dashboard__disclaimer" v-if="warningText">
        <p v-html="warningText" />
      </div>
      <div
        class="ic-modal-body__form-buttons ic-modal-body__form-buttons--two-columns"
      >
        <button
          type="submit"
          class="ic-modal-body__form-button"
          :class="{
            'ic-modal-body__form-button--loading': isProcessingPaymentInfo
          }"
          :disabled="isAddPaymentDisabled || isProcessingPaymentInfo"
        >
          {{ submitBtnText }}
        </button>
        <button
          class="ic-modal-body__form-button ic-modal-body__form-button--cancel"
          :disabled="isProcessingPaymentInfo"
          v-touch:tap="closeModal"
        >
          {{ cancelButtonText }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import helpers from '@/assets/js/shared/misc/helpers';
import billingSDK from '@/assets/js/shared/services/billing-sdk';
import billingHelper from '@/assets/js/shared/billingHelper';
import getSessionItem from '@/assets/js/shared/helpers/getSessionItem';

const cms = require('@/assets/cms/pages/dashboard/dashboard-account.json');
const minYear = new Date().getFullYear();
const maxYear = minYear + 20;
const selectYears = [];
const digitsRegex = /^[0-9]+$/;

for (let i = minYear; i <= maxYear; i++) {
  selectYears.push(i);
}

export default {
  name: 'ic-add-payment',
  props: {
    // logged in user's id
    userId: {
      type: String,
      required: true
    },
    // domain of user
    domain: {
      type: String,
      required: true
    },
    errorMsg: {
      type: String
    },
    heading: {
      type: String,
      default: cms.add_payment_modal_header
    },
    subtitle: {
      type: String,
      default: null
    },
    submitBtnText: {
      type: String,
      default: 'Add'
    },
    warningText: {
      type: String,
      default: null
    },
    forceDefaultPayment: {
      type: Boolean,
      default: false
    },
    // this prop is used when the user adds a payment and that payment needs to be charged immediately
    shouldChargeCardImmediately: {
      type: Boolean,
      default: false
    },
    // pass line items if added payment needs to be charged immediately
    lineItems: {
      type: Array,
      default: null
    },
    // if substitution of subscription is needed when updating payment
    shouldSubstituteSubscription: {
      type: Boolean,
      default: false
    },
    billingVersion: {
      type: String,
      default: 'v1'
    }
  },
  methods: {
    /**
     * When input blurred handles that inputs error message
     * @param event
     */
    handleBlur(event) {
      const fieldName = event.target.name;
      if (fieldName === 'firstName' && this.form.firstName === '') {
        this.inputErrors.first_name = true;
      }
      if (fieldName === 'lastName' && this.form.lastName === '') {
        this.inputErrors.last_name = true;
      }
      if (fieldName === 'expMonth' && !this.form.expMonth) {
        this.inputErrors.month = true;
      }
      if (fieldName === 'expYear' && !this.form.expYear) {
        this.inputErrors.year = true;
      }
      if (fieldName === 'zipCode' && !this.form.zipCode) {
        this.inputErrors.zipCode = true;
      }
    },
    /**
     * When focused on an input, error is removed
     * @param event
     */
    handleFocus(event) {
      this.formErrorMessage = '';
      const fieldName = event.target.name;
      if (fieldName === 'firstName' && this.inputErrors.first_name) {
        this.inputErrors.first_name = false;
      }
      if (fieldName === 'lastName' && this.inputErrors.last_name) {
        this.inputErrors.last_name = false;
      }
      if (fieldName === 'expMonth' && this.inputErrors.month) {
        this.inputErrors.month = false;
      }
      if (fieldName === 'expYear' && this.inputErrors.year) {
        this.inputErrors.year = false;
      }
      if (fieldName === 'zipCode' && this.inputErrors.zipCode) {
        this.inputErrors.zipCode = false;
      }
    },
    /**
     * Only accept allow letters, spaces, and dashes on the field
     * @param {object} evt
     */
    handleLettersSpacesDashes(evt) {
      let value = null;
      if (evt.type === 'paste') {
        const clipboardData = evt.clipboardData;
        value = clipboardData.getData('Text');
      } else if (evt.type === 'keypress') {
        const charCode = evt.charCode ? evt.charCode : evt.which;
        value = String.fromCharCode(charCode);
      }
      if (!helpers.lettersDashesAndSpaces.test(value)) {
        evt.preventDefault();
      }
      return false;
    },
    /**
     * Only accept numbers and dashes in the field
     * @param {object} evt
     */
    handleNumbersDashes(evt) {
      let value = null;
      if (evt.type === 'paste') {
        const clipboardData = evt.clipboardData;
        value = clipboardData.getData('Text');
      } else if (evt.type === 'keypress') {
        const charCode = evt.charCode ? evt.charCode : evt.which;
        value = String.fromCharCode(charCode);
      }
      if (!digitsRegex.test(value)) {
        evt.preventDefault();
      }
      return false;
    },
    /**
     * Checks payment form for all fields to be ready for submit
     */
    checkToActivateAddButton() {
      let disabled = true;
      for (const prop in this.form) {
        if (
          this.form[prop] &&
          prop === 'zipCode' &&
          this.form[prop].length !== 5
        ) {
          disabled = true;
        } else if (
          this.form[prop] &&
          this.form[prop] !== '' &&
          prop !== 'isDefaultPayment'
        ) {
          disabled = false;
        } else if (prop !== 'isDefaultPayment') {
          disabled = true;
          break;
        }
      }
      this.isAddPaymentDisabled = disabled;
    },
    /**
     * Submits form to tokenize payment method via Spreedly, which will trigger the Spreedly.on('payment_method') event to add payment
     */
    async addPaymentInfo(event) {
      event.preventDefault();
      this.isProcessingPaymentInfo = true;
      if (this.billingVersion !== 'v2') {
        const tokenizeBody = {
          first_name: this.form.firstName,
          last_name: this.form.lastName,
          month: this.form.expMonth,
          year: this.form.expYear,
          zip: this.form.zipCode
        };
        Spreedly.tokenizeCreditCard(tokenizeBody);
      } else {
        this.submitVgsForm();
      }
    },
    /**
     * Closes modal
     */
    closeModal() {
      this.$emit('ic-account-modal-close');
    },
    /**
     * Gets month name from month number
     * @param {string} monthNum
     * @returns string
     */
    getMonthName(monthNum) {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
      return monthNames[Number(monthNum) - 1];
    },
    /**
     * Sets options for spreedly form inputs
     * @param {object} spreedly
     */
    setSpreedlyOptions(spreedly) {
      spreedly.setFieldType('number', 'text');
      spreedly.setFieldType('cvv', 'text');
      spreedly.setNumberFormat('prettyFormat');
      const spreedlyStyles =
        'width: 100%; height: 45px; padding: 10px 15px; border: solid 1px #ddd; border-radius: 4px; color: #595a74; font-size: 16px; font-family: ubuntu, sans-serif; fon-weight: 200; box-sizing: border-box;';
      spreedly.setStyle('number', spreedlyStyles);
      spreedly.setStyle('cvv', spreedlyStyles);
    },
    /**
     * Handles spreedly input
     * @param {string} name
     * @param {object} inputData
     */
    handleSpreedlyInput(name, inputData) {
      const isCardMasterOrVisa =
        inputData['cardType'] === 'master' || inputData['cardType'] === 'visa';
      this.cardNumberModifier = isCardMasterOrVisa
        ? `ic-modal-body__form-input-wrapper--${inputData['cardType']}`
        : null;
      this.form.isValidCvv = inputData['validCvv'];
      this.cardInputLength = inputData['numberLength'];
      const isValidLength = this.cardInputLength >= 16;
      this.form.isValidCardNumber = isCardMasterOrVisa && isValidLength;
    },
    /**
     * When Spreedly input blurred handles that inputs error message
     * @param {string} name
     */
    handleSpreedlyBlur(name) {
      if (name === 'number') {
        if (this.form.isValidCardNumber) {
          this.inputErrors.number = false;
        } else if (this.cardInputLength === 0) {
          this.inputErrors.number = true;
          this.inputErrorMessages.number = 'This field is required';
        } else if (this.cardInputLength < 16) {
          this.inputErrors.number = true;
          this.inputErrorMessages.number = 'Invalid card number';
        } else {
          this.inputErrors.number = true;
          this.inputErrorMessages.number = 'Payment must be Visa or Mastercard';
        }
      }
      if (name === 'cvv') {
        if (this.form.isValidCvv) {
          this.inputErrors.verification_value = false;
        } else {
          this.inputErrors.verification_value = true;
          this.inputErrorMessages.verification_value = 'Invalid CVV';
        }
      }
    },
    /**
     * When Spreedly input blurred handles that inputs error message
     * @param {string} name
     */
    handleSpreedlyFocus(name) {
      this.formErrorMessage = '';
      if (name === 'number') {
        this.inputErrors.number = false;
      }
      if (name === 'cvv') {
        this.inputErrors.verification_value = false;
      }
    },
    handleUpsertErrors(res) {
      let err;
      if (
        !res ||
        res.msg ===
          `Processor: Payment could not be verified: Unable to process the verify transaction.` ||
        (res.type && res.type.includes('error'))
      ) {
        err = `Payment could not be verified. Please check the information provided.`;
      }
      if (
        res.msg ===
        'Unable to update payment, can not unset preferred payment until another card is set'
      ) {
        err = `Cannot update this payment's default payment status while it is currently your default payment.`;
      }

      if (
        res.msg === `Payment method already exists on this account.` ||
        res.msg.includes('Duplicate payment')
      ) {
        err = `Payment method already exists on this account.`;
      }
      if (err) throw { formMessage: err };
    },
    /**
     * uses billing SDK to add payment
     * @param {string} name
     */
    async upsertPayment(token, paymentData) {
      const {
        year: expire_year,
        month: expire_month,
        first_name: firstname,
        last_name: lastname,
        card_type: type,
        payment_method_type: kind,
        fingerprint,
        last_four_digits: card_check_digit,
        zip
      } = paymentData;
      const {
        isDefaultPayment: preferred_payment,
        firstName: formFirstName,
        lastName: formLastName,
        expMonth: formExpMonth,
        expYear: formExpYear,
        zipCode: formZip
      } = this.form;
      const paymentBody = {
        preferred_payment,
        token,
        fingerprint,
        card_check_digit,
        type,
        kind,
        expire_month,
        expire_year,
        firstname,
        lastname,
        zip
      };

      // need to check on things below due to funky spreedly event functionality
      if (
        firstname != formFirstName &&
        lastname != formLastName &&
        expire_year != formExpYear &&
        expire_month != formExpMonth &&
        zip != formZip
      )
        return false;

      try {
        const res = await this.billingSDK.Billing.Payments.addByAccount(
          paymentBody,
          this.domain,
          this.userId
        );

        await this.handleUpsertErrors(res);

        this.$emit('add-payment', res.data);
        if (!this.shouldChargeCardImmediately) {
          this.closeModal();
        }
        return true;
      } catch (err) {
        this.formErrorMessage =
          err.formMessage ?? `Error adding payment method`;
        console.error(err);
        this.$bugsnag?.notify(err);
        return false;
      }
    },
    async chargeByInventoryRetryRebill() {
      const aid =
        getSessionItem({ itemName: 'landing' })?.aid ??
        this.$config.defaultVars.aid;
      const body = {
        currency_code: 'USD',
        line_items: this.lineItems ?? [],
        data: {
          city: this.userCity,
          state: this.userState,
          country: this.userCountry,
          aid,
          extra_tracking: {}
        }
      };
      const res = await billingHelper.chargeByInventoryRetryRebill({
        body,
        domain: this.domain,
        billingVersion: this.billingVersion,
        vm: this
      });
      if (!res) {
        return await this.$emit('failed-retry-rebill');
      }
      this.closeModal();
      this.$emit('successful-retry-rebill');
    },
    initSpreedly() {
      if (this.cardValidatorLoaded || this.cardValidatorLoading) return;
      this.cardValidatorLoading = true;

      if (Spreedly.numberTarget && Spreedly.cvvTarget) {
        Spreedly.reload();
      } else {
        Spreedly.init(this.$config.spreedlyEnvironmentKey, {
          numberEl: 'spreedly-number',
          cvvEl: 'spreedly-cvv'
        });
      }
      const vm = this;

      Spreedly.on('ready', function(frame) {
        vm.setSpreedlyOptions(Spreedly);
        vm.cardValidatorLoaded = true;
        vm.cardValidatorLoading = false;
      });

      Spreedly.on('fieldEvent', function(
        name,
        event,
        activeElement,
        inputData
      ) {
        switch (event) {
          case 'input':
            vm.handleSpreedlyInput(name, inputData);
            vm.checkToActivateAddButton();
            break;
          case 'blur':
            vm.handleSpreedlyBlur(name);
            break;
          case 'focus':
            vm.handleSpreedlyFocus(name);
            break;
        }
      });

      Spreedly.on('errors', function(errors) {
        errors.forEach(error => {
          for (const prop in vm.inputErrors) {
            if (prop === error.attribute) {
              vm.inputErrors[prop] = true;
              vm.inputErrorMessages[prop] = error.message;
            }
          }
        });
        vm.formErrorMessage = `Error adding payment method`;
        vm.isProcessingPaymentInfo = false;
      });

      Spreedly.on('paymentMethod', async function(token, paymentData) {
        if (!vm.isProcessingPaymentInfo) {
          return;
        }

        if (vm.shouldSubstituteSubscription) {
          return await vm.$emit('substitution', {
            token,
            paymentData,
            form: vm.form
          });
        }
        const successfulUpsert = await vm.upsertPayment(token, paymentData);
        if (successfulUpsert && vm.shouldChargeCardImmediately) {
          await vm.chargeByInventoryRetryRebill();
        }

        vm.isProcessingPaymentInfo = false;
      });
    },
    async initVgs() {
      if (this.cardValidatorLoaded || this.cardValidatorLoading) return;
      const cnamePrefix = this.$config.vgsEnv === 'sandbox' ? 'staging.' : '';
      const cname = `${cnamePrefix}vault.trazidev.com`;
      this.cardValidatorLoading = true;
      this.vgsForm = await VGSCollect.create(
        this.$config.vgsVaultId,
        this.$config.vgsEnv,
        () => {}
      ).useCname(cname);
      const css = {
        'font-size': '16px',
        'font-weight': '400',
        'line-height': '1',
        'background-color': '#fff',
        border: '1px solid #d4d4d4',
        'border-radius': '4px',
        padding: '10px 15px',
        width: '100%',
        height: '45px',
        'box-sizing': 'border-box',
        color: '#1d1e2a'
      };
      const cardField = await this.vgsForm.field('#cc-number', {
        type: 'card-number',
        name: 'number',
        showCardIcon: false,
        validations: ['required', 'validCardNumber'],
        showCardIcon: {
          right: '10px'
        },
        css: css
      });
      const cvcField = await this.vgsForm.field('#cc-cvc', {
        type: 'card-security-code',
        name: 'cvv',
        validations: ['required', 'validCardSecurityCode'],
        css: css
      });
      [cardField, cvcField].forEach(field => {
        field.on('update', () => this.handleVgsInput());
        field.on('blur', () => this.handleVgsBlur(field.name));
        field.on('focus', () => this.handleVgsFocus(field.name));
      });
      this.cardValidatorLoaded = true;
      this.cardValidatorLoading = false;
    },
    handleVgsInput() {
      const cardNumber = this.vgsForm.state.number;
      const isCardMasterOrVisa =
        cardNumber?.cardType === 'mastercard' ||
        cardNumber?.cardType === 'visa';
      this.form.isValidCardNumber = isCardMasterOrVisa && cardNumber.isValid;
    },
    handleVgsFocus(name) {
      this.formErrorMessage = '';
      this.$emit('remove-form-error');
      switch (name) {
        case 'card_number':
          this.inputErrors.number = false;
          break;
        case 'card_cvc':
          this.inputErrors.verification_value = false;
          break;
      }
    },
    handleVgsBlur(name) {
      let fieldState;
      switch (name) {
        case 'number':
          fieldState = this.vgsForm.state.number;

          const isCardMasterOrVisa =
            fieldState.cardType === 'mastercard' ||
            fieldState.cardType === 'visa';
          if (fieldState.isValid) {
            this.inputErrors.number = false;
            this.form.isValidCardNumber = isCardMasterOrVisa;
          } else {
            this.inputErrors.number = true;
            if (fieldState.isEmpty) {
              this.inputErrorMessages.number = 'This field is required';
            } else if (!isCardMasterOrVisa) {
              this.inputErrorMessages.number =
                'Payment must be Visa or Mastercard';
            } else {
              this.inputErrorMessages.number = 'Invalid card number';
            }
          }
          break;
        case 'cvv':
          fieldState = this.vgsForm.state.cvv;
          if (fieldState.isValid) {
            this.inputErrors.verification_value = false;
            this.form.isValidCvv = true;
          } else {
            this.inputErrors.verification_value = true;
            this.inputErrorMessages.verification_value = fieldState.isEmpty
              ? 'This field is required'
              : 'Invalid CVV';
          }
      }
    },
    submitVgsForm() {
      // TODO: confirm prod endpoint is correct
      const endpoint =
        this.$config.vgsEnv === 'sandbox'
          ? `/staging/api/billing-processor/v1/payment/setup/domain/${this.domain}`
          : `/api/billing-processor/v1/payment/setup/domain/${this.domain}`;
      this.vgsForm.submit(
        endpoint,
        {
          method: 'POST',
          data: formValues => {
            return {
              card_number: formValues['number'],
              card_cvc: formValues['cvv'],
              zipcode: this.form.zipCode,
              firstname: this.form.firstName,
              lastname: this.form.lastName,
              exp_month:
                this.form.expMonth.toString().length === 1
                  ? `0${this.form.expMonth}`
                  : this.form.expMonth,
              exp_year: this.form.expYear.toString().slice(-2),
              preferred_payment: this.form.isDefaultPayment,
              account_id: this.userId
            };
          }
        },
        async (status, response) => {
          if (response?.type === 'success') {
            if (this.shouldSubstituteSubscription) {
              return this.$emit('substitution', {
                id: response.payment_id,
                form: this.form
              });
            }
            this.$emit('add-payment', {
              id: response.payment_id
            });
            if (!this.shouldChargeCardImmediately) {
              this.closeModal();
            } else {
              await this.chargeByInventoryRetryRebill();
            }

            this.isProcessingPaymentInfo = false;
          } else {
            handleUpsertErrors(response);
          }
        },
        err => {
          this.formErrorMessage =
            err.formMessage ?? `Error adding payment method`;
          console.error(err);
          this.$bugsnag?.notify(err);
          return false;
        }
      );
    }
  },
  mounted() {
    this.billingSDK = billingSDK(window);

    if (this.cardValidatorLoading) return;

    const vgsScript = document.querySelector('[data-hid=vgs-ic-add-payment]');
    const spreedlyScript = document.querySelector(
      '[data-hid=spreedly-ic-add-payment]'
    );
    if (vgsScript) {
      this.initVgs();
    } else if (spreedlyScript) {
      this.initSpreedly();
    }
  },
  data() {
    return {
      addPaymentModalHeading: cms.add_payment_modal_header,
      billingSDK,
      makeDefaultPaymentText: cms.make_default_payment_text,
      addButtonText: cms.buttons.add,
      cancelButtonText: cms.buttons.cancel,
      isPaymentModalOpen: false,
      isProcessingPaymentInfo: false,
      isAddPaymentDisabled: true,
      cardNumberModifier: null,
      isValidCardNumber: false,
      isValidCvv: false,
      cardInputLength: 0,
      formFields: {
        month: {
          options: [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12'
          ]
        },
        year: {
          options: selectYears
        }
      },
      formErrorMessage: this.errorMsg || '',
      inputErrors: {
        first_name: false,
        last_name: false,
        number: false,
        verification_value: false,
        month: false,
        year: false,
        zipCode: false
      },
      inputErrorMessages: {
        first_name: 'This field is required',
        last_name: 'This field is required',
        number: '',
        verification_value: '',
        month: 'This field is required',
        year: 'This field is required',
        zipCode: 'This field is required'
      },
      form: {
        firstName: '',
        lastName: '',
        isValidCardNumber: false,
        isValidCvv: false,
        expMonth: null,
        expYear: null,
        isDefaultPayment: this.forceDefaultPayment,
        zipCode: null
      },
      userState: this.$store.getters['location/state'],
      userCity: this.$store.getters['location/city'],
      userCountry: this.$store.getters['location/country'],
      vgsForm: null,
      cardValidatorLoading: false,
      cardValidatorLoaded: false
    };
  },
  beforeDestroy() {
    if (this.billingVersion === 'v1') {
      Spreedly.removeHandlers();
    }
  },
  head() {
    let cardValidatorScript;
    if (this.billingVersion !== 'v1') {
      cardValidatorScript = {
        hid: 'vgs-ic-add-payment',
        src: 'https://js.verygoodvault.com/vgs-collect/2.24.6/vgs-collect.js',
        callback: () => {
          this.initVgs();
        }
      };
    } else {
      cardValidatorScript = {
        hid: 'spreedly-ic-add-payment',
        src: 'https://core.spreedly.com/iframe/iframe-v1.min.js',
        callback: () => {
          this.initSpreedly();
        }
      };
    }
    return {
      script: [cardValidatorScript]
    };
  }
};
</script>

<template>
  <div
    class="wl-radio"
    :class="{ 'wl-radio--selected': isSelected }"
    v-touch:tap="onRadioClicked"
  >
    <div class="wl-radio__selection-container">
      <span class="wl-radio__input">
        <input
          name="radio-option"
          type="radio"
          :checked="isSelected"
          :value="value"
        />
        <span class="wl-radio__input--control"></span>
      </span>
      <div class="wl-radio__label-container">
        <p v-if="title" class="wl-radio__title" v-html="title" />
        <p v-if="subTitle" class="wl-radio__subtitle">
          {{ subTitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WlRadio',
  methods: {
    onRadioClicked() {
      this.$emit('radio-selection', this.value);
    }
  },
  props: {
    // title of radio label
    title: {
      type: String,
      required: true
    },
    // Sub title of radio label
    subTitle: {
      type: String
    },
    // Index of radio
    value: {
      type: Number,
      default: null
    },
    // Boolean for if radio is selected
    isSelected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

/**
 * Generates gender string from traits property returned in response
 *
 * @param {object} opts
 * @param {string} opts.gender
 *  @param {string} opts.defaultValue
 * @returns {string}
 */

const getGender = (opts = {}) => {
  if (!opts || !opts.gender) {
    return opts?.defaultValue || 'Unknown';
  }
  const { gender } = opts;
  switch (gender.toLowerCase()) {
    case 'm':
      return 'Male';
    case 'f':
      return 'Female';
    default:
      return gender;
  }
};

export default getGender;

/**
 * Removes period that is automatically added on some devices when a double space is typed in an input
 *
 * @param {object} opts
 * @param {string} opts.inputData
 * @param {string} opts.inputValue
 * @returns {string}
 */
const removeInputAutoPeriod = (opts = {}) => {
  if (!opts || !opts.inputValue) {
    return '';
  }
  if (!opts.inputData) {
    return opts.inputValue;
  }
  let str = opts.inputValue;
  if (opts.inputData === '. ') {
    str = str.slice(0, str.length - 2) + ' ';
  }
  return str;
};

export default removeInputAutoPeriod;

import getCheckRomanNumeral from './getCheckRomanNumeral';
import formatNameCaseWithSplitterAndSpacer from './formatNameCaseWithSplitterAndSpacer';

/**
 * @deprecated - does not follow opts = {} params
 *
 * Returns a formatted object
 *   Removes/replaces values that are null, undefined, and empty strings to a specified value.
 *   Values that are strings without numbers will be title cased
 *
 * @param {object} obj
 * @param {string|undefined} defaultValue
 * @param {Boolean} setTitleCase
 * @returns {object}
 */
const getFormattedNonNullObjectDeprecated = (
  obj,
  defaultValue = undefined,
  setTitleCase = false
) => {
  for (const [key, value] of Object.entries(obj)) {
    const isValidString = typeof value === 'string' && value.trim().length;
    const isObj = typeof value === 'object';
    if (value) {
      if (isObj) {
        getFormattedNonNullObjectDeprecated(value, defaultValue, setTitleCase);
      }
      if (isValidString && setTitleCase) {
        obj[key] = value
          .split(' ')
          .map(i =>
            i.match(/\d+/g) || getCheckRomanNumeral({ value: i })
              ? i
              : formatNameCaseWithSplitterAndSpacer({ name: i })
          )
          .join(' ');
      }
    } else {
      obj[key] = defaultValue;
    }
  }
  return obj;
};

/**
 * Returns a formatted object
 *   Removes/replaces values that are null, undefined, and empty strings to a specified value.
 *   Values that are strings without numbers will be title cased
 *   Keys in the skipCasingKeys array will not be title cased, even with a valid string value
 *
 * @param {object} opts
 * @param {object} opts.obj
 * @param {string|undefined} opts.defaultValue
 * @param {array} opts.skipCasingKeys
 * @param {Boolean} opts.setTitleCase
 * @returns {object}
 */
const getFormattedNonNullObjectData = (opts = {}) => {
  for (const [key, value] of Object.entries(opts.obj)) {
    const isValidString = typeof value === 'string' && value.trim().length;
    const isObj = typeof value === 'object';
    const isSkippingTitleCase = Array.isArray(opts.skipCasingKeys)
      ? opts.skipCasingKeys.includes(key)
      : false;
    if (value) {
      if (isObj) {
        getFormattedNonNullObjectData({
          obj: value,
          defaultValue: opts.defaultValue,
          setTitleCase: opts.setTitleCase
        });
      }
      if (isValidString && opts.setTitleCase && !isSkippingTitleCase) {
        opts.obj[key] = value
          .split(' ')
          .map(i =>
            i.match(/\d+/g) || getCheckRomanNumeral({ value: i })
              ? i
              : formatNameCaseWithSplitterAndSpacer({ name: i })
          )
          .join(' ');
      }
    } else {
      opts.obj[key] = opts.defaultValue;
    }
  }

  return opts.obj;
};

/**
 * Determines whether to use new or deprecated code based on params passed in
 *
 * @param {object} obj
 * @param {string|null|undefined} defaultValue
 * @param {string|null|undefined} setTitleCase
 * @returns {Object|(function(Object, (string|undefined)=, Boolean=): Object)}
 */
const getFormattedNonNullObject = (obj, defaultValue, setTitleCase) => {
  if (obj.hasOwnProperty('obj')) {
    return getFormattedNonNullObjectData(obj);
  }

  return getFormattedNonNullObjectDeprecated(obj, defaultValue, setTitleCase);
};

export default getFormattedNonNullObject;

<template>
  <button class="wl-upsell-cta" v-touch.tap="handleClick">
    <div class="wl-upsell-cta__container">
      <span class="wl-upsell-cta__title" v-text="title" />
      <span class="wl-upsell-cta__content">
        <span class="wl-upsell-cta__sub-text" v-text="subText" />
        <span class="wl-upsell-cta__cta-label" v-text="ctaLabel" />
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'wl-upsell-cta',
  props: {
    title: {
      type: String,
      default: null
    },
    subText: {
      type: String,
      default: null
    },
    ctaLabel: {
      type: String,
      default: null
    }
  },
  methods: {
    handleClick() {
      this.$emit('wl-upsell-cta-click');
    }
  }
};
</script>

import { render, staticRenderFns } from "./wl-banner-alert.vue?vue&type=template&id=dee62764"
import script from "./wl-banner-alert.vue?vue&type=script&lang=js"
export * from "./wl-banner-alert.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@1.19.1_vue-tem_hkinhojqo3oripkn5da2gimk7m/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import getSessionItem from './getSessionItem';
import billingSDK from '@/assets/js/shared/services/billing-sdk';
import billingV2SDK from '@/assets/js/shared/services/billing-v2-sdk';

/**
 * Gets user's initial aid at sign up
 * Gets aid either from route, from session storage, from first transaction, or default
 * @param {object} opts
 * @param {object} opts.window
 * @param {object} opts.user
 * @param {*} opts.defaultValue
 * @param {object} $bugsnag
 * @returns {string|*}
 */
const getUsersInitialAid = async (opts, $bugsnag = {}) => {
  const fallbackVal = '';
  if (!opts) {
    return opts?.defaultValue ?? fallbackVal;
  }
  let aid = opts.route?.query?.aid;
  if (aid) {
    return aid;
  }
  aid = getSessionItem({ itemName: 'landing' })?.aid;
  if (aid) {
    return aid;
  }
  if (opts.window && opts.user) {
    try {
      let transactions;
      if (!opts.user.user_data?.v2) {
        transactions = await billingSDK(
          opts.window
        ).Billing.Transactions.byAccount(
          opts.user.domain,
          opts.user.id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          'created_at',
          false
        );
      } else {
        transactions = await billingV2SDK(opts.window).Transactions.byAccount(
          opts.user.domain,
          opts.user.id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          'created_at',
          false
        );
      }
      if (
        !transactions ||
        (transactions &&
          transactions.type &&
          transactions.type.includes('error')) ||
        !transactions.length
      ) {
        return opts.defaultValue ?? fallbackVal;
      }

      return transactions[0].aid;
    } catch (err) {
      console.error(err);
      $bugsnag?.notify(err);
      return opts.defaultValue ?? fallbackVal;
    }
  }
  return opts.defaultValue ?? fallbackVal;
};

export default getUsersInitialAid;

import b64toBlob from './helpers/b64toBlob';
import formatFullName from './helpers/formatFullName';
import formatPhoneNumber from './helpers/formatPhoneNumber';
import FileSaver from 'file-saver';
import digitalProductsSDK from './services/digital-products-sdk';
import formatAddressWithStreet from '@/assets/js/shared/helpers/formatAddressWithStreet';

const pdfHelper = {
  /**
   * checks permission and emails PDF
   * @param {object} opts
   * @param {string} opts.userId
   * @param {string} opts.reportType
   * @param {string} opts.reportCode
   * @param {string} [opts.filename]
   */
  async emailPDF(opts) {
    if (!opts || !opts.userId || !opts.reportType || !opts.reportCode) {
      return;
    }
    await digitalProductsSDK(window).ReportsEmail.pdf(
      opts.userId,
      opts.reportType,
      opts.reportCode,
      opts.filename
    );
  },

  /**
   * checks permission and downloads PDF
   * @param {object} opts
   * @param {object} opts.vm
   * @param {object} opts.name
   * @param {object} opts.reportCode
   * @param {*} opts.get
   */
  async downloadPDF(opts) {
    let reportDisplayParam;
    switch (opts.type) {
      case 'phone':
        reportDisplayParam = formatPhoneNumber({
          phoneNumber: opts.phone
        }).replace(/ /g, '_');
        break;
      case 'address':
        const address = await formatAddressWithStreet({
          address: opts.address
        });
        reportDisplayParam = address.replaceAll(',', '').replaceAll(' ', '-');
        break;
      default:
        reportDisplayParam = formatFullName({
          firstName: opts.name.first,
          lastName: opts.name.last,
          titleCase: true
        });
    }

    try {
      // when testing this endpoint you will have to run ifconfig in your terminal to get your vpn ip, when you have
      // the ip you need to run this project with your env BASE_URL with your IP ex: BASE_URL=http://10.69.42.24:8008
      // NOTE: this endpoint hits digital products, and then the service hits our endpoint that we have running
      // in middleware/generate-pdf
      const todayArray = new Date()
        .toLocaleDateString(undefined, {
          timeZone: 'UTC',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        .split('/');

      let fileSaveName;
      switch (opts.type) {
        case 'phone':
        case 'address':
          fileSaveName = `information_${reportDisplayParam}_${todayArray[0]}_${todayArray[1]}_${todayArray[2]}.pdf`;
          break;
        default:
          // People PDF Report
          const nameArray = reportDisplayParam.split(' ');
          fileSaveName = `information_${nameArray[0]}_${nameArray[1]}_${todayArray[0]}_${todayArray[1]}_${todayArray[2]}.pdf`;
      }
      const res = await opts.vm.$http.$get(
        `${
          window.location.origin
        }/api/digital-products/v1/reports/pdf/base64/account/${
          opts.vm.currentUser.id
        }/type/${opts.type || 'people'}/report/${
          opts.reportCode
        }?sex_offenders=true`
      );
      const blob = b64toBlob(res);
      FileSaver.saveAs(blob, fileSaveName);
    } catch (err) {
      console.error(err);
      opts.vm.$bugsnag?.notify(err);
      opts.vm.$parent.$emit('send-toast', {
        type: 'download-error',
        reportDisplayParam
      });
      throw err;
    }
  }
};

export default pdfHelper;

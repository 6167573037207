/**
 * @param {object} opts
 * @param {string} opts.regexType
 */

const getRegex = (opts = {}) => {
  const staticRegex = {
    lettersOnly: new RegExp('^[A-Za-z]+$'),
    lettersAndDashes: new RegExp('^[A-Za-z\\-]+$'),
    lettersDashesAndSpaces: new RegExp('^([a-zA-Z\\s\\-]+)$'),
    notLettersDashesPeriodsAndSpaces: new RegExp('[^a-zA-Z\\s\\-\\.]', 'g'),
    lettersDashesNumbersAndSpaces: new RegExp('^([a-zA-Z0-9\\s\\-]+)$'),
    notLettersDashesNumbersPoundsPeriodsAndSpaces: new RegExp(
      '[^a-zA-Z0-9#\\s\\-\\.]',
      'g'
    ),
    lettersDashesAndUnderscores: new RegExp('^[A-Za-z_\\-]+$'),
    emailRegex: /^\w+([+\.-]\w{1,64})*@\w+(\.\w{2,3})+$/,
    passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&(){}[\]|¬¦! "£$%^&*<>:;'#~_\-+=,@.`/\\])[A-Za-z\d@$!%*?&(){}[\]|¬¦! "£$%^&*<>:;'#~_\-+=,@.`/\\]{8,}$/,
    spaceRegEx: new RegExp(/ |\+/, 'g'),
    specialCharacters: new RegExp(/([.;,=!@#$%^&*()]+)/, 'g'),
    dashRegEx: new RegExp('-', 'g'),
    underscoreRegEx: new RegExp('_', 'g'),
    apostropheRegEx: new RegExp("'", 'g'),
    // matches phone format of 5555555555 or 555-555-5555, that's it
    phoneRegex: /^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/,
    zipRegex: /^[0-9]{5}$/,
    peopleInvalidRegex: /[\[\]/!@#$%^&*(),^.\.{3,}\u2026\\.\\.\\.?~`À-ÿ‘’"“”:;{}|<>_\\=+\d]/g,
    poBoxRegex: /\b(?:Po|P\.O|P\/O|Post\sOffice)\sBox\b/i
  };

  if (!opts.regexType || !staticRegex.hasOwnProperty(opts.regexType)) {
    return '';
  }

  return staticRegex[opts.regexType];
};

export default getRegex;

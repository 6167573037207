/**
 * Gets address, phone, email, and social counts for all people displayed on a page
 *
 * @param {object} opts
 * @param {array} opts.people
 * @param {obj} opts.defaultValue
 * @returns {obj}
 */
const getAllPagePeopleDataSums = (opts = {}) => {
  if (!opts || !opts.people || !opts.people.length) {
    return (
      opts?.defaultValue ?? {
        address: 0,
        phone: 0,
        email: 0,
        social: 0
      }
    );
  }
  let countsObj = {
    address: 0,
    phone: 0,
    email: 0,
    social: 0
  };
  for (let i = 0; i < opts.people.length; i++) {
    const person = opts.people[i];
    countsObj.address += person.addresses?.length ?? 0;
    countsObj.phone += person.has_phones ?? 0;
    countsObj.email += person.has_emails ?? 0;
    countsObj.social += person.has_social_media ?? 0;
  }
  return countsObj;
};

export default getAllPagePeopleDataSums;

/**
 * Encode TLO CID
 *
 * @param {object} opts
 * @param {string} opts.cid
 * @returns {string}
 */
const encodeCID = (opts = {}) => {
  if (!opts.cid) {
    return '';
  }

  let encodedString = '';
  let unicodeCharString;

  for (let i = 0; i < opts.cid.length; i++) {
    unicodeCharString = opts.cid.charCodeAt(i).toString(16);
    encodedString +=
      unicodeCharString.length < 2
        ? '0' + unicodeCharString
        : unicodeCharString;
  }

  return encodedString;
};

export default encodeCID;

/**
 * queryWithSearchSDK - a helper to query from the searchSDK without having to throw try/catch blocks all over
 * @param {object} opts
 * @param {object} opts.queryParams - the data required for the passed search method
 * @param {function} opts.searchMethod - a function pointer to a searchSDK method eg. LocalPeopleFull.byLocation
 * @param {any} [opts.defaultReturnValue] - the value the method should return if the query fails
 * @param {object} [$bugsnag] - an instance of the bugsnag error reporting service
 * @returns {Promise<*|*|*[]>}
 */
const queryWithSearchSDK = async (opts = {}, $bugsnag = {}) => {
  const defaultReturnValue =
    opts.defaultReturnValue || opts.defaultReturnValue === 0
      ? opts.defaultReturnValue
      : [];

  // If required params don't exist, return default
  if (!opts || !opts.searchMethod || !opts.queryParams) {
    return defaultReturnValue;
  }

  // If required params are wrong type, return default
  if (
    typeof opts.searchMethod !== 'function' ||
    typeof opts.queryParams !== 'object'
  ) {
    return defaultReturnValue;
  }
  const { searchMethod, queryParams } = opts;
  try {
    return await searchMethod(queryParams);
  } catch (err) {
    console.error(err);
    $bugsnag?.notify(err);
    return defaultReturnValue;
  }
};

export default queryWithSearchSDK;

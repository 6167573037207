import { mapGetters } from 'vuex';
export default {
  data: function() {
    return {
      userLocation: null
    };
  },
  computed: {
    ...mapGetters('location', ['city', 'state'])
  },
  async fetch() {
    if (
      !this.$store.getters['location/state'] ||
      !this.$store.getters['location/city']
    ) {
      try {
        // Fallback if cloudflare headers are not present
        const res = await fetch(this.$config.apiLocationUrl);
        const data = await res.json();
        data.fullState = data.fullstate;
        delete data.fullstate;
        this.$store.commit('location/setLocation', data);
        this.userLocation = data;
        if (
          this.prefillFields &&
          !this.prefillFields.state &&
          this.updatePrefillFields
        ) {
          this.updatePrefillFields({ state: data.state });
        }
      } catch (error) {
        // do nothing
      }
    }
  },
  fetchOnServer: true,
  fetchKey: 'user-location'
};

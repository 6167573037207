/**
 * Returns a string set in title case ex. 'abc def' becomes 'Abc Def'
 *  If no opts or opts.text, or if opts.text is empty string, return null
 *
 * @param {object} opts
 * @param {string|null|undefined} opts.text
 * @param {string} opts.defaultValue
 * @returns {string|null}
 */
const setTitleCase = (opts = {}) => {
  if (
    !opts ||
    !opts.text ||
    opts.text === '' ||
    typeof opts.text !== 'string'
  ) {
    return opts?.defaultValue ?? null;
  }

  return opts.text.toLowerCase().replace(/(?:^|\s|\/|\-)\w/g, function(match) {
    return match.toUpperCase();
  });
};

export default setTitleCase;

import otherLocationsCount from './otherLocationsCount';

/**
 *
 * @param {object} opts
 * @param {array} opts.locationList
 * @returns {boolean}
 */
const hasOtherLocations = function(opts = {}) {
  if (!opts || !opts.locationList || !opts.locationList.length) {
    return false;
  }

  const { locationList } = opts;

  return otherLocationsCount({ locationList }) > 0;
};

export default hasOtherLocations;

import getStates from './getStatesList';

/**
 * Return standard abbreviation from full state name
 *
 * @param {object} opts
 * @param {string} opts.state
 * @return {null|string}
 */
const getStateAbbrFromName = (opts = {}) => {
  if (!opts.state || typeof opts.state !== 'string') {
    return null;
  }

  if (opts.state.length === 2) {
    return opts.state.toUpperCase();
  }

  const states = getStates({
    hasTerritories: true
  });
  for (let key in states) {
    const stateKey = states[key].toString().toLowerCase();
    const stateKeyWithHyphen = stateKey?.replace(/ /g, '-');

    if (
      opts.state.toLowerCase() === stateKey ||
      opts.state.toLowerCase() === stateKeyWithHyphen
    ) {
      return key;
    }
  }
};

export default getStateAbbrFromName;

/**
 * @param {object} opts
 * @param {string} opts.itemName
 * @param opts.item
 */
const setSessionItem = (opts = {}) => {
  if (!opts.item || !opts.itemName) {
    return false;
  }
  sessionStorage.setItem(opts.itemName, JSON.stringify(opts.item));
};

export default setSessionItem;

/**
 * Returns the unformatted title based upon route name
 *
 * @param {object} opts
 * @param {string} opts.routeName
 * @param {object} opts.pageCMS
 * @param {object} opts.defaultValue
 * @returns {string}
 */
const getSEOMetaTitleText = function(opts = {}) {
  if (!opts || !opts.routeName || !opts.pageCMS) {
    return opts?.defaultValue ?? '';
  }

  const { routeName, pageCMS } = opts;

  switch (routeName) {
    case 'seo.city':
      return pageCMS.page_title_city;
    case 'seo.state':
      return pageCMS.page_title_state;
    case 'seo.address':
      return pageCMS.page_title_address;
    case 'seo.phone':
      return pageCMS.page_title_phone;
    default:
      return pageCMS.page_title_root;
  }
};

export default getSEOMetaTitleText;

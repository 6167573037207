/**
 * Returns url query string from a passed in object of queries keys/values
 * @param {object} opts
 * @param {object} opts.queryObj - the object of url query keys/values
 * @param {string} opts.default
 * @returns {string}
 */
const getUrlQueryString = opts => {
  if (!opts || !opts.queryObj) {
    return opts?.default ? opts.default : '';
  }
  const props = Object.keys(opts.queryObj);
  if (!props.length) {
    return '';
  }
  let str = `?`;
  for (const prop in opts.queryObj) {
    const isLast = prop === props[props.length - 1];
    const endPiece = isLast ? '' : '&';
    const value = opts.queryObj[prop] ?? '';
    str += `${prop}=${value}${endPiece}`;
  }
  return str;
};

export default getUrlQueryString;

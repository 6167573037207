import formatNameCaseWithSplitterAndSpacer from './formatNameCaseWithSplitterAndSpacer';

/**
 * Returns formatted street address only with optional affix
 *
 * @param {object} opts
 * @param {object} opts.address
 * @param {boolean} opts.skipAffix
 * @param {string} opts.defaultValue
 * @returns {string}
 */
const formatStreetOnly = (opts = {}) => {
  if (!opts || !opts.address) {
    return opts?.defaultValue ?? '';
  }
  if (opts.address.street && !opts.skipAffix) {
    return formatNameCaseWithSplitterAndSpacer({ name: opts.address.street });
  }
  let street = '';

  if (!opts.address.street_name && !opts.address.street_type) {
    return street;
  }

  if (opts.address.street_number) {
    street += opts.address.street_number;
  }

  if (opts.address.street_name) {
    street +=
      street !== '' ? ` ${opts.address.street_name}` : opts.address.street_name;
  }

  if (opts.address.street_type) {
    street +=
      street !== '' ? ` ${opts.address.street_type}` : opts.address.street_type;
  }

  if (opts.address.street_affix && !opts.skipAffix) {
    street +=
      street !== ''
        ? ` ${opts.address.street_affix}`
        : opts.address.street_affix;
  }

  return formatNameCaseWithSplitterAndSpacer({ name: street });
};

export default formatStreetOnly;

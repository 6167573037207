import setTitleCase from './setTitleCase';

/**
 * Return formatted city, state, and zipcode
 *
 * @param {object} opts
 * @param {object} opts.address
 * @param {string} opts.separator
 * @param {boolean|null} opts.spaceBeforeSeparator
 * @param {string} opts.defaultValue
 * @returns {string}
 */
const formatAddressWithoutStreet = (opts = {}) => {
  if (!opts || !opts.address) {
    return opts?.defaultValue ?? '';
  }
  const { state, zip } = opts.address;
  // Split the city name on any spacing
  let city =
    typeof opts.address === 'object' && opts.address.city
      ? opts.address.city.split(' ')
      : null;

  if (city) {
    // Ensure every part of the city name is title case
    for (let i = 0; i < city.length; i++) {
      city[i] = setTitleCase({ text: city[i] });
    }

    city = city.join(' ');
  }

  let formattedString = city ? city : '';
  if (state) {
    formattedString += city ? `, ${state.toUpperCase()}` : state.toUpperCase();
  }
  if (zip) {
    if (!state && !city) {
      formattedString += zip;
    } else {
      formattedString += state ? ` ${zip}` : `, ${zip}`;
    }
  }

  if (opts.separator) {
    formattedString += opts.spaceBeforeSeparator
      ? ` ${opts.separator}`
      : opts.separator;
  }

  // Return the title cased city name, state, and zip code
  return formattedString;
};

export default formatAddressWithoutStreet;

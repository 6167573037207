import formatFullName from '../helpers/formatFullName';
import setTitleCase from '../helpers/setTitleCase';
/**
 * Returns formatted full name for owner
 * @param {object} opts
 * @param {object} opts.data
 * @param {string} opts.default
 * @returns {string|null}
 */

const getFormattedNameOwner = opts => {
  if (!opts || !opts.data) {
    return opts?.default || 'Unknown';
  }
  if (opts.data.owner_firstname && opts.data.owner_lastname) {
    return (
      formatFullName({
        firstName: opts.data.owner_firstname,
        lastName: opts.data.owner_lastname,
        titleCase: true
      }) ||
      opts.default ||
      'Unknown'
    );
  } else if (opts.data.owners?.length) {
    return (
      formatFullName({
        firstName: opts.data.owners[0].firstname,
        lastName: opts.data.owners[0].lastname,
        titleCase: true
      }) ||
      setTitleCase({ text: opts.data.owners[0].business_name }) ||
      opts.default ||
      'Unknown'
    );
  } else {
    return opts.default || 'Unknown';
  }
};

export default getFormattedNameOwner;

/**
 * Return mobile media query event for standard mobile max (768px)
 * @param {object} opts
 * @param {number|null} opts.width
 * @returns {boolean|MediaQueryList}
 */
const getMobileQuery = (opts = {}) => {
  const width = opts.width || 768;

  // Ensure we do not error if method called before window object exists
  if (typeof window !== 'undefined') {
    return window.matchMedia(`(max-width: ${width}px)`);
  }

  return false;
};

export default getMobileQuery;

/**
 *
 * @param {object} opts
 * @param {MediaQueryList} opts.selector
 * @param {function} opts.handleMethod
 * @param {object} $bugsnag
 */
const mediaQueryListener = (opts = {}, $bugsnag = {}) => {
  try {
    // Chrome, Firefox, some Safari versions
    if (opts.selector.addEventListener !== undefined) {
      opts.selector.addEventListener('change', opts.handleMethod);
      return;
    }
    // Some Safari versions
    opts.selector.addListener(opts.handleMethod);
  } catch (err) {
    $bugsnag?.notify(err);
  }
};

export default mediaQueryListener;

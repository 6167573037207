import getStates from './getStatesList';

/**
 * Return full state name from standard abbreviation
 * e.g. FL -> Florida
 *
 * @param {object} opts
 * @param {string} opts.stateAbbr
 * @param {boolean} opts.includeTerritories
 * @param {string} opts.defaultValue
 */
const getStateFromAbbr = function(opts = {}) {
  if (!opts && !opts.stateAbbr) {
    return opts?.defaultValue ?? undefined;
  }
  const states = getStates({ hasTerritories: opts.includeTerritories });
  return states[opts.stateAbbr];
};

export default getStateFromAbbr;

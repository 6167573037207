import formatStringAsUsDate from '../helpers/formatStringAsUsDate';

/**
 * Returns formatted employment dates or unknown text
 *
 * @param {object} opts
 * @param {object} opts.employer
 * @param {string} opts.default
 * @returns {string}
 */
const getEmploymentDates = opts => {
  if (!opts || !opts.employer) {
    return opts.default || 'Unknown';
  }
  const start = opts.employer.first_reported || opts.employer.start_date;
  const end = opts.employer.last_reported || opts.employer.end_date;
  const startDate =
    start && start !== '1600-01-01'
      ? formatStringAsUsDate({ string: start })
      : null;
  const endDate =
    end && end !== '1600-01-01' ? formatStringAsUsDate({ string: end }) : null;

  if (!startDate && !endDate) {
    return opts.default || 'Unknown';
  }

  return `${startDate || opts.default} - ${endDate || 'Present'}`;
};

export default getEmploymentDates;

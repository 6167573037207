import hasExtraTaxesData from './hasExtraTaxesData';
/**
 * Returns Municipality code or unknown text
 *
 * @param {object} opts
 * @param {object} opts.asset
 * @param {object} opts.default
 * @return {string}
 */
const getMunicipalityCode = opts => {
  if (!opts || !opts.asset) {
    return opts?.default || 'Unknown';
  }
  if (opts.asset.municipality_code) {
    return opts.asset.municipality_code;
  }
  return hasExtraTaxesData({ asset: opts.asset }) &&
    opts.asset.extra.property.taxes[0].municipality_code
    ? opts.asset.extra.property.taxes[0].municipality_code
    : opts?.default || 'Unknown';
};

export default getMunicipalityCode;

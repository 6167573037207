import setTitleCase from '../helpers/setTitleCase';

/**
 * Gets zodiac based off of date of birth
 * @param {object} opts
 * @param {object} opts report
 * @param {*} opts defaultValue
 * @returns {string} dob
 */
const getZodiac = opts => {
  if (!opts || !opts.report) {
    return opts?.defaultValue || 'Unknown';
  }

  let dob = opts.report.dob;

  if (dob && dob !== '1600-01-01' && opts.report.zodiac) {
    return (
      setTitleCase({ text: opts.report.zodiac }) ||
      opts.defaultValue ||
      'Unknown'
    );
  }

  if (
    (!dob || dob === '1600-01-01') &&
    opts.report.dates_of_birth &&
    opts.report.dates_of_birth.length
  ) {
    for (let i = 0; i < opts.report.dates_of_birth.length; i++) {
      const dobObj = opts.report.dates_of_birth[i];
      if (dobObj.dob && dobObj.dob !== '1600-01-01' && dobObj.zodiac) {
        return setTitleCase({ text: dobObj.zodiac });
      }
    }
  }
  return opts?.defaultValue || 'Unknown';
};

export default getZodiac;

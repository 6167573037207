export const actions = {
  nuxtServerInit({ commit }, { req }) {
    if (process.server && req?.headers) {
      const h = req.headers;
      const state = h['cf-region-code'];
      if (state) {
        commit('location/setLocation', {
          state,
          fullState: h['cf-region'],
          city: h['cf-ipcity'],
          country: h['cf-ipcountry'],
          zip: h['cf-postal-code']
        });
      }
    }
  }
};

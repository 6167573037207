/**
 * Return formatted year from date string
 * @param {object} opts
 * @param {string} opts.date
 * @param {string} opts.default
 * @returns {string}
 */
const getFormattedDateYear = opts => {
  if (!opts || !opts.date || opts.date === '1600-01-01') {
    return opts?.default || 'Unknown';
  }

  return opts.date.slice(0, 4);
};

export default getFormattedDateYear;

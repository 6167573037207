import formatStringAsUsDate from '../helpers/formatStringAsUsDate';

/**
 * Returns formatted employment dates or unknown text
 *
 * @param {object} opts
 * @param {object} opts.objWithDates
 * @param {string} opts.default
 * @returns {string}
 */
const getDateRange = opts => {
  if (!opts || !opts.objWithDates) {
    return opts.default || 'Unknown';
  }
  const start =
    opts.objWithDates.first_reported ||
    opts.objWithDates.record_first_reported ||
    opts.objWithDates.start_date ||
    opts.objWithDates.first_seen_as_owner;
  const end =
    opts.objWithDates.last_reported ||
    opts.objWithDates.record_last_reported ||
    opts.objWithDates.end_date ||
    opts.objWithDates.last_seen_as_owner;
  const startDate =
    start && start !== '1600-01-01'
      ? formatStringAsUsDate({ string: start })
      : null;
  const endDate =
    end && end !== '1600-01-01' ? formatStringAsUsDate({ string: end }) : null;

  if (!startDate && !endDate) {
    return opts.default;
  }

  return `${startDate || opts.default} - ${endDate || 'Present'}`;
};

export default getDateRange;

<template>
  <form
    class="search-form search-form--address"
    @submit.prevent="searchSubmit($event)"
  >
    <h2 v-if="title" v-text="title" class="search-form__title" />
    <div class="form-message form-message--error" v-if="formErrorMsg.length">
      <TzMarkdown class="form-message__body" :content="formErrorMsg" />
    </div>
    <div class="search-form__group search-form__group--full">
      <label for="street" class="search-form__label" v-text="streetLabel" />
      <input
        type="text"
        class="search-form__input"
        name="street"
        maxlength="255"
        :placeholder="streetPlaceholder"
        aria-label="Street Name"
        v-model.trim="form.street"
        @input="validateStreet"
        :class="{
          'search-form__input--invalid': error.street,
          'search-form__input--valid': streetValid
        }"
      />
    </div>
    <div class="search-form__bunch">
      <div class="search-form__group">
        <label for="city" class="search-form__label" v-text="cityLabel" />
        <input
          type="text"
          class="search-form__input"
          name="city"
          maxlength="255"
          :placeholder="cityPlaceholder"
          aria-label="City"
          v-model.trim="form.city"
          @input="validateCity"
          :class="{
            'search-form__input--invalid': error.city,
            'search-form__input--valid': cityValid
          }"
        />
      </div>
      <div class="search-form__group">
        <label for="state" class="search-form__label" v-text="stateLabel" />
        <select
          class="search-form__input search-form__input--select"
          aria-label="State"
          name="state"
          required
          v-model.trim="form.state"
        >
          <option
            v-for="state in getStatesForDropdown({
              hasTerritories: true,
              useStateAbbrAsValue: true,
              includeAllStatesOption: true,
              allStatesDisplayText: statePlaceholder
            })"
            :value="state.value"
            :key="state.value"
            v-text="state.name"
          />
        </select>
      </div>
      <div class="search-form__group">
        <label for="zip" class="search-form__label" v-text="zipLabel" />
        <input
          type="number"
          class="search-form__input"
          name="zip"
          :placeholder="zipPlaceholder"
          aria-label="Zip Code"
          maxlength="5"
          v-model.trim="form.zip"
          @input="validateZip"
          :class="{
            'search-form__input--invalid': error.zip,
            'search-form__input--valid': zipValid
          }"
        />
      </div>
      <button
        v-if="!showCancelBtn"
        class="search-form__button"
        :disabled="isProcessing"
        aria-label="Search"
        v-text="submitBtnText"
      />
    </div>

    <div class="search-form__buttons" v-if="showCancelBtn">
      <button
        class="search-form__button"
        :disabled="isProcessing"
        aria-label="Search"
        v-text="submitBtnText"
      />
      <button
        class="search-form__button search-form__button--cancel"
        v-touch:tap.prevent="cancelSubmit"
        aria-label="Cancel"
        v-text="cancelBtnText"
      />
    </div>
  </form>
</template>

<script>
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import getStatesForDropdown from '@/assets/js/shared/helpers/getStatesForDropdown';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import removeInputAutoPeriod from '@/assets/js/shared/helpers/removeInputAutoPeriod';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import parseStreet from '@/assets/js/shared/helpers/parseStreet';
import formatStreetOnly from '@/assets/js/shared/helpers/formatStreetOnly';
import isValidStreetObj from '@/assets/js/shared/helpers/isValidStreetObj';

export default {
  name: 'wl-address-form',
  components: {
    TzMarkdown
  },
  props: {
    // title of form
    title: {
      type: String,
      default: null
    },
    // label for street input
    streetLabel: {
      type: String,
      default: 'Street Address'
    },
    // placeholder for street input
    streetPlaceholder: {
      type: String,
      default: 'Ex: 123 Main Street'
    },
    // label for city input
    cityLabel: {
      type: String,
      default: 'City'
    },
    // placeholder for city input
    cityPlaceholder: {
      type: String,
      default: 'Ex: Miami'
    },
    // label for state select
    stateLabel: {
      type: String,
      default: 'State'
    },
    // placeholder for state input
    statePlaceholder: {
      type: String,
      default: 'Select State'
    },
    // label for zip input
    zipLabel: {
      type: String,
      default: 'Zip'
    },
    // placeholder for zip input
    zipPlaceholder: {
      type: String,
      default: 'Ex: 12345'
    },
    submitBtnText: {
      type: String,
      default: 'Search'
    },
    formErrorMsg: {
      type: String,
      default: ''
    },
    cancelBtnText: {
      type: String,
      default: 'Cancel'
    },
    showCancelBtn: {
      type: Boolean,
      default: false
    },
    initialValues: {
      type: Object,
      default: () => {
        return {
          street: '',
          city: '',
          state: '',
          zip: ''
        };
      }
    }
  },
  data() {
    return {
      isProcessing: false,
      setTitleCase,
      getStatesForDropdown,
      error: {
        street: false,
        city: false,
        state: false,
        zip: false
      },
      form: {
        city: '',
        street: '',
        state: this.$store?.getters['location/city'] ?? '',
        zip: ''
      },
      streetValid: false,
      cityValid: false,
      zipValid: false
    };
  },
  watch: {
    initialValues: {
      handler(newValues) {
        this.form = { ...newValues }; // React to only the form value changes in the parent
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.form = this.initialValues;
    if (this.form.state.length) {
      return;
    }
    // TODO: keeping support for $location plugin on shared files until VueX is added to all projects
    if (!this.$store) {
      const location = await this.$location();
      if (!location || !location.state) {
        this.form.state = '';
      } else {
        this.form.state = location.state;
      }
    }
  },
  methods: {
    searchSubmit: async function(e) {
      e.preventDefault();
      if (!this.isProcessing) {
        this.isProcessing = true;
        this.form.street = this.form.street.trim();
        this.form.city = this.form.city.trim();
        /* Just for `dashboard.address` and `dashboard-reports-person-generate` pages
        that use the address search */
        if (
          this.$route.name === 'dashboard.address' ||
          this.$route.name === 'dashboard-reports-person-generate'
        ) {
          // New street validation logic for dashboard.address and dashboard-reports-person-generate
          const streetValidationRegex = /^\d+[a-zA-Z0-9\-]*\s+[a-zA-Z0-9\-]+(?:\s+[a-zA-Z]+)*(?:\s*#\s*\d+|(?:\s+[a-zA-Z]+\s*\d+)*)?$/i;
          const streetInputValue = e.target[0].value.trim();

          if (!streetValidationRegex.test(streetInputValue)) {
            this.isProcessing = false;
            this.error.street = true;
            return;
          }

          // If passed validation, continue with processing
          this.isProcessing = true;
          this.error.street = false;
        }
        // Old logic (applies to all other cases)
        const streetObj = parseStreet({
          street: this.form.street,
          defaultValue: null
        });
        const isValidStreetObj = this.isValidStreetObj(streetObj);

        let hasErrors = false;
        for (const key in this.error) {
          if (
            !this.form[key] ||
            !this.form[key].length ||
            (key === 'zip' && this.form[key].length != 5) ||
            (key === 'street' && !isValidStreetObj)
          ) {
            this.error[key] = true;
            hasErrors = true;
          } else {
            this.error[key] = false;
          }
        }
        if (hasErrors) {
          this.isProcessing = false; // Stop the process if there are errors
          return;
        }
        // Prepare address pieces for submission
        const addressPieces = {
          ...streetObj,
          city: this.form.city,
          state: this.form.state,
          zip: this.form.zip
        };
        await this.$emit('address-submit', addressPieces);
        this.isProcessing = false;
      }
    },
    cancelSubmit() {
      this.$emit('cancel-submit', this.form);
    },
    isValidStreetObj(streetObj) {
      if (!streetObj) {
        return false;
      }
      if (!isValidStreetObj({ streetObj })) {
        return false;
      }
      // extra check
      const formattedStreet = formatStreetOnly({ address: streetObj });
      if (!formattedStreet.length) {
        return false;
      }
      return true;
    },
    validateStreet(event) {
      this.error.street = false;
      const inputStreet = removeInputAutoPeriod({
        inputValue: event.target.value,
        inputData: event.data
      });
      const formattedStreetValue = inputStreet
        .replace(
          getRegex({
            regexType: 'notLettersDashesNumbersPoundsPeriodsAndSpaces'
          }),
          ''
        )
        .replace(/  +/g, ' ');
      this.form.street = formattedStreetValue;
      this.streetValid = this.form.street.trim().length > 0;
    },
    validateCity(event) {
      this.error.city = false;
      const inputCity = removeInputAutoPeriod({
        inputValue: event.target.value,
        inputData: event.data
      });
      const formattedCityValue = inputCity.replace(
        getRegex({ regexType: 'notLettersDashesPeriodsAndSpaces' }),
        ''
      );
      this.form.city = formattedCityValue;
      this.cityValid = this.form.city.trim().length > 0;
    },
    validateZip(event) {
      this.error.zip = false;
      const inputZip = removeInputAutoPeriod({
        inputValue: event.target.value,
        inputData: event.data
      });
      const formattedZipValue = inputZip.replace(/[^0-9]/g, '').substr(0, 5);
      this.form.zip = formattedZipValue;
      this.zipValid = this.form.zip.trim().length === 5;
    }
  }
};
</script>

/**
 * Get Full Language Spoken String From Abbreviation
 *
 * @param {object} opts
 * @param {string} opts.languageAbbr
 * @param {string} opts.defaultValue
 * @returns {string}
 */

const getLanguageSpoken = (opts = {}) => {
  if (!opts || !opts.languageAbbr) {
    return opts?.defaultValue || 'N/A';
  }

  const abbr = opts.languageAbbr.toLowerCase();

  // Language object based on ISO 639-1 codes
  const languages = {
    aa: 'Afar',
    ab: 'Abkhazian',
    af: 'Afrikaans',
    ak: 'Akan',
    am: 'Amharic',
    ar: 'Arabic',
    as: 'Assamese',
    av: 'Avaric',
    ay: 'Aymara',
    az: 'Azerbaijani',
    ba: 'Bashkir',
    be: 'Belarusian',
    bg: 'Bulgarian',
    bh: 'Bihari languages',
    bi: 'Bislama',
    bm: 'Bambara',
    bn: 'Bengali',
    bo: 'Tibetan',
    br: 'Breton',
    bs: 'Bosnian',
    ca: 'Catalan, Valencian',
    ce: 'Chechen',
    ch: 'Chamorro',
    co: 'Corsican',
    cr: 'Cree',
    cs: 'Czech',
    cu:
      'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    cv: 'Chuvash',
    cy: 'Welsh',
    da: 'Danish',
    de: 'German',
    dv: 'Divehi, Dhivehi, Maldivian',
    dz: 'Dzongkha',
    ee: 'Ewe',
    el: 'Greek, Modern (1453-)',
    en: 'English',
    eo: 'Esperanto',
    es: 'Spanish, Castilian',
    et: 'Estonian',
    eu: 'Basque',
    fa: 'Persian',
    ff: 'Fulah',
    fi: 'Finnish',
    fj: 'Fijian',
    fo: 'Faroese',
    fr: 'French',
    ga: 'Irish',
    gl: 'Galician',
    gn: 'Guarani',
    gu: 'Gujarati',
    gv: 'Manx',
    he: 'Hebrew',
    hi: 'Hindi',
    ho: 'Hiri Motu',
    hr: 'Croatian',
    ht: 'Haitian, Haitian Creole',
    hu: 'Hungarian',
    hy: 'Armenian',
    hz: 'Herero',
    id: 'Indonesian',
    ig: 'Igbo',
    is: 'Icelandic',
    it: 'Italian',
    iu: 'Inuktitut',
    ja: 'Japanese',
    jv: 'Javanese',
    ka: 'Georgian',
    kg: 'Kongo',
    ki: 'Kikuyu, Gikuyu',
    kj: 'Kuanyama, Kwanyama',
    kk: 'Kazakh',
    kl: 'Kalaallisut, Greenlandic',
    km: 'Central Khmer',
    kn: 'Kannada',
    ko: 'Korean',
    kr: 'Kanuri',
    ks: 'Kashmiri',
    ku: 'Kurdish',
    kv: 'Komi',
    kw: 'Cornish',
    ky: 'Kirghiz, Kyrgyz',
    la: 'Latin',
    lb: 'Luxembourgish, Letzeburgesch',
    lg: 'Ganda',
    li: 'Limburgan, Limburger, Limburgish',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Lithuanian',
    lu: 'Luba-Katanga',
    lv: 'Latvian',
    mg: 'Malagasy',
    mh: 'Marshallese',
    mi: 'Maori',
    mk: 'Macedonian',
    ml: 'Malayalam',
    mn: 'Mongolian',
    mr: 'Marathi',
    ms: 'Malay',
    mt: 'Maltese',
    nb: 'Norwegian Bokmål',
    nd: 'North Ndebele',
    ne: 'Nepali',
    ng: 'Ndonga',
    nl: 'Dutch, Flemish',
    nn: 'Norwegian Nynorsk',
    no: 'Norwegian',
    nr: 'South Ndebele',
    nv: 'Navajo, Navaho',
    ny: 'Chichewa, Chewa, Nyanja',
    oc: 'Occitan (post 1500)',
    oj: 'Ojibwa',
    om: 'Oromo',
    or: 'Oriya',
    os: 'Ossetian, Ossetic',
    pa: 'Punjabi, Panjabi',
    pi: 'Pali',
    pl: 'Polish',
    ps: 'Pashto, Pushto',
    pt: 'Portuguese',
    qu: 'Quechua',
    rm: 'Romansh',
    rn: 'Rundi',
    ro: 'Romanian, Moldavian, Moldovan',
    ru: 'Russian',
    rw: 'Kinyarwanda',
    se: 'Northern Sami',
    sg: 'Sango',
    si: 'Sinhala, Sinhalese',
    sk: 'Slovak',
    sl: 'Slovenian',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'Somali',
    sq: 'Albanian',
    sr: 'Serbian',
    sv: 'Swedish',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thai',
    ti: 'Tigrinya',
    tk: 'Turkmen',
    tl: 'Tagalog',
    tn: 'Tswana',
    to: 'Tonga (Tonga Islands)',
    tr: 'Turkish',
    ts: 'Tsonga',
    uk: 'Ukrainian',
    ur: 'Urdu',
    vi: 'Vietnamese',
    vo: 'Volapük',
    wa: 'Walloon',
    wo: 'Wolof',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    za: 'Zhuang, Chuang',
    zh: 'Chinese',
    zu: 'Zulu'
  };

  if (!languages.hasOwnProperty(abbr)) {
    return opts?.defaultValue || 'N/A';
  }

  return languages[abbr];
};

export default getLanguageSpoken;

/**
 * Formats string as date in DD/MM/YYYY format
 *
 * @param {object} opts
 * @param {string} opts.string
 * @param {string} opts.defaultValue
 * @returns {string|null}
 */
const formatStringAsUsDate = (opts = {}) => {
  if (!opts || !opts.string || opts.string === '1600-01-01') {
    return opts?.defaultValue ?? null;
  }

  const date = new Date(opts.string);

  return date.toLocaleDateString('en-us', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'UTC'
  });
};

export default formatStringAsUsDate;

import setTitleCase from './setTitleCase';
/**
 * Splits string on separator, filters out null values, title cases all and joins on spacer
 * Default: ex jOhn SMITH => John Smith
 *
 * @param {object} opts
 * @param {string} opts.name
 * @param {string} opts.defaultValue
 * @param {string|null} [opts.spacer]
 * @param {string|null} [opts.separator]
 * @returns {string}
 */
const formatNameCaseWithSplitterAndSpacer = (opts = {}) => {
  if (!opts || !opts.name || opts.name === '') {
    return opts?.defaultValue ?? '';
  }

  const spacer = opts.spacer ?? ' ';
  const separator = opts.separator ?? ' ';

  return opts.name
    .split(separator)
    .filter(x => x !== 'null')
    .map(function(item) {
      return setTitleCase({ text: item });
    })
    .join(spacer);
};

export default formatNameCaseWithSplitterAndSpacer;

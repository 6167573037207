<template>
  <div class="wl-card">
    <header
      v-for="item in renderIfReturnArray({ condition: title })"
      :key="item"
      class="wl-card__header"
    >
      <div class="wl-card__header-slot">
        <h2 class="wl-card__title" v-text="title" :id="titleId" />
        <slot name="subtext"></slot>
      </div>
      <slot name="header-cta"></slot>
    </header>
    <div
      v-for="role in renderIf({ condition: parentRole })"
      :key="role"
      :role="parentRole"
    >
      <div class="wl-card-items" :role="itemsRole">
        <slot></slot>
      </div>
    </div>
    <div
      v-for="noParentRole in renderIf({ condition: !parentRole })"
      :key="noParentRole"
      class="wl-card-items"
      :role="itemsRole"
    >
      <slot></slot>
    </div>
    <button
      v-for="item in renderIfReturnArray({
        condition: ctaTitle && ctaLink && isCtaButton
      })"
      :key="item"
      class="wl-card__cta-link"
      :class="ctaClass"
      v-touch:tap="handleClick"
    >
      <span class="wl-card__cta-link-label" v-text="ctaTitle" />
    </button>
    <a
      v-for="item in renderIfReturnArray({
        condition: ctaTitle && ctaLink && !isCtaButton
      })"
      :key="item"
      :target="ctaTarget"
      :href="ctaLink"
      :rel="ctaRel"
      class="wl-card__cta-link"
      :class="ctaClass"
    >
      <span class="wl-card__cta-link-label" v-text="ctaTitle" />
    </a>
  </div>
</template>

<script>
import renderIfReturnArray from '@/assets/js/shared/helpers/renderIfReturnArray';
import renderIf from '@/assets/js/shared/helpers/renderIf';

export default {
  name: 'wl-card',
  methods: {
    handleClick: function() {
      if (this.ctaLink && this.ctaTarget !== '_self') {
        window.open(this.ctaLink);
      } else if (this.ctaLink) {
        window.location.href = this.ctaLink;
      }

      if (this.handlePostClick) {
        this.handlePostClick();
      }
    }
  },
  data() {
    return {
      renderIfReturnArray,
      renderIf
    };
  },
  props: {
    // If you want a title for the card
    title: {
      type: String,
      require: false,
      default: null
    },
    // Optional Title ID
    titleId: {
      type: String,
      require: false,
      default: ''
    },
    // Title of the cta
    ctaTitle: {
      type: String,
      require: false,
      default: null
    },
    // Url for the cta
    ctaLink: {
      type: String,
      require: false,
      default: null
    },
    // Specify the target of the url
    ctaTarget: {
      type: String,
      require: false,
      default: '_blank'
    },
    // Specify the rel of the url
    ctaRel: {
      type: String,
      require: false,
      default: 'noopener nofollow'
    },
    // If you want to add any custom modifier classes to the cta link
    ctaClass: {
      type: String,
      require: false,
      default: null
    },
    // Make link a button
    isCtaButton: {
      type: Boolean,
      default: false
    },
    handlePostClick: {
      type: Function,
      required: false
    },
    // Role To Apply To Parent Element
    parentRole: {
      type: String,
      require: false,
      default: null
    },
    // Role To Include Around Items Slot
    itemsRole: {
      type: String,
      require: false,
      default: null
    }
  }
};
</script>

const loggedInCookie = 'trav:ls=1';

export default ({ $bugsnag, app, $travellingSDK }, inject) => {
  const { UserCurrent, Auth } = $travellingSDK;
  const authHelper = {
    isUserLoggedInSync() {
      // Check client exposed cookie for logged in status
      return document.cookie.includes(loggedInCookie);
    },
    /**
     * Checks if a user is logged in
     * @returns {Promise<boolean>}
     */
    async isUserLoggedInAsync() {
      try {
        if (!this.isUserLoggedInSync()) {
          return false;
        }
        //checking user's locked property is the fastest way to check auth status
        const userLocked = await UserCurrent.getProperty('locked');
        if (userLocked === true) {
          await this.logoutUser();
          return false;
        }
        if (userLocked === 'Access Denied') {
          throw new Error(userLocked);
        }
        //if userLocked is false, then the user is logged in and not locked, good to go
        return true;
      } catch (err) {
        $bugsnag?.notify(err);
        return false;
      }
    },
    /**
     * logs out user
     * @returns {Promise<null>}
     */
    async logoutUser(redirectUrl = '') {
      try {
        await Auth.logout();
        if (redirectUrl) {
          location.href = redirectUrl;
        }
      } catch (err) {
        console.error(err);
        $bugsnag?.notify(err);
      }
    },
    /**
     * gets current logged in user's data from local storage, checks validity, if it doesn't exist or not valid,
     * hits endpoint for data and sets it in local storage
     * @returns {Promise<{currentUser}|null>}
     */
    async getOrSetCurrentUser() {
      const currentUserStored = localStorage.getItem('currentUser');
      if (
        currentUserStored === 'Access Denied' ||
        currentUserStored === 'null'
      ) {
        localStorage.removeItem('currentUser');
        return currentUserStored;
      }

      if (!currentUserStored) {
        return await this.getAndSetUserData();
      }

      const currentUser = JSON.parse(currentUserStored);
      let userLastUpdate;
      try {
        userLastUpdate = await UserCurrent.getProperty('updated_on');
      } catch (err) {
        $bugsnag?.notify(err);
        if (err.status === 401) {
          return await this.getAndSetUserData();
        }
      }

      if (currentUser.updated_on !== userLastUpdate) {
        return await this.getAndSetUserData();
      }

      return currentUser;
    },
    /**
     * gets current logged in user's data from endpoint and stores in local storage
     * @returns {Promise<{obj}|null>}
     */
    async getAndSetUserData() {
      const currentUser = await this.getCurrentUser();
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      return currentUser;
    },
    /**
     * gets current logged in user's data
     * @returns {Promise<{currentUser}|null>}
     */
    async getCurrentUser() {
      try {
        return await UserCurrent.get();
      } catch (err) {
        $bugsnag?.notify(err);
        return null;
      }
    },
    /**
     * gets current user from local storage, NOTE THIS METHOD SHOULD ONLY BE USED IN PAGES/COMPONENTS
     * the local storage will always be set from the parent layout/dashboard.vue
     * @returns {object}
     */
    getStoredCurrentUser() {
      return JSON.parse(localStorage.getItem('currentUser'));
    },
    /**
     * checks if a user has permission for passed in feature
     * @param {object} opts
     * @param {string} opts.feature feature to check permission for, options: full-reports
     * @param {string} opts.userId
     * @returns {Promise<{boolean}>}
     */
    async checkUserPermission(opts = {}) {
      let routeToCheck = null;
      switch (opts.feature) {
        case 'full-reports':
          routeToCheck = `/api/digital-products/v1/reports/account/${opts.userId}/person/1/generate/json`;
          break;
        case 'pdf-generation':
          routeToCheck = `/permissions/product/pdf_generation`;
          break;
        case 'report-monitoring':
          routeToCheck = `/permissions/product/report_monitoring`;
          break;
      }
      if (!routeToCheck) {
        return false;
      }
      let res = false;
      try {
        res = await UserCurrent.routeCheck('GET', routeToCheck);
      } catch (err) {
        console.error(err);
        $bugsnag?.notify(err);
      }
      return res;
    },
    /**
     * checks if a user has multiple permissions in one request
     * @param {object} opts
     * @param {string} opts.userId
     */
    async checkUserPermissions(opts = {}) {
      if (!opts || !opts.userId) {
        return {};
      }
      let routes = [
        {
          feature: 'peopleReport',
          method: 'GET',
          route: `/permissions/product/people_reports`,
          allowed: false
        },
        {
          feature: 'emailReport',
          method: 'GET',
          route: `/permissions/product/email_reports`,
          allowed: false
        },
        {
          feature: 'addressReport',
          method: 'GET',
          route: `/permissions/product/address_reports`,
          allowed: false
        },
        {
          feature: 'phoneReport',
          method: 'GET',
          route: `/permissions/product/phone_reports`,
          allowed: false
        },
        {
          feature: 'sexOffenderReport',
          method: 'GET',
          route: `/permissions/product/sexoffender_reports`,
          allowed: false
        },
        {
          feature: 'generatePdf',
          method: 'GET',
          route: `/permissions/product/pdf_generation`,
          allowed: false
        },
        {
          feature: 'reportMonitoring',
          method: 'GET',
          route: `/permissions/product/report_monitoring`,
          allowed: false
        },
        {
          feature: 'sexOffenderReport',
          method: 'GET',
          route: `/permissions/product/sexoffender_reports`,
          allowed: false
        }
      ];
      const routesForEndpoint = [];
      for (let i = 0; i < routes.length; i++) {
        const { method, route } = routes[i];
        routesForEndpoint.push({ method, route });
      }

      let checkedRoutes = [];
      try {
        checkedRoutes = await UserCurrent.routesCheck(routesForEndpoint);
      } catch (err) {
        console.error(err);
        $bugsnag?.notify(err);
      }
      let permissions = {};
      for (let i = 0; i < routes.length; i++) {
        const route = routes[i];
        if (!checkedRoutes.length) {
          permissions[route.feature] = false;
          continue;
        }
        for (let j = 0; j < checkedRoutes.length; j++) {
          const checkedRoute = checkedRoutes[j];
          if (checkedRoute.route === route.route) {
            permissions[route.feature] = checkedRoute.allowed;
          }
        }
      }
      return permissions;
    }
  };
  inject('authHelper', authHelper);
};

import setTitleCase from './setTitleCase';
/**
 * @param {object} opts
 * @param {string} opts.word
 * @param {string} opts.parseBy
 * @param opts.splitBy
 * @return {string}
 */

const parseParams = (opts = {}) => {
  if (!opts || !opts.word) {
    return '';
  }
  const word = setTitleCase({
    text: opts.word
  });

  if (!opts.parseBy || !opts.splitBy) {
    return word;
  }

  const { parseBy, splitBy } = opts;
  let splitChar = null;

  splitBy.some(character => {
    if (!splitChar && word.includes(character)) {
      splitChar = character;
    }
  });

  if (!splitChar) {
    return word;
  }

  let parsed = word.split(splitChar);
  parsed.forEach((value, index) => {
    parsed[index] = setTitleCase({
      text: value
    });
  });

  return parsed.join(parseBy);
};

export default parseParams;

/**
 * Returns a formatted string from an object containing area, office, and line key values
 * Returns null if phone is missing area, office, or line
 *
 * @param {object} opts
 * @param {string} opts.defaultValue
 * @returns {string|null}
 */
const formatPhoneFromObject = (opts = {}) => {
  if (!opts || !opts.area || !opts.office || !opts.line) {
    return opts?.defaultValue ?? null;
  }

  return `(${opts.area}) ${opts.office}-${opts.line}`;
};

export default formatPhoneFromObject;

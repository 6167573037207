/**
 * Return the total amount to display - total possible or length of array, whichever is smaller
 *
 * @param {object} opts
 * @param {array} opts.array
 * @param {number} opts.totalPossible
 * @returns {int}
 */
const calculateDisplayMax = (opts = {}) => {
  const { array, totalPossible = 0 } = opts;
  if (!opts || !opts.array) {
    return totalPossible;
  }
  return array.length < totalPossible ? array.length : totalPossible;
};

export default calculateDisplayMax;

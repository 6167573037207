export class Meta {
  constructor() {}
  static convertToNuxtMeta(cmsMeta) {
    if (!cmsMeta) {
      return [];
    }
    var nuxtMeta = [];

    for (var i = 0; i < cmsMeta.length; i++) {
      var nMeta = {
        hid: cmsMeta[i].hid
      };
      if (cmsMeta[i].name) {
        nMeta['name'] = cmsMeta[i].name;
      } else if (cmsMeta[i].property) {
        nMeta['property'] = cmsMeta[i].property;
      } else if (cmsMeta[i].charset) {
        nMeta['charset'] = cmsMeta[i].charset;
      }

      if (cmsMeta[i].content) {
        nMeta.content = cmsMeta[i].content;
      }
      nuxtMeta.push(nMeta);
    }
    return nuxtMeta;
  }
}
export default Meta;

/**
 * Returns true/false if viewing on a mobile device using the user-agent
 * @param userAgent
 * @returns {boolean}
 */
const isMobile = ({ userAgent = navigator.userAgent } = {}) => {
  return /Android|webOS|iPhone|iPod|IEMobile|Opera Mini/i.test(userAgent);
};

export default isMobile;

/**
 * @param {object} opts
 * @param {string} opts.itemName
 */
const getSessionItem = (opts = {}) => {
  if (!opts.itemName) {
    return {};
  }
  const itemValue = sessionStorage.getItem(opts.itemName);
  return itemValue ? JSON.parse(itemValue) : {};
};

export default getSessionItem;

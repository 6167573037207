<template>
  <section class="ic-error">
    <div class="ic-error__inner">
      <div class="ic-error__description">
        <h1 v-text="`You have found a page that does not exist`" />
        <a
          href="/"
          class="ic-error__link"
          title="People Search"
          v-text="`Click here to return to the homepage.`"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Meta from '@/assets/js/shared/misc/meta.mjs';
const cms = require(`@/assets/cms/pages/error.json`);
export default {
  mounted() {
    this.$bugsnag.notify(new Error('404 Error'));
  },
  head() {
    return {
      title: cms.page_title ?? '404 Error',
      meta: cms.meta ? Meta.convertToNuxtMeta(cms.meta) : []
    };
  }
};
</script>

<template>
  <div class="ic-modal-body ic-modal-body--no-search">
    <h2 class="ic-modal-body__title">
      {{ isProcessing ? 'Processing...' : title }}
    </h2>
    <div v-if="isProcessing" class="ic-report-modal__loader">
      <TzSpinner
        class="ic-report-modal__spinner"
        :is-check="false"
        spinner-size="140"
        :spinner-animation="true"
        spinner-color="#4476ff"
        spinner-stroke="2"
        :spinner-delay="1000"
        :is-starting-spin="true"
      />
      <img class="ic-report-modal__icon" :src="InfoLogo" />
    </div>
    <div v-else>
      <p class="ic-modal-body__sub-title">
        {{ subtitle }}
      </p>
      <p class="ic-modal-body__bold">
        {{ hideRetryBtn ? updateStatement : retryStatement }}
      </p>
      <p>
        You may also contact customer support at
        <a href="mailto:support@information.com">support@information.com</a> or
        call <a href="tel:1-800-915-0696">1-800-915-0696</a>. You may reference
        error code 223.
      </p>
      <div
        class="ic-modal-body__form-buttons ic-modal-body__form-buttons--no-search"
      >
        <button
          class="ic-modal-body__form-button ic-modal-body__form-button--first"
          v-if="!hideRetryBtn"
          v-touch:tap="chargeByInventoryRetryRebill"
        >
          {{ retryBtnText }}
        </button>
        <button
          class="ic-modal-body__form-button "
          :class="{
            'ic-modal-body__form-button--update': !hideRetryBtn,
            'ic-modal-body__form-button--first': hideRetryBtn
          }"
          v-touch:tap="handleUpdateClick"
        >
          {{ updateBtnText }}
        </button>
        <button
          class="ic-modal-body__form-button ic-modal-body__form-button--cancel"
          v-touch:tap="closeModal"
        >
          {{ cancelBtnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import getUsersInitialAid from '@/assets/js/shared/helpers/getUsersInitialAid';
import billingHelper from '@/assets/js/shared/billingHelper';
import TzSpinner from '@trazi/tz-spinner/src/tz-spinner.vue';
import InfoLogo from '@/assets/images/information-logo.svg';

export default {
  name: 'ic-no-search-access-modal',
  components: {
    TzSpinner
  },
  data() {
    return {
      userState: this.$store.getters['location/state'],
      userCity: this.$store.getters['location/city'],
      userCountry: this.$store.getters['location/country'],
      isProcessing: false,
      InfoLogo
    };
  },
  methods: {
    /**
     * handle update button click
     * @param type
     * @returns {*}
     */
    async handleUpdateClick() {
      this.closeModal();
      // save subscription id and type
      sessionStorage.setItem(
        'retry',
        JSON.stringify({
          type: this.subscriptionType,
          id: this.inventoryId,
          redirectPath: this.redirectPath
        })
      );
      // send to account page to update their default payment and retry rebill
      return this.$router.push({
        name: 'dashboard-account',
        query: { update: true }
      });
    },
    async chargeByInventoryRetryRebill() {
      this.isProcessing = true;
      const aid = getUsersInitialAid({ window, user: this.user });
      const lineItems = [
        {
          id: this.inventoryId,
          qty: 1,
          type: 'inventory'
        }
      ];
      const body = {
        currency_code: 'USD',
        line_items: lineItems,
        data: {
          city: this.userCity,
          state: this.userState,
          country: this.userCountry,
          aid,
          extra_tracking: {}
        }
      };
      const res = await billingHelper.chargeByInventoryRetryRebill({
        body,
        domain: this.domain
      });
      setTimeout(() => {
        this.isProcessing = false;
        if (!res) {
          return this.$emit('failed-charge-by-inventory');
        }
        this.closeModal();
        this.$emit('successful-charge-by-inventory');
      }, 4000);
    },
    /**
     * Closes modal
     */
    closeModal() {
      this.$emit('ic-account-modal-close');
    }
  },
  props: {
    title: {
      type: String,
      default: 'Payment Error'
    },
    subtitle: {
      type: String,
      default:
        'You already have this subscription in your account, but there was an error with your past default payment method on file.'
    },
    updateStatement: {
      type: String,
      default: 'Please update the current payment method on file.'
    },
    retryStatement: {
      type: String,
      default: 'Please retry or update the current payment method on file.'
    },
    showSupportMsg: {
      type: Boolean,
      default: true
    },
    retryBtnText: {
      type: String,
      default: 'Retry'
    },
    updateBtnText: {
      type: String,
      default: 'Update'
    },
    cancelBtnText: {
      type: String,
      default: 'Cancel'
    },
    hideRetryBtn: {
      type: Boolean,
      default: false
    },
    subscriptionType: {
      type: String,
      default: 'default'
    },
    inventoryId: {
      type: String
    },
    user: {
      type: Object,
      default: null
    },
    domain: {
      type: String,
      default: null
    },
    redirectPath: {
      type: String,
      default: null
    }
  }
};
</script>

import roads from '../utils/roads.js';
const affixMatches = ['APT', '#', 'APARTMENT'];

/**
 * Returns Street Obj from Street String
 *
 * @param {object} opts
 * @param {string} opts.street
 * @param {*} opts.defaultValue
 * @returns {obj|*}
 */
const parseStreet = opts => {
  let newStreet = {
    street_physical: false,
    street_name: '',
    street_number: '',
    street_type: '',
    street_affix: ''
  };

  if (!opts || !opts.street) {
    return opts?.defaultValue ?? newStreet;
  }

  let fullStreet = opts.street.toUpperCase().replace(/[\,\.]/g, '');
  const poundIndex = fullStreet.indexOf('#');
  if (poundIndex > -1 && fullStreet.charAt(poundIndex + 1) === ' ') {
    fullStreet = fullStreet.replace('# ', '#');
  }

  const splitStreet = fullStreet.split(' ');

  // Extract street number (e.g., "9-3" or "10512")
  if (/^\d+(-\d+)?[A-Z]?$/.test(splitStreet[0])) {
    newStreet.street_number = splitStreet.shift();
    newStreet.street_physical = true;
  }

  // Process the rest of the parts
  for (let i = 0; i < splitStreet.length; i++) {
    const part = splitStreet[i];

    // Check if part is an affix (e.g., APT, #)
    if (affixMatches.includes(part)) {
      newStreet.street_affix = part;
      // Check if the next part is a number and append it to the affix
      if (i + 1 < splitStreet.length && /^\d+$/.test(splitStreet[i + 1])) {
        newStreet.street_affix += ` ${splitStreet[++i]}`;
      }
    }
    // Check if part is a recognized street type
    else if (roads[part]) {
      newStreet.street_type = roads[part].full;
    }
    // Otherwise, treat as part of the street name
    else {
      newStreet.street_name += (newStreet.street_name ? ' ' : '') + part;
    }
  }

  return newStreet;
};

export default parseStreet;

/**
 * Return true or false if value is roman numeral
 *
 * @param {object} opts
 * @param {string} opts.value
 * @returns {Boolean}
 */
const getCheckRomanNumeral = (opts = {}) => {
  if (!opts || !opts.value) {
    return false;
  }

  const check = /^(M{1,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})|M{0,4}(CM|C?D|D?C{1,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})|M{0,4}(CM|CD|D?C{0,3})(XC|X?L|L?X{1,3})(IX|IV|V?I{0,3})|M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|I?V|V?I{1,3}))$/;
  return check.test(opts.value.toUpperCase());
};

export default getCheckRomanNumeral;

/**
 * setApiLimit - a function to limit the query results to a specific amount
 * @param {Object} opts
 * @param {Object} opts.currentPayload - an existing query object
 * @param {number} opts.limit - a number that you want the limit to be eg. 250 most of the time
 * @param {number} [opts.page] - the page the user is currently on in the search results
 * @param {string} opts.type - tells method what logic to use
 * @returns {number|*|number} - returns the api limit that was calculated
 */
const setApiLimit = (opts = {}) => {
  if (
    !opts.type ||
    !opts.currentPayload ||
    typeof opts.currentPayload !== 'object' ||
    typeof opts.limit !== 'number'
  ) {
    return 0;
  }
  const { currentPayload, limit, type } = opts;
  const page = opts.page && typeof opts.page === 'number' ? opts.page : 1;
  switch (type) {
    case 'results':
      return currentPayload.totalPeople >= limit
        ? currentPayload.itemsPerPage * page
        : limit;
    case 'peopleItems':
      return currentPayload.totalPeople >= limit
        ? limit
        : currentPayload.peopleResponse.length;
    default:
      return 0;
  }
};

export default setApiLimit;

<template>
  <div
    class="ic-account-modal"
    v-if="isModalOpen"
    v-touch:tap.self="closeModal"
  >
    <tz-modal
      class="ic-account-modal__inner"
      :open="isModalOpen"
      @tz-modal-close="closeModal"
    >
      <slot></slot>
    </tz-modal>
  </div>
</template>

<script>
import TzModal from '@trazi/tz-modal/src/tz-modal.vue';

export default {
  name: 'ic-account-modal',
  components: { TzModal },
  props: {
    isModalOpen: {
      type: Boolean
    }
  },
  methods: {
    closeModal() {
      this.$emit('ic-account-modal-close');
    }
  }
};
</script>

import calculateDisplayMax from './calculateDisplayMax';
import getRandomItems from './getRandomItems';
import maskPhoneNumber from './maskPhoneNumber';
import getSeparatorSymbol from './getSeparatorSymbol';
/**
 * Return array of phone numbers as single formatted string
 *
 * @param {object} opts
 * @param {array} opts.phoneList
 * @param {number} opts.displayCount
 * @param {string} [opts.maskingSymbol]
 * @param {string} opts.generalSeparatorSymbol
 * @param {number} opts.exposedDigits
 * @param {boolean} opts.isRandom
 * @returns {string|null}
 */
const formatPhoneNumbersAsString = (opts = {}) => {
  // Early exit if no phone numbers
  if (!opts || !opts.phoneList) {
    return null;
  }
  const {
    phoneList,
    displayCount,
    maskingSymbol,
    exposedDigits,
    generalSeparatorSymbol,
    isRandom
  } = opts;
  let list = [];

  // Check if object and map to flat array
  if (typeof phoneList[0] === 'object' && phoneList[0].full) {
    list = phoneList.map(({ full }) => full);
  }

  // Calculate total possible to display
  const totalPossible = calculateDisplayMax({
    array: list,
    totalPossible: displayCount
  });

  let formattedNumbers = '';
  let items = list;
  if (isRandom) {
    items = getRandomItems({
      stuff: list,
      limit: totalPossible
    });
  }

  // Go through each phone number we can display
  for (let i = 0; i < totalPossible; i++) {
    const isLast = i === totalPossible - 1;
    // Format number with masking if required
    formattedNumbers += maskPhoneNumber({
      phone: items[i],
      separator: getSeparatorSymbol({
        isLast: isLast,
        generalSymbol: generalSeparatorSymbol
      }),
      maskingSymbol: maskingSymbol,
      exposedDigits: exposedDigits
    });
  }

  return formattedNumbers;
};

export default formatPhoneNumbersAsString;

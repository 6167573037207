import getStateFromAbbr from './getStateFromAbbr';
/**
 * @param {object} opts
 * @param {array} opts.people
 * @param {number} opts.amount
 * @returns {array|null}
 */
const getRandomRelatives = (opts = {}) => {
  if (!opts.people) return null;

  const { people, amount = 6 } = opts;

  const relatives = [];
  // Go through each person in array
  people.forEach(person => {
    // Early exit if no relative
    if (!(person.relatives && person.relatives.length > 0)) {
      return;
    }

    // Go through each relative
    for (let i = 0; i < person.relatives.length; i++) {
      const relative = person.relatives[i];
      // If the relative is in a standard US State and has first and last name, push them to the relatives array
      if (
        relative.firstname &&
        relative.lastname &&
        getStateFromAbbr({
          stateAbbr: relative.state
        })
      ) {
        relatives.push(relative);
      }
    }
  });

  // If there are no relatives in the US, return nothing
  if (relatives.length === 0) {
    return null;
  }

  // Otherwise, randomly pull the indicated amount of relatives in an array
  return relatives.sort(() => 0.5 - Math.random()).slice(0, amount);
};

export default getRandomRelatives;

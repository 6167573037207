import formatUnderScoreToSpace from './formatUnderScoreToSpace';
import formatNameCaseWithSplitterAndSpacer from './formatNameCaseWithSplitterAndSpacer';

/**
 * Returns the string with underscores replace by spaces and a hyphen in place of mid word spaces
 * Title case is optional
 *   ex. BettyJo => Betty-Jo
 * @param {object} opts
 * @param {string} opts.name
 * @param {boolean} [opts.titleCase]
 * @returns {string}
 */
const formatFirstName = (opts = {}) => {
  if (!opts.name || opts.name === '') {
    return '';
  }

  const regex = /\B[A-Z]\B/g;
  let name = formatUnderScoreToSpace({ string: opts.name });

  if (opts.titleCase) {
    name = formatNameCaseWithSplitterAndSpacer({ name });
  }

  return name.replace(regex, '-$&');
};

export default formatFirstName;

const roads = {
  ALLEY: {
    full: 'ALLEY',
    abbr: 'ALY'
  },
  ALLEE: {
    full: 'ALLEY',
    abbr: 'ALY'
  },
  ALLY: {
    full: 'ALLEY',
    abbr: 'ALY'
  },
  ALY: {
    full: 'ALLEY',
    abbr: 'ALY'
  },
  ANEX: {
    full: 'ANEX',
    abbr: 'ANX'
  },
  ANNEX: {
    full: 'ANEX',
    abbr: 'ANX'
  },
  ANNX: {
    full: 'ANEX',
    abbr: 'ANX'
  },
  ANX: {
    full: 'ANEX',
    abbr: 'ANX'
  },
  ARCADE: {
    full: 'ARCADE',
    abbr: 'ARC'
  },
  ARC: {
    full: 'ARCADE',
    abbr: 'ARC'
  },
  AVENUE: {
    full: 'AVENUE',
    abbr: 'AVE'
  },
  AV: {
    full: 'AVENUE',
    abbr: 'AVE'
  },
  AVE: {
    full: 'AVENUE',
    abbr: 'AVE'
  },
  AVEN: {
    full: 'AVENUE',
    abbr: 'AVE'
  },
  AVENU: {
    full: 'AVENUE',
    abbr: 'AVE'
  },
  AVN: {
    full: 'AVENUE',
    abbr: 'AVE'
  },
  AVNUE: {
    full: 'AVENUE',
    abbr: 'AVE'
  },
  BAYOU: {
    full: 'BAYOU',
    abbr: 'BYU'
  },
  BAYOO: {
    full: 'BAYOU',
    abbr: 'BYU'
  },
  BYU: {
    full: 'BAYOU',
    abbr: 'BYU'
  },
  BEACH: {
    full: 'BEACH',
    abbr: 'BCH'
  },
  BCH: {
    full: 'BEACH',
    abbr: 'BCH'
  },
  BEND: {
    full: 'BEND',
    abbr: 'BND'
  },
  BND: {
    full: 'BEND',
    abbr: 'BND'
  },
  BLUFF: {
    full: 'BLUFF',
    abbr: 'BLF'
  },
  BLF: {
    full: 'BLUFF',
    abbr: 'BLF'
  },
  BLUF: {
    full: 'BLUFF',
    abbr: 'BLF'
  },
  BLUFFS: {
    full: 'BLUFFS',
    abbr: 'BLFS'
  },
  BLFS: {
    full: 'BLUFFS',
    abbr: 'BLFS'
  },
  BOTTOM: {
    full: 'BOTTOM',
    abbr: 'BTM'
  },
  BOT: {
    full: 'BOTTOM',
    abbr: 'BTM'
  },
  BTM: {
    full: 'BOTTOM',
    abbr: 'BTM'
  },
  BOTTM: {
    full: 'BOTTOM',
    abbr: 'BTM'
  },
  BOULEVARD: {
    full: 'BOULEVARD',
    abbr: 'BLVD'
  },
  BLVD: {
    full: 'BOULEVARD',
    abbr: 'BLVD'
  },
  BOUL: {
    full: 'BOULEVARD',
    abbr: 'BLVD'
  },
  BOULV: {
    full: 'BOULEVARD',
    abbr: 'BLVD'
  },
  BRANCH: {
    full: 'BRANCH',
    abbr: 'BR'
  },
  BR: {
    full: 'BRANCH',
    abbr: 'BR'
  },
  BRNCH: {
    full: 'BRANCH',
    abbr: 'BR'
  },
  BRIDGE: {
    full: 'BRIDGE',
    abbr: 'BRG'
  },
  BRDGE: {
    full: 'BRIDGE',
    abbr: 'BRG'
  },
  BRG: {
    full: 'BRIDGE',
    abbr: 'BRG'
  },
  BROOK: {
    full: 'BROOK',
    abbr: 'BRK'
  },
  BRK: {
    full: 'BROOK',
    abbr: 'BRK'
  },
  BROOKS: {
    full: 'BROOKS',
    abbr: 'BRKS'
  },
  BRKS: {
    full: 'BROOKS',
    abbr: 'BRKS'
  },
  BURG: {
    full: 'BURG',
    abbr: 'BG'
  },
  BG: {
    full: 'BURG',
    abbr: 'BG'
  },
  BURGS: {
    full: 'BURGS',
    abbr: 'BGS'
  },
  BGS: {
    full: 'BURGS',
    abbr: 'BGS'
  },
  BYPASS: {
    full: 'BYPASS',
    abbr: 'BYP'
  },
  BYP: {
    full: 'BYPASS',
    abbr: 'BYP'
  },
  BYPA: {
    full: 'BYPASS',
    abbr: 'BYP'
  },
  BYPAS: {
    full: 'BYPASS',
    abbr: 'BYP'
  },
  BYPS: {
    full: 'BYPASS',
    abbr: 'BYP'
  },
  CAMP: {
    full: 'CAMP',
    abbr: 'CP'
  },
  CP: {
    full: 'CAMP',
    abbr: 'CP'
  },
  CMP: {
    full: 'CAMP',
    abbr: 'CP'
  },
  CANYON: {
    full: 'CANYON',
    abbr: 'CYN'
  },
  CANYN: {
    full: 'CANYON',
    abbr: 'CYN'
  },
  CNYN: {
    full: 'CANYON',
    abbr: 'CYN'
  },
  CYN: {
    full: 'CANYON',
    abbr: 'CYN'
  },
  CAPE: {
    full: 'CAPE',
    abbr: 'CPE'
  },
  CPE: {
    full: 'CAPE',
    abbr: 'CPE'
  },
  CAUSEWAY: {
    full: 'CAUSEWAY',
    abbr: 'CSWY'
  },
  CAUSWA: {
    full: 'CAUSEWAY',
    abbr: 'CSWY'
  },
  CSWY: {
    full: 'CAUSEWAY',
    abbr: 'CSWY'
  },
  CENTER: {
    full: 'CENTER',
    abbr: 'CTR'
  },
  CEN: {
    full: 'CENTER',
    abbr: 'CTR'
  },
  CENT: {
    full: 'CENTER',
    abbr: 'CTR'
  },
  CENTR: {
    full: 'CENTER',
    abbr: 'CTR'
  },
  CENTRE: {
    full: 'CENTER',
    abbr: 'CTR'
  },
  CNTER: {
    full: 'CENTER',
    abbr: 'CTR'
  },
  CNTR: {
    full: 'CENTER',
    abbr: 'CTR'
  },
  CTR: {
    full: 'CENTER',
    abbr: 'CTR'
  },
  CENTERS: {
    full: 'CENTERS',
    abbr: 'CTRS'
  },
  CTRS: {
    full: 'CENTERS',
    abbr: 'CTRS'
  },
  CIRCLE: {
    full: 'CIRCLE',
    abbr: 'CIR'
  },
  CIR: {
    full: 'CIRCLE',
    abbr: 'CIR'
  },
  CIRC: {
    full: 'CIRCLE',
    abbr: 'CIR'
  },
  CIRCL: {
    full: 'CIRCLE',
    abbr: 'CIR'
  },
  CRCL: {
    full: 'CIRCLE',
    abbr: 'CIR'
  },
  CRCLE: {
    full: 'CIRCLE',
    abbr: 'CIR'
  },
  CIRCLES: {
    full: 'CIRCLES',
    abbr: 'CIRS'
  },
  CIRS: {
    full: 'CIRCLES',
    abbr: 'CIRS'
  },
  CLIFF: {
    full: 'CLIFF',
    abbr: 'CLF'
  },
  CLF: {
    full: 'CLIFF',
    abbr: 'CLF'
  },
  CLIFFS: {
    full: 'CLIFFS',
    abbr: 'CLFS'
  },
  CLFS: {
    full: 'CLIFFS',
    abbr: 'CLFS'
  },
  CLUB: {
    full: 'CLUB',
    abbr: 'CLB'
  },
  CLB: {
    full: 'CLUB',
    abbr: 'CLB'
  },
  COMMON: {
    full: 'COMMON',
    abbr: 'CMN'
  },
  CMN: {
    full: 'COMMON',
    abbr: 'CMN'
  },
  COMMONS: {
    full: 'COMMONS',
    abbr: 'CMNS'
  },
  CMNS: {
    full: 'COMMONS',
    abbr: 'CMNS'
  },
  CORNER: {
    full: 'CORNER',
    abbr: 'COR'
  },
  COR: {
    full: 'CORNER',
    abbr: 'COR'
  },
  CORNERS: {
    full: 'CORNERS',
    abbr: 'CORS'
  },
  CORS: {
    full: 'CORNERS',
    abbr: 'CORS'
  },
  COURSE: {
    full: 'COURSE',
    abbr: 'CRSE'
  },
  CRSE: {
    full: 'COURSE',
    abbr: 'CRSE'
  },
  COURT: {
    full: 'COURT',
    abbr: 'CT'
  },
  CT: {
    full: 'COURT',
    abbr: 'CT'
  },
  COURTS: {
    full: 'COURTS',
    abbr: 'CTS'
  },
  CTS: {
    full: 'COURTS',
    abbr: 'CTS'
  },
  COVE: {
    full: 'COVE',
    abbr: 'CV'
  },
  CV: {
    full: 'COVE',
    abbr: 'CV'
  },
  COVES: {
    full: 'COVES',
    abbr: 'CVS'
  },
  CVS: {
    full: 'COVES',
    abbr: 'CVS'
  },
  CREEK: {
    full: 'CREEK',
    abbr: 'CRK'
  },
  CRK: {
    full: 'CREEK',
    abbr: 'CRK'
  },
  CRESCENT: {
    full: 'CRESCENT',
    abbr: 'CRES'
  },
  CRES: {
    full: 'CRESCENT',
    abbr: 'CRES'
  },
  CRSENT: {
    full: 'CRESCENT',
    abbr: 'CRES'
  },
  CRSNT: {
    full: 'CRESCENT',
    abbr: 'CRES'
  },
  CREST: {
    full: 'CREST',
    abbr: 'CRST'
  },
  CRST: {
    full: 'CREST',
    abbr: 'CRST'
  },
  CROSSING: {
    full: 'CROSSING',
    abbr: 'XING'
  },
  'CRSSNG ': {
    full: 'CROSSING',
    abbr: 'XING'
  },
  XING: {
    full: 'CROSSING',
    abbr: 'XING'
  },
  CROSSROAD: {
    full: 'CROSSROAD',
    abbr: 'XRD'
  },
  XRD: {
    full: 'CROSSROAD',
    abbr: 'XRD'
  },
  CROSSROADS: {
    full: 'CROSSROADS',
    abbr: 'XRDS'
  },
  XRDS: {
    full: 'CROSSROADS',
    abbr: 'XRDS'
  },
  'CURVE ': {
    full: 'CURVE ',
    abbr: 'CURV'
  },
  CURVE: {
    full: 'CURVE ',
    abbr: 'CURV'
  },
  CURV: {
    full: 'CURVE ',
    abbr: 'CURV'
  },
  DALE: {
    full: 'DALE',
    abbr: 'DL'
  },
  DL: {
    full: 'DALE',
    abbr: 'DL'
  },
  DAM: {
    full: 'DAM',
    abbr: 'DM'
  },
  DM: {
    full: 'DAM',
    abbr: 'DM'
  },
  DIVIDE: {
    full: 'DIVIDE',
    abbr: 'DV'
  },
  DIV: {
    full: 'DIVIDE',
    abbr: 'DV'
  },
  DV: {
    full: 'DIVIDE',
    abbr: 'DV'
  },
  DVD: {
    full: 'DIVIDE',
    abbr: 'DV'
  },
  DRIVE: {
    full: 'DRIVE',
    abbr: 'DR'
  },
  DR: {
    full: 'DRIVE',
    abbr: 'DR'
  },
  DRIV: {
    full: 'DRIVE',
    abbr: 'DR'
  },
  DRV: {
    full: 'DRIVE',
    abbr: 'DR'
  },
  DRIVES: {
    full: 'DRIVES',
    abbr: 'DRS'
  },
  DRS: {
    full: 'DRIVES',
    abbr: 'DRS'
  },
  ESTATE: {
    full: 'ESTATE',
    abbr: 'EST'
  },
  EST: {
    full: 'ESTATE',
    abbr: 'EST'
  },
  ESTATES: {
    full: 'ESTATES',
    abbr: 'ESTS'
  },
  ESTS: {
    full: 'ESTATES',
    abbr: 'ESTS'
  },
  EXPRESSWAY: {
    full: 'EXPRESSWAY',
    abbr: 'EXPY'
  },
  EXP: {
    full: 'EXPRESSWAY',
    abbr: 'EXPY'
  },
  EXPR: {
    full: 'EXPRESSWAY',
    abbr: 'EXPY'
  },
  EXPRESS: {
    full: 'EXPRESSWAY',
    abbr: 'EXPY'
  },
  EXPW: {
    full: 'EXPRESSWAY',
    abbr: 'EXPY'
  },
  EXPY: {
    full: 'EXPRESSWAY',
    abbr: 'EXPY'
  },
  EXTENSION: {
    full: 'EXTENSION',
    abbr: 'EXT'
  },
  EXT: {
    full: 'EXTENSION',
    abbr: 'EXT'
  },
  EXTN: {
    full: 'EXTENSION',
    abbr: 'EXT'
  },
  EXTNSN: {
    full: 'EXTENSION',
    abbr: 'EXT'
  },
  EXTENSIONS: {
    full: 'EXTENSIONS',
    abbr: 'EXTS'
  },
  EXTS: {
    full: 'EXTENSIONS',
    abbr: 'EXTS'
  },
  FALL: {
    full: 'FALL',
    abbr: 'FALL'
  },
  FALLS: {
    full: 'FALLS',
    abbr: 'FLS'
  },
  FLS: {
    full: 'FALLS',
    abbr: 'FLS'
  },
  FERRY: {
    full: 'FERRY',
    abbr: 'FRY'
  },
  FRRY: {
    full: 'FERRY',
    abbr: 'FRY'
  },
  FRY: {
    full: 'FERRY',
    abbr: 'FRY'
  },
  FIELD: {
    full: 'FIELD',
    abbr: 'FLD'
  },
  FLD: {
    full: 'FIELD',
    abbr: 'FLD'
  },
  FIELDS: {
    full: 'FIELDS',
    abbr: 'FLDS'
  },
  FLDS: {
    full: 'FIELDS',
    abbr: 'FLDS'
  },
  FLAT: {
    full: 'FLAT',
    abbr: 'FLT'
  },
  FLT: {
    full: 'FLAT',
    abbr: 'FLT'
  },
  FLATS: {
    full: 'FLATS',
    abbr: 'FLTS'
  },
  FLTS: {
    full: 'FLATS',
    abbr: 'FLTS'
  },
  FORD: {
    full: 'FORD',
    abbr: 'FRD'
  },
  FRD: {
    full: 'FORD',
    abbr: 'FRD'
  },
  FORDS: {
    full: 'FORDS',
    abbr: 'FRDS'
  },
  FRDS: {
    full: 'FORDS',
    abbr: 'FRDS'
  },
  FOREST: {
    full: 'FOREST',
    abbr: 'FRST'
  },
  FORESTS: {
    full: 'FOREST',
    abbr: 'FRST'
  },
  FRST: {
    full: 'FOREST',
    abbr: 'FRST'
  },
  FORGE: {
    full: 'FORGE',
    abbr: 'FRG'
  },
  FORG: {
    full: 'FORGE',
    abbr: 'FRG'
  },
  FRG: {
    full: 'FORGE',
    abbr: 'FRG'
  },
  FORGES: {
    full: 'FORGES',
    abbr: 'FRGS'
  },
  FRGS: {
    full: 'FORGES',
    abbr: 'FRGS'
  },
  FORK: {
    full: 'FORK',
    abbr: 'FRK'
  },
  FRK: {
    full: 'FORK',
    abbr: 'FRK'
  },
  FORKS: {
    full: 'FORKS',
    abbr: 'FRKS'
  },
  FRKS: {
    full: 'FORKS',
    abbr: 'FRKS'
  },
  FORT: {
    full: 'FORT',
    abbr: 'FT'
  },
  FRT: {
    full: 'FORT',
    abbr: 'FT'
  },
  FT: {
    full: 'FORT',
    abbr: 'FT'
  },
  FREEWAY: {
    full: 'FREEWAY',
    abbr: 'FWY'
  },
  FREEWY: {
    full: 'FREEWAY',
    abbr: 'FWY'
  },
  FRWAY: {
    full: 'FREEWAY',
    abbr: 'FWY'
  },
  FRWY: {
    full: 'FREEWAY',
    abbr: 'FWY'
  },
  FWY: {
    full: 'FREEWAY',
    abbr: 'FWY'
  },
  GARDEN: {
    full: 'GARDEN',
    abbr: 'GDN'
  },
  GARDN: {
    full: 'GARDEN',
    abbr: 'GDN'
  },
  GRDEN: {
    full: 'GARDEN',
    abbr: 'GDN'
  },
  GRDN: {
    full: 'GARDEN',
    abbr: 'GDN'
  },
  GDN: {
    full: 'GARDEN',
    abbr: 'GDN'
  },
  GARDENS: {
    full: 'GARDENS',
    abbr: 'GDNS'
  },
  GDNS: {
    full: 'GARDENS',
    abbr: 'GDNS'
  },
  GRDNS: {
    full: 'GARDENS',
    abbr: 'GDNS'
  },
  GATEWAY: {
    full: 'GATEWAY',
    abbr: 'GTWY'
  },
  GATEWY: {
    full: 'GATEWAY',
    abbr: 'GTWY'
  },
  GATWAY: {
    full: 'GATEWAY',
    abbr: 'GTWY'
  },
  GTWAY: {
    full: 'GATEWAY',
    abbr: 'GTWY'
  },
  GTWY: {
    full: 'GATEWAY',
    abbr: 'GTWY'
  },
  GLEN: {
    full: 'GLEN',
    abbr: 'GLN'
  },
  GLN: {
    full: 'GLEN',
    abbr: 'GLN'
  },
  GLENS: {
    full: 'GLENS',
    abbr: 'GLNS'
  },
  GLNS: {
    full: 'GLENS',
    abbr: 'GLNS'
  },
  GREEN: {
    full: 'GREEN',
    abbr: 'GRN'
  },
  GRN: {
    full: 'GREEN',
    abbr: 'GRN'
  },
  GREENS: {
    full: 'GREENS',
    abbr: 'GRNS'
  },
  GRNS: {
    full: 'GREENS',
    abbr: 'GRNS'
  },
  GROVE: {
    full: 'GROVE',
    abbr: 'GRV'
  },
  GROV: {
    full: 'GROVE',
    abbr: 'GRV'
  },
  GRV: {
    full: 'GROVE',
    abbr: 'GRV'
  },
  GROVES: {
    full: 'GROVES',
    abbr: 'GRVS'
  },
  GRVS: {
    full: 'GROVES',
    abbr: 'GRVS'
  },
  HARBOR: {
    full: 'HARBOR',
    abbr: 'HBR'
  },
  HARB: {
    full: 'HARBOR',
    abbr: 'HBR'
  },
  HARBR: {
    full: 'HARBOR',
    abbr: 'HBR'
  },
  HBR: {
    full: 'HARBOR',
    abbr: 'HBR'
  },
  HRBOR: {
    full: 'HARBOR',
    abbr: 'HBR'
  },
  HARBORS: {
    full: 'HARBORS',
    abbr: 'HBRS'
  },
  HBRS: {
    full: 'HARBORS',
    abbr: 'HBRS'
  },
  HAVEN: {
    full: 'HAVEN',
    abbr: 'HVN'
  },
  HVN: {
    full: 'HAVEN',
    abbr: 'HVN'
  },
  HEIGHTS: {
    full: 'HEIGHTS',
    abbr: 'HTS'
  },
  HT: {
    full: 'HEIGHTS',
    abbr: 'HTS'
  },
  HTS: {
    full: 'HEIGHTS',
    abbr: 'HTS'
  },
  HIGHWAY: {
    full: 'HIGHWAY',
    abbr: 'HWY'
  },
  HIGHWY: {
    full: 'HIGHWAY',
    abbr: 'HWY'
  },
  HIWAY: {
    full: 'HIGHWAY',
    abbr: 'HWY'
  },
  HIWY: {
    full: 'HIGHWAY',
    abbr: 'HWY'
  },
  HWAY: {
    full: 'HIGHWAY',
    abbr: 'HWY'
  },
  HWY: {
    full: 'HIGHWAY',
    abbr: 'HWY'
  },
  HILL: {
    full: 'HILL',
    abbr: 'HL'
  },
  HL: {
    full: 'HILL',
    abbr: 'HL'
  },
  HILLS: {
    full: 'HILLS',
    abbr: 'HLS'
  },
  HLS: {
    full: 'HILLS',
    abbr: 'HLS'
  },
  HOLLOW: {
    full: 'HOLLOW',
    abbr: 'HOLW'
  },
  HLLW: {
    full: 'HOLLOW',
    abbr: 'HOLW'
  },
  HOLLOWS: {
    full: 'HOLLOW',
    abbr: 'HOLW'
  },
  HOLW: {
    full: 'HOLLOW',
    abbr: 'HOLW'
  },
  HOLWS: {
    full: 'HOLLOW',
    abbr: 'HOLW'
  },
  INLET: {
    full: 'INLET',
    abbr: 'INLT'
  },
  INLT: {
    full: 'INLET',
    abbr: 'INLT'
  },
  ISLAND: {
    full: 'ISLAND',
    abbr: 'IS'
  },
  IS: {
    full: 'ISLAND',
    abbr: 'IS'
  },
  ISLND: {
    full: 'ISLAND',
    abbr: 'IS'
  },
  ISLANDS: {
    full: 'ISLANDS',
    abbr: 'ISS'
  },
  ISLNDS: {
    full: 'ISLANDS',
    abbr: 'ISS'
  },
  ISS: {
    full: 'ISLANDS',
    abbr: 'ISS'
  },
  ISLE: {
    full: 'ISLE',
    abbr: 'ISLE'
  },
  ISLES: {
    full: 'ISLE',
    abbr: 'ISLE'
  },
  JUNCTION: {
    full: 'JUNCTION',
    abbr: 'JCT'
  },
  JCT: {
    full: 'JUNCTION',
    abbr: 'JCT'
  },
  JCTION: {
    full: 'JUNCTION',
    abbr: 'JCT'
  },
  JCTN: {
    full: 'JUNCTION',
    abbr: 'JCT'
  },
  JUNCTN: {
    full: 'JUNCTION',
    abbr: 'JCT'
  },
  JUNCTON: {
    full: 'JUNCTION',
    abbr: 'JCT'
  },
  JUNCTIONS: {
    full: 'JUNCTIONS',
    abbr: 'JCTS'
  },
  JCTNS: {
    full: 'JUNCTIONS',
    abbr: 'JCTS'
  },
  JCTS: {
    full: 'JUNCTIONS',
    abbr: 'JCTS'
  },
  KEY: {
    full: 'KEY',
    abbr: 'KY'
  },
  KY: {
    full: 'KEY',
    abbr: 'KY'
  },
  KEYS: {
    full: 'KEYS',
    abbr: 'KYS'
  },
  KYS: {
    full: 'KEYS',
    abbr: 'KYS'
  },
  KNOLL: {
    full: 'KNOLL',
    abbr: 'KNL '
  },
  KNL: {
    full: 'KNOLL',
    abbr: 'KNL '
  },
  KNOL: {
    full: 'KNOLL',
    abbr: 'KNL '
  },
  'KNL ': {
    full: 'KNOLL',
    abbr: 'KNL '
  },
  KNOLLS: {
    full: 'KNOLLS',
    abbr: 'KNLS'
  },
  KNLS: {
    full: 'KNOLLS',
    abbr: 'KNLS'
  },
  LAKE: {
    full: 'LAKE',
    abbr: 'LK'
  },
  LK: {
    full: 'LAKE',
    abbr: 'LK'
  },
  LAKES: {
    full: 'LAKES',
    abbr: 'LKS'
  },
  LKS: {
    full: 'LAKES',
    abbr: 'LKS'
  },
  LAND: {
    full: 'LAND',
    abbr: 'LAND'
  },
  LANDING: {
    full: 'LANDING',
    abbr: 'LNDG'
  },
  LNDG: {
    full: 'LANDING',
    abbr: 'LNDG'
  },
  LNDNG: {
    full: 'LANDING',
    abbr: 'LNDG'
  },
  LANE: {
    full: 'LANE',
    abbr: 'LN'
  },
  LN: {
    full: 'LANE',
    abbr: 'LN'
  },
  LIGHT: {
    full: 'LIGHT',
    abbr: 'LGT'
  },
  LGT: {
    full: 'LIGHT',
    abbr: 'LGT'
  },
  LIGHTS: {
    full: 'LIGHTS',
    abbr: 'LGTS'
  },
  LGTS: {
    full: 'LIGHTS',
    abbr: 'LGTS'
  },
  LOAF: {
    full: 'LOAF',
    abbr: 'LF'
  },
  LF: {
    full: 'LOAF',
    abbr: 'LF'
  },
  LOCK: {
    full: 'LOCK',
    abbr: 'LCK'
  },
  LCK: {
    full: 'LOCK',
    abbr: 'LCK'
  },
  LOCKS: {
    full: 'LOCKS',
    abbr: 'LCKS'
  },
  LCKS: {
    full: 'LOCKS',
    abbr: 'LCKS'
  },
  LODGE: {
    full: 'LODGE',
    abbr: 'LDG'
  },
  LDG: {
    full: 'LODGE',
    abbr: 'LDG'
  },
  LDGE: {
    full: 'LODGE',
    abbr: 'LDG'
  },
  LODG: {
    full: 'LODGE',
    abbr: 'LDG'
  },
  LOOP: {
    full: 'LOOP',
    abbr: 'LOOP'
  },
  LOOPS: {
    full: 'LOOP',
    abbr: 'LOOP'
  },
  MALL: {
    full: 'MALL',
    abbr: 'MALL'
  },
  MANOR: {
    full: 'MANOR',
    abbr: 'MNR'
  },
  MNR: {
    full: 'MANOR',
    abbr: 'MNR'
  },
  MANORS: {
    full: 'MANORS',
    abbr: 'MNRS'
  },
  MNRS: {
    full: 'MANORS',
    abbr: 'MNRS'
  },
  MEADOW: {
    full: 'MEADOW',
    abbr: 'MDW'
  },
  MDW: {
    full: 'MEADOWS',
    abbr: 'MDWS'
  },
  MEADOWS: {
    full: 'MEADOWS',
    abbr: 'MDWS'
  },
  MDWS: {
    full: 'MEADOWS',
    abbr: 'MDWS'
  },
  MEDOWS: {
    full: 'MEADOWS',
    abbr: 'MDWS'
  },
  MEWS: {
    full: 'MEWS',
    abbr: 'MEWS'
  },
  MILL: {
    full: 'MILL',
    abbr: 'ML'
  },
  ML: {
    full: 'MILL',
    abbr: 'ML'
  },
  MILLS: {
    full: 'MILLS',
    abbr: 'MLS'
  },
  MLS: {
    full: 'MILLS',
    abbr: 'MLS'
  },
  MISSION: {
    full: 'MISSION',
    abbr: 'MSN'
  },
  MISSN: {
    full: 'MISSION',
    abbr: 'MSN'
  },
  MSSN: {
    full: 'MISSION',
    abbr: 'MSN'
  },
  MSN: {
    full: 'MISSION',
    abbr: 'MSN'
  },
  MOTORWAY: {
    full: 'MOTORWAY',
    abbr: 'MTWY'
  },
  MTWY: {
    full: 'MOTORWAY',
    abbr: 'MTWY'
  },
  MOUNT: {
    full: 'MOUNT',
    abbr: 'MT'
  },
  MNT: {
    full: 'MOUNT',
    abbr: 'MT'
  },
  MT: {
    full: 'MOUNT',
    abbr: 'MT'
  },
  MOUNTAIN: {
    full: 'MOUNTAIN',
    abbr: 'MTN'
  },
  MNTAIN: {
    full: 'MOUNTAIN',
    abbr: 'MTN'
  },
  MNTN: {
    full: 'MOUNTAIN',
    abbr: 'MTN'
  },
  MOUNTIN: {
    full: 'MOUNTAIN',
    abbr: 'MTN'
  },
  MTIN: {
    full: 'MOUNTAIN',
    abbr: 'MTN'
  },
  MTN: {
    full: 'MOUNTAIN',
    abbr: 'MTN'
  },
  MOUNTAINS: {
    full: 'MOUNTAINS',
    abbr: 'MTNS'
  },
  MNTNS: {
    full: 'MOUNTAINS',
    abbr: 'MTNS'
  },
  MTNS: {
    full: 'MOUNTAINS',
    abbr: 'MTNS'
  },
  NECK: {
    full: 'NECK',
    abbr: 'NCK'
  },
  NCK: {
    full: 'NECK',
    abbr: 'NCK'
  },
  ORCHARD: {
    full: 'ORCHARD',
    abbr: 'ORCH'
  },
  ORCH: {
    full: 'ORCHARD',
    abbr: 'ORCH'
  },
  ORCHRD: {
    full: 'ORCHARD',
    abbr: 'ORCH'
  },
  OVAL: {
    full: 'OVAL',
    abbr: 'OVAL'
  },
  OVL: {
    full: 'OVAL',
    abbr: 'OVAL'
  },
  OVERPASS: {
    full: 'OVERPASS',
    abbr: 'OPAS'
  },
  OPAS: {
    full: 'OVERPASS',
    abbr: 'OPAS'
  },
  PARK: {
    full: 'PARKS',
    abbr: 'PARK'
  },
  PRK: {
    full: 'PARK',
    abbr: 'PARK'
  },
  PARKS: {
    full: 'PARKS',
    abbr: 'PARK'
  },
  PARKWAY: {
    full: 'PARKWAY',
    abbr: 'PKWY'
  },
  PARKWY: {
    full: 'PARKWAY',
    abbr: 'PKWY'
  },
  PKWAY: {
    full: 'PARKWAY',
    abbr: 'PKWY'
  },
  PKWY: {
    full: 'PARKWAYS',
    abbr: 'PKWY'
  },
  PKY: {
    full: 'PARKWAY',
    abbr: 'PKWY'
  },
  PARKWAYS: {
    full: 'PARKWAYS',
    abbr: 'PKWY'
  },
  PKWYS: {
    full: 'PARKWAYS',
    abbr: 'PKWY'
  },
  PASS: {
    full: 'PASS',
    abbr: 'PASS'
  },
  PASSAGE: {
    full: 'PASSAGE',
    abbr: 'PSGE'
  },
  PSGE: {
    full: 'PASSAGE',
    abbr: 'PSGE'
  },
  PATH: {
    full: 'PATH',
    abbr: 'PATH'
  },
  PATHS: {
    full: 'PATH',
    abbr: 'PATH'
  },
  PIKE: {
    full: 'PIKE',
    abbr: 'PIKE'
  },
  PIKES: {
    full: 'PIKE',
    abbr: 'PIKE'
  },
  PINE: {
    full: 'PINE',
    abbr: 'PNE '
  },
  'PNE ': {
    full: 'PINE',
    abbr: 'PNE '
  },
  PINES: {
    full: 'PINES',
    abbr: 'PNES'
  },
  PNES: {
    full: 'PINES',
    abbr: 'PNES'
  },
  PLACE: {
    full: 'PLACE',
    abbr: 'PL'
  },
  PL: {
    full: 'PLACE',
    abbr: 'PL'
  },
  PLAIN: {
    full: 'PLAIN',
    abbr: 'PLN'
  },
  PLN: {
    full: 'PLAIN',
    abbr: 'PLN'
  },
  PLAINS: {
    full: 'PLAINS',
    abbr: 'PLNS'
  },
  PLNS: {
    full: 'PLAINS',
    abbr: 'PLNS'
  },
  PLAZA: {
    full: 'PLAZA',
    abbr: 'PLZ'
  },
  PLZ: {
    full: 'PLAZA',
    abbr: 'PLZ'
  },
  PLZA: {
    full: 'PLAZA',
    abbr: 'PLZ'
  },
  POINT: {
    full: 'POINT',
    abbr: 'PT'
  },
  PT: {
    full: 'POINT',
    abbr: 'PT'
  },
  POINTS: {
    full: 'POINTS',
    abbr: 'PTS'
  },
  PTS: {
    full: 'POINTS',
    abbr: 'PTS'
  },
  PORT: {
    full: 'PORT',
    abbr: 'PRT'
  },
  PRT: {
    full: 'PORT',
    abbr: 'PRT'
  },
  PORTS: {
    full: 'PORTS',
    abbr: 'PRTS'
  },
  PRTS: {
    full: 'PORTS',
    abbr: 'PRTS'
  },
  PRAIRIE: {
    full: 'PRAIRIE',
    abbr: 'PR'
  },
  PR: {
    full: 'PRAIRIE',
    abbr: 'PR'
  },
  PRR: {
    full: 'PRAIRIE',
    abbr: 'PR'
  },
  RADIAL: {
    full: 'RADIAL',
    abbr: 'RADL'
  },
  RAD: {
    full: 'RADIAL',
    abbr: 'RADL'
  },
  RADIEL: {
    full: 'RADIAL',
    abbr: 'RADL'
  },
  RADL: {
    full: 'RADIAL',
    abbr: 'RADL'
  },
  RAMP: {
    full: 'RAMP',
    abbr: 'RAMP'
  },
  RANCH: {
    full: 'RANCH',
    abbr: 'RNCH'
  },
  RANCHES: {
    full: 'RANCH',
    abbr: 'RNCH'
  },
  RNCH: {
    full: 'RANCH',
    abbr: 'RNCH'
  },
  RNCHS: {
    full: 'RANCH',
    abbr: 'RNCH'
  },
  RAPID: {
    full: 'RAPID',
    abbr: 'RPD'
  },
  RPD: {
    full: 'RAPID',
    abbr: 'RPD'
  },
  RAPIDS: {
    full: 'RAPIDS',
    abbr: 'RPDS'
  },
  RPDS: {
    full: 'RAPIDS',
    abbr: 'RPDS'
  },
  REST: {
    full: 'REST',
    abbr: 'RST'
  },
  RST: {
    full: 'REST',
    abbr: 'RST'
  },
  RIDGE: {
    full: 'RIDGE',
    abbr: 'RDG'
  },
  RDG: {
    full: 'RIDGE',
    abbr: 'RDG'
  },
  RDGE: {
    full: 'RIDGE',
    abbr: 'RDG'
  },
  RIDGES: {
    full: 'RIDGES',
    abbr: 'RDGS'
  },
  RDGS: {
    full: 'RIDGES',
    abbr: 'RDGS'
  },
  RIVER: {
    full: 'RIVER',
    abbr: 'RIV'
  },
  RIV: {
    full: 'RIVER',
    abbr: 'RIV'
  },
  RVR: {
    full: 'RIVER',
    abbr: 'RIV'
  },
  RIVR: {
    full: 'RIVER',
    abbr: 'RIV'
  },
  ROAD: {
    full: 'ROAD',
    abbr: 'RD'
  },
  RD: {
    full: 'ROAD',
    abbr: 'RD'
  },
  ROADS: {
    full: 'ROADS',
    abbr: 'RDS'
  },
  RDS: {
    full: 'ROADS',
    abbr: 'RDS'
  },
  ROUTE: {
    full: 'ROUTE',
    abbr: 'RTE'
  },
  RTE: {
    full: 'ROUTE',
    abbr: 'RTE'
  },
  ROW: {
    full: 'ROW',
    abbr: 'ROW'
  },
  RUE: {
    full: 'RUE',
    abbr: 'RUE'
  },
  RUN: {
    full: 'RUN',
    abbr: 'RUN'
  },
  SHOAL: {
    full: 'SHOAL',
    abbr: 'SHL'
  },
  SHL: {
    full: 'SHOAL',
    abbr: 'SHL'
  },
  SHOALS: {
    full: 'SHOALS',
    abbr: 'SHLS'
  },
  SHLS: {
    full: 'SHOALS',
    abbr: 'SHLS'
  },
  SHORE: {
    full: 'SHORE',
    abbr: 'SHR'
  },
  SHOAR: {
    full: 'SHORE',
    abbr: 'SHR'
  },
  SHR: {
    full: 'SHORE',
    abbr: 'SHR'
  },
  SHORES: {
    full: 'SHORES',
    abbr: 'SHRS'
  },
  SHOARS: {
    full: 'SHORES',
    abbr: 'SHRS'
  },
  SHRS: {
    full: 'SHORES',
    abbr: 'SHRS'
  },
  SKYWAY: {
    full: 'SKYWAY',
    abbr: 'SKWY'
  },
  SKWY: {
    full: 'SKYWAY',
    abbr: 'SKWY'
  },
  SPRING: {
    full: 'SPRING',
    abbr: 'SPG'
  },
  SPG: {
    full: 'SPRING',
    abbr: 'SPG'
  },
  SPNG: {
    full: 'SPRING',
    abbr: 'SPG'
  },
  SPRNG: {
    full: 'SPRING',
    abbr: 'SPG'
  },
  SPRINGS: {
    full: 'SPRINGS',
    abbr: 'SPGS'
  },
  SPGS: {
    full: 'SPRINGS',
    abbr: 'SPGS'
  },
  SPNGS: {
    full: 'SPRINGS',
    abbr: 'SPGS'
  },
  SPRNGS: {
    full: 'SPRINGS',
    abbr: 'SPGS'
  },
  SPUR: {
    full: 'SPURS',
    abbr: 'SPUR'
  },
  SPURS: {
    full: 'SPURS',
    abbr: 'SPUR'
  },
  SQUARE: {
    full: 'SQUARE',
    abbr: 'SQ'
  },
  SQ: {
    full: 'SQUARE',
    abbr: 'SQ'
  },
  SQR: {
    full: 'SQUARE',
    abbr: 'SQ'
  },
  SQRE: {
    full: 'SQUARE',
    abbr: 'SQ'
  },
  SQU: {
    full: 'SQUARE',
    abbr: 'SQ'
  },
  SQUARES: {
    full: 'SQUARES',
    abbr: 'SQS'
  },
  SQRS: {
    full: 'SQUARES',
    abbr: 'SQS'
  },
  SQS: {
    full: 'SQUARES',
    abbr: 'SQS'
  },
  STATION: {
    full: 'STATION',
    abbr: 'STA'
  },
  STA: {
    full: 'STATION',
    abbr: 'STA'
  },
  STATN: {
    full: 'STATION',
    abbr: 'STA'
  },
  STN: {
    full: 'STATION',
    abbr: 'STA'
  },
  STRAVENUE: {
    full: 'STRAVENUE',
    abbr: 'STRA'
  },
  STRA: {
    full: 'STRAVENUE',
    abbr: 'STRA'
  },
  STRAV: {
    full: 'STRAVENUE',
    abbr: 'STRA'
  },
  STRAVEN: {
    full: 'STRAVENUE',
    abbr: 'STRA'
  },
  STRAVN: {
    full: 'STRAVENUE',
    abbr: 'STRA'
  },
  STRVN: {
    full: 'STRAVENUE',
    abbr: 'STRA'
  },
  STRVNUE: {
    full: 'STRAVENUE',
    abbr: 'STRA'
  },
  STREAM: {
    full: 'STREAM',
    abbr: 'STRM'
  },
  STREME: {
    full: 'STREAM',
    abbr: 'STRM'
  },
  STRM: {
    full: 'STREAM',
    abbr: 'STRM'
  },
  STREET: {
    full: 'STREET',
    abbr: 'ST'
  },
  STRT: {
    full: 'STREET',
    abbr: 'ST'
  },
  ST: {
    full: 'STREET',
    abbr: 'ST'
  },
  STR: {
    full: 'STREET',
    abbr: 'ST'
  },
  STREETS: {
    full: 'STREETS',
    abbr: 'STS'
  },
  STS: {
    full: 'STREETS',
    abbr: 'STS'
  },
  SUMMIT: {
    full: 'SUMMIT',
    abbr: 'SMT'
  },
  SMT: {
    full: 'SUMMIT',
    abbr: 'SMT'
  },
  SUMIT: {
    full: 'SUMMIT',
    abbr: 'SMT'
  },
  SUMITT: {
    full: 'SUMMIT',
    abbr: 'SMT'
  },
  TERRACE: {
    full: 'TERRACE',
    abbr: 'TER'
  },
  TER: {
    full: 'TERRACE',
    abbr: 'TER'
  },
  TERR: {
    full: 'TERRACE',
    abbr: 'TER'
  },
  THROUGHWAY: {
    full: 'THROUGHWAY',
    abbr: 'TRWY'
  },
  TRWY: {
    full: 'THROUGHWAY',
    abbr: 'TRWY'
  },
  TRACE: {
    full: 'TRACE',
    abbr: 'TRCE'
  },
  TRACES: {
    full: 'TRACE',
    abbr: 'TRCE'
  },
  TRCE: {
    full: 'TRACE',
    abbr: 'TRCE'
  },
  TRACK: {
    full: 'TRACK',
    abbr: 'TRAK'
  },
  TRACKS: {
    full: 'TRACK',
    abbr: 'TRAK'
  },
  TRAK: {
    full: 'TRACK',
    abbr: 'TRAK'
  },
  TRK: {
    full: 'TRACK',
    abbr: 'TRAK'
  },
  TRKS: {
    full: 'TRACK',
    abbr: 'TRAK'
  },
  TRAFFICWAY: {
    full: 'TRAFFICWAY',
    abbr: 'TRFY'
  },
  TRFY: {
    full: 'TRAFFICWAY',
    abbr: 'TRFY'
  },
  TRAIL: {
    full: 'TRAIL',
    abbr: 'TRL'
  },
  TRAILS: {
    full: 'TRAIL',
    abbr: 'TRL'
  },
  TRL: {
    full: 'TRAIL',
    abbr: 'TRL'
  },
  TRLS: {
    full: 'TRAIL',
    abbr: 'TRL'
  },
  TRAILER: {
    full: 'TRAILER',
    abbr: 'TRLR'
  },
  TRLR: {
    full: 'TRAILER',
    abbr: 'TRLR'
  },
  TRLRS: {
    full: 'TRAILER',
    abbr: 'TRLR'
  },
  TUNNEL: {
    full: 'TUNNEL',
    abbr: 'TUNL'
  },
  TUNEL: {
    full: 'TUNNEL',
    abbr: 'TUNL'
  },
  TUNL: {
    full: 'TUNNEL',
    abbr: 'TUNL'
  },
  TUNLS: {
    full: 'TUNNEL',
    abbr: 'TUNL'
  },
  TUNNELS: {
    full: 'TUNNEL',
    abbr: 'TUNL'
  },
  TUNNL: {
    full: 'TUNNEL',
    abbr: 'TUNL'
  },
  TURNPIKE: {
    full: 'TURNPIKE',
    abbr: 'TPKE'
  },
  TRNPK: {
    full: 'TURNPIKE',
    abbr: 'TPKE'
  },
  TURNPK: {
    full: 'TURNPIKE',
    abbr: 'TPKE'
  },
  TPKE: {
    full: 'TURNPIKE',
    abbr: 'TPKE'
  },
  UNDERPASS: {
    full: 'UNDERPASS',
    abbr: 'UPAS'
  },
  UPAS: {
    full: 'UNDERPASS',
    abbr: 'UPAS'
  },
  UNION: {
    full: 'UNION',
    abbr: 'UN'
  },
  UN: {
    full: 'UNION',
    abbr: 'UN'
  },
  UNIONS: {
    full: 'UNIONS',
    abbr: 'UNS'
  },
  UNS: {
    full: 'UNIONS',
    abbr: 'UNS'
  },
  VALLEY: {
    full: 'VALLEY',
    abbr: 'VLY'
  },
  VALLY: {
    full: 'VALLEY',
    abbr: 'VLY'
  },
  VLLY: {
    full: 'VALLEY',
    abbr: 'VLY'
  },
  VLY: {
    full: 'VALLEY',
    abbr: 'VLY'
  },
  VALLEYS: {
    full: 'VALLEYS',
    abbr: 'VLYS'
  },
  VLYS: {
    full: 'VALLEYS',
    abbr: 'VLYS'
  },
  VIADUCT: {
    full: 'VIADUCT',
    abbr: 'VIA'
  },
  VDCT: {
    full: 'VIADUCT',
    abbr: 'VIA'
  },
  VIA: {
    full: 'VIADUCT',
    abbr: 'VIA'
  },
  VIADCT: {
    full: 'VIADUCT',
    abbr: 'VIA'
  },
  VIEW: {
    full: 'VIEW',
    abbr: 'VW'
  },
  VW: {
    full: 'VIEW',
    abbr: 'VW'
  },
  VIEWS: {
    full: 'VIEWS',
    abbr: 'VWS'
  },
  VWS: {
    full: 'VIEWS',
    abbr: 'VWS'
  },
  VILLAGE: {
    full: 'VILLAGE',
    abbr: 'VLG'
  },
  VILL: {
    full: 'VILLAGE',
    abbr: 'VLG'
  },
  VILLAG: {
    full: 'VILLAGE',
    abbr: 'VLG'
  },
  VILLG: {
    full: 'VILLAGE',
    abbr: 'VLG'
  },
  VILLIAGE: {
    full: 'VILLAGE',
    abbr: 'VLG'
  },
  VLG: {
    full: 'VILLAGE',
    abbr: 'VLG'
  },
  VILLAGES: {
    full: 'VILLAGES',
    abbr: 'VLGS'
  },
  VLGS: {
    full: 'VILLAGES',
    abbr: 'VLGS'
  },
  VILLE: {
    full: 'VILLE',
    abbr: 'VL'
  },
  VL: {
    full: 'VILLE',
    abbr: 'VL'
  },
  VISTA: {
    full: 'VISTA',
    abbr: 'VIS'
  },
  VIS: {
    full: 'VISTA',
    abbr: 'VIS'
  },
  VIST: {
    full: 'VISTA',
    abbr: 'VIS'
  },
  VST: {
    full: 'VISTA',
    abbr: 'VIS'
  },
  VSTA: {
    full: 'VISTA',
    abbr: 'VIS'
  },
  WALK: {
    full: 'WALKS',
    abbr: 'WALK'
  },
  WALKS: {
    full: 'WALKS',
    abbr: 'WALK'
  },
  WALL: {
    full: 'WALL',
    abbr: 'WALL'
  },
  WAY: {
    full: 'WAY',
    abbr: 'WAY'
  },
  WY: {
    full: 'WAY',
    abbr: 'WAY'
  },
  WAYS: {
    full: 'WAYS',
    abbr: 'WAYS'
  },
  WELL: {
    full: 'WELL',
    abbr: 'WL'
  },
  WL: {
    full: 'WELL',
    abbr: 'WL'
  },
  WELLS: {
    full: 'WELLS',
    abbr: 'WLS'
  },
  WLS: {
    full: 'WELLS',
    abbr: 'WLS'
  }
};

export default roads;

/**
 * If statement vue hack to prevent <!-- --> comment
 *
 * @param opts
 * @param opts.condition
 * @returns {[]}
 */
const renderIfReturnArray = (opts = {}) => {
  if (!opts || !opts.condition) {
    return [];
  }

  return Array.isArray(opts.condition) ? opts.condition : [opts.condition];
};

export default renderIfReturnArray;

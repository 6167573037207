const NBars = require('nbars/commonjs.js');

/**
 * @param {object} opts
 * @param {object} opts.params
 * @param {string} opts.firstName
 * @param {string} opts.lastName
 * @param {string} opts.urlContent
 * @param {string} [opts.state]
 * @param {string} [opts.city]
 * @returns {string}
 */
const setLandingUrl = (opts = {}) => {
  if (
    !opts ||
    !opts.urlContent ||
    !opts.params ||
    !opts.firstName ||
    !opts.lastName
  ) {
    return '';
  }

  const { params, firstName, lastName, urlContent, state, city } = opts;

  let url = NBars.transform(urlContent, {
    firstName,
    lastName
  });

  if (state) {
    url += `&state=${state}`;
  }

  if (city) {
    url += `&city=${city}`;
  }

  if (Object.keys(params).length !== 0) {
    Object.keys(params).forEach(param => {
      url += `&${param}=${params[param]}`;
    });
  }

  return url;
};

export default setLandingUrl;

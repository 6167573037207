import getSubset from '../helpers/getSubset';
import getFormattedNonNullObject from '../helpers/getFormattedNonNullObject';
import getFormattedMultilineAddressOrUnknown from '../helpers/getFormattedMultilineAddressOrUnknown';

/**
 * Returns formatted aircraft data for presentation on aircrafts card
 * @param {object} opts
 * @param {object} opts.aircrafts
 * @param {string} opts.default
 * @param {object} $bugsnag
 * @returns {array}
 */
const getAircraftData = (opts, $bugsnag) => {
  if (!opts || !opts.aircrafts || !opts.aircrafts.length) {
    return opts?.default ? opts.default : [];
  }
  try {
    return opts.aircrafts.map(i => {
      const res = getSubset({
        object: i,
        paths: [
          'tail_number',
          'model_name',
          'serial_number',
          'extra.aircraft.manufacturer_name',
          'extra.aircraft.type_of_registrant',
          'extra.aircraft.engines',
          'extra.aircraft.model_code',
          'extra.aircraft.category_code',
          'extra.aircraft.aircraft_code',
          'extra.aircraft.aircraft_type',
          'extra.aircraft.builder_certification',
          'extra.aircraft.manufacture_year',
          'extra.aircraft.popular_name',
          'extra.aircraft.engine_code',
          'extra.aircraft.registrant.firstname',
          'extra.aircraft.registrant.middlename',
          'extra.aircraft.registrant.lastname',
          'extra.aircraft.registrant.suffix',
          'extra.aircraft.registrant.street_number',
          'extra.aircraft.registrant.street_name',
          'extra.aircraft.registrant.street_type',
          'extra.aircraft.registrant.street_affix',
          'extra.aircraft.registrant.city',
          'extra.aircraft.registrant.stateAbbr',
          'extra.aircraft.registrant.zip'
        ]
      });
      res.registrant_address = getFormattedMultilineAddressOrUnknown({
        address: res
      });
      return getFormattedNonNullObject(res, 'Unknown', true);
    });
  } catch (err) {
    console.error(err);
    $bugsnag?.notify(err);
    return opts.default ?? [];
  }
};

export default getAircraftData;

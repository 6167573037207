/**
 * If statement vue hack to prevent <!-- --> comment
 *
 * @param opts
 * @param opts.condition
 * @returns {[]}
 */
const renderIf = (opts = {}) => {
  return opts.condition ? [Math.random()] : [];
};

export default renderIf;

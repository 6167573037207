import getOffsetCount from './getOffsetCount';

/**
 *
 * @param {object} opts
 * @param {boolean} opts.offset
 * @param {number} opts.number
 * @param {number} [opts.count_offset]
 *   @param {number} [opts.count_offset.max]
 *   @param {number} [opts.count_offset.med]
 *   @param {number} [opts.count_offset.min]
 * @param {object} [opts.limits]
 *   @param {number} [opts.limits.max]
 *   @param {number} [opts.limits.med]
 *   @param {number} [opts.limits.min]
 * @returns {*}
 */
const getOffsetCountMap = opts => {
  if (!opts || typeof opts !== 'object') {
    return opts || null;
  }

  if (opts.count && !opts.offset) {
    opts.offset = true;
    opts.count = getOffsetCount({
      count: opts.count,
      count_offset: opts.count_offset,
      limits: opts.limits
    });
  }

  return opts;
};

export default getOffsetCountMap;

import billingSDK from '@/assets/js/shared/services/billing-sdk';
import billingV2SDK from '@/assets/js/shared/services/billing-v2-sdk';
import billingProcessorSDK from '@/assets/js/shared/services/billing-processor-sdk';

const paymentHelper = {
  async getPreferredPayment(user, $bugsnag = {}) {
    try {
      let payments;
      if (!user.user_data?.v2) {
        payments = await billingSDK(window).Billing.Payments.byAccount(
          user.domain,
          user.id
        );
      } else {
        payments = await billingProcessorSDK(window).Payment.List.byAccount(
          user.domain,
          user.id
        );
      }
      if (payments.type && payments.type.includes('error')) {
        return false;
      }
      return payments.find(payment => payment.preferred_payment);
    } catch (err) {
      console.error('Error Fetching Preferred Payment');
      $bugsnag?.notify(err);
      return false;
    }
  },
  async chargeCard(
    paymentData,
    product,
    userData,
    aid,
    extra_tracking,
    type = 'product',
    $bugsnag = {}
  ) {
    const { city, state, country } = userData;
    const body = {
      currency_code: 'USD',
      line_items: [
        {
          id: product.id,
          qty: 1,
          type
        }
      ],
      data: {
        city,
        state,
        country,
        aid,
        extra_tracking
      }
    };
    try {
      let billingRes;
      if (!userData.user_data?.v2) {
        const { token, fingerprint } = paymentData;
        billingRes = await billingSDK(window).Charge.byToken(
          body,
          userData.domain,
          token,
          fingerprint,
          userData.id,
          true
        );
      } else {
        billingRes = await billingV2SDK(window).Charge.byPayment(
          body,
          userData.domain,
          paymentData.id,
          userData.id,
          true
        );
      }
      return !(billingRes.type && billingRes.type.includes('error'));
    } catch (err) {
      console.error(err);
      $bugsnag?.notify(err);
      return false;
    }
  }
};

export default paymentHelper;

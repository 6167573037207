const accountHelper = {
  /**
   * Takes all address parts and updates person's account
   *
   * @param {object} opts
   * @param {object} opts.addressObj
   * @param {object} opts.travellingSdk
   * @param {*} opts.defaultValue
   * @param {object} opts.vm
   * @returns {string}
   */
  async updateAddress(opts, $bugsnag) {
    if (
      !opts ||
      !opts.addressObj ||
      !opts.travellingSdk ||
      !opts.addressObj.hasOwnProperty('street_number') ||
      typeof opts.addressObj.street_name !== 'string' ||
      typeof opts.addressObj.street_affix !== 'string' ||
      typeof opts.addressObj.street_type !== 'string' ||
      !opts.addressObj.hasOwnProperty('street_physical') ||
      !opts.addressObj.city ||
      !opts.addressObj.state ||
      !opts.addressObj.zip
    ) {
      return opts?.defaultValue ?? false;
    }
    const {
      street_number,
      street_name,
      street_type,
      street_affix,
      street_physical,
      city,
      state,
      zip
    } = opts.addressObj;
    const allReqData = [
      // zip must be updated first, to prevent issues with updating city/state on backend
      { name: 'zip', value: zip.toString() },
      { name: 'street_number', value: street_number.toString() },
      { name: 'street_name', value: street_name.toLowerCase() },
      { name: 'street_type', value: street_type.toLowerCase() },
      {
        name: 'street_affix',
        value: street_affix.length ? street_affix.toLowerCase() : ''
      },
      { name: 'street_physical', value: street_physical ? 'true' : 'false' },
      { name: 'city', value: city.toLowerCase() },
      { name: 'state', value: state.toLowerCase() }
    ];
    let updateError = false;
    // updating user's address values
    for (let i = 0; i < allReqData.length; i++) {
      const reqData = allReqData[i];
      try {
        const res = await opts.travellingSdk.UserCurrent.editProperty(
          reqData.value,
          reqData.name
        );
        if (
          (res && res.type && res.type.includes('error')) ||
          (res && res === 'Access Denied')
        ) {
          updateError = true;
          continue;
        }
      } catch (err) {
        console.error(err);
        opts.vm?.$bugsnag?.notify(err);
        updateError = true;
      }
    }
    if (updateError) {
      return opts?.defaultValue ?? false;
    }
    return opts.addressObj;
  }
};

export default accountHelper;

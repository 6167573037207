import dateHelper from '../dateHelper';

/**
 * Sets dates based off of extra data
 * @param {object} opts
 * @param {object} opts.address
 * @param {string} opts.unknownText
 * @returns {string}
 */
const setAddressDates = (opts = {}) => {
  if (!opts.unknownText) {
    opts.unknownText = 'Unknown';
  }
  if (
    !opts.address ||
    !opts.address.extra ||
    !opts.address.extra.address_record
  ) {
    return opts.unknownText;
  }

  const firstReportedRaw =
    opts.address.extra.address_record.record_first_reported;
  const lastReportedRaw =
    opts.address.extra.address_record.record_last_reported;

  const firstYear = new Date(firstReportedRaw).getFullYear() + 1;
  const lastYear = new Date(lastReportedRaw).getFullYear() + 1;

  if (firstYear === 1600 || lastYear === 1600) {
    return opts.unknownText;
  }

  const firstDate = dateHelper.formatDate(firstReportedRaw, '/');
  const lastDate = dateHelper.formatDate(lastReportedRaw, '/');

  if (firstDate === lastDate) {
    return opts.unknownText;
  }

  return `${firstDate} - ${lastDate}`;
};

export default setAddressDates;

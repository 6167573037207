/**
 * Returns the count of other locations
 *
 * @param {object} opts
 * @param {array} opts.locationList
 * @param {number} opts.displayCount
 * @param {string} opts.defaultValue
 * @returns {number}
 */

const otherLocationsCount = function(opts = {}, displayCount = 3) {
  if (!opts || !opts.locationList || !opts.locationList.length) {
    return opts?.defaultValue ?? 0;
  }

  const { locationList } = opts;

  return locationList.length > displayCount
    ? locationList.length - displayCount
    : 0;
};

export default otherLocationsCount;

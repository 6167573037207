/**
 * Get offset count
 * @param {object} opts
 * @param {number} opts.count
 * @param {object} [opts.count_offset]
 *   @param {number} [opts.count_offset.max]
 *   @param {number} [opts.count_offset.med]
 *   @param {number} [opts.count_offset.min]
 *   @param {number} [opts.count_offset.over_100] - Deprecated - Use count_offset.max instead
 *   @param {number} [opts.count_offset.over_50] - Deprecated - Use count_offset.med Instead
 *   @param {number} [opts.count_offset.over_25] - Deprecated - Use count_offset.min Instead
 * @param {array} [opts.limits]
 *   @param {number} [opts.limits.max]
 *   @param {number} [opts.limits.med]
 *   @param {number} [opts.limits.min]
 * @returns {*}
 */
const getOffsetCount = (opts = {}) => {
  if (!opts.count || typeof opts.count !== 'number') {
    return null;
  }

  const { count, count_offset, limits } = opts;

  let maxLimit = 100;
  let medLimit = 50;
  let minLimit = 25;

  if (limits) {
    maxLimit = typeof limits.max === 'number' ? limits.max : maxLimit;
    medLimit = typeof limits.med === 'number' ? limits.med : medLimit;
    minLimit = typeof limits.min === 'number' ? limits.min : minLimit;
  }

  if (!count_offset || count <= minLimit) {
    return count;
  }

  const maxOffset = count_offset.over_100 || count_offset.max || 0;
  const medOffset = count_offset.over_50 || count_offset.med || 0;
  const minOffset = count_offset.over_25 || count_offset.min || 0;

  if (count > maxLimit) {
    return count + 1 * (maxOffset || 0);
  }

  if (count > medLimit) {
    return count + 1 * (medOffset || 0);
  }

  if (count > minLimit) {
    return count + 1 * (minOffset || 0);
  }
};

export default getOffsetCount;

/**
 * getSubset - Intakes an object and array of queried data points and returns a flatten object.
 * @param {object} opts
 * @param {object} opts.object - the object from which to extract data points.
 * @param {array} opts.paths - Array containing the set of query data points.
 * @returns {object} - A flattened object with specifed data.
 */
const getSubsetData = (opts = {}) => {
  if (!opts.object || !opts.paths) {
    return opts;
  }
  const _get = (obj, path) => {
    const travel = regexp =>
      String.prototype.split
        .call(path, regexp)
        .filter(Boolean)
        .reduce(
          (res, key) => (res !== null && res !== undefined ? res[key] : res),
          obj
        );

    const result = travel(/[,[\].]+?/);
    return result;
  };
  const _fromPairs = function(arr) {
    return arr.reduce(function(accumulator, value) {
      accumulator[value[0]] = value[1];
      return accumulator;
    }, {});
  };
  return _fromPairs(
    opts.paths.map(p => {
      if (typeof p === 'string') {
        return [p.split('.').slice(-1)[0], _get(opts.object, p)];
      }
    })
  );
};

/**
 * @deprecated - does not follow opts = {} params
 *
 * getSubset - Intakes an object and array of queried data points and returns a flatten object.
 * @param {object} object - the object from which to extract data points.
 * @param {array} paths - Array containing the set of query data points.
 * @returns {object} - A flattened object with specifed data.
 */
const getSubsetDeprecated = (object, paths = []) => {
  const _get = (obj, path) => {
    const travel = regexp =>
      String.prototype.split
        .call(path, regexp)
        .filter(Boolean)
        .reduce(
          (res, key) => (res !== null && res !== undefined ? res[key] : res),
          obj
        );

    const result = travel(/[,[\].]+?/);
    return result;
  };
  const _fromPairs = function(arr) {
    return arr.reduce(function(accumulator, value) {
      accumulator[value[0]] = value[1];
      return accumulator;
    }, {});
  };
  return _fromPairs(
    paths.map(p => {
      if (typeof p === 'string') {
        return [p.split('.').slice(-1)[0], _get(object, p)];
      }
    })
  );
};

/**
 * Determines whether to use new or deprecated code based on params passed in
 *
 * @param {object} object
 * @param {array} [paths]
 */
const getSubset = (object, paths) => {
  if (paths) {
    return getSubsetDeprecated(object, paths);
  }

  return getSubsetData(object);
};

export default getSubset;

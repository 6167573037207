/**
 * Returns true if asset has extra.property.taxes[0] data
 *
 * @param {object} opts
 * @param {object} opts.asset
 * @return {boolean}
 */
const hasExtraTaxesData = opts => {
  if (
    !opts ||
    !opts.asset ||
    !opts.asset.extra ||
    !opts.asset.extra.property ||
    !opts.asset.extra.property.taxes ||
    !opts.asset.extra.property.taxes.length
  ) {
    return false;
  }
  return true;
};

export default hasExtraTaxesData;

<template>
  <div class="ic-modal-body ic-modal-body--upsell">
    <h2 class="ic-modal-body__title">{{ heading }}</h2>
    <p class="ic-modal-body__sub-title">{{ subHeading }}</p>
    <ul class="ic-modal-body__perks">
      <li v-for="perk in perks" class="ic-modal-body__perk">
        <span>Unlimited</span> {{ perk }}
      </li>
    </ul>
    <div class="ic-modal-body__form-buttons">
      <button class="ic-modal-body__form-button" v-touch:tap="handleSubmit">
        {{ submitText }}
      </button>
      <button
        class="ic-modal-body__form-button ic-modal-body__form-button--cancel"
        v-touch:tap="closeModal"
      >
        {{ cancelButtonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ic-upsell',
  props: {
    // user's Id
    userId: {
      type: String
    },
    // modal heading
    heading: {
      type: String
    },
    // text under heading
    subHeading: {
      type: String
    },
    // array of perks subscription includes
    perks: {
      type: Array
    },
    // text for submit button
    submitText: {
      type: String
    },
    // text for cancel button
    cancelButtonText: {
      type: String
    }
  },
  methods: {
    /**
     * handle modal submission -> navigates to checkout
     * @param type
     * @returns {*}
     */
    handleSubmit() {
      return (location.href = `${window.location.origin}/dashboard/reactivate/`);
    },
    /**
     * Closes modal
     */
    closeModal() {
      this.$emit('ic-account-modal-close');
    }
  }
};
</script>

/**
 * Get limited array of random items from large array
 * @param {object} opts
 * @param opts.stuff
 * @param {number} opts.limit
 * @returns {*[]}
 */
const getRandomItems = (opts = {}) => {
  if (!opts || !opts.stuff || !opts.limit) {
    return null;
  }
  const { stuff, limit } = opts;
  // Randomly select limited crimes
  const items = [];
  for (let i = 0; i < stuff.length && i < limit; i++) {
    const item = stuff[Math.floor(Math.random() * stuff.length)];

    if (items.includes(item)) {
      // Count down if item already exists
      // and dont add to list
      i--;
    } else {
      // Add item to list
      items.push(item);
    }
  }
  return items;
};

export default getRandomItems;

import getRegex from '@/assets/js/shared/helpers/getRegex';
import parseParams from '@/assets/js/shared/helpers/parseParams';
import getStates from '@/assets/js/shared/helpers/getStatesList';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';

/**
 * Information-Specific Results Redirect Middleware
 * @param route
 * @param redirect
 * @returns {*}
 */

export default function({ route, redirect }) {
  const { fullPath, query, params } = route;
  const queryParams = Object.keys(query);
  const hasValidPage = params.page && params.page <= 10;
  const hasValidQueryParams = queryParams.length;
  let stateParam = params.state ?? null;
  let cityParam = params.city ?? null;
  const firstName = parseParams({ word: params.firstName })
    .replace(getRegex({ regexType: 'spaceRegEx' }), '_')
    .replace(getRegex({ regexType: 'dashRegEx' }), '')
    .replace(getRegex({ regexType: 'specialCharacters' }), '')
    .toLowerCase();

  const lastName = parseParams({ word: params.lastName })
    .replace(getRegex({ regexType: 'spaceRegEx' }), '_')
    .replace(getRegex({ regexType: 'specialCharacters' }), '')
    .toLowerCase();

  let redirectUrl = `/people/${firstName}-${lastName}/`;

  switch (route.name) {
    case 'seo.state':
    case 'seo.city':
      stateParam = stateParam
        .replace(getRegex({ regexType: 'spaceRegEx' }), '-')
        .toLowerCase();
      const stateList = Object.values(
        getStates({ hasTerritories: true })
      ).map(state => state.replace(/ /g, '-').toLowerCase());
      const hasValidState = stateParam && !!stateList.includes(stateParam);
      if (hasValidState) {
        redirectUrl = redirectUrl + stateParam + '/';
      }
      const hasValidCity = hasValidState && params.city;
      if (hasValidCity) {
        const formattedCity = cityParam
          .replace(getRegex({ regexType: 'spaceRegEx' }), '-')
          .toLowerCase();
        redirectUrl = redirectUrl + `${formattedCity}/`;
      }
      break;

    case 'seo.address':
      const redirectStreet = setTitleCase({
        text: params.street.replace('-', ' ')
      }).replace(' ', '-');
      const redirectCity = setTitleCase({
        text: cityParam.replace(getRegex({ regexType: 'underscoreRegEx' }), ' ')
      }).replace(getRegex({ regexType: 'spaceRegEx' }), '_');
      const redirectState = stateParam.toUpperCase();

      redirectUrl = `/reverse-address-lookup/${redirectStreet}--${redirectCity}-${redirectState}/`;
      if (fullPath[fullPath.length - 1] !== '/') {
        return redirect(301, redirectUrl);
      }
      break;

    case 'seo.phone':
      return redirect(301, `/reverse-phone-lookup/${params.phone}/`);
  }

  if (hasValidPage) {
    redirectUrl = redirectUrl + params.page + '/';
  }

  if (hasValidQueryParams) {
    const queryString = queryParams
      .map(key => key + '=' + query[key])
      .join('&');
    redirectUrl = redirectUrl + '?' + queryString;
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }
}

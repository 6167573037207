const NBars = require('nbars/commonjs.js');
import calculateDisplayMax from '@/assets/js/shared/helpers/calculateDisplayMax';
import chunkArray from '@/assets/js/shared/helpers/chunkArray';
import dashedPhoneNumber from '@/assets/js/shared/helpers/dashedPhoneNumber';
import encodeCID from '@/assets/js/shared/helpers/encodeCID';
import formatAddressWithoutStreet from '@/assets/js/shared/helpers/formatAddressWithoutStreet';
import formatCity from '@/assets/js/shared/helpers/formatCity';
import formatFullName from '@/assets/js/shared/helpers/formatFullName';
import formatNameCaseWithSplitterAndSpacer from '@/assets/js/shared/helpers/formatNameCaseWithSplitterAndSpacer';
import formatPersonListAddresses from '@/assets/js/helpers/formatPersonListAddresses';
import formatPersonListEmails from '@/assets/js/shared/helpers/formatPersonListEmails';
import formatPhoneNumber from '@/assets/js/shared/helpers/formatPhoneNumber';
import formattedLocations from '@/assets/js/shared/helpers/formattedLocations';
import getAllPagePeopleDataSums from '@/assets/js/shared/helpers/getAllPagePeopleDataSums';
import getCurrentResident from '@/assets/js/shared/helpers/getCurrentResident';
import getMaskingString from '@/assets/js/shared/helpers/getMaskingString';
import getOffsetCount from '@/assets/js/shared/helpers/getOffsetCount';
import getOffsetCountMap from '@/assets/js/shared/helpers/getOffsetCountMap';
import getRandomRelatives from '@/assets/js/shared/helpers/getRandomRelatives';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import getResultNames from '@/assets/js/shared/helpers/getResultNames';
import getSelectedLocationArray from '@/assets/js/shared/helpers/getSelectedLocationArray';
import getSEOMetaTitleText from '@/assets/js/shared/helpers/getSEOMetaTitleText';
import getStateAbbrFromName from '@/assets/js/shared/helpers/getStateAbbrFromName';
import getStateCount from '@/assets/js/shared/helpers/getStateCount';
import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';
import isMobile from '@/assets/js/shared/helpers/isMobile';
import otherLocationsCount from '@/assets/js/shared/helpers/otherLocationsCount';
import parseParams from '@/assets/js/shared/helpers/parseParams';
import queryWithSearchSDK from '@/assets/js/shared/helpers/queryWithSearchSDK';
import setApiLimit from '@/assets/js/shared/helpers/setApiLimit';
import setLandingUrl from '@/assets/js/shared/helpers/setLandingURL';
import setPeopleAges from '@/assets/js/shared/helpers/setPeopleAges';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import unlinkRelatives from '@/assets/js/shared/helpers/unlinkRelatives';

// @deprecated - Will want to remove the following helpers import entirely once we know nothing is needed
import helpers from '@/assets/js/shared/resultHelper';

const spaceRegEx = getRegex({ regexType: 'spaceRegEx' });
const dashRegEx = getRegex({ regexType: 'dashRegEx' });
const PAGE_LIMIT = 25;
const supportedFlows = ['city', 'state', 'root', 'phone', 'address'];
const localPeopleFullFilters =
  'id,cid,city,state,firstname,lastname,middlename,dob,aliases,phones,relatives,zip,street_name,street_type,street_number,street_affix,street_physical,addresses,crimes,optout,employers,emails,has_emails,has_employers,has_phones,has_relatives,has_social_media,social_media';
const countOffsets = {
  max: -10,
  med: -5,
  min: -2
};
const offsetLimits = {
  max: 100,
  med: 50,
  min: 27
};

let pageCms = {};
try {
  pageCms = require(`@/assets/cms/pages/results.json`);
} catch (err) {
  // Do nothing
}

function getSeparatorData() {
  // Get symbols from the person card
  return {
    address: pageCms.person_card.separator_address,
    general: pageCms.person_card.separator_symbol,
    masking: pageCms.person_card.masking_symbol
  };
}
const symbols = getSeparatorData();

/**
 * Override totalPeople if less than 25
 * @param totalPeople
 * @param totalPeopleItems
 * @returns {*}
 */
const adjustCount = (totalPeople, totalPeopleItems) => {
  if (totalPeopleItems <= PAGE_LIMIT) {
    return totalPeopleItems;
  }
  return totalPeople;
};

/**
 * Sets total records found count and plurality for records and match text on generatedPayload
 *
 * @param {string} flowType
 * @param {object} generatedPayload
 */
const appendPeopleCount = (flowType, generatedPayload) => {
  if (
    (flowType === 'city' && !generatedPayload.searchFilterCityList.length) ||
    (flowType === 'state' && !generatedPayload.searchFilterStateList.length)
  ) {
    return;
  }

  const totalAmount =
    flowType === 'city'
      ? generatedPayload.searchFilterCityList[1].count
      : generatedPayload.searchFilterStateList[1].count;
  // Set total records found count
  generatedPayload.totalPeople = totalAmount;
  // Set plurality for records and matches
  generatedPayload.recordPlural = totalAmount === 1 ? 'record' : 'records';
  generatedPayload.matchPlural = totalAmount === 1 ? 'match' : 'matches';
};

/**
 *
 * @param {array} dataSet
 * @param {object} options
 * @returns {*[]}
 */
const prepareMobileFilterDropdowns = (dataSet, options) => {
  if (!Array.isArray(dataSet) || dataSet.length < 1) {
    return [];
  }
  const { filterType, urlBase } = options;
  const isState = filterType === 'state';
  const filteredDataSet = isState
    ? dataSet.filter(item => item.state_full)
    : dataSet;

  const builtDataSet = [];
  filteredDataSet.forEach(item => {
    if (item.state === '' || item.city === '') {
      return null;
    }
    const stateParam =
      getStateFromAbbr({ stateAbbr: item.state, includeTerritories: true }) ||
      '';
    builtDataSet.push({
      displayName: isState
        ? formatNameCaseWithSplitterAndSpacer({ name: item.state_full })
        : formatNameCaseWithSplitterAndSpacer({ name: item.city }),
      count: item.count,
      urlValue: isState
        ? `/${stateParam.replace(/ /g, '-').toLowerCase()}/`
        : `${urlBase}/${item.city.replace(/ /g, '-').toLowerCase()}/`
    });
  });

  return builtDataSet;
};

/**
 * Adds searchFilterSelectedState to generatedPayloadwith optional hash
 *
 * @param {string} firstName
 * @param {string} lastName
 * @param {object} generatedPayload
 * @param {boolean|null} addHash
 * @returns {null}
 */
const appendSearchFilter = (firstName, lastName, generatedPayload, addHash) => {
  if (!generatedPayload.searchFilterStateList.length) {
    return null;
  }
  generatedPayload.searchFilterSelectedState = `/people/${firstName.toLowerCase()}-${lastName.toLowerCase()}${generatedPayload.searchFilterStateList[1].urlValue.toLowerCase()}${
    addHash ? '#' : ''
  }`;
};

/**
 * Sets the location list for root level results
 * @param peopleResponse
 * @param locationList
 * @returns {{locationList, searchFilterStateList}}
 */
const setInformationLocationList = (peopleResponse, locationList) => {
  let stateList = [];
  let searchFilterStateList = [];
  let stateCount = {};

  const formattedLocationList =
    locationList && typeof locationList === 'object' ? locationList : [];

  const formattedPeopleResponse =
    peopleResponse && Array.isArray(peopleResponse) ? peopleResponse : [];

  for (let i = 0; i < formattedPeopleResponse.length; i++) {
    const state = formattedPeopleResponse[i].state;
    if (!stateList.includes(state)) {
      stateList.push(state);
      stateCount[state] = 1;
    } else {
      stateCount[state] += 1;
    }
  }

  for (let i = 0; i < formattedLocationList.length; i++) {
    if (!formattedLocationList[i].state_full) {
      continue;
    }
    const stateParam = getStateFromAbbr({
      stateAbbr: formattedLocationList[i].state,
      includeTerritories: true
    }).replace(/ /g, '-');
    formattedLocationList[i]['name'] = formattedLocationList[i].state_full;

    const urlValue =
      process.env.website === 'information' ||
      process.env.website === 'template'
        ? `/${stateParam.toLowerCase()}/`
        : `${formattedLocationList[i].state}/`;

    let count = !formattedLocationList[i].offset
      ? getOffsetCount({
          count: formattedLocationList[i].count,
          count_offset: countOffsets,
          limits: offsetLimits
        })
      : formattedLocationList[i].count;

    searchFilterStateList.push({
      displayName: formattedLocationList[i].state_full,
      urlValue,
      count,
      offset: true
    });
  }

  return {
    locationList: formattedLocationList,
    searchFilterStateList
  };
};

const resultHelper = {
  ...helpers,
  /**
   * buildPayload - Creates a payload from a base and returns the build out object
   * @param {object} routeParams - route parameters passed in from view
   * @param {object} payloadBase - a baseline for building final payload, contains default values
   * @param {object} searchMethods - passed in query methods from the searchSDK
   * @param {string} userAgent - the browser/os of the entity requesting data
   * @param {string} flowType - tells the payload builder what path to take
   * @param {object} $bugsnag - bugsnag instance
   * @returns {Promise<{}|*>} - a build out payload based on the flowType
   */
  buildPayload: async function(
    routeParams,
    payloadBase,
    searchMethods,
    userAgent,
    flowType,
    $bugsnag = {}
  ) {
    // Return nothing if this is not an SEO page
    if (!supportedFlows.includes(flowType)) {
      return {};
    }
    const { LocalNames, LocalPeopleFull } = searchMethods;
    const page = +routeParams.page || 1;
    const generatedPayload = { ...payloadBase };
    const isPhoneOrAddress = routeParams.phone || routeParams.street;

    let firstName = '';
    let lastName = '';
    let firstNameRequestFormat = '';
    let lastNameRequestFormat = '';
    let stateAbbr = null;
    let stateShort = null;
    let cityQuery = null;

    if (!isPhoneOrAddress) {
      // Names are altered by middleware upon hitting page, and are safe to submit to getResultNames
      firstName = getResultNames({ params: routeParams }).firstName;
      lastName = getResultNames({ params: routeParams }).lastName;
      firstNameRequestFormat = getResultNames({ params: routeParams })
        .firstNameRequestFormat;
      lastNameRequestFormat = getResultNames({ params: routeParams })
        .lastNameRequestFormat;
    }

    const nameParams = {
      lastName: lastNameRequestFormat,
      firstName: firstNameRequestFormat
    };
    const localNameParams = nameParams;

    const { state, city, phone } = routeParams;

    // If state exists in params, set stateAbbr, stateShort, and cityQuery
    if (state) {
      stateAbbr = getStateAbbrFromName({
        state: state.replace(/[-_]/g, ' ')
      });
      stateShort = stateAbbr.toUpperCase();
      cityQuery = {
        ...localNameParams,
        state: stateShort
      };
    }

    // Go through each SEO Flow
    switch (flowType) {
      case 'city': {
        const cityName = formatNameCaseWithSplitterAndSpacer({
          name: city.replace(/-/g, ' ')
        });
        const cityNameUpper = cityName.toUpperCase().replace(/ /g, '%20');

        // Build city location List
        try {
          generatedPayload.cityLocationList = await queryWithSearchSDK({
            queryParams: cityQuery,
            searchMethod: LocalNames.countByCity,
            defaultReturnValue: []
          });

          generatedPayload.cityLocationList = this.updateSeoResultCounts(
            generatedPayload.cityLocationList
          );
        } catch (err) {
          generatedPayload.cityLocationList = [];
          console.error('BuildPayload: City - CityLocationList', err);
          $bugsnag?.notify(err);
        }

        // Build All State List for display in mobile dropdown
        try {
          generatedPayload.allStateList = await queryWithSearchSDK({
            queryParams: localNameParams,
            searchMethod: LocalNames.countByState,
            defaultReturnValue: []
          });

          generatedPayload.allStateList = this.updateSeoResultCounts(
            generatedPayload.allStateList
          );
        } catch (err) {
          generatedPayload.allStateList = [];
          console.error('BuildPayload: City - AllStateList', err);
          $bugsnag?.notify(err);
        }

        // Build location list for display in overview, city count, meta data
        // Build searchFilterCityList for display in desktop sidebar
        try {
          generatedPayload.locationList = [];
          generatedPayload.searchFilterCityList = [];

          if (generatedPayload.cityLocationList.length > 0) {
            // filter the city list
            const cityCount = generatedPayload.cityLocationList.filter(
              countItem =>
                countItem.city.toLowerCase() === cityName.toLowerCase()
            )[0];

            const cityCountValue = cityCount ? cityCount.count : 0;
            generatedPayload.locationList.push({
              name: `${cityName}, ${getStateFromAbbr({
                stateAbbr: stateShort,
                includeTerritories: true
              })} - ${stateShort}`
            });

            generatedPayload.searchFilterCityList = getSelectedLocationArray({
              allText: 'All Cities',
              displayName: cityName,
              count: cityCountValue,
              url: `${state.toLowerCase()}/${cityName.toLowerCase()}/`,
              allUrl: `${state}/`
            });
          }

          generatedPayload.searchFilterCityLocationPrepend = '';
          generatedPayload.searchFilterStateList = [];

          if (generatedPayload.searchFilterCityList.length > 0) {
            // Set preselected location in search filter
            generatedPayload.searchFilterSelectedCity = `/people/${firstName.toLowerCase()}-${lastName.toLowerCase()}/${generatedPayload.searchFilterCityList[1].urlValue
              .replace(/ /g, '-')
              .toLowerCase()}`;
            // Set desktop state list for sidebar
            generatedPayload.searchFilterStateList = getSelectedLocationArray({
              allText: 'All States',
              displayName: getStateFromAbbr({
                stateAbbr: stateShort,
                includeTerritories: true
              }),
              count: null, // Do not display number for state on city level
              url: `/${state.toLowerCase()}/`,
              allUrl: `/`
            });

            appendSearchFilter(firstName, lastName, generatedPayload);
            appendPeopleCount(flowType, generatedPayload);
          }
        } catch (err) {
          console.error('BuildPayload: City', err);
          $bugsnag?.notify(err);
        }

        // Get and set limit
        try {
          // Limit API Call to only what is needed on page if we have more than 250 results, otherwise set to 250
          generatedPayload.limit = setApiLimit({
            currentPayload: generatedPayload,
            limit: 250,
            page: page,
            type: 'results'
          });
        } catch (err) {
          generatedPayload.limit = 0;
          console.error('BuildPayload: City - Limit', err);
          $bugsnag?.notify(err);
        }

        // Get peopleResponse, set redirect if no items returned
        try {
          const cityPeopleQuery = {
            lastName: lastNameRequestFormat,
            firstName: firstNameRequestFormat,
            state: stateShort,
            city: cityNameUpper,
            limit: generatedPayload.limit,
            filter: localPeopleFullFilters
          };

          // Get Page Results
          generatedPayload.peopleResponse = await queryWithSearchSDK({
            queryParams: cityPeopleQuery,
            searchMethod: LocalPeopleFull.byLocation,
            defaultReturnValue: []
          });

          if (generatedPayload.peopleResponse.length === 0) {
            // bailout if no results
            generatedPayload.redirect = true;
            return generatedPayload;
          }
        } catch (err) {
          generatedPayload.peopleResponse = [];
          console.error('BuildPayload: City - Limit', err);
          $bugsnag?.notify(err);
        }

        // Set totalPeople and totalPeopleItems
        try {
          // Total is amount of people possible with cap of 250
          generatedPayload.totalPeopleItems = setApiLimit({
            currentPayload: generatedPayload,
            limit: 250,
            page: null,
            type: 'peopleItems'
          });

          generatedPayload.totalPeople = adjustCount(
            generatedPayload.totalPeople,
            generatedPayload.totalPeopleItems
          );

          if (!generatedPayload.totalPeople) {
            generatedPayload.totalPeople = '';
          }
        } catch (err) {
          generatedPayload.totalPeopleItems = 0;
          generatedPayload.totalPeople = '';
          console.error('BuildPayload: City - TotalPeopleItems', err);
          $bugsnag?.notify(err);
        }

        const cityOptions = {
          filterType: 'city',
          urlBase: `${state.toLowerCase()}`,
          stateShort: stateShort
        };
        const stateOptions = {
          filterType: 'state'
        };
        const dataLists = {
          cityLocationList: generatedPayload.cityLocationList,
          locationList: generatedPayload.locationList,
          searchFilterCityList: generatedPayload.searchFilterCityList,
          searchFilterStateList: generatedPayload.searchFilterStateList,
          allCityList: prepareMobileFilterDropdowns(
            generatedPayload.cityLocationList,
            cityOptions
          ),
          allStateList: prepareMobileFilterDropdowns(
            generatedPayload.allStateList,
            stateOptions
          )
        };
        const personNames = {
          firstName,
          lastName,
          firstNameRequestFormat,
          lastNameRequestFormat,
          stateShort,
          cityName
        };

        // Send all data to formatter
        return this.formatPayload(
          generatedPayload,
          dataLists,
          personNames,
          page,
          userAgent,
          flowType
        );
      }
      case 'state': {
        const cityList = [];
        const cityCount = {};

        // Build state location list for display on Desktop
        try {
          generatedPayload.stateLocationList = await queryWithSearchSDK({
            queryParams: nameParams,
            searchMethod: LocalNames.countByState,
            defaultReturnValue: []
          });

          generatedPayload.stateLocationList = this.updateSeoResultCounts(
            generatedPayload.stateLocationList
          );
        } catch (err) {
          generatedPayload.stateLocationList = [];
          console.error('BuildPayload: State - StateLocationList', err);
          $bugsnag?.notify(err);
        }

        // Build city location list for display on Desktop
        try {
          generatedPayload.cityLocationList = await queryWithSearchSDK({
            queryParams: cityQuery,
            searchMethod: LocalNames.countByCity,
            defaultReturnValue: []
          });

          generatedPayload.cityLocationList = this.updateSeoResultCounts(
            generatedPayload.cityLocationList
          );
        } catch (err) {
          generatedPayload.cityLocationList = [];
          console.error('BuildPayload: State - CityLocationList', err);
          $bugsnag?.notify(err);
        }

        // Set locationList to cityLocationList, set state list for mobile
        try {
          const stateCount = getStateCount({
            stateList: generatedPayload.stateLocationList,
            stateAbbr: stateShort
          });
          const stateCountName = stateCount
            ? stateCount.state_full
            : getStateAbbrFromName(state);

          const stateCountValue = stateCount ? stateCount.count : 0;

          generatedPayload.locationList = generatedPayload.cityLocationList;

          generatedPayload.searchFilterStateList = getSelectedLocationArray({
            allText: 'All States',
            displayName: stateCountName,
            count: stateCountValue,
            url: `/${state.toLowerCase()}/`,
            allUrl: '/'
          });

          if (generatedPayload.locationList.length !== 0) {
            // set preselected state
            appendSearchFilter(firstName, lastName, generatedPayload);
            appendPeopleCount(flowType, generatedPayload);
          }
        } catch (err) {
          console.error('BuildPayload: State', err);
          $bugsnag?.notify(err);
        }

        // Set Limit
        try {
          // Limit API Call to only what is needed on page if we have more than 250 results, otherwise set to 250
          generatedPayload.limit = setApiLimit({
            currentPayload: generatedPayload,
            limit: 250,
            page: page,
            type: 'results'
          });
        } catch (err) {
          generatedPayload.limit = 0;
          console.error('BuildPayload: State - Limit', err);
          $bugsnag?.notify(err);
        }

        // Get People Response
        try {
          const statePeopleQuery = {
            lastName: lastNameRequestFormat,
            firstName: firstNameRequestFormat,
            state: stateShort,
            limit: generatedPayload.limit,
            filter: localPeopleFullFilters
          };
          generatedPayload.peopleResponse = await queryWithSearchSDK({
            queryParams: statePeopleQuery,
            searchMethod: LocalPeopleFull.byLocation,
            defaultReturnValue: []
          });
        } catch (err) {
          generatedPayload.peopleResponse = [];
          console.error('BuildPayload: State - PeopleResponse', err);
          $bugsnag?.notify(err);
        }

        // Set totalPeopleItems
        try {
          // Total is amount of people possible with cap of 250
          generatedPayload.totalPeopleItems = setApiLimit({
            currentPayload: generatedPayload,
            limit: 250,
            page: null,
            type: 'peopleItems'
          });
        } catch (err) {
          generatedPayload.totalPeopleItems = 0;
          console.error('BuildPayload: State - TotalPeopleItems', err);
          $bugsnag?.notify(err);
        }

        // Set totalPeople, format cities in peopleResponse with totals, set city list for mobile dropdown
        try {
          generatedPayload.totalPeople = adjustCount(
            generatedPayload.totalPeople,
            generatedPayload.totalPeopleItems
          );

          // Format all cities in response and add to total city count
          generatedPayload.peopleResponse.forEach(person => {
            const city = formatNameCaseWithSplitterAndSpacer({
              name: person.city
            });
            if (!cityList.includes(city)) {
              cityList.push(city);
              cityCount[city] = 1;
            } else {
              cityCount[city] += 1;
            }
          });

          generatedPayload.searchFilterCityList = [];
          // Add cities to dropdowns for mobile
          for (let i = 0; i < generatedPayload.cityLocationList.length; i++) {
            generatedPayload.locationList[i][
              'name'
            ] = formatNameCaseWithSplitterAndSpacer({
              name: generatedPayload.cityLocationList[i].city
            });
            generatedPayload.searchFilterCityList.push({
              displayName: formatNameCaseWithSplitterAndSpacer({
                name: generatedPayload.cityLocationList[i].city
              }),
              urlValue: `${state.toLowerCase()}/${generatedPayload.cityLocationList[
                i
              ].city
                .replace(/ /g, '-')
                .toLowerCase()}/`,
              count: generatedPayload.cityLocationList[i].count
            });
          }
        } catch (err) {
          console.error('BuildPayload: State', err);
          $bugsnag?.notify(err);
        }

        const personNames = {
          firstName,
          lastName,
          firstNameRequestFormat,
          lastNameRequestFormat,
          stateShort
        };
        const stateParam = getStateFromAbbr({
          stateAbbr: stateShort,
          includeTerritories: true
        });
        const cityOptions = {
          filterType: 'city',
          urlBase: stateParam.replace(/ /g, '-').toLowerCase(),
          stateShort: stateParam.replace(/ /g, '-').toLowerCase()
        };
        const stateOptions = {
          filterType: 'state'
        };
        const dataLists = {
          locationList: generatedPayload.locationList,
          searchFilterCityList: generatedPayload.searchFilterCityList,
          cityLocationList: generatedPayload.cityLocationList,
          searchFilterStateList: generatedPayload.searchFilterStateList,
          allCityList: prepareMobileFilterDropdowns(
            generatedPayload.cityLocationList,
            cityOptions
          ),
          allStateList: prepareMobileFilterDropdowns(
            generatedPayload.stateLocationList,
            stateOptions
          )
        };
        return this.formatPayload(
          generatedPayload,
          dataLists,
          personNames,
          page,
          userAgent,
          flowType
        );
      }
      case 'root': {
        // Build location List
        try {
          generatedPayload.locationList = await queryWithSearchSDK({
            queryParams: localNameParams,
            searchMethod: LocalNames.countByState,
            defaultReturnValue: []
          });

          generatedPayload.locationList = this.updateSeoResultCounts(
            generatedPayload.locationList
          );
        } catch (err) {
          generatedPayload.locationList = [];
          console.error('BuildPayload: Root - LocationList', err);
          $bugsnag?.notify(err);
        }

        // Set totalPeople
        try {
          generatedPayload.totalPeople = await queryWithSearchSDK({
            queryParams: localNameParams,
            searchMethod: LocalNames.namesCount,
            defaultReturnValue: 0
          });

          const updateCountValue = Array.isArray(generatedPayload.totalPeople)
            ? generatedPayload.totalPeople[0]
            : generatedPayload.totalPeople;
          generatedPayload.totalPeople = getOffsetCount({
            count: updateCountValue,
            count_offset: countOffsets,
            limits: offsetLimits
          });

          if (!generatedPayload.totalPeople) {
            generatedPayload.totalPeople = '';
          }
        } catch (err) {
          generatedPayload.totalPeople = '';
          console.error('BuildPayload: Root - TotalPeople', err);
          $bugsnag?.notify(err);
        }

        const pluralPeople = generatedPayload.totalPeople === 1;
        generatedPayload.recordPlural = pluralPeople ? 'record' : 'records';

        // Set Limit
        try {
          // Limit API Call to only what is needed on page if we have more than 250 results, otherwise set to 250
          generatedPayload.limit = setApiLimit({
            currentPayload: generatedPayload,
            limit: 250,
            page: page,
            type: 'results'
          });
        } catch (err) {
          generatedPayload.limit = 0;
          console.error('BuildPayload: Root - Llimit', err);
          $bugsnag?.notify(err);
        }

        const peopleFullData = {
          firstName,
          lastName,
          limit: generatedPayload.limit,
          filter: localPeopleFullFilters
        };

        // Set People Response
        try {
          generatedPayload.peopleResponse = await queryWithSearchSDK({
            queryParams: peopleFullData,
            searchMethod: LocalPeopleFull.byName,
            defaultReturnValue: []
          });
        } catch (err) {
          generatedPayload.peopleResponse = [];
          console.error('BuildPayload: Root - PeopleResponse', err);
          $bugsnag?.notify(err);
        }

        // Set Total People Items
        try {
          // Total is amount of people possible with cap of 250
          generatedPayload.totalPeopleItems = setApiLimit({
            currentPayload: generatedPayload,
            limit: 250,
            page: null,
            type: 'peopleItems'
          });
        } catch (err) {
          generatedPayload.totalPeopleItems = 0;
          console.error('BuildPayload: Root - TotalPeopleItems', err);
          $bugsnag?.notify(err);
        }

        generatedPayload.totalPeople = adjustCount(
          generatedPayload.totalPeople,
          generatedPayload.totalPeopleItems
        );

        const {
          locationList,
          searchFilterStateList
        } = setInformationLocationList(
          generatedPayload.peopleResponse,
          generatedPayload.locationList
        );

        const dataLists = {
          locationList,
          searchFilterStateList,
          allStateList: searchFilterStateList
        };
        const personNames = {
          firstName,
          lastName,
          firstNameRequestFormat,
          lastNameRequestFormat
        };

        return this.formatPayload(
          generatedPayload,
          dataLists,
          personNames,
          page,
          userAgent,
          flowType
        );
      }
      case 'phone': {
        const phoneQuery = {
          phone: phone.replace(/-/g, ''),
          limit: 250
          // filter: localPeopleFullFilters  // TODO: Apply filter once endpoint has been updated to accepted it
        };

        // Get People Response, set redirect if no items returned
        try {
          generatedPayload.peopleResponse = await queryWithSearchSDK({
            queryParams: phoneQuery,
            searchMethod: LocalPeopleFull.byPhone,
            defaultReturnValue: []
          });
          if (
            generatedPayload.peopleResponse.type &&
            generatedPayload.peopleResponse.type.includes('error')
          ) {
            generatedPayload.redirect = true;
            return generatedPayload;
          }
        } catch (err) {
          generatedPayload.peopleResponse = [];
          console.error('BuildPayload: Phone - PeopleResponse', err);
          $bugsnag?.notify(err);
        }

        if (generatedPayload.peopleResponse.length === 0) {
          // redirect at the view layer passing flag for quick exit
          generatedPayload.redirect = true;
          return generatedPayload;
        }

        // Set TotalPeopleItems
        try {
          // Total is amount of people possible with cap of 250
          generatedPayload.totalPeopleItems = setApiLimit({
            currentPayload: generatedPayload,
            limit: 250,
            page: null,
            type: 'peopleItems'
          });
        } catch (err) {
          generatedPayload.totalPeopleItems = 0;
          console.error('BuildPayload: Phone - TotalPeopleItems', err);
          $bugsnag?.notify(err);
        }

        generatedPayload.totalPeople = getOffsetCount({
          count: generatedPayload.peopleResponse.length
        });

        generatedPayload.limit =
          generatedPayload.totalPeople >= 250 ? PAGE_LIMIT * page : 250;

        if (!generatedPayload.totalPeople) {
          generatedPayload.totalPeople = '';
        }

        generatedPayload.phone = phone
          ? formatPhoneNumber({ phoneNumber: phone })
          : '';

        return this.formatPayload(
          generatedPayload,
          null,
          null,
          page,
          userAgent,
          flowType
        );
      }
      case 'address': {
        let streetNum;
        let streetName;
        let street;
        // Split street and title case
        try {
          // To avoid getting a 404, replace # with |
          street = setTitleCase({
            text: routeParams.street.replace(/-/g, ' ').replace('|', '#')
          });
          const streetArr = routeParams.street.split('-');
          if (
            streetArr[0].toUpperCase().includes('PO') ||
            streetArr[0].toUpperCase().includes('P/O')
          ) {
            streetNum = streetArr[streetArr.length - 1];
            streetArr.splice(streetArr.length - 1, 1);
          } else {
            streetNum = streetArr[0];
            streetArr.splice(0, 1);
          }
          streetName = streetArr.join(' ');
        } catch (err) {
          console.error('BuildPayload: Address - Split Street', err);
          $bugsnag?.notify(err);
          generatedPayload.redirect = true;
          return generatedPayload;
        }

        const addressQuery = {
          street: encodeURIComponent(street),
          city: formatCity({ city: city }),
          state: state.toUpperCase(),
          filter: localPeopleFullFilters,
          limit: 250
        };

        // Get People Response
        try {
          generatedPayload.peopleResponse = await queryWithSearchSDK({
            queryParams: addressQuery,
            searchMethod: LocalPeopleFull.byStreet,
            defaultReturnValue: []
          });
          if (
            generatedPayload.peopleResponse.type &&
            generatedPayload.peopleResponse.type.includes('error')
          ) {
            generatedPayload.redirect = true;
          }
        } catch (err) {
          generatedPayload.peopleResponse = [];
          console.error('BuildPayload: Address - PeopleResponse', err);
          $bugsnag?.notify(err);
        }

        if (
          !Array.isArray(generatedPayload.peopleResponse) ||
          generatedPayload.peopleResponse.length === 0
        ) {
          // redirect at the view layer passing flag for quick exit
          generatedPayload.redirect = true;
          return generatedPayload;
        }

        // Set TotalPeople Items
        try {
          // Total is amount of people possible with cap of 250
          generatedPayload.totalPeopleItems = setApiLimit({
            currentPayload: generatedPayload,
            limit: 250,
            page: null,
            type: 'peopleItems'
          });
        } catch (err) {
          generatedPayload.totalPeopleItems = 0;
          console.error('BuildPayload: Address - TotalPeopleItems', err);
          $bugsnag?.notify(err);
        }

        generatedPayload.totalPeople = getOffsetCount({
          count: generatedPayload.peopleResponse.length
        });

        generatedPayload.limit =
          generatedPayload.totalPeople >= 250 ? PAGE_LIMIT * page : 250;

        if (!generatedPayload.totalPeople) {
          generatedPayload.totalPeople = '';
        }

        // Already ensured generatedPayload.peopleResponse is an array by this point
        generatedPayload.currentResident = generatedPayload.peopleResponse[0]
          ? getCurrentResident(
              generatedPayload.peopleResponse[0],
              state,
              city.toUpperCase(),
              streetName.toUpperCase(),
              streetNum
            )
          : null;

        generatedPayload.mapboxToken = process.env.MAP_BOX_TOKEN;

        return this.formatPayload(
          generatedPayload,
          null,
          null,
          page,
          userAgent,
          flowType
        );
      }
      default:
        return {};
    }
  },
  /**
   * formatPayload - Shapes and formats api data into what the view layer is looking for
   * @param {object} payload - raw query data in an object
   * @param {object} lists - an object of arrays that contains iterable lists for the view
   * @param {object} names - an object containing the searched persons name in various formats
   * @param {number} page - the page number for the results
   * @param {string} userAgent - the browser/device that made the request for information
   * @param {string} flowType - what flow we are in eg. root, city, state
   * @returns {object} a payload that is formatted for use in various views
   */
  formatPayload: function(payload, lists, names, page, userAgent, flowType) {
    const formattedPayload = { ...payload };
    const firstNameRequestFormat = names?.firstNameRequestFormat || '';
    const lastNameRequestFormat = names?.lastNameRequestFormat || '';
    const locationList = lists?.locationList || '';

    const searchFilterStateList = lists?.searchFilterStateList || '';
    const searchFilterCityList = lists?.searchFilterCityList || '';
    const cityLocationList = lists?.cityLocationList || '';
    const allCityList = lists?.allCityList || '';
    const allStateList = lists?.allStateList || '';

    formattedPayload.locationList = locationList || [];
    formattedPayload.searchFilterStateList = searchFilterStateList || [];
    formattedPayload.searchFilterCityList = searchFilterCityList || [];
    formattedPayload.allCityList = allCityList || [];
    formattedPayload.allStateList = allStateList || [];
    formattedPayload.cityLocationList = cityLocationList || [];
    formattedPayload.peoplePages = chunkArray({
      arr: formattedPayload.peopleResponse,
      size: formattedPayload.itemsPerPage
    });

    formattedPayload.totalPages = formattedPayload.peoplePages.length;
    formattedPayload.currentPage =
      page <= formattedPayload.totalPages ? page : 1;
    formattedPayload.currentPageIndex = formattedPayload.currentPage - 1;
    formattedPayload.people =
      formattedPayload.peoplePages[formattedPayload.currentPageIndex] || [];

    formattedPayload.people = setPeopleAges({
      people: formattedPayload.people
    });

    if (flowType === 'root' || flowType === 'address') {
      formattedPayload.people = unlinkRelatives({
        people: formattedPayload.people,
        firstName: firstNameRequestFormat,
        lastName: lastNameRequestFormat
      });
    }

    formattedPayload.isMobile = isMobile({
      userAgent
    });

    formattedPayload.peopleDataSums = getAllPagePeopleDataSums({
      people: formattedPayload.people
    });

    return formattedPayload;
  },
  /**
   * Links to Checkpeople Landing Flow
   *   Used only on Profile Page
   *
   * @param {object} person
   * @param {boolean} isLanding
   * @returns {string}
   */
  getLinkToCPStepOne(person, isLanding = false) {
    const cidEncoded = isLanding ? person.cid : encodeCID({ cid: person.cid });
    const searchingParams = new URLSearchParams({
      firstName: setTitleCase({ text: person.firstname }),
      lastName: setTitleCase({ text: person.lastname }),
      state: person.state,
      city: setTitleCase({ text: person.city }),
      providerID: cidEncoded,
      aid: '26',
      sid: 'INFORMATION',
      tid: '3',
      sub1: '3',
      sub2: 'INFORMATION'
    });
    return `https://www.chkppl.com/cmp/M4MBH/GNS64/?${searchingParams}`;
  },
  /**
   * Link to the Checkpeople Everflow Link
   * @param person
   * @param query
   * @returns {string}
   */
  getEverFlowLink(person, query = {}) {
    const cid = person.cid.includes('-')
      ? encodeCID({ cid: person.cid })
      : person.cid;
    const firstNameParam = formatNameCaseWithSplitterAndSpacer({
      name: person.firstname
    });
    const lastNameParam = formatNameCaseWithSplitterAndSpacer({
      name: person.lastname
    });
    const stateParam = person.state;
    const cityParam = person.city
      ? formatNameCaseWithSplitterAndSpacer({ name: person.city }).replace(
          spaceRegEx,
          '_'
        )
      : '';

    const newQuery = { ...query };

    newQuery.sub1 = '3';
    newQuery.sub2 = 'INFORMATION';
    newQuery.sid = 'INFORMATION';
    newQuery.providerID = cid;
    newQuery.firstName = firstNameParam;
    newQuery.lastName = lastNameParam;
    newQuery.state = stateParam;
    newQuery.city = cityParam;

    const baseUrl = 'https://www.chkppl.com/cmp/M4MBH/GNS64/';
    const finalUrl = `${baseUrl}?${new URLSearchParams(newQuery)}`;

    return finalUrl;
  },
  /**
   * @deprecated - Replace with shared Helper
   * Get Profile Link
   * @param person
   * @param router
   * @returns {*}
   */
  getProfileLink(person, router) {
    if (router && person.id) {
      const { href } = router.resolve({
        name: 'seo.profile',
        params: {
          firstName: person.firstname,
          lastName: person.lastname,
          city: person.city,
          stateAbbr: person.state,
          uuid: person.id
        }
      });
      return href;
    } else if (router && !person.id) {
      return setLandingUrl({
        params: '',
        firstName: person.firstname,
        lastName: person.lastname,
        state: person.state,
        city: person.city
      });
    }

    // Create Profile URL Structure
    const firstNameParam = person.firstname.replace(/ /g, '_').toLowerCase();
    const lastNameParam = person.lastname.replace(/ /g, '_').toLowerCase();
    const root = `/people/${firstNameParam}-${lastNameParam}/`;
    const state = `${getStateFromAbbr({
      stateAbbr: person.state,
      includeTerritories: true
    })
      .replace(/ /g, '-')
      .toLowerCase()}/`;
    const city = `${person.city.replace(spaceRegEx, '-').toLowerCase()}/`;
    const profile = person.id;
    const profileLink = `${root}${state}${city}${profile}`;

    return profileLink;
  },
  /**
   * Return array of relatives as array formatted as [{name: string, url: string}]
   *
   * @param {array} relativesList
   * @param {number} displayCount
   * @returns {array|null}
   */
  formatRelativesArray: function(relativesList, displayCount) {
    // Early exit if there are no relatives
    if (!relativesList || relativesList.length === 0) {
      return null;
    }

    // Calculate total possible for display
    const totalPossible = calculateDisplayMax({
      array: relativesList,
      totalPossible: displayCount
    });

    const formattedRelatives = [];

    // Go through each relative we can display
    for (let i = 0; i < totalPossible; i++) {
      const firstName = setTitleCase({ text: relativesList[i].firstname });
      const lastName = setTitleCase({ text: relativesList[i].lastname });
      const city = relativesList[i].city?.replace(/ /g, '-').toLowerCase();
      const state = getStateFromAbbr({
        stateAbbr: relativesList[i].state,
        includeTerritories: true
      })
        ?.replace(/ /g, '-')
        ?.toLowerCase();
      const firstNameParam = firstName
        .replace(spaceRegEx, '_')
        .replace(dashRegEx, '');
      const lastNameParam = lastName.replace(spaceRegEx, '_');
      // Create object and push to array
      const relative = {
        name: `${firstName} ${lastName}`
      };

      // If a relative has an id, link them to their profile page. Else link them to the seo root page
      if (state && city && relativesList[i].id) {
        relative[
          'url'
        ] = `/people/${firstNameParam}-${lastNameParam}/${state}/${city}/${relativesList[i].id}/`;
      } else {
        relative['url'] = `/people/${firstNameParam}-${lastNameParam}/`;
      }

      formattedRelatives.push(relative);
    }

    return formattedRelatives;
  },
  /**
   * Return address as a title cased string with optional masking
   *
   * @param address
   * @param separator
   * @param shouldMaskStreet
   * @param {boolean|null} spaceBeforeSeparator
   * @returns {string}
   */
  formatAddress: function(
    address,
    separator,
    shouldMaskStreet,
    spaceBeforeSeparator = false
  ) {
    // Map over street address object to ensure title case
    let street = address.street_name
      ? [
          address.street_number,
          address.street_name,
          address.street_type,
          address.street_affix
        ]
          .map(function(item) {
            // changes street number to a string
            if (typeof item === 'number') {
              return item.toString();
            }
            return setTitleCase({ text: item });
          })
          .filter(function(item) {
            return item !== undefined;
          })
      : [];

    // If we should mask the street name
    if (shouldMaskStreet && street !== []) {
      // Street name is the first and last item of street array with masking string in between
      street = `${street[0]} ${getMaskingString({
        number: 5,
        masking: symbols
      })} ${street[street.length - 1]}`;
      // Otherwise street name is recompiled with title case
    } else {
      street = street.join(' ');
    }

    // Return the newly formatted street name and remaining address data
    return `${street ? street + ', ' : ''}${formatAddressWithoutStreet({
      address: address,
      separator: separator,
      spaceBeforeSeparator: spaceBeforeSeparator
    })}`;
  },
  /**
   * Format person list aliases
   * @param person
   * @returns {{itempropType: string, label: string, list: *[], separator: string}|*[]}
   */
  formatPersonListAliases(person) {
    if (!(Array.isArray(person.aliases) && person.aliases.length)) {
      return null;
    }

    const aliases = [];

    for (
      let i = 0;
      i < person.aliases.slice(0, pageCms.person_card.count_alias).length;
      i++
    ) {
      aliases.push(setTitleCase({ text: person.aliases[i] }));
    }

    return {
      label: 'Also goes by: ',
      className: 'person__info--alias',
      itempropType: 'alternateName',
      separator: ', ',
      list: aliases
    };
  },
  /**
   * Format person list phones
   * @param person
   * @returns {{itempropType: string, label: string, list: *[], separator: string}|null}
   */
  formatPersonListPhones(person) {
    if (!(Array.isArray(person.phones) && person.phones.length)) {
      return null;
    }

    const phones = [];

    for (
      let i = 0;
      i < person.phones.slice(0, pageCms.person_card.count_phone).length;
      i++
    ) {
      const phone = person.phones[i];
      const url = phone.unlink
        ? false
        : `/reverse-phone-lookup/${dashedPhoneNumber({
            phoneNumber: phone.full
          })}/`;
      phones.push({
        label: formatPhoneNumber({ phoneNumber: phone.full }),
        url
      });
    }

    return {
      label: 'Phone Numbers: ',
      className: 'person__info--phone',
      itempropType: 'telephone',
      separator: ', ',
      list: phones
    };
  },
  /**
   * Format person list relatives
   * @param {object} person
   * @returns {{itempropType: string, label: string, list: *[], separator: string}|null}
   */
  formatPersonListRelatives(person) {
    if (!(Array.isArray(person.relatives) && person.relatives.length)) {
      return null;
    }

    const relativesList = person.relatives.filter(
      relative => relative.firstname && relative.lastname
    );
    const relatives = [];

    for (
      let i = 0;
      i < relativesList.slice(0, pageCms.person_card.count_relatives).length;
      i++
    ) {
      const relative = relativesList[i];
      delete relative.middlename;
      const { firstname, lastname, state, id } = relative;
      const firstName = firstname.toLowerCase();
      const lastName = lastname.toLowerCase();
      const firstNameParam = firstName
        .replace(spaceRegEx, '_')
        .replace(dashRegEx, '');
      const lastNameParam = lastName.replace(spaceRegEx, '_');
      const url = !relative.unlink
        ? false
        : `/people/${firstNameParam}-${lastNameParam}/`;

      relatives.push({
        label: formatFullName({
          firstName: firstname,
          lastName: lastname,
          titleCase: true
        }),
        url
      });
    }

    return {
      label: 'Related to: ',
      className: 'person__info--relative',
      itempropType: 'relatedTo',
      separator: ', ',
      list: relatives
    };
  },
  /**
   * Get person list social
   * @param person
   * @returns {null|{className: string, label: string, list: string[], separator: string}}
   */
  formatPersonListSocial(person) {
    if (person.has_social_media === 0) {
      return null;
    }

    const profileText = person.has_social_media === 1 ? 'Profile' : 'Profiles';

    return {
      label: 'Social Media: ',
      className: 'person__info--social',
      separator: ', ',
      itempropType: false,
      list: [`${person.has_social_media} ${profileText} Found`]
    };
  },
  /**
   * Get the lists for the person
   * @param person
   * @returns {*[]}
   */
  getPersonLists(person) {
    const aliases = this.formatPersonListAliases(person);
    const phones = this.formatPersonListPhones(person);
    const emails = formatPersonListEmails({
      person: person,
      maskingSymbol: symbols.masking,
      count: pageCms.person_card.count_email,
      label: 'Emails: ',
      className: 'person__info--email',
      separator: ', '
    });
    const addresses = formatPersonListAddresses({
      person,
      label: 'Address History:',
      hasItemscope: true,
      separator: '; ',
      maxLocations: pageCms.person_card.count_address,
      maxFullAddresses: pageCms.person_card.count_address_street
    });
    const relatives = this.formatPersonListRelatives(person);
    const social = this.formatPersonListSocial(person);
    const list = [];

    if (aliases) {
      list.push(aliases);
    }

    if (relatives) {
      list.push(relatives);
    }

    if (phones) {
      list.push(phones);
    }

    if (social) {
      list.push(social);
    }

    if (addresses) {
      list.push(addresses);
    }

    if (emails) {
      list.push(emails);
    }

    return list;
  },
  /**
   * Return structured data for ld+json
   *
   * @param {string} addresses
   * @param {string} aliases
   * @param {string} fullName
   * @param {string} phoneNumbers
   * @param {string} profileLink
   * @param {string} relationships
   * @returns {{"@type": string, homeLocation: [], name: string, telephone: [], "@context": string, additionalName: [], url: string, relatedTo: []}}
   */
  getPersonSchema: function(
    addresses,
    aliases,
    fullName,
    phoneNumbers,
    profileLink,
    relationships
  ) {
    relationships = relationships
      ? relationships.map(relative => {
          relative['@type'] = 'Person';
          relative.url =
            relative.profile ?? `https://information.com${relative.url}`;

          // we don't want props profile and location in ld+json
          if (relative.profile) {
            delete relative.profile;
          }
          if (relative.location) {
            delete relative.location;
          }

          return relative;
        })
      : [];
    aliases = aliases
      ? aliases.split(symbols.general).map(item => item.trim())
      : [];
    phoneNumbers = phoneNumbers
      ? phoneNumbers.split(symbols.general).map(item => item.trim())
      : [];
    addresses = addresses
      ? addresses.split(symbols.address).map(address => {
          const addressParts = address
            .trim()
            .split(symbols.general)
            .map(item => item.trim());
          const noStreet = addressParts.length === 2;
          const stateZip = noStreet ? addressParts[1] : addressParts[2];
          const stateZipParts = stateZip ? stateZip.split(' ') : [];
          return {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              '@id': '',
              url: '',
              description: '',
              streetAddress: noStreet ? '' : addressParts[0],
              addressLocality: noStreet ? addressParts[0] : addressParts[1],
              addressRegion: stateZipParts[0] ? stateZipParts[0] : '',
              postalCode: stateZipParts[1] ? stateZipParts[1] : ''
            }
          };
        })
      : [];
    return {
      '@context': 'http://schema.org',
      '@type': 'Person',
      url: profileLink,
      name: fullName,
      additionalName: aliases,
      homeLocation: addresses,
      telephone: phoneNumbers,
      relatedTo: relationships
    };
  },
  getInformationCanonicalUrl: function(urlSections) {
    const { firstName, lastName, page, city, state } = urlSections;
    let url = `https://information.com/people/${firstName.toLowerCase()}-${lastName.toLowerCase()}/`;

    if (state) {
      url += `${state}/`;
    }
    if (city) {
      url += `${city}/`;
    }

    if (page > 1) {
      url += `${page}/`;
    }

    return url;
  },
  /**
   * Return a random selection of relatives with full name and location formatted for display, id and city formatted for url
   *   Used only on Profile Page
   *
   * @param {object} people
   * @returns {Array|null}
   */
  getRelativesFormattedForSection: function(people) {
    const setRelatives = [];
    // Get a randomized list of relatives
    const relatives = getRandomRelatives({ people: people });
    if (relatives) {
      // Go through each relative object
      for (let i = 0; i < relatives.length; i++) {
        const city = setTitleCase({ text: relatives[i].city }).replace(
          / /g,
          '-'
        );
        const person = {
          // Add formatted name and location
          firstname: relatives[i].firstname.toLowerCase(),
          lastname: relatives[i].lastname.toLowerCase(),
          fullName: formatFullName({
            firstName: relatives[i].firstname,
            lastName: relatives[i].lastname,
            middleName: relatives[i].middlename,
            titleCase: true
          }),
          fullLocation: `${city}, ${relatives[i].state}`,
          id: relatives[i].id || relatives[i].relative_id,
          // Add dashes instead of spaces in city
          city: city.replace(spaceRegEx, '-'),
          state: relatives[i].state,
          cid: relatives[i].cid || null
        };
        setRelatives.push(person);
      }
    }

    // Return the list
    return setRelatives;
  },
  /**
   * Formats link for SEO Schema
   *   Used only on Profile Page
   *
   * @param {object} person
   * @returns {string}
   */
  getSEOSchemaLink: function(person) {
    const paramFirstName = parseParams({
      word: person.firstname.toLowerCase(),
      parseBy: ' ',
      splitBy: ['_', '+', ' ']
    });
    const paramLastName = parseParams({
      word: person.lastname,
      parseBy: ' ',
      splitBy: ['_', '+', ' ']
    });
    const firstName = setTitleCase({ text: paramFirstName })
      .replace(spaceRegEx, '_')
      .replace(dashRegEx, '');
    const lastName = setTitleCase({ text: paramLastName }).replace(
      spaceRegEx,
      '_'
    );
    let url = `/people/${firstName}-${lastName}`;
    if (person.state) {
      const stateParam = getStateFromAbbr({
        stateAbbr: person.state,
        includeTerritories: true
      }).replace(/ /g, '-');
      url = `${url}/${stateParam}`;
    }
    if (person.state && person.city) {
      const city = setTitleCase({ text: person.city }).replace(spaceRegEx, '-');

      url = `${url}/${city}`;
    }
    if (person.state && person.city && person.id) {
      url = `${url}/${person.id}`;
    }

    return url;
  },
  /**
   * Returns formatted meta data for profile page
   *
   * @param {object} pageCMS
   * @param {object} data
   * @returns {*[]}
   */
  getSEOProfileMetaFormatted: function(pageCMS, data) {
    const transformedMeta = [];

    for (let i = 0; i < pageCMS.length; i++) {
      const metaCopy = Object.assign({}, pageCMS[i]);
      const { content } = metaCopy;
      metaCopy.content = NBars.transform(content, data);
      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Get the formatted meta data for city pages
   *
   * @param {string} city
   * @param {string} firstName
   * @param {string} lastName
   * @param {object} pageMeta
   * @param {string} page
   * @param {string} state
   * @param {string} stateAbbr
   * @param {string} stateParam
   * @param {string} total
   * @param {object} profile
   * @param {string} dataSumsStr
   * @returns {*[]}
   */
  getSEOResultsCityMetaFormatted: function(
    city,
    firstName,
    lastName,
    pageMeta,
    page,
    state,
    stateAbbr,
    stateParam,
    total,
    profile,
    dataSumsStr
  ) {
    const transformedMeta = [];
    const formattedCity = city.includes(' ') ? city.split(' ').join('-') : city;

    for (let i = 0; i < pageMeta.length; i++) {
      const metaCopy = Object.assign({}, pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
        case 'og:title':
        case 'apple-mobile-web-app-title':
        case 'keywords':
          const formattedState = formatNameCaseWithSplitterAndSpacer({
            name: stateParam.replace(/-/g, ' ')
          });
          metaCopy.content = NBars.transform(content, {
            city: formatCity({ city: city }),
            firstName,
            lastName,
            page,
            stateAbbr,
            total,
            state: formattedState,
            profile,
            dataSumsStr
          });
          break;
        case 'url':
          metaCopy.content = NBars.transform(content, {
            city,
            firstName,
            lastName,
            stateAbbr,
            state,
            stateParam
          });
          break;
        case 'og:url': {
          metaCopy.content = NBars.transform(content, {
            city: formattedCity,
            firstName: firstName.toLowerCase(),
            lastName: lastName.toLowerCase(),
            stateAbbr,
            state,
            stateParam
          });
        }
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Returns the transformed string for the city title
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @param {string} city
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} page
   * @param {string} state
   * @param {string} stateAbbr
   * @param {string} total
   * @param {object} profile
   * @returns {string}
   */
  getSEOResultsCityTitleFormatted: function(
    routeName,
    pageCMS,
    city,
    firstName,
    lastName,
    page,
    state,
    stateAbbr,
    total,
    profile
  ) {
    return NBars.transform(getSEOMetaTitleText({ routeName, pageCMS }), {
      city: formatCity({ city: city }),
      firstName,
      lastName,
      page,
      state,
      stateAbbr,
      total,
      profile
    });
  },
  /**
   * Return formatted meta list for seo root results
   *
   * @param {string} firstName
   * @param {string} lastName
   * @param {array} locationList
   * @param {object} pageMeta
   * @param {string} page
   * @param {number} total
   * @param {object} metaAlternatives
   * @param {array} locationsCountSort
   * @param {object} profile
   * @param {string} dataSumsStr
   * @returns {*[]}
   */
  getSEOResultsRootMetaFormatted: function(
    firstName,
    lastName,
    locationList,
    pageMeta,
    page,
    total,
    metaAlternatives,
    locationsCountSort,
    profile,
    dataSumsStr
  ) {
    // if there are no other locations, add a period at the end
    const locationsText = !otherLocationsCount({ locationList: locationList })
      ? `${formattedLocations({ locationList: locationsCountSort })}`
      : formattedLocations({ locationList: locationsCountSort });

    const otherLocationsText = !otherLocationsCount({
      locationList: locationList
    })
      ? ''
      : ` and ${otherLocationsCount({
          locationList: locationList
        })} other states`;

    const transformedMeta = [];

    for (let i = 0; i < pageMeta.length; i++) {
      const metaCopy = Object.assign({}, pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
          metaCopy.content = NBars.transform(content, {
            firstName: firstName,
            lastName: lastName,
            page,
            total,
            locations: locationsText,
            otherLocations: otherLocationsText,
            dataSumsStr
          });
          break;
        case 'og:title':
        case 'apple-mobile-web-app-title':
          metaCopy.content = NBars.transform(content, {
            firstName: firstName,
            lastName: lastName,
            page,
            total,
            profile
          });
          break;
        case 'keywords':
          metaCopy.content = NBars.transform(content, {
            firstName,
            lastName
          });
          break;
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            firstName: firstName.toLowerCase(),
            lastName: lastName.toLowerCase()
          });
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Returns the transformed string for the root title
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} page
   * @param {string} total
   * @param {object} profile
   * @returns {string}
   */
  getSEOResultsRootTitleFormatted: function(
    routeName,
    pageCMS,
    firstName,
    lastName,
    page,
    total,
    profile
  ) {
    return NBars.transform(getSEOMetaTitleText({ routeName, pageCMS }), {
      firstName,
      lastName,
      page,
      total,
      profile
    });
  },
  /**
   * Returns the transformed string for the state title
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} page
   * @param {string} state
   * @param {string} stateAbbr
   * @param {string} total
   * @param {object} profile
   * @returns {string}
   */
  getSEOResultsStateTitleFormatted: function(
    routeName,
    pageCMS,
    firstName,
    lastName,
    page,
    state,
    stateAbbr,
    total,
    profile
  ) {
    return NBars.transform(getSEOMetaTitleText({ routeName, pageCMS }), {
      firstName,
      lastName,
      page,
      state,
      stateAbbr,
      total,
      profile
    });
  },
  /**
   * Returns formatted meta list for SEO state results
   *
   * @param {number} cityCount
   * @param {string} firstName
   * @param {string} lastName
   * @param {object} pageMeta
   * @param {string} page
   * @param {string} stateAbbr
   * @param {string} state
   * @param {string} stateParam
   * @param {string} total
   * @param {string} locationsCountSort
   * @param {array} locationList
   * @param {object} profile
   * @param {string} dataSumsStr
   * @returns {*[]}
   */
  getSEOResultsStateMetaFormatted: function(
    cityCount,
    firstName,
    lastName,
    pageMeta,
    page,
    stateAbbr,
    state,
    stateParam,
    total,
    locationsCountSort,
    locationList,
    profile,
    dataSumsStr
  ) {
    // if there are no other locations, add a period at the end
    const locationsText = !otherLocationsCount({ locationList: locationList })
      ? `${formattedLocations({ locationList: locationsCountSort })}`
      : formattedLocations({ locationList: locationsCountSort });

    const otherLocationsText = !otherLocationsCount({
      locationList: locationList
    })
      ? ''
      : ` and ${otherLocationsCount({
          locationList: locationList
        })} other cities`;
    const transformedMeta = [];

    for (let i = 0; i < pageMeta.length; i++) {
      const metaCopy = Object.assign({}, pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
          metaCopy.content = NBars.transform(content, {
            firstName,
            lastName,
            page,
            stateAbbr,
            state,
            total,
            locations: locationsText,
            otherLocations: otherLocationsText,
            dataSumsStr
          });
          break;
        case 'og:title':
        case 'apple-mobile-web-app-title':
        case 'keywords':
          metaCopy.content = NBars.transform(content, {
            firstName,
            lastName,
            page,
            stateAbbr,
            state,
            total,
            profile
          });
          break;
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            firstName: firstName.toLowerCase(),
            lastName: lastName.toLowerCase(),
            stateAbbr,
            state,
            stateParam
          });
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Returns the transformed string for the state title
   *
   * @param {object} opts
   * @param {string} opts.routeName
   * @param {object} opts.pageCMS
   * @param {string} opts.phone
   * @param {string} opts.page
   * @param {string} opts.total
   * @param {string} opts.profile
   * @returns {string}
   */
  getSEOResultsPhoneTitleFormatted: function(opts = {}) {
    return NBars.transform(
      getSEOMetaTitleText({ routeName: opts.routeName, pageCMS: opts.pageCMS }),
      {
        phone: opts.phone,
        page: opts.page,
        total: opts.total,
        profile: opts.profile
      }
    );
  },
  /**
   * Returns formatted meta list for SEO phone results
   *
   * @param {object} opts
   * @param {string} opts.phone
   * @param {object} opts.pageMeta
   * @param {string} opts.page
   * @param {string} opts.total
   * @param {string} opts.dataSumsStr
   * @returns {*[]}
   */
  getSEOResultsPhoneMetaFormatted: function(opts = {}) {
    const transformedMeta = [];

    for (let i = 0; i < opts.pageMeta.length; i++) {
      const metaCopy = Object.assign({}, opts.pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
          metaCopy.content = NBars.transform(content, {
            phone: opts.phone,
            page: opts.page,

            total: opts.total,

            dataSumsStr: opts.dataSumsStr
          });
          break;
        case 'og:title':
        case 'apple-mobile-web-app-title':
        case 'keywords':
          metaCopy.content = NBars.transform(content, {
            phone: opts.phone,
            page: opts.page,
            total: opts.total,
            profile: opts.profile
          });
          break;
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            phone: opts.phone
          });
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Return a string of non-duplicate previous cities
   * @param {array} addresses
   * @param {boolean} includeState
   * @returns {string | (any | string)[]}
   */
  previousCities: function(addresses, includeState = false) {
    const previousCitiesArr = [];
    addresses.forEach(address => {
      const formattedCity = setTitleCase({ text: address.city });
      const state = address.state;
      const city = includeState ? `${formattedCity}, ${state}` : formattedCity;

      previousCitiesArr.push(city);
    });

    if (!previousCitiesArr.length) {
      return null;
    }

    return previousCitiesArr;
  },
  /**
   *
   * @param {array} results
   * @returns {(any & {count: any})[]}
   */
  updateSeoResultCounts(results) {
    if (!results || !Array.isArray(results)) {
      return [];
    }

    for (let i = 0; i < results.length; i++) {
      results[i].count = getOffsetCountMap({
        count: results[i].count,
        count_offset: countOffsets,
        limits: offsetLimits
      }).count;
      results[i].offset = true;
    }

    return results;
  }
};

export default resultHelper;

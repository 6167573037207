/**
 * @param {object} opts
 * @param {boolean} opts.isLast
 * @param {boolean} [opts.isAddress]
 * @param {string} [opts.addressSymbol]
 * @param {string|null} opts.generalSymbol
 * @returns {string}
 */
// Return separator symbol
const getSeparatorSymbol = (opts = {}) => {
  // If last item, no separator, otherwise use correct separator symbol
  if ((!opts.addressSymbol && !opts.generalSymbol) || opts.isLast) {
    return '';
  }

  if (opts.isAddress && opts.addressSymbol) {
    return opts.addressSymbol + ' ';
  }

  return opts.generalSymbol + ' ';
};

export default getSeparatorSymbol;

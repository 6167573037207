import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';

/**
 * Formats List of Addresses
 * @param {object} opts
 * @param {object} opts.person
 * @param {number} opts.maxLocations
 * @param {number} opts.maxFullAddresses
 * @param {boolean} opts.hasItemScope
 * @param {string} opts.label
 * @param {string} opts.separator
 * @param {string|null} [opts.className]
 * @returns {{hasItemscope: boolean, className, label, list: *[], separator: string}|null}
 */

const formatPersonListAddresses = (opts = {}) => {
  const {
    person,
    maxLocations,
    maxFullAddresses,
    label,
    hasItemScope,
    separator
  } = opts;
  const className = opts.className || null;

  if (!opts || !opts.person || !opts.person.addresses) {
    return null;
  }
  if (!(Array.isArray(person.addresses) && person.addresses.length)) {
    return null;
  }

  const addresses = [];
  // sort list of addresses to have current address be the first item
  const sortedAddresses = person.addresses.sort((a, b) => {
    if (a.current_address && !b.current_address) {
      return -1;
    } else if (!a.current_address && b.current_address) {
      return 1;
    } else {
      return 0;
    }
  });

  const locationsDisplayed = [];
  for (let i = 0; i < sortedAddresses.length; i++) {
    if (sortedAddresses[i].street_number === 0) {
      continue;
    }
    const address = sortedAddresses[i];
    const state = address.state;
    const city = setTitleCase({ text: address.city });
    const cityParam = city.split(' ').join('_');
    const streetItems = [
      address.street_number,
      address.street_name,
      address.street_type,
      address.street_affix
    ];
    let streetString = '';
    let streetUrl = '';
    //accounts for missing data so it doesn't cause malformed url
    streetItems.forEach((item, index) => {
      if (item && item != '') {
        streetString = index === 0 ? `${item}` : streetString + ` ${item}`;
        streetUrl =
          index === 0
            ? `${item}`
            : streetUrl +
              ` ${
                item === address.street_affix ? item.replace('#', '%7C') : item
              }`;
      }
    });
    const street = setTitleCase({ text: streetString })?.trim();
    let streetUrlFormat = setTitleCase({ text: streetUrl })?.trim();
    if (!street) {
      continue;
    }
    const streetParam = streetUrlFormat
      .split(' ')
      .join('-')
      .replace('--', '-');
    const zip = address.zip ?? null;
    const url = `/reverse-address-lookup/${streetParam}--${cityParam}-${state}/`;
    const locationString = `${city} ${state}`;
    if (i < maxFullAddresses) {
      locationsDisplayed.push(locationString);
      addresses.push({
        street,
        city,
        state,
        zip,
        url
      });
    } else if (
      !locationsDisplayed.includes(locationString) &&
      locationsDisplayed.length < maxLocations
    ) {
      locationsDisplayed.push(locationString);
      addresses.push({
        street: false,
        city,
        state,
        zip,
        url: false
      });
    }
    if (locationsDisplayed.length === maxLocations) {
      break;
    }
  }

  if (!addresses.length) {
    return null;
  }

  return {
    label: label,
    className: className,
    hasItemscope: hasItemScope,
    separator: separator,
    list: addresses
  };
};

export default formatPersonListAddresses;

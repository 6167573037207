<template>
  <div class="ic-monitoring-updates">
    <h3 class="ic-monitoring-updates__title" v-text="title" />
    <template>
      <a
        class="ic-monitoring-updates__report"
        href="/dashboard/report/people/989001540834c265b0f89617d887b432"
      >
        8 new jobs identified for Frank J Maio
      </a>

      <a
        class="ic-monitoring-updates__report"
        href="/dashboard/report/people/989001540834c265b0f89617d887b432"
      >
        8 new jobs identified for Frank J Maio
      </a>

      <a
        class="ic-monitoring-updates__report"
        href="/dashboard/report/people/989001540834c265b0f89617d887b432"
      >
        8 new jobs identified for Frank J Maio
      </a>

      <a
        class="ic-monitoring-updates__button"
        :href="buttonUrl"
        v-text="buttonText"
      />
    </template>
    <!--Display when there's no updates on monitored updates-->
    <div class="ic-monitoring-updates__wrapper">
      <h4 class="ic-monitoring-updates__subtitle" v-text="secondaryTitle" />
      <p class="ic-monitoring-updates__para" v-text="secondaryParagraph" />
    </div>
    <!--Display when reports aren't being monitored-->
    <div class="ic-monitoring-updates__wrapper">
      <h4 class="ic-monitoring-updates__subtitle" v-text="tertiaryTitle" />
      <p class="ic-monitoring-updates__para" v-text="tertiaryParagraph" />
    </div>
  </div>
</template>

<script>
import renderIf from '@/assets/js/shared/helpers/renderIf';

export default {
  name: 'ic-monitoring-updates',
  props: {
    /**
     * The title of the component
     */
    title: {
      type: String
    },
    /**
     * The text to display on the main button
     */
    buttonText: {
      type: String,
      required: true
    },
    /**
     * The URL that the main button should link to
     */
    buttonUrl: {
      type: String,
      required: true
    },
    /**
     * The title of the secondary section
     */
    secondaryTitle: {
      type: String,
      required: true
    },
    /**
     * The text to display in the secondary section
     */
    secondaryParagraph: {
      type: String
    },
    /**
     * The title of the tertiary section
     */
    tertiaryTitle: {
      type: String,
      required: true
    },
    /**
     * The text to display in the tertiary section
     */
    tertiaryParagraph: {
      type: String
    },
    /**
     * An array of items to display the latest monitored report updates as a list
     */
    list: {
      type: Array
    }
  },
  data() {
    return {
      renderIf
    };
  }
};
</script>

import getDateRange from './getDateRange';

/**
 * Sorts report employers array by date range
 * @param {object} opts
 * @param {object} opts.employers
 * @param {*} opts.defaultValue
 * @param {string} opts.dateDefault
 * @returns {string}
 */
const sortEmployersByDate = (opts = {}) => {
  if (!opts || !opts.employers) {
    return opts?.defaultValue ?? [];
  }

  for (let i = 0; i < opts.employers.length; i++) {
    const employer = opts.employers[i];
    // setting first_reported if not already set to work with sort
    if (!employer.first_reported && employer.start_date) {
      employer.first_reported = employer.start_date;
    } else if (!employer.first_reported) {
      employer.first_reported = '1600-01-01';
    }
    // setting last_reported if not already set to work with sort
    if (!employer.last_reported && employer.end_date) {
      employer.last_reported = employer.end_date;
    } else if (!employer.last_reported) {
      employer.last_reported = '1600-01-01';
    }
  }

  opts.employers.sort(function(a, b) {
    return new Date(b.last_reported) - new Date(a.last_reported);
  });

  for (let i = 0; i < opts.employers.length; i++) {
    const employer = opts.employers[i];
    employer.dateRange = getDateRange({
      objWithDates: employer,
      default: opts.dateDefault
    });
  }

  opts.employers.sort(function(a, b) {
    a = a.dateRange && a.dateRange.includes('Present');
    b = b.dateRange && b.dateRange.includes('Present');
    return b - a;
  });

  return opts.employers ?? opts.defaultValue ?? [];
};

export default sortEmployersByDate;

import formatStreetOnly from './formatStreetOnly';
import formatAddressWithoutStreet from './formatAddressWithoutStreet';

/**
 * Format address for multiline display
 *
 * @param {object} opts.address
 * @param {object} opts.address
 * @param {string} opts.default
 * @returns {string}
 */
const getFormattedMultilineAddressOrUnknown = opts => {
  if (!opts || !opts.address) {
    return opts?.default ? opts.default : '';
  }
  const street = formatStreetOnly({ address: opts.address });
  const location = formatAddressWithoutStreet({ address: opts.address });

  let formattedLocation = '';
  if (street) {
    formattedLocation = street;
  }
  if (location) {
    formattedLocation +=
      formattedLocation === '' ? location : `<br/>${location}`;
  }
  return formattedLocation === '' ? opts.default : formattedLocation;
};

export default getFormattedMultilineAddressOrUnknown;
